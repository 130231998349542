import React, { useEffect } from 'react';
import { Card, Loading, Table, Badge, Alert } from '@factory5/ui-kit';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import {
  CogIcon,
  DocumentTextIcon,
  DocumentIcon,
  KeyIcon,
} from '@heroicons/react/24/outline';
import { history, pageMotion } from 'lib';
import { useStore } from 'effector-react';
import duration from 'dayjs/plugin/duration';

import { AlgoRunsRead } from 'api';

import {
  $isAlgoRunning,
  $runningResults,
  generateFx,
  fetchLogsFx,
  fetchRunningResultsFx,
  fetchVerificationFileFx,
  changeIsRunning,
} from 'features/manage';
import { $appVersion, getVersionFx } from 'features/auth/models/app.model';

import { OneColumnLayout } from 'layouts';

import './styles.css';

dayjs.extend(duration)

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 100,
  },
  {
    title: 'Инициатор',
    dataIndex: 'initiator',
    key: 'initiator',
  },
  {
    title: 'Начало расчета',
    dataIndex: 'started_at',
    key: 'started_at',
    render: (value: string) => dayjs(value).format('DD.MM.YYYY HH:mm'),
  },
  {
    title: 'Длительность',
    dataIndex: 'duration',
    key: 'duration',
    render: (_: any, record: AlgoRunsRead) => {
      if (!record.started_at || !record.finished_at) {
        return '-';
      }
      const start = dayjs(record.started_at);
      const end = dayjs(record.finished_at);

      return dayjs.duration(end.diff(start)).format('H [ч] m [мин]')
    },
  },
  {
    title: 'Состояние',
    dataIndex: 'status',
    key: 'status',
    render: (value: string) => {
      if (value === 'Ошибка') {
        return <Badge color="red">{value}</Badge>;
      }
      if (value === 'Завершено') {
        return <Badge color="emerald">{value}</Badge>;
      }
      return <Badge color="gray">{value}</Badge>;
    },
  },
  {
    title: 'Рейсы',
    dataIndex: 'trips_count',
    key: 'trips_count',
  },
  {
    title: 'Детально',
    key: 'more',
    render: (_: any, record: any) => {
      return (
        <Link
          className="font-medium text-sm text-indigo-600 hover:text-indigo-500"
          to={`/management/${record.id}`}
        >
          Подробнее
        </Link>
      );
    },
  },
];

const ManagementPage = () => {
  const isRunning = useStore($isAlgoRunning);
  const isStarting = useStore(generateFx.pending);
  const runResults = useStore($runningResults);
  const isLogsPending = useStore(fetchLogsFx.pending);
  const isTablePending = useStore(fetchRunningResultsFx.pending);
  const isVerificationFilePending = useStore(fetchVerificationFileFx.pending);
  const appVersion = useStore($appVersion);

  useEffect(() => {
    fetchRunningResultsFx({ page: 1, pageSize: 10 });
    getVersionFx();
  }, []);

  return (
    <>
      <Helmet>
        <title>ЦПД: Управление запусками оптимизатора</title>
      </Helmet>

      <OneColumnLayout>
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageMotion}
          className="flex p-6 gap-6 w-full"
        >
          <Card
            className={cx(
              'transition duration-300 cursor-pointer ease-in-out w-1/2',
              isRunning.is_running
                ? 'hover:cursor-wait animate-pulse'
                : 'hover:shadow-md hover:scale-105',
            )}
            bodyClassName="relative p-4"
            noSpace
            onClick={() => {
              if (!isRunning.is_running) {
                generateFx();
                changeIsRunning(true);
              }
            }}
          >
            <div className="flex items-center">
              <div className="p-3 bg-indigo-50 inline-block rounded-md">
                <CogIcon
                  className={cx(
                    'w-6 h-6 text-indigo-900',
                    isRunning.is_running && 'animate-spin',
                  )}
                />
              </div>
              <div className="text-md ml-4">
                {isRunning.is_running ? 'Идет расчет...' : 'Запустить расчет'}
              </div>
            </div>
            {isStarting && (
              <div className="absolute left-0 top-0 w-full h-full bg-white/90 rounded-lg">
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <Loading />
                </div>
              </div>
            )}
          </Card>

          <Card
            className={
              'hover:shadow-md hover:scale-105 transition duration-300 cursor-pointer ease-in-out w-1/2'
            }
            bodyClassName="relative p-4"
            noSpace
            onClick={() => {
              fetchLogsFx();
            }}
          >
            <div className="flex items-center">
              <div className="p-3 bg-indigo-50 inline-block rounded-md">
                <DocumentTextIcon className="w-6 h-6 text-indigo-900" />
              </div>
              <div className="text-md ml-4">Последние логи</div>
            </div>

            {isLogsPending && (
              <div className="absolute left-0 top-0 w-full h-full bg-white/90 rounded-lg">
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <Loading />
                </div>
              </div>
            )}
          </Card>
        </motion.div>
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageMotion}
          className="flex px-6 pb-6 w-full gpt-table"
        >
          <Table
            columns={columns}
            data={runResults ? runResults.objects : []}
            pending={isTablePending}
            design="simple"
            onChange={({ current }) => {
              fetchRunningResultsFx({ page: current, pageSize: 10 });
            }}
            pagination={{
              current: runResults?.page,
              total: runResults?.objects_total_count ?? 0,
            }}
          />
        </motion.div>

        <div className="flex px-6 pb-6 w-auto">
          <Alert
            type="info"
            trailing="1"
            message={`Frontend: ${
              process.env.REACT_APP_COMMIT_REF || 'No Data'
            } | Backend: ${appVersion}`}
            className="[&>*>*>*]:!text-black bg-transparent [&>*>svg]:!fill-blue-400"
          />
        </div>
      </OneColumnLayout>
    </>
  );
};

export default ManagementPage;
