import React, { createRef, useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { MapOptions, Map } from 'leaflet';
import useResizeObserver from '@react-hook/resize-observer';
import 'leaflet/dist/leaflet.css';

import './styles.css';

const tileLayerURL = `${process.env.REACT_APP_MAP_SERVICE_URL}{z}/{x}/{y}.png`;

type MapViewProps = MapOptions & {
  children?: React.ReactNode;
};

export default function MapView({
  center = [51.505, -0.09],
  zoom = 13,
  children,
}: MapViewProps) {
  const mapRef = createRef<Map>();
  const containerRef = createRef<HTMLDivElement>();

  useResizeObserver(containerRef, () => {
    mapRef.current?.invalidateSize();
  });

  useEffect(() => {
    mapRef.current?.flyTo(center, undefined, { animate: false });
  }, [center, mapRef]);

  return (
    <div ref={containerRef} className="h-full z-100">
      <MapContainer
        ref={mapRef}
        center={center}
        zoom={zoom}
        scrollWheelZoom={false}
        className="h-full"
      >
        <TileLayer url={tileLayerURL} />

        {children}
      </MapContainer>
    </div>
  );
}
