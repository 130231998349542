import { createStore, createEffect, createEvent } from 'effector';

import api, {
  ProposedTripsAutocompleteFields,
  LoadingStationsAutocompleteFields,
  CurrentTripsAutocompleteFields,
  ControlTripsAutocompleteFields,
} from 'api';

import { IAutocompleteStore } from './filters.types';

// MODELS -------------------

export const $filtersAutocomplete = createStore<IAutocompleteStore>({});

// EFFECTS -------------------

export const fetchProposedTripsFilterOptionsFx = createEffect({
  handler: async ({
    field,
    query,
  }: {
    field: ProposedTripsAutocompleteFields;
    query?: string;
  }) => {
    const { data } = await api.verification.proposedTripsAutocomplete({
      field: field,
      query,
    });

    return { data, field };
  },
});

export const fetchLoadingStationsFilterOptionsFx = createEffect({
  handler: async ({
    field,
    query,
  }: {
    field: LoadingStationsAutocompleteFields;
    query?: string;
  }) => {
    const { data } = await api.verification.loadingStationsAutocomplete({
      field: field,
      query,
    });

    return { data, field };
  },
});

export const fetchWagonDeploymentFilterOptionsFx = createEffect({
  handler: async ({
    field,
    query,
  }: {
    field: CurrentTripsAutocompleteFields;
    query?: string;
  }) => {
    const { data } = await api.verification.currentTripsAutocomplete({
      field: field,
      query,
    });

    return { data, field };
  },
});

export const fetchApproveTripsFilterOptionsFx = createEffect({
  handler: async ({
    field,
    query,
  }: {
    field: ControlTripsAutocompleteFields;
    query?: string;
  }) => {
    const { data } = await api.verification.controlTripsAutocomplete({
      field: field,
      query,
    });

    return { data, field };
  },
});

// EVENTS -------------------

export const resetAutocomplete = createEvent();

// STORE METHODS -------------------

$filtersAutocomplete.on(
  fetchProposedTripsFilterOptionsFx.doneData,
  (store, { data, field }) => {
    return { ...store, [field]: data.values as unknown as string[] };
  },
);

$filtersAutocomplete.on(
  fetchLoadingStationsFilterOptionsFx.doneData,
  (store, { data, field }) => {
    return { ...store, [field]: data.values as unknown as string[] };
  },
);

$filtersAutocomplete.on(
  fetchWagonDeploymentFilterOptionsFx.doneData,
  (store, { data, field }) => {
    return { ...store, [field]: data.values as unknown as string[] };
  },
);

$filtersAutocomplete.on(
  fetchApproveTripsFilterOptionsFx.doneData,
  (store, { data, field }) => {
    return { ...store, [field]: data.values as unknown as string[] };
  },
);

$filtersAutocomplete.reset(resetAutocomplete);
