import React from 'react';
import { Navigate } from 'react-router-dom';
import { history } from 'lib';

interface IPrivateRoute {
  children: JSX.Element;
  redirectTo?: string;
}

const PrivateRoute = ({ children, redirectTo = '/login' }: IPrivateRoute) => {
  if (!localStorage.getItem('access')) {
    return (
      <Navigate to={redirectTo} replace state={{ from: history.location }} />
    );
  }

  return children;
};

export default PrivateRoute;
