import {
  ControlTripsResponse,
  VerificationApiGetControlTripsRequest,
} from 'api';

export type ControlTripList = Pick<ControlTripsResponse, 'objects'> &
  Partial<ControlTripsResponse>;
export type ControlTripListItem = ControlTripList['objects'][number];

export type ControlTripListRequestParams =
  VerificationApiGetControlTripsRequest & {
    arrivalEstimate?: [Date | null, Date | null];
    opDuration?: [number | null, number | null];
    accessRemainingDistance?: [number | null, number | null];
  };

export enum ControlTripStatus {
  NEW = 0,
  APPROVED = 1,
  REJECTED = 2,
  PRE_APPROVED = 3,
  PRE_REJECTED = 4,
}

export enum ActionType {
  all = 'all',
  page = 'page',
  manual = 'manual',
}
