import { createEffect, createStore, createEvent } from 'effector';
import { camelCase } from 'lodash';

import api, { LoadingStationSummary } from 'api';

import { StationList, StationListRequestParams } from './stations.types';

// MODELS -------------------

export const $stationListStore = createStore<StationList>({
  objects: [],
});

export const $stationsRequestParams = createStore<StationListRequestParams>({
  page: 1,
  pageSize: 100,
});

export const $stationInfo = createStore<LoadingStationSummary | null>(null);

// EFFECTS -------------------

export const resetStationInfo = createEvent();

// EFFECTS -------------------

export const getStationListFx = createEffect({
  handler: async (params: StationListRequestParams) => {
    const formattedParams: { [k: string]: any } = Object.fromEntries(
      Object.entries(params).map(([k, v]) => [camelCase(k), v]),
    );
    const { planCompletion, ...restFilters } = {
      ...params,
      ...formattedParams,
    };

    return await api.verification.getLoadingStations({
      planCompletionGte:
        planCompletion && planCompletion[0] ? planCompletion[0] : undefined,
      planCompletionLte:
        planCompletion && planCompletion[1] ? planCompletion[1] : undefined,
      ...restFilters,
    });
  },
});

export const getStationFx = createEffect(
  async (id: number) =>
    await api.verification.getLoadingStations({ id: [id], pageSize: 1 }),
);

export const fetchStationInfoFx = createEffect(
  async (id: number) => await api.verification.getStationSummary({ pk: id }),
);

// STORE METHODS -------------------

$stationListStore.on(
  [getStationListFx.done, getStationFx.done],
  (state, { result }) => result.data,
);

$stationInfo
  .on(fetchStationInfoFx.doneData, (_, data) => data.data)
  .reset(resetStationInfo);
