// Конфигурации колонок таблицы для каждого теста по ключу

export default {
  // Все грузы из плана погрузки есть в справочнике грузов
  plan_cargoes_in_reference: [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID груза',
    },
  ],

  // Все грузы из дислокации есть в справочнике грузов
  dislocation_cargoes_in_reference: [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID груза',
    },
  ],

  // В матрице промывок есть правила промывки под все грузы из плана погрузки
  plan_target_cargoes_wash_rules: [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Груз',
    },
    {
      key: 'etsng',
      dataIndex: 'etsng',
      title: 'ЕТСНГ',
    },
  ],

  // В матрице промывок есть правила промывки под все грузы из дислокации
  dislocation_target_cargoes_wash_rules: [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Груз',
    },
    {
      key: 'etsng',
      dataIndex: 'etsng',
      title: 'ЕТСНГ',
    },
  ],

  // В матрице промывок есть правила промывки из-под всех грузов из плана погрузки
  plan_from_cargoes_wash_rules: [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Груз',
    },
    {
      key: 'etsng',
      dataIndex: 'etsng',
      title: 'ЕТСНГ',
    },
  ],

  // В матрице промывок есть правила промывки из-под всех грузов из дислокации
  dislocation_from_cargoes_wash_rules: [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Груз',
    },
    {
      key: 'etsng',
      dataIndex: 'etsng',
      title: 'ЕТСНГ',
    },
  ],

  // Для всех груженых вагонов из прогнозных рейсов указан груз
  cargo_indication_for_loaded_cars_in_result: [
    {
      key: 'no',
      dataIndex: 'no',
      title: '№ вагона',
    },
    {
      key: 'model',
      dataIndex: 'model',
      title: 'Модель',
    },
  ],

  // Ни для каких порожних вагонов из прогнозных рейсов не указан груз
  no_cargo_indication_for_empty_cars_in_result: [
    {
      key: 'no',
      dataIndex: 'no',
      title: '№ вагона',
    },
    {
      key: 'model',
      dataIndex: 'model',
      title: 'Модель',
    },
  ],

  // Все вагоны из прогнозных рейсов есть в справочнике подвижного состава
  result_cars_in_reference: [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID груза',
    },
  ],

  // Все грузы из прогнозных рейсов совместимы с моделями вагонов
  result_car_models_cargoes_compatibility: [
    {
      key: 'cargo',
      dataIndex: 'cargo',
      title: 'Груз',
      render: (record: any) => record.name,
    },
    {
      key: 'etsng',
      dataIndex: 'cargo',
      title: 'ЕТСНГ',
      render: (record: any) => record.etsng,
    },
    {
      key: 'car_models',
      dataIndex: 'car_models',
      title: 'Модели вагонов',
      render: (record: any) => record.join(', '),
    },
  ],

  // Все модели вагонов из прогнозных рейсов есть в матрице совместимости
  result_car_models_in_compatibility_table: [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Модели вагонов',
      render: (record: any) => record.join(', '),
    },
  ],

  // Все грузы из прогнозных рейсов есть в матрице совместимости
  result_cargoes_in_compatibility_table: [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Груз',
    },
    {
      key: 'etsng',
      dataIndex: 'etsng',
      title: 'ЕТСНГ',
    },
  ],

  // Первые прогнозные рейсы по всем вагонам отправляются со станции назначения из дислокации
  proposed_trips_dislocation_compliant: [
    {
      key: 'no',
      dataIndex: 'car',
      title: '№ вагона',
      render: (record: any) => record.no,
    },
    {
      key: 'model',
      dataIndex: 'car',
      title: 'Модель',
      render: (record: any) => record.model,
    },
    {
      key: 'proposed_from_station',
      dataIndex: 'proposed_from_station',
      title: 'Прогнозная станция отправления',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
    {
      key: 'expected_from_station',
      dataIndex: 'expected_from_station',
      title: 'Ожидаемая станция отправления',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
  ],

  // Все вагоны из дислокации присутствуют в прогнозных рейсах
  dislocation_cars_in_result: [
    {
      key: 'no',
      dataIndex: 'no',
      title: '№ вагона',
    },
    {
      key: 'model',
      dataIndex: 'model',
      title: 'Модель',
    },
  ],

  // Все вагоны из прогнозных рейсов присутствуют в дислокации
  result_cars_in_dislocation: [
    {
      key: 'no',
      dataIndex: 'no',
      title: '№ вагона',
    },
    {
      key: 'model',
      dataIndex: 'model',
      title: 'Модель',
    },
  ],

  // Груз из дислокации соответствует предыдущему грузу первого прогнозного рейса
  proposed_cargoes_dislocation_compliant: [
    {
      key: 'no',
      dataIndex: 'car',
      title: '№ вагона',
      render: (record: any) => record.no,
    },
    {
      key: 'model',
      dataIndex: 'car',
      title: 'Модель',
      render: (record: any) => record.model,
    },
    {
      key: 'dislocation_cargo',
      dataIndex: 'dislocation_cargo',
      title: 'Груз в дислокации',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
    {
      key: 'proposed_cargo',
      dataIndex: 'proposed_cargo',
      title: 'Пред. груз в прогнозе',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
  ],

  // Все вагоны выполняют операцию погрузки хотя бы один раз
  cars_have_loading_operations: [
    {
      key: 'no',
      dataIndex: 'no',
      title: '№ вагона',
    },
    {
      key: 'model',
      dataIndex: 'model',
      title: 'Модель',
    },
  ],

  // У всех вагонов соблюдается очередность операций погрузки и выгрузки
  unloading_after_loading: [
    {
      key: 'no',
      dataIndex: 'no',
      title: '№ вагона',
    },
    {
      key: 'model',
      dataIndex: 'model',
      title: 'Модель',
    },
  ],

  // Вагоны идут на филиальную станцию только после разгрузки
  branch_station_after_unloading: [
    {
      key: 'no',
      dataIndex: 'no',
      title: '№ вагона',
    },
    {
      key: 'model',
      dataIndex: 'model',
      title: 'Модель',
    },
  ],

  // Вагоны по операции отправки на филиал идут на филиальную станцию
  branch_station_test: [
    {
      key: 'no',
      dataIndex: 'car',
      title: '№ вагона',
      render: (record: any) => record.no,
    },
    {
      key: 'model',
      dataIndex: 'car',
      title: 'Модель',
      render: (record: any) => record.model,
    },
    {
      key: 'to_station',
      dataIndex: 'to_station',
      title: 'Станция назначения',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
  ],

  // Вагоны по операции отправки в отстой идут на филиальную станцию
  parking_on_branch_stations: [
    {
      key: 'no',
      dataIndex: 'car',
      title: '№ вагона',
      render: (record: any) => record.no,
    },
    {
      key: 'model',
      dataIndex: 'car',
      title: 'Модель',
      render: (record: any) => record.model,
    },
    {
      key: 'to_station',
      dataIndex: 'to_station',
      title: 'Станция назначения',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
  ],

  // План погрузки выполняется полностью
  loading_plan_fulfilled: [
    {
      key: 'station',
      dataIndex: 'station',
      title: 'Станция отправления',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
    {
      key: 'cargo',
      dataIndex: 'cargo',
      title: 'Груз',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
    {
      key: 'cars_planned',
      dataIndex: 'cars_planned',
      title: 'План, вг',
      render: (record: any) => Intl.NumberFormat('ru-RU').format(record),
    },
    {
      key: 'cars_fact',
      dataIndex: 'cars_fact',
      title: 'Факт, вг',
      render: (record: any) => Intl.NumberFormat('ru-RU').format(record),
    },
    {
      key: 'difference',
      title: 'Нехватка, вг',
      render: (record: any) =>
        `${Intl.NumberFormat('ru-RU').format(
          record.cars_planned - record.cars_fact,
        )}`,
    },
  ],

  // План погрузки не перевыполняется
  loading_plan_not_exceeded: [
    {
      key: 'station',
      dataIndex: 'station',
      title: 'Станция отправления',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
    {
      key: 'cargo',
      dataIndex: 'cargo',
      title: 'Груз',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
    {
      key: 'cars_planned',
      dataIndex: 'cars_planned',
      title: 'План, вг',
      render: (record: any) => Intl.NumberFormat('ru-RU').format(record),
    },
    {
      key: 'cars_fact',
      dataIndex: 'cars_fact',
      title: 'Факт, вг',
      render: (record: any) => Intl.NumberFormat('ru-RU').format(record),
    },
    {
      key: 'difference',
      title: 'Лишние, вг',
      render: (record: any) =>
        `${Intl.NumberFormat('ru-RU').format(
          record.cars_fact - record.cars_planned,
        )}`,
    },
  ],

  // Все вывозимые грузы есть в плане погрузки
  loaded_cargoes_are_planned: [
    {
      key: 'station',
      dataIndex: 'station',
      title: 'Станция отправления',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
    {
      key: 'cargo',
      dataIndex: 'cargo',
      title: 'Груз',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
  ],

  // Все грузы из плана погрузки вывозятся
  planned_cargoes_are_loaded: [
    {
      key: 'station',
      dataIndex: 'station',
      title: 'Станция отправления',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
    {
      key: 'cargo',
      dataIndex: 'cargo',
      title: 'Груз',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
  ],

  // Погрузка только на станциях из плана погрузки
  loading_on_planned_stations: [
    {
      key: 'station',
      dataIndex: 'station',
      title: 'Станция отправления',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
    {
      key: 'cargo',
      dataIndex: 'cargo',
      title: 'Груз',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
  ],

  // Соблюдаются ёмкости станций
  stations_capacities_respected: [
    {
      key: 'station',
      dataIndex: 'station',
      title: 'Станция отправления',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'Дата',
    },
    {
      key: 'capacity',
      dataIndex: 'capacity',
      title: 'Номинальная емкость вг/сут',
    },
    {
      key: 'req_capacity',
      dataIndex: 'req_capacity',
      title: 'Требуемая емкость вг/сут',
    },
    {
      key: 'difference',
      title: 'Превышение вг/сут',
      render: (record: any) =>
        Intl.NumberFormat('ru-RU').format(
          record.req_capacity - record.capacity,
        ),
    },
  ],

  // Для всех ППС указана пропускная способность (мощность)
  washing_stations_have_capacity_configured: [
    {
      key: 'station',
      title: 'ППС',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
  ],

  // Пропускная способность (мощность) всех ППС соблюдается
  washing_stations_capacity_respected: [
    {
      key: 'station',
      dataIndex: 'station',
      title: 'Станция отправления',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'Дата',
    },
    {
      key: 'capacity',
      dataIndex: 'capacity',
      title: 'Номинальная мощность вг/сут',
      render: (record: any) => Intl.NumberFormat('ru-RU').format(record),
    },
    {
      key: 'req_capacity',
      dataIndex: 'req_capacity',
      title: 'Требуемая мощность вг/сут',
      render: (record: any) => Intl.NumberFormat('ru-RU').format(record),
    },
    {
      key: 'difference',
      title: 'Превышение вг/сут',
      render: (record: any) =>
        Intl.NumberFormat('ru-RU').format(
          record.req_capacity - record.capacity,
        ),
    },
  ],

  // Для всех ремонтных депо указана пропускная способность (мощность)
  repair_stations_have_capacity_configured: [
    {
      key: 'station',
      title: 'Депо',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
  ],

  // Пропускная способность (мощность) всех ремонтных депо соблюдается
  repair_stations_capacity_respected: [
    {
      key: 'station',
      dataIndex: 'station',
      title: 'Станция отправления',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'Дата',
    },
    {
      key: 'capacity',
      dataIndex: 'capacity',
      title: 'Номинальная мощность вг/сут',
      render: (record: any) => Intl.NumberFormat('ru-RU').format(record),
    },
    {
      key: 'req_capacity',
      dataIndex: 'req_capacity',
      title: 'Требуемая мощность вг/сут',
      render: (record: any) => Intl.NumberFormat('ru-RU').format(record),
    },
    {
      key: 'difference',
      title: 'Превышение вг/сут',
      render: (record: any) =>
        Intl.NumberFormat('ru-RU').format(
          record.req_capacity - record.capacity,
        ),
    },
  ],

  // Для всех станций погрузки указана пропускная способность (мощность)
  load_stations_have_capacity_configured: [
    {
      key: 'station',
      title: 'Cтанция погрузки',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
  ],

  // Пропускная способность (мощность) всех станций погрузки соблюдается
  load_stations_capacity_respected: [
    {
      key: 'station',
      dataIndex: 'station',
      title: 'Станция отправления',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'Дата',
    },
    {
      key: 'capacity',
      dataIndex: 'capacity',
      title: 'Номинальная мощность вг/сут',
      render: (record: any) => Intl.NumberFormat('ru-RU').format(record),
    },
    {
      key: 'req_capacity',
      dataIndex: 'req_capacity',
      title: 'Требуемая мощность вг/сут',
      render: (record: any) => Intl.NumberFormat('ru-RU').format(record),
    },
    {
      key: 'difference',
      title: 'Превышение вг/сут',
      render: (record: any) =>
        Intl.NumberFormat('ru-RU').format(
          record.req_capacity - record.capacity,
        ),
    },
  ],

  // Для всех станций указана пропускная способность (мощность) на выгрузке
  unload_stations_have_capacity_configured: [
    {
      key: 'station',
      title: 'Cтанция погрузки',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
  ],

  // Пропускная способность (мощность) на выгрузке на всех станциях соблюдается
  unload_stations_capacity_respected: [
    {
      key: 'station',
      dataIndex: 'station',
      title: 'Станция отправления',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'Дата',
    },
    {
      key: 'capacity',
      dataIndex: 'capacity',
      title: 'Номинальная мощность вг/сут',
      render: (record: any) => Intl.NumberFormat('ru-RU').format(record),
    },
    {
      key: 'req_capacity',
      dataIndex: 'req_capacity',
      title: 'Требуемая мощность вг/сут',
      render: (record: any) => Intl.NumberFormat('ru-RU').format(record),
    },
    {
      key: 'difference',
      title: 'Превышение вг/сут',
      render: (record: any) =>
        Intl.NumberFormat('ru-RU').format(
          record.req_capacity - record.capacity,
        ),
    },
  ],

  // Все неиспользуемые вагоны отгоняются на филиал
  unused_cars_go_to_branch_stations: [
    {
      key: 'no',
      title: '№ вагона',
      render: (record: any) => record.no,
    },
    {
      key: 'model',
      title: 'Модель',
      render: (record: any) => record.model,
    },
  ],

  // Все рейсы отбывают в пределах горизонта планирования
  departure_within_opt_period: [
    {
      key: 'no',
      dataIndex: 'car',
      title: '№ вагона',
      render: (record: any) => record.no,
    },
    {
      key: 'model',
      dataIndex: 'car',
      title: 'Модель',
      render: (record: any) => record.model,
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'Дата отпр.',
    },
  ],

  // Все вагоны при необходимости промываются
  mandatory_wash_executed: [
    {
      key: 'no',
      dataIndex: 'car',
      title: '№ вагона',
      render: (record: any) => record.no,
    },
    {
      key: 'model',
      dataIndex: 'car',
      title: 'Модель',
      render: (record: any) => record.model,
    },
    {
      key: 'from_cargo',
      dataIndex: 'from_cargo',
      title: 'Предыдущий груз',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
    {
      key: 'target_cargo',
      dataIndex: 'target_cargo',
      title: 'Следующий груз',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
  ],

  // Никакие вагоны не промываются без необходимости
  no_optional_wash_executed: [
    {
      key: 'no',
      dataIndex: 'car',
      title: '№ вагона',
      render: (record: any) => record.no,
    },
    {
      key: 'model',
      dataIndex: 'car',
      title: 'Модель',
      render: (record: any) => record.model,
    },
    {
      key: 'from_cargo',
      dataIndex: 'from_cargo',
      title: 'Предыдущий груз',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
    {
      key: 'target_cargo',
      dataIndex: 'target_cargo',
      title: 'Следующий груз',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
  ],

  // Все вагоны перевозят только разрешённые грузы (Соблюдаются правила совместиости при смене грузов)
  permitted_cargoes_compliance: [
    {
      key: 'no',
      dataIndex: 'car',
      title: '№ вагона',
      render: (record: any) => record.no,
    },
    {
      key: 'model',
      dataIndex: 'car',
      title: 'Модель',
      render: (record: any) => record.model,
    },
    {
      key: 'from_cargo',
      dataIndex: 'from_cargo',
      title: 'Предыдущий груз',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
    {
      key: 'target_cargo',
      dataIndex: 'target_cargo',
      title: 'Следующий груз',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
  ],

  // Вагоны промываются на ППС с соответствующей функцией промывки
  wash_station_cargoes_compliant: [
    {
      key: 'no',
      dataIndex: 'car',
      title: '№ вагона',
      render: (record: any) => record.no,
    },
    {
      key: 'model',
      dataIndex: 'car',
      title: 'Модель',
      render: (record: any) => record.model,
    },
    {
      key: 'wash_station',
      dataIndex: 'wash_station',
      title: 'ППС',
      render: (record: any) => `${record.station} (${record.railway})`,
    },
    {
      key: 'from_cargo',
      dataIndex: 'from_cargo',
      title: 'Предыдущий груз',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
    {
      key: 'target_cargo',
      dataIndex: 'target_cargo',
      title: 'Следующий груз',
      render: (record: any) => `${record.name} (${record.etsng})`,
    },
  ],
};
