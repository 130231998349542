import { useStore } from 'effector-react';
import React, { useEffect } from 'react';

import { $stationsRequestParams } from 'features/stations/models/stations.model';
import {
  $stationsChartsStore,
  getStationsChartsFx,
} from 'features/stations/models/stationCharts.model';
import { $selectedFilters } from 'features/filters';

import ChartList from '../ChartList';

export default function StationCharts() {
  const stationsCharts = useStore($stationsChartsStore);
  const requestParams = useStore($stationsRequestParams);
  const filters = useStore($selectedFilters)['stations'];

  useEffect(() => {
    getStationsChartsFx({ ...requestParams, ...filters });
  }, [requestParams, filters]);

  if (stationsCharts) return <ChartList data={stationsCharts} />;

  return null;
}
