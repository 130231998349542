import React from 'react';
import cx from 'classnames';
import Icon from 'ui/Icon';
import { useNavigate } from 'react-router-dom';
import { FormattedValue, Tooltip } from 'ui';

import StationAddress from 'features/stations/components/StationAddress';
import { StationListItem as StationListItemType } from 'features/stations/models/stations.types';

export type StationListItemProps = {
  className?: string;
  station: StationListItemType;
};

// eslint-disable-next-line no-empty-pattern
export default function StationListItem({
  station,
  className,
}: StationListItemProps) {
  const navigate = useNavigate();

  const {
    railway: { name: railwayName },
    name: stationName,
    loaded_cars,
    planned_cars,
    code6,
    delays,
    abandoned_cars,
    backlog,
    plan_completion,
    address_75,
  } = station;

  return (
    <div
      className={cx('p-4 flex flex-col gap-3', className)}
      onClick={() => navigate('/stations/' + station.id)}
    >
      <div className="flex flex-row items-center">
        <div className="flex flex-col flex-1">
          <StationAddress
            station={stationName}
            road={railwayName}
            className="grow gap-0.5"
          />
          <div className="text-xs text-slate-500 mt-1">{code6}</div>
        </div>

        <div className="flex flex-row gap-1.5 grow-0">
          {address_75 && (
            <Tooltip content="75 адрес">
              <div>
                <Icon icon="code75" size={18} />
              </div>
            </Tooltip>
          )}
          {abandoned_cars && (
            <Tooltip content="Брошенные вагоны">
              <div>
                <Icon icon="wagon" size={18} />
              </div>
            </Tooltip>
          )}
          {backlog && (
            <Tooltip content="Отставание от плана">
              <div>
                <Icon icon="wait" size={16} />
              </div>
            </Tooltip>
          )}
          {delays && (
            <Tooltip content="Простои">
              <div>
                <Icon icon="clock-outline" size={16} />
              </div>
            </Tooltip>
          )}
        </div>
      </div>

      <div className="text-xs text-gray-500">
        {planned_cars !== null ? <FormattedValue value={planned_cars} /> : '-'}
        {' / '}
        {loaded_cars !== null ? <FormattedValue value={loaded_cars} /> : '-'}
        {` (${plan_completion !== null ? plan_completion : '-'}%)`}
      </div>
    </div>
  );
}
