import React, { useState, useEffect } from 'react';
import { Collapse, Button, Modal, Table } from '@factory5/ui-kit';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import { Verification as VerificationType } from 'api/generated';

import tableSchema from './tableConfigs';

// Расположено в порядке вывода в интерфейс
const categories = [
  {
    id: 9,
    title: 'План погрузки',
  },
  {
    id: 6,
    title: 'Ограничения емкости и мощности',
  },
  {
    id: 5,
    title: 'Горизонт планирования',
  },
  {
    id: 2,
    title: 'Промывка',
  },
  {
    id: 1,
    title: 'Совместимость грузов',
  },
  {
    id: 3,
    title: 'Дислокация',
  },
  {
    id: 4,
    title: 'Жизненный цикла вагона',
  },
  {
    id: 8,
    title: 'Справочники',
  },
  {
    id: 7,
    title: 'Отправка вагонов на филиальные станции',
  },
];

const tests = {
  plan_cargoes_in_reference:
    'Все грузы из плана погрузки есть в справочнике грузов',
  dislocation_cargoes_in_reference:
    'Все грузы из дислокации есть в справочнике грузов',
  plan_target_cargoes_wash_rules:
    'В матрице промывок есть правила промывки под все грузы из плана погрузки',
  dislocation_target_cargoes_wash_rules:
    'В матрице промывок есть правила промывки под все грузы из дислокации',
  plan_from_cargoes_wash_rules:
    'В матрице промывок есть правила промывки из-под всех грузов из плана погрузки',
  dislocation_from_cargoes_wash_rules:
    'В матрице промывок есть правила промывки из-под всех грузов из дислокации',
  cargo_indication_for_loaded_cars_in_result:
    'Для всех груженых вагонов из прогнозных рейсов указан груз',
  no_cargo_indication_for_empty_cars_in_result:
    'Ни для каких порожних вагонов из прогнозных рейсов не указан груз',
  result_cars_in_reference:
    'Все вагоны из прогнозных рейсов есть в справочнике подвижного состава',
  result_car_models_cargoes_compatibility:
    'Все грузы из прогнозных рейсов совместимы с моделями вагонов',
  result_car_models_in_compatibility_table:
    'Все модели вагонов из прогнозных рейсов есть в матрице совместимости',
  result_cargoes_in_compatibility_table:
    'Все грузы из прогнозных рейсов есть в матрице совместимости',
  proposed_trips_dislocation_compliant:
    'Первые прогнозные рейсы по всем вагонам отправляются со станции назначения из дислокации',
  dislocation_cars_in_result:
    'Все вагоны из дислокации присутствуют в прогнозных рейсах',
  result_cars_in_dislocation:
    'Все вагоны из прогнозных рейсов присутствуют в дислокации',
  proposed_cargoes_dislocation_compliant:
    'Груз из дислокации соответствует предыдущему грузу первого прогнозного рейса',
  cars_have_loading_operations:
    'Все вагоны выполняют операцию погрузки хотя бы один раз',
  unloading_after_loading:
    'У всех вагонов соблюдается очередность операций погрузки и выгрузки',
  branch_station_after_unloading:
    'Вагоны идут на филиальную станцию только после разгрузки',
  branch_station_test:
    'Вагоны по операции отправки на филиал идут на филиальную станцию',
  parking_on_branch_stations:
    'Вагоны по операции отправки в отстой идут на филиальную станцию',
  loading_plan_fulfilled: 'План погрузки выполняется полностью',
  loading_plan_not_exceeded: 'План погрузки не перевыполняется',
  loaded_cargoes_are_planned: 'Все вывозимые грузы есть в плане погрузки',
  planned_cargoes_are_loaded: 'Все грузы из плана погрузки вывозятся',
  loading_on_planned_stations: 'Погрузка только на станциях из плана погрузки',
  stations_capacities_respected: 'Соблюдаются ёмкости станций',
  washing_stations_have_capacity_configured:
    'Для всех ППС указана пропускная способность (мощность)',
  washing_stations_capacity_respected:
    'Пропускная способность (мощность) всех ППС соблюдается',
  repair_stations_have_capacity_configured:
    'Для всех ремонтных депо указана пропускная способность (мощность)',
  repair_stations_capacity_respected:
    'Пропускная способность (мощность) всех ремонтных депо соблюдается',
  load_stations_have_capacity_configured:
    'Для всех станций погрузки указана пропускная способность (мощность)',
  load_stations_capacity_respected:
    'Пропускная способность (мощность) всех станций погрузки соблюдается',
  unload_stations_have_capacity_configured:
    'Для всех станций указана пропускная способность (мощность) на выгрузке',
  unload_stations_capacity_respected:
    'Пропускная способность (мощность) на выгрузке на всех станциях соблюдается',
  unused_cars_go_to_branch_stations:
    'Все неиспользуемые вагоны отгоняются на филиал',
  departure_within_opt_period:
    'Все рейсы отбывают в пределах горизонта планирования',
  mandatory_wash_executed: 'Все вагоны при необходимости промываются',
  no_optional_wash_executed: 'Никакие вагоны не промываются без необходимости',
  permitted_cargoes_compliance:
    'Все вагоны перевозят только разрешённые грузы (Соблюдаются правила совместиости при смене грузов)',
  wash_station_cargoes_compliant:
    'Вагоны промываются на ППС с соответствующей функцией промывки',
};

interface IVerificationProps {
  data: VerificationType;
}

const Verification = ({ data }: IVerificationProps) => {
  const [modalData, setModalData] = useState<{
    title: string;
    columns: any;
    table: any;
  }>({ title: '', columns: [], table: [] });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!showModal) {
      setTimeout(() => {
        setModalData({
          title: '',
          columns: [],
          table: [],
        });
      }, 300);
    }
  }, [showModal]);

  const getCollapse = (
    data: VerificationType,
    category: { id: number; title: string },
  ) => {
    const testKeys = (Object.keys(data) as (keyof typeof data)[]).filter(
      (key) => data[key] && data[key]?.category === category.id,
    );

    const allTestsFailsInCategory = testKeys.reduce(
      (acc, val) => acc + data[val]!.failed.length,
      0,
    );

    return (
      <Collapse
        key={category.id}
        title={
          <div className="flex items-center">
            <span>{category.title}</span>
            {allTestsFailsInCategory > 0 ? (
              <div className="ml-2 font-semibold text-red-600">
                {Intl.NumberFormat('ru-RU').format(allTestsFailsInCategory)}
              </div>
            ) : (
              <CheckCircleIcon className="w-4 h-4 text-green-500 ml-2" />
            )}
          </div>
        }
        design="simple"
        arrowPosition="left"
        noSpace
      >
        <div className="flex flex-col gap-2">
          {testKeys.map((key) => (
            <div className="flex items-start pl-8" key={key}>
              <div className="w-3/5">{tests[key]}</div>
              {data[key]?.result ? (
                <div className="font-semibold text-red-600 w-1/5 flex justify-center">
                  <CheckCircleIcon className="w-4 h-4 text-green-500" />
                </div>
              ) : (
                <>
                  <div className="font-semibold text-red-600 w-1/5 flex justify-center">
                    {Intl.NumberFormat('ru-RU').format(
                      data[key]?.failed.length as number,
                    )}
                  </div>
                  <div className="flex justify-end w-1/5">
                    <Button
                      type="link"
                      onClick={() => {
                        setModalData({
                          title: tests[key],
                          columns: tableSchema[key],
                          table: data[key]?.failed,
                        });
                        setShowModal(true);
                      }}
                    >
                      Подробнее
                    </Button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </Collapse>
    );
  };

  return (
    <>
      <div>{categories.map((category) => getCollapse(data, category))}</div>
      <Modal
        visible={showModal}
        onDismiss={() => {
          setShowModal(false);
        }}
        title="Данные, не подходящие под критерии проверки"
        width={1080}
      >
        <Table
          columns={modalData.columns}
          data={modalData.table}
          pagination={{
            total: modalData.table.length,
            pageSize: 5,
            hideOnSinglePage: true,
          }}
        />
      </Modal>
    </>
  );
};

export default Verification;
