import React, { createRef } from 'react';
import dayjs from 'dayjs';
import cx from 'classnames';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/24/outline';
import { Tooltip } from 'ui';
import { TooltipForwardedRef } from 'ui/Tooltip';

import { Time } from './types';

export type VerticalLineProps = {
  dates: string[];
  startDate?: string;
  cursor: Time;
  onClick: (time: Time) => void;
};

export default function VerticalLine({
  dates,
  startDate,
  cursor,
  onClick,
}: VerticalLineProps) {
  const tooltipRef = createRef<TooltipForwardedRef>();

  return (
    <tr className="sticky top-0 bg-white z-20">
      <th colSpan={8}></th>
      {dates.map((date) => {
        if (
          dayjs(date, 'YYYY-MM-DD').isSame(
            dayjs(startDate, 'YYYY-MM-DD'),
            'day',
          )
        ) {
          return (
            <th key={`timeline-${date}`}>
              <div
                className={cx(
                  'border-l-2 border-red-600 border-dashed cursor-pointer relative h-8 -left-0.5',
                )}
              >
                <div
                  onClick={() => {
                    onClick(cursor === Time.FUTURE ? Time.NOW : Time.FUTURE);
                    tooltipRef.current?.close();
                  }}
                >
                  <Tooltip
                    ref={tooltipRef}
                    content={
                      cursor === Time.FUTURE
                        ? 'Показать прошедшие дни'
                        : 'Скрыть прошедшие дни'
                    }
                  >
                    {cursor === Time.FUTURE ? (
                      <ArrowLongRightIcon className="w-8 text-red-600 px-1" />
                    ) : (
                      <ArrowLongLeftIcon className="w-8 text-red-600 px-1 absolute -left-9 top-0" />
                    )}
                  </Tooltip>
                </div>

                <div className="absolute -left-0.5 top-[34px] w-1 h-screen border-l-2 border-red-600 z-20 border-dashed"></div>
              </div>
            </th>
          );
        } else {
          return <th key={`timeline-${date}`}></th>;
        }
      })}
    </tr>
  );
}
