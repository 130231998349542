import React from 'react';
import { useStore } from 'effector-react';
import { Pagination, Empty, Card, Select } from '@factory5/ui-kit';

import {
  $proposedTripListRequestParams,
  $proposedTripListStore,
  changeProposedTripListRequestParams,
  getProposedTripsFx,
} from 'features/stations/models/proposedTrips.model';
import { $selectedFilters } from 'features/filters';

import ProposedTripListRow from './ProposedTripListRow';

export default function ProposedTripList() {
  const {
    objects: proposedTripList,
    page,
    page_size,
    objects_count,
  } = useStore($proposedTripListStore);
  const proposedTripListRequestParams = useStore(
    $proposedTripListRequestParams,
  );
  const filters = useStore($selectedFilters)['proposedTrips'];

  return (
    <>
      <Card bodyClassName="flex flex-col" noSpace>
        {/* HEADER */}
        {/* <div className="py-3 px-6 border-b border-slate-100"></div> */}

        {/* BODY LIST */}
        <div>
          {proposedTripList.length > 0 ? (
            proposedTripList.map((proposedTrip) => (
              <div
                key={`${proposedTrip.id}`}
                className="border-b border-slate-100"
              >
                <ProposedTripListRow proposedTrip={proposedTrip} />
              </div>
            ))
          ) : (
            <div className="py-5">
              <Empty />
            </div>
          )}
        </div>
      </Card>

      <div className="mt-2 flex flex-col items-center relative">
        {proposedTripList.length > 0 && (
          <>
            <Pagination
              current={page}
              hideOnSinglePage={true}
              pageSize={page_size}
              total={objects_count || 0}
              onChange={(page) => {
                changeProposedTripListRequestParams({
                  page: page.current,
                });
                getProposedTripsFx({
                  ...proposedTripListRequestParams,
                  page: page.current,
                  pageSize: Number(page_size),
                  ...filters,
                });
              }}
            />
            <div className="absolute right-0 w-20">
              <Select
                search={false}
                options={[
                  {
                    label: '25',
                    value: 25,
                  },
                  {
                    label: '50',
                    value: 50,
                  },
                  {
                    label: '100',
                    value: 100,
                  },
                ]}
                value={proposedTripListRequestParams.pageSize}
                dropdownClassName="bottom-12"
                onSelect={(pageSize) =>
                  changeProposedTripListRequestParams({
                    page: 1,
                    pageSize: Number(pageSize),
                  })
                }
                editable={false}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
