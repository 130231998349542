import { ChartPieIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart as RePieChart,
  PieProps as RePieProps,
  ResponsiveContainer,
  Tooltip,
  LegendProps,
} from 'recharts';

export type PieChartProps = {
  colors: string[];
  data: Array<{ name: string; value: number }>;
} & RePieProps;

export type CustomLegendProps = {} & Pick<
  LegendProps,
  'onMouseEnter' | 'onMouseLeave'
>;

const renderCustomizedLabel = ({ percent, ...props }: Record<any, any>) => {
  return (
    <text {...props} dominantBaseline="central">
      {`${(percent * 100).toFixed(percent < 0.01 ? 1 : 0)}%`}
    </text>
  );
};

export default function PieChart({
  data,
  colors,
  dataKey,
}: PieChartProps): React.ReactElement<PieChartProps> {
  const [legendMouseEntered, setLegendMouseEntered] = useState<
    { fill: string; value: number } | undefined
  >();

  if (
    data.length === 0 ||
    data.reduce((acc, { value }) => acc + value, 0) === 0
  ) {
    return (
      <div className="w-full h-full border bg-slate-50 flex flex-col justify-center items-center">
        <ChartPieIcon className="w-24 text-slate-300" />
        <div className="text-lg text-slate-500">Нет данных для отображения</div>
      </div>
    );
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RePieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          dataKey={dataKey}
          label={renderCustomizedLabel}
          isAnimationActive
        >
          {colors.map((color) => (
            <Cell key="color" fill={color} />
          ))}
        </Pie>

        {legendMouseEntered && (
          <g>
            <circle
              cx="50%"
              cy="50%"
              r="20%"
              fill="#fff"
              transform="translate(0, -36)"
            />
            <text
              x={'50%'}
              y={'50%'}
              textAnchor="middle"
              fill={legendMouseEntered.fill}
              fontSize={24}
              transform="translate(0, -24)"
            >
              {legendMouseEntered.value}
            </text>
          </g>
        )}

        <Legend
          height={72}
          onMouseEnter={({ payload }) => {
            if (!payload) return;
            setLegendMouseEntered({ value: payload.value, fill: payload.fill });
          }}
          onMouseLeave={() => setLegendMouseEntered(undefined)}
        />

        <Tooltip />
      </RePieChart>
    </ResponsiveContainer>
  );
}
