import { IResultBlock, LoadingType, Mode } from "features/stations/models/resultBlock.types";

export const getIndicators = (blockOptions: Partial<IResultBlock>) => {
  const indicators: {
    order: number;
    text: string;
    color: 'gray' | 'red' | 'emerald';
  }[] = [];
  if (blockOptions.resultMode === Mode.available) {
    indicators.push(
      { order: 0, text: 'Уже на станции', color: 'gray' },
      { order: 0, text: 'Подход порожних', color: 'gray' },
      { order: 0, text: 'Регулировка', color: 'gray' },
    );
  }
  if (blockOptions.resultMode === Mode.arriving) {
    indicators.push(
      { order: 0, text: 'Подход порожних', color: 'gray' },
      { order: 0, text: 'Регулировка', color: 'gray' },
    );
  }
  if (!blockOptions.withLoading && blockOptions.resultMode !== Mode.arriving) {
    indicators.push({ order: 2, text: 'Без погрузки', color: 'red' });
  }
  if (
    blockOptions.withLoading &&
    blockOptions.loadingType === LoadingType.loadingByPlan
  ) {
    indicators.push({
      order: 1,
      text: 'Погрузка по графику',
      color: 'emerald',
    });
  }
  if (
    blockOptions.withLoading &&
    blockOptions.loadingType === LoadingType.loadingByAvailable
  ) {
    indicators.push({
      order: 1,
      text: 'Погрузка по наличию',
      color: 'emerald',
    });
  }
  if (blockOptions.showShortage) {
    indicators.push({ order: 2, text: 'Нехватка/избыток', color: 'red' });
  }
  if (blockOptions.addLoadedCars) {
    indicators.push({ order: 1, text: 'Подход гружёных', color: 'emerald' });
  }
  if (blockOptions.addEmptyProposed) {
    indicators.push({ order: 1, text: 'Прогноз порожних', color: 'emerald' });
  }
  if (blockOptions.addEmptyProposed && blockOptions.addEmptyProposedApproved) {
    indicators.push({ order: 2, text: 'Одобренные рейсы', color: 'red' });
  }
  if (blockOptions.addLoadedProposed) {
    indicators.push({ order: 1, text: 'Расчётные гружёные', color: 'emerald' });
  }

  return indicators.sort((a, b) => a.order - b.order);
};