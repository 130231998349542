import { createStore, createEffect } from 'effector';

import api, { SortingFieldSchema } from 'api';

// MODELS -------------------

export const $sortFields = createStore<SortingFieldSchema[]>([]);

// EFFECTS -------------------

export const fetchSortFieldsFx = createEffect({
  handler: async () => {
    const { data } = await api.verification.proposedTripsSortingFields();
    return data.data;
  },
});

// EVENTS -------------------

// STORE METHODS -------------------

$sortFields.on(fetchSortFieldsFx.doneData, (_, data) => data);
