import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { PageHeader, Button } from '@factory5/ui-kit';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useFirstMountState } from 'hooks';
import { pageMotion, pageHeaderMotion } from 'lib';

import {
  ProposedTripList,
  $sortFields,
  getFile,
  fetchSortFieldsFx,
  Sort,
  ProposedTripsFiltersProvider,
} from 'features/stations';
import {
  fetchProposedTripsFiltersFx,
  resetAutocomplete,
} from 'features/filters';

import { OneColumnLayout } from 'layouts';

const ProposedTripsPage = () => {
  const sort = useStore($sortFields);
  const isFileDownloading = useStore(getFile.pending);
  const isFirstMount = useFirstMountState();

  useEffect(() => {
    if (isFirstMount) {
      fetchProposedTripsFiltersFx();
      fetchSortFieldsFx();
    }
    return () => {
      resetAutocomplete();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>ЦПД: Прогнозные рейсы</title>
      </Helmet>
      <OneColumnLayout
        pageHeader={
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageHeaderMotion}
          >
            <PageHeader
              title={
                <div className="flex justify-between items-center py-2">
                  <div className="text-xl">Список прогнозных рейсов</div>
                  <Button
                    type="primary"
                    size="sm"
                    icon={<ArrowDownTrayIcon />}
                    className="focus:ring-0"
                    onClick={() => {
                      getFile();
                    }}
                    pending={isFileDownloading}
                  >
                    Скачать результат
                  </Button>
                </div>
              }
              meta={
                <div className="flex justify-between items-start mt-4">
                  <ProposedTripsFiltersProvider />
                  <Sort data={sort} className="ml-5" />
                </div>
              }
            />
          </motion.div>
        }
      >
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageMotion}
        >
          <div className="py-6 flex flex-col gap-4">
            <ProposedTripList />
          </div>
        </motion.div>
      </OneColumnLayout>
    </>
  );
};

export default ProposedTripsPage;
