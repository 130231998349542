/* tslint:disable */
/* eslint-disable */
/**
 * gazpromtans
 * gazpromtans API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AlgoPhaseData
 */
export interface AlgoPhaseData {
    /**
     * 
     * @type {number}
     * @memberof AlgoPhaseData
     */
    'initial_states_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlgoPhaseData
     */
    'total_states_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlgoPhaseData
     */
    'edges_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlgoPhaseData
     */
    'compressed_initial_states_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlgoPhaseData
     */
    'compressed_total_states_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlgoPhaseData
     */
    'compressed_edges_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlgoPhaseData
     */
    'unroll_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlgoPhaseData
     */
    'optimization_time'?: number;
}
/**
 * 
 * @export
 * @interface AlgoRunRead
 */
export interface AlgoRunRead {
    /**
     * 
     * @type {string}
     * @memberof AlgoRunRead
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof AlgoRunRead
     */
    'trips_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlgoRunRead
     */
    'started_at': string;
    /**
     * 
     * @type {string}
     * @memberof AlgoRunRead
     */
    'finished_at': string;
    /**
     * 
     * @type {number}
     * @memberof AlgoRunRead
     */
    'user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlgoRunRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AlgoRunRead
     */
    'initiator': string;
    /**
     * 
     * @type {string}
     * @memberof AlgoRunRead
     */
    'duration': string;
    /**
     * 
     * @type {OptPeriod}
     * @memberof AlgoRunRead
     */
    'opt_period': OptPeriod;
    /**
     * 
     * @type {string}
     * @memberof AlgoRunRead
     */
    'dislocation_datetime': string;
    /**
     * 
     * @type {Stats}
     * @memberof AlgoRunRead
     */
    'stats'?: Stats;
    /**
     * 
     * @type {Verification}
     * @memberof AlgoRunRead
     */
    'verification'?: Verification;
    /**
     * 
     * @type {ReferenceErrors}
     * @memberof AlgoRunRead
     */
    'references_errors'?: ReferenceErrors;
    /**
     * 
     * @type {Array<DownloadPath>}
     * @memberof AlgoRunRead
     */
    'files'?: Array<DownloadPath>;
    /**
     * 
     * @type {{ [key: string]: StationReferenceData; }}
     * @memberof AlgoRunRead
     */
    'stations'?: { [key: string]: StationReferenceData; };
    /**
     * 
     * @type {OptimizationStats}
     * @memberof AlgoRunRead
     */
    'optimization_stats'?: OptimizationStats;
}
/**
 * 
 * @export
 * @interface AlgoRunsListResponse
 */
export interface AlgoRunsListResponse {
    /**
     * 
     * @type {number}
     * @memberof AlgoRunsListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AlgoRunsListResponse
     */
    'pages_count': number;
    /**
     * 
     * @type {number}
     * @memberof AlgoRunsListResponse
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof AlgoRunsListResponse
     */
    'output_size': number;
    /**
     * 
     * @type {number}
     * @memberof AlgoRunsListResponse
     */
    'objects_count': number;
    /**
     * 
     * @type {number}
     * @memberof AlgoRunsListResponse
     */
    'objects_total_count': number;
    /**
     * 
     * @type {Array<AlgoRunsRead>}
     * @memberof AlgoRunsListResponse
     */
    'objects': Array<AlgoRunsRead>;
}
/**
 * 
 * @export
 * @interface AlgoRunsRead
 */
export interface AlgoRunsRead {
    /**
     * 
     * @type {string}
     * @memberof AlgoRunsRead
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof AlgoRunsRead
     */
    'trips_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlgoRunsRead
     */
    'started_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlgoRunsRead
     */
    'finished_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof AlgoRunsRead
     */
    'user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlgoRunsRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AlgoRunsRead
     */
    'initiator': string;
}
/**
 * 
 * @export
 * @interface Arrival
 */
export interface Arrival {
    /**
     * 
     * @type {string}
     * @memberof Arrival
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof Arrival
     */
    'time': string;
    /**
     * 
     * @type {Station}
     * @memberof Arrival
     */
    'station': Station;
}
/**
 * 
 * @export
 * @interface AutocompleteResponseSchema
 */
export interface AutocompleteResponseSchema {
    /**
     * 
     * @type {Array<string>}
     * @memberof AutocompleteResponseSchema
     */
    'values': Array<string>;
}
/**
 * 
 * @export
 * @interface BackendAppSchemasTripsRailway
 */
export interface BackendAppSchemasTripsRailway {
    /**
     * 
     * @type {number}
     * @memberof BackendAppSchemasTripsRailway
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BackendAppSchemasTripsRailway
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface BackendAppSchemasVerificationRailway
 */
export interface BackendAppSchemasVerificationRailway {
    /**
     * 
     * @type {number}
     * @memberof BackendAppSchemasVerificationRailway
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BackendAppSchemasVerificationRailway
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface BearerResponse
 */
export interface BearerResponse {
    /**
     * 
     * @type {string}
     * @memberof BearerResponse
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof BearerResponse
     */
    'token_type': string;
}
/**
 * 
 * @export
 * @interface BreakdownByGroups
 */
export interface BreakdownByGroups {
    /**
     * 
     * @type {string}
     * @memberof BreakdownByGroups
     */
    'name': string;
    /**
     * 
     * @type {Array<BreakdownBySubgroups>}
     * @memberof BreakdownByGroups
     */
    'by_cargo_groups': Array<BreakdownBySubgroups>;
    /**
     * 
     * @type {number}
     * @memberof BreakdownByGroups
     */
    'total_amount': number;
}
/**
 * 
 * @export
 * @interface BreakdownBySubgroups
 */
export interface BreakdownBySubgroups {
    /**
     * 
     * @type {number}
     * @memberof BreakdownBySubgroups
     */
    'cargo_group_id': number;
    /**
     * 
     * @type {string}
     * @memberof BreakdownBySubgroups
     */
    'cargo_group_name': string;
    /**
     * 
     * @type {Array<SubgroupData>}
     * @memberof BreakdownBySubgroups
     */
    'by_cargo_subgroups'?: Array<SubgroupData>;
    /**
     * 
     * @type {number}
     * @memberof BreakdownBySubgroups
     */
    'total_amount': number;
}
/**
 * 
 * @export
 * @interface Car
 */
export interface Car {
    /**
     * 
     * @type {number}
     * @memberof Car
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Car
     */
    'no'?: number;
    /**
     * 
     * @type {CarModel}
     * @memberof Car
     */
    'model'?: CarModel;
    /**
     * 
     * @type {boolean}
     * @memberof Car
     */
    'is_rented': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Car
     */
    'rzd_code_92'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Car
     */
    'fitting_45_deg'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Car
     */
    'drain_device_prot_3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Car
     */
    'steam_jacket'?: boolean;
}
/**
 * 
 * @export
 * @interface CarCargoesChange
 */
export interface CarCargoesChange {
    /**
     * 
     * @type {CarData}
     * @memberof CarCargoesChange
     */
    'car': CarData;
    /**
     * 
     * @type {CargoData}
     * @memberof CarCargoesChange
     */
    'from_cargo': CargoData;
    /**
     * 
     * @type {CargoData}
     * @memberof CarCargoesChange
     */
    'target_cargo': CargoData;
}
/**
 * 
 * @export
 * @interface CarCargoesChangeWithStation
 */
export interface CarCargoesChangeWithStation {
    /**
     * 
     * @type {CarData}
     * @memberof CarCargoesChangeWithStation
     */
    'car': CarData;
    /**
     * 
     * @type {CargoData}
     * @memberof CarCargoesChangeWithStation
     */
    'from_cargo': CargoData;
    /**
     * 
     * @type {CargoData}
     * @memberof CarCargoesChangeWithStation
     */
    'target_cargo': CargoData;
    /**
     * 
     * @type {StationData}
     * @memberof CarCargoesChangeWithStation
     */
    'wash_station': StationData;
}
/**
 * 
 * @export
 * @interface CarData
 */
export interface CarData {
    /**
     * 
     * @type {string}
     * @memberof CarData
     */
    'no': string;
    /**
     * 
     * @type {string}
     * @memberof CarData
     */
    'model': string;
}
/**
 * 
 * @export
 * @interface CarDeparture
 */
export interface CarDeparture {
    /**
     * 
     * @type {CarData}
     * @memberof CarDeparture
     */
    'car': CarData;
    /**
     * 
     * @type {string}
     * @memberof CarDeparture
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface CarDestination
 */
export interface CarDestination {
    /**
     * 
     * @type {CarData}
     * @memberof CarDestination
     */
    'car': CarData;
    /**
     * 
     * @type {StationData}
     * @memberof CarDestination
     */
    'to_station': StationData;
}
/**
 * 
 * @export
 * @interface CarModel
 */
export interface CarModel {
    /**
     * 
     * @type {number}
     * @memberof CarModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CarModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CarModelData
 */
export interface CarModelData {
    /**
     * 
     * @type {string}
     * @memberof CarModelData
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CarModelsRead
 */
export interface CarModelsRead {
    /**
     * 
     * @type {number}
     * @memberof CarModelsRead
     */
    'sync_id': number;
    /**
     * 
     * @type {string}
     * @memberof CarModelsRead
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CarModelsRead
     */
    'axes': number;
    /**
     * 
     * @type {number}
     * @memberof CarModelsRead
     */
    'load_capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarModelsRead
     */
    'boiler_volume'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarModelsRead
     */
    'gpt_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarModelsRead
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface Cargo
 */
export interface Cargo {
    /**
     * 
     * @type {number}
     * @memberof Cargo
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Cargo
     */
    'name': string;
    /**
     * 
     * @type {Group}
     * @memberof Cargo
     */
    'group': Group;
    /**
     * 
     * @type {SubGroup}
     * @memberof Cargo
     */
    'subgroup': SubGroup;
}
/**
 * 
 * @export
 * @interface CargoAnalysis
 */
export interface CargoAnalysis {
    /**
     * 
     * @type {CarData}
     * @memberof CargoAnalysis
     */
    'car': CarData;
    /**
     * 
     * @type {CargoData}
     * @memberof CargoAnalysis
     */
    'dislocation_cargo': CargoData;
    /**
     * 
     * @type {CargoData}
     * @memberof CargoAnalysis
     */
    'proposed_cargo': CargoData;
}
/**
 * 
 * @export
 * @interface CargoCarModel
 */
export interface CargoCarModel {
    /**
     * 
     * @type {CargoData}
     * @memberof CargoCarModel
     */
    'cargo': CargoData;
    /**
     * 
     * @type {Array<string>}
     * @memberof CargoCarModel
     */
    'car_models': Array<string>;
}
/**
 * 
 * @export
 * @interface CargoData
 */
export interface CargoData {
    /**
     * 
     * @type {string}
     * @memberof CargoData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CargoData
     */
    'etsng': string;
}
/**
 * 
 * @export
 * @interface CargoGroupsRead
 */
export interface CargoGroupsRead {
    /**
     * 
     * @type {number}
     * @memberof CargoGroupsRead
     */
    'sync_id': number;
    /**
     * 
     * @type {string}
     * @memberof CargoGroupsRead
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CargoGroupsRead
     */
    'gpt_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoGroupsRead
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface CargoSubgroupsRead
 */
export interface CargoSubgroupsRead {
    /**
     * 
     * @type {number}
     * @memberof CargoSubgroupsRead
     */
    'sync_id': number;
    /**
     * 
     * @type {string}
     * @memberof CargoSubgroupsRead
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CargoSubgroupsRead
     */
    'group_id': number;
    /**
     * 
     * @type {number}
     * @memberof CargoSubgroupsRead
     */
    'gpt_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargoSubgroupsRead
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface CargoesSupplies
 */
export interface CargoesSupplies {
    /**
     * 
     * @type {StationData}
     * @memberof CargoesSupplies
     */
    'from_station': StationData;
    /**
     * 
     * @type {CargoData}
     * @memberof CargoesSupplies
     */
    'cargo': CargoData;
    /**
     * 
     * @type {number}
     * @memberof CargoesSupplies
     */
    'current_month_plan': number;
    /**
     * 
     * @type {number}
     * @memberof CargoesSupplies
     */
    'total_cars': number;
}
/**
 * 
 * @export
 * @interface CargosRead
 */
export interface CargosRead {
    /**
     * 
     * @type {number}
     * @memberof CargosRead
     */
    'sync_id': number;
    /**
     * 
     * @type {string}
     * @memberof CargosRead
     */
    'etsng': string;
    /**
     * 
     * @type {string}
     * @memberof CargosRead
     */
    'etsng6': string;
    /**
     * 
     * @type {string}
     * @memberof CargosRead
     */
    'gpt_class': string;
    /**
     * 
     * @type {string}
     * @memberof CargosRead
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CargosRead
     */
    'subgroup_id': number;
    /**
     * 
     * @type {number}
     * @memberof CargosRead
     */
    'gpt_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargosRead
     */
    'stat_load'?: number;
    /**
     * 
     * @type {number}
     * @memberof CargosRead
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface CarsRead
 */
export interface CarsRead {
    /**
     * 
     * @type {number}
     * @memberof CarsRead
     */
    'sync_id': number;
    /**
     * 
     * @type {string}
     * @memberof CarsRead
     */
    'car_no': string;
    /**
     * 
     * @type {number}
     * @memberof CarsRead
     */
    'car_model_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarsRead
     */
    'owner_id'?: number;
    /**
     * 
     * @type {OwnershipTypeEnum}
     * @memberof CarsRead
     */
    'ownership_type'?: OwnershipTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CarsRead
     */
    'fitting_45_deg'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarsRead
     */
    'drain_device_prot_3'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CarsRead
     */
    'gpt_id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CarsRead
     */
    'steam_jacket'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CarsRead
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface CarsStats
 */
export interface CarsStats {
    /**
     * 
     * @type {BreakdownByGroups}
     * @memberof CarsStats
     */
    'plan'?: BreakdownByGroups;
    /**
     * 
     * @type {BreakdownByGroups}
     * @memberof CarsStats
     */
    'available'?: BreakdownByGroups;
    /**
     * 
     * @type {BreakdownByGroups}
     * @memberof CarsStats
     */
    'arriving_empty': BreakdownByGroups;
    /**
     * 
     * @type {BreakdownByGroups}
     * @memberof CarsStats
     */
    'arriving_after_unloading': BreakdownByGroups;
    /**
     * 
     * @type {BreakdownByGroups}
     * @memberof CarsStats
     */
    'arriving_loaded': BreakdownByGroups;
    /**
     * 
     * @type {BreakdownByGroups}
     * @memberof CarsStats
     */
    'arriving_empty_proposed': BreakdownByGroups;
    /**
     * 
     * @type {BreakdownByGroups}
     * @memberof CarsStats
     */
    'arriving_loaded_proposed': BreakdownByGroups;
    /**
     * 
     * @type {BreakdownByGroups}
     * @memberof CarsStats
     */
    'arriving_empty_proposed_approved': BreakdownByGroups;
    /**
     * 
     * @type {BreakdownByGroups}
     * @memberof CarsStats
     */
    'backlog': BreakdownByGroups;
    /**
     * 
     * @type {BreakdownByGroups}
     * @memberof CarsStats
     */
    'loaded': BreakdownByGroups;
}
/**
 * 
 * @export
 * @interface ControlProposedTripStatus
 */
export interface ControlProposedTripStatus {
    /**
     * 
     * @type {number}
     * @memberof ControlProposedTripStatus
     */
    'id': number;
    /**
     * 
     * @type {StatusEnum}
     * @memberof ControlProposedTripStatus
     */
    'status': StatusEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof ControlProposedTripStatus
     */
    'reasons'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof ControlProposedTripStatus
     */
    'first_leg': boolean;
}
/**
 * 
 * @export
 * @interface ControlTripObject
 */
export interface ControlTripObject {
    /**
     * 
     * @type {ProposedTripStation}
     * @memberof ControlTripObject
     */
    'washing_station'?: ProposedTripStation;
    /**
     * 
     * @type {ProposedTripStation}
     * @memberof ControlTripObject
     */
    'parking_station'?: ProposedTripStation;
    /**
     * 
     * @type {ControlProposedTripStatus}
     * @memberof ControlTripObject
     */
    'proposed_trip_status'?: ControlProposedTripStatus;
    /**
     * 
     * @type {CurrentTripObject}
     * @memberof ControlTripObject
     */
    'current_trip': CurrentTripObject;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ControlTripsAutocompleteFields = {
    WashingStationName: 'washing_station_name',
    ParkingStationName: 'parking_station_name',
    Status: 'status',
    FromStationName: 'from_station_name',
    ToStationName: 'to_station_name',
    CargoSubgroup: 'cargo_subgroup',
    CargoGroup: 'cargo_group',
    CargoName: 'cargo_name',
    FromRailway: 'from_railway',
    ToRailway: 'to_railway',
    FromStationEsr: 'from_station_esr',
    ToStationEsr: 'to_station_esr',
    ReturnStationName: 'return_station_name',
    ReturnStationEsr: 'return_station_esr',
    CarModel: 'car_model',
    ToOperation: 'to_operation',
    CarNo: 'car_no',
    OperationStationName: 'operation_station_name',
    OperationRailway: 'operation_railway',
    OperationStationEsr: 'operation_station_esr',
    TrainNo: 'train_no'
} as const;

export type ControlTripsAutocompleteFields = typeof ControlTripsAutocompleteFields[keyof typeof ControlTripsAutocompleteFields];


/**
 * 
 * @export
 * @interface ControlTripsResponse
 */
export interface ControlTripsResponse {
    /**
     * 
     * @type {number}
     * @memberof ControlTripsResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ControlTripsResponse
     */
    'pages_count': number;
    /**
     * 
     * @type {number}
     * @memberof ControlTripsResponse
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof ControlTripsResponse
     */
    'output_size': number;
    /**
     * 
     * @type {number}
     * @memberof ControlTripsResponse
     */
    'objects_count': number;
    /**
     * 
     * @type {number}
     * @memberof ControlTripsResponse
     */
    'objects_total_count': number;
    /**
     * 
     * @type {Array<ControlTripObject>}
     * @memberof ControlTripsResponse
     */
    'objects': Array<ControlTripObject>;
    /**
     * 
     * @type {ProposedTripStation}
     * @memberof ControlTripsResponse
     */
    'proposed_station': ProposedTripStation;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlTripsResponse
     */
    'product_subgroup_names': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ControlTripsResponse
     */
    'approved_trips': number;
    /**
     * 
     * @type {number}
     * @memberof ControlTripsResponse
     */
    'rejected_trips': number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ControlTripsSectionEnum = {
    Empty: 'arriving_empty',
    Loaded: 'arriving_loaded',
    AfterUnloading: 'arriving_after_unloading',
    EmptyProposed: 'arriving_empty_proposed',
    EmptyProposedApproved: 'arriving_empty_proposed_approved',
    LoadedProposed: 'arriving_loaded_proposed'
} as const;

export type ControlTripsSectionEnum = typeof ControlTripsSectionEnum[keyof typeof ControlTripsSectionEnum];


/**
 * 
 * @export
 * @interface CurrentLoadingStation
 */
export interface CurrentLoadingStation {
    /**
     * 
     * @type {string}
     * @memberof CurrentLoadingStation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentLoadingStation
     */
    'railway_name': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentLoadingStation
     */
    'code6': string;
    /**
     * 
     * @type {number}
     * @memberof CurrentLoadingStation
     */
    'planned_ton': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentLoadingStation
     */
    'loaded_ton': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentLoadingStation
     */
    'car_count_station': number;
}
/**
 * 
 * @export
 * @interface CurrentTripCar
 */
export interface CurrentTripCar {
    /**
     * 
     * @type {string}
     * @memberof CurrentTripCar
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentTripCar
     */
    'model': string;
}
/**
 * 
 * @export
 * @interface CurrentTripCarModel
 */
export interface CurrentTripCarModel {
    /**
     * 
     * @type {string}
     * @memberof CurrentTripCarModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripCarModel
     */
    'load_capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripCarModel
     */
    'boiler_volume'?: number;
    /**
     * 
     * @type {Array<Cargo>}
     * @memberof CurrentTripCarModel
     */
    'cargos': Array<Cargo>;
}
/**
 * 
 * @export
 * @interface CurrentTripObject
 */
export interface CurrentTripObject {
    /**
     * 
     * @type {CurrentTripCar}
     * @memberof CurrentTripObject
     */
    'car': CurrentTripCar;
    /**
     * 
     * @type {string}
     * @memberof CurrentTripObject
     */
    'train_no'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripObject
     */
    'on_route': boolean;
    /**
     * 
     * @type {OperationEngLabelsEnum}
     * @memberof CurrentTripObject
     */
    'objective': OperationEngLabelsEnum;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripObject
     */
    'op_duration'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripObject
     */
    'op_duration_exceed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripObject
     */
    'speed'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripObject
     */
    'speed_norm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripObject
     */
    'speed_warning': boolean;
    /**
     * 
     * @type {string}
     * @memberof CurrentTripObject
     */
    'planned_repair'?: string;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripObject
     */
    'repair_remaining_distance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripObject
     */
    'off_schedule_repair'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripObject
     */
    'is_empty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripObject
     */
    'is_on_way': boolean;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripObject
     */
    'proposed_trips_count': number;
    /**
     * 
     * @type {UtilizationTypeEnum}
     * @memberof CurrentTripObject
     */
    'utilization_type': UtilizationTypeEnum;
    /**
     * 
     * @type {ProposedTrip}
     * @memberof CurrentTripObject
     */
    'first_leg_trip'?: ProposedTrip;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripObject
     */
    'access_remaining_distance'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripObject
     */
    'access_remaining_days'?: number;
    /**
     * 
     * @type {CurrentTripStation}
     * @memberof CurrentTripObject
     */
    'departure_station'?: CurrentTripStation;
    /**
     * 
     * @type {CurrentTripStation}
     * @memberof CurrentTripObject
     */
    'operation_station'?: CurrentTripStation;
    /**
     * 
     * @type {CurrentTripStation}
     * @memberof CurrentTripObject
     */
    'destination_station'?: CurrentTripStation;
    /**
     * 
     * @type {CurrentTripStation}
     * @memberof CurrentTripObject
     */
    'return_station'?: CurrentTripStation;
    /**
     * 
     * @type {ReturnWaybillCodeEngLabelsEnum}
     * @memberof CurrentTripObject
     */
    'return_waybill_status'?: ReturnWaybillCodeEngLabelsEnum;
    /**
     * 
     * @type {Cargo}
     * @memberof CurrentTripObject
     */
    'cargo'?: Cargo;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripObject
     */
    'wait'?: number;
    /**
     * 
     * @type {Array<SignEnum>}
     * @memberof CurrentTripObject
     */
    'signs': Array<SignEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripObject
     */
    'upcoming_repair_flag'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripObject
     */
    'repair_pending_period'?: number;
}
/**
 * 
 * @export
 * @interface CurrentTripResponse
 */
export interface CurrentTripResponse {
    /**
     * 
     * @type {CurrentTripCar}
     * @memberof CurrentTripResponse
     */
    'car': CurrentTripCar;
    /**
     * 
     * @type {string}
     * @memberof CurrentTripResponse
     */
    'train_no'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripResponse
     */
    'on_route': boolean;
    /**
     * 
     * @type {OperationEngLabelsEnum}
     * @memberof CurrentTripResponse
     */
    'objective': OperationEngLabelsEnum;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripResponse
     */
    'op_duration'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripResponse
     */
    'op_duration_exceed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripResponse
     */
    'speed'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripResponse
     */
    'speed_norm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripResponse
     */
    'speed_warning': boolean;
    /**
     * 
     * @type {string}
     * @memberof CurrentTripResponse
     */
    'planned_repair'?: string;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripResponse
     */
    'repair_remaining_distance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripResponse
     */
    'off_schedule_repair'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripResponse
     */
    'is_empty': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripResponse
     */
    'is_on_way': boolean;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripResponse
     */
    'proposed_trips_count': number;
    /**
     * 
     * @type {UtilizationTypeEnum}
     * @memberof CurrentTripResponse
     */
    'utilization_type': UtilizationTypeEnum;
    /**
     * 
     * @type {ProposedTrip}
     * @memberof CurrentTripResponse
     */
    'first_leg_trip'?: ProposedTrip;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripResponse
     */
    'access_remaining_distance'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripResponse
     */
    'access_remaining_days'?: number;
    /**
     * 
     * @type {CurrentTripStation}
     * @memberof CurrentTripResponse
     */
    'departure_station'?: CurrentTripStation;
    /**
     * 
     * @type {CurrentTripStation}
     * @memberof CurrentTripResponse
     */
    'operation_station'?: CurrentTripStation;
    /**
     * 
     * @type {CurrentTripStation}
     * @memberof CurrentTripResponse
     */
    'destination_station'?: CurrentTripStation;
    /**
     * 
     * @type {CurrentTripStation}
     * @memberof CurrentTripResponse
     */
    'return_station'?: CurrentTripStation;
    /**
     * 
     * @type {ReturnWaybillCodeEngLabelsEnum}
     * @memberof CurrentTripResponse
     */
    'return_waybill_status'?: ReturnWaybillCodeEngLabelsEnum;
    /**
     * 
     * @type {Cargo}
     * @memberof CurrentTripResponse
     */
    'cargo'?: Cargo;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripResponse
     */
    'wait'?: number;
    /**
     * 
     * @type {Array<SignEnum>}
     * @memberof CurrentTripResponse
     */
    'signs': Array<SignEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentTripResponse
     */
    'upcoming_repair_flag'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripResponse
     */
    'repair_pending_period'?: number;
    /**
     * 
     * @type {CurrentTripCarModel}
     * @memberof CurrentTripResponse
     */
    'car_model': CurrentTripCarModel;
    /**
     * 
     * @type {Array<ProposedTrip>}
     * @memberof CurrentTripResponse
     */
    'proposed_trips': Array<ProposedTrip>;
}
/**
 * 
 * @export
 * @interface CurrentTripStation
 */
export interface CurrentTripStation {
    /**
     * 
     * @type {string}
     * @memberof CurrentTripStation
     */
    'railway': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentTripStation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentTripStation
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentTripStation
     */
    'date'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const CurrentTripsAutocompleteFields = {
    FromStationName: 'from_station_name',
    ToStationName: 'to_station_name',
    CargoSubgroup: 'cargo_subgroup',
    CargoGroup: 'cargo_group',
    CargoName: 'cargo_name',
    FromRailway: 'from_railway',
    ToRailway: 'to_railway',
    FromStationEsr: 'from_station_esr',
    ToStationEsr: 'to_station_esr',
    ReturnStationName: 'return_station_name',
    ReturnStationEsr: 'return_station_esr',
    CarModel: 'car_model',
    ToOperation: 'to_operation',
    CarNo: 'car_no',
    OperationStationName: 'operation_station_name',
    OperationRailway: 'operation_railway',
    OperationStationEsr: 'operation_station_esr',
    TrainNo: 'train_no',
    UtilizationType: 'utilization_type'
} as const;

export type CurrentTripsAutocompleteFields = typeof CurrentTripsAutocompleteFields[keyof typeof CurrentTripsAutocompleteFields];


/**
 * 
 * @export
 * @interface CurrentTripsResponse
 */
export interface CurrentTripsResponse {
    /**
     * 
     * @type {number}
     * @memberof CurrentTripsResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripsResponse
     */
    'pages_count': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripsResponse
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripsResponse
     */
    'output_size': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripsResponse
     */
    'objects_count': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentTripsResponse
     */
    'objects_total_count': number;
    /**
     * 
     * @type {Array<CurrentTripObject>}
     * @memberof CurrentTripsResponse
     */
    'objects': Array<CurrentTripObject>;
}
/**
 * 
 * @export
 * @interface DateData
 */
export interface DateData {
    /**
     * 
     * @type {string}
     * @memberof DateData
     */
    'date': string;
    /**
     * 
     * @type {CarsStats}
     * @memberof DateData
     */
    'cars': CarsStats;
}
/**
 * 
 * @export
 * @interface Departure
 */
export interface Departure {
    /**
     * 
     * @type {string}
     * @memberof Departure
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof Departure
     */
    'time': string;
    /**
     * 
     * @type {Station}
     * @memberof Departure
     */
    'station': Station;
}
/**
 * 
 * @export
 * @interface Detail
 */
export interface Detail {
}
/**
 * 
 * @export
 * @interface DownloadPath
 */
export interface DownloadPath {
    /**
     * 
     * @type {string}
     * @memberof DownloadPath
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof DownloadPath
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface ErrorModel
 */
export interface ErrorModel {
    /**
     * 
     * @type {Detail}
     * @memberof ErrorModel
     */
    'detail': Detail;
}
/**
 * 
 * @export
 * @interface ErrorStatCarData
 */
export interface ErrorStatCarData {
    /**
     * 
     * @type {string}
     * @memberof ErrorStatCarData
     */
    'error': string;
    /**
     * 
     * @type {number}
     * @memberof ErrorStatCarData
     */
    'count': number;
    /**
     * 
     * @type {Array<CarData>}
     * @memberof ErrorStatCarData
     */
    'objects': Array<CarData>;
}
/**
 * 
 * @export
 * @interface ErrorStatCarDestination
 */
export interface ErrorStatCarDestination {
    /**
     * 
     * @type {string}
     * @memberof ErrorStatCarDestination
     */
    'error': string;
    /**
     * 
     * @type {number}
     * @memberof ErrorStatCarDestination
     */
    'count': number;
    /**
     * 
     * @type {Array<CarDestination>}
     * @memberof ErrorStatCarDestination
     */
    'objects': Array<CarDestination>;
}
/**
 * 
 * @export
 * @interface ErrorStatCargoData
 */
export interface ErrorStatCargoData {
    /**
     * 
     * @type {string}
     * @memberof ErrorStatCargoData
     */
    'error': string;
    /**
     * 
     * @type {number}
     * @memberof ErrorStatCargoData
     */
    'count': number;
    /**
     * 
     * @type {Array<CargoData>}
     * @memberof ErrorStatCargoData
     */
    'objects': Array<CargoData>;
}
/**
 * 
 * @export
 * @interface ErrorStatRailway
 */
export interface ErrorStatRailway {
    /**
     * 
     * @type {string}
     * @memberof ErrorStatRailway
     */
    'error': string;
    /**
     * 
     * @type {number}
     * @memberof ErrorStatRailway
     */
    'count': number;
    /**
     * 
     * @type {Array<BackendAppSchemasVerificationRailway>}
     * @memberof ErrorStatRailway
     */
    'objects': Array<BackendAppSchemasVerificationRailway>;
}
/**
 * 
 * @export
 * @interface ErrorStatStationData
 */
export interface ErrorStatStationData {
    /**
     * 
     * @type {string}
     * @memberof ErrorStatStationData
     */
    'error': string;
    /**
     * 
     * @type {number}
     * @memberof ErrorStatStationData
     */
    'count': number;
    /**
     * 
     * @type {Array<StationData>}
     * @memberof ErrorStatStationData
     */
    'objects': Array<StationData>;
}
/**
 * 
 * @export
 * @interface ErrorStatStationLoading
 */
export interface ErrorStatStationLoading {
    /**
     * 
     * @type {string}
     * @memberof ErrorStatStationLoading
     */
    'error': string;
    /**
     * 
     * @type {number}
     * @memberof ErrorStatStationLoading
     */
    'count': number;
    /**
     * 
     * @type {Array<StationLoading>}
     * @memberof ErrorStatStationLoading
     */
    'objects': Array<StationLoading>;
}
/**
 * 
 * @export
 * @interface FilterFieldSchema
 */
export interface FilterFieldSchema {
    /**
     * 
     * @type {string}
     * @memberof FilterFieldSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof FilterFieldSchema
     */
    'field': string;
    /**
     * 
     * @type {WidgetTypeEnum}
     * @memberof FilterFieldSchema
     */
    'widget_type': WidgetTypeEnum;
}
/**
 * 
 * @export
 * @interface FiltersResponseSchema
 */
export interface FiltersResponseSchema {
    /**
     * 
     * @type {Array<FilterFieldSchema>}
     * @memberof FiltersResponseSchema
     */
    'data': Array<FilterFieldSchema>;
}
/**
 * 
 * @export
 * @interface FullPlanData
 */
export interface FullPlanData {
    /**
     * 
     * @type {number}
     * @memberof FullPlanData
     */
    'month_planned_cars': number;
    /**
     * 
     * @type {number}
     * @memberof FullPlanData
     */
    'period_planned_cars': number;
    /**
     * 
     * @type {number}
     * @memberof FullPlanData
     */
    'proposed_cars': number;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface InfoSchema
 */
export interface InfoSchema {
    /**
     * 
     * @type {Array<string>}
     * @memberof InfoSchema
     */
    'domains': Array<string>;
}
/**
 * 
 * @export
 * @interface LegalEntitiesRead
 */
export interface LegalEntitiesRead {
    /**
     * 
     * @type {number}
     * @memberof LegalEntitiesRead
     */
    'sync_id': number;
    /**
     * 
     * @type {string}
     * @memberof LegalEntitiesRead
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof LegalEntitiesRead
     */
    'gpt_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LegalEntitiesRead
     */
    'id': number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const LoadedCargoAutocompleteFields = {
    Name: 'cargo_name',
    GroupName: 'cargo_group_name',
    SubgroupName: 'cargo_subgroup_name'
} as const;

export type LoadedCargoAutocompleteFields = typeof LoadedCargoAutocompleteFields[keyof typeof LoadedCargoAutocompleteFields];


/**
 * 
 * @export
 * @interface LoadingStationChartsData
 */
export interface LoadingStationChartsData {
    /**
     * 
     * @type {number}
     * @memberof LoadingStationChartsData
     */
    'cars_empty_on_way': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationChartsData
     */
    'cars_loaded_on_way': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationChartsData
     */
    'cars_empty_on_station': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationChartsData
     */
    'cars_loaded_on_station': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationChartsData
     */
    'idle_cars_less_than_3_days': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationChartsData
     */
    'idle_cars_3_5_days': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationChartsData
     */
    'idle_cars_6_9_days': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationChartsData
     */
    'idle_cars_more_than_10_days': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationChartsData
     */
    'empty_cars_abandoned': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationChartsData
     */
    'empty_cars_in_move': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationChartsData
     */
    'loaded_cars_abandoned': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationChartsData
     */
    'loaded_cars_in_move': number;
}
/**
 * 
 * @export
 * @interface LoadingStationMarkers
 */
export interface LoadingStationMarkers {
    /**
     * 
     * @type {CurrentLoadingStation}
     * @memberof LoadingStationMarkers
     */
    'current_station': CurrentLoadingStation;
    /**
     * 
     * @type {Array<LoadingStationsDirection>}
     * @memberof LoadingStationMarkers
     */
    'stations_directions': Array<LoadingStationsDirection>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const LoadingStationMarkersDirectionEnum = {
    FromCurrentStation: 'from_current_station',
    ToCurrentStation: 'to_current_station'
} as const;

export type LoadingStationMarkersDirectionEnum = typeof LoadingStationMarkersDirectionEnum[keyof typeof LoadingStationMarkersDirectionEnum];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const LoadingStationMarkersViewEnum = {
    Dislocation: 'dislocation',
    ScheduledTrips: 'scheduled_trips'
} as const;

export type LoadingStationMarkersViewEnum = typeof LoadingStationMarkersViewEnum[keyof typeof LoadingStationMarkersViewEnum];


/**
 * 
 * @export
 * @interface LoadingStationSummary
 */
export interface LoadingStationSummary {
    /**
     * 
     * @type {string}
     * @memberof LoadingStationSummary
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'plan_car_chart': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'plan_ton_chart': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'plan_car_operational'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'plan_ton_operational'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'fact_car': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'fact_ton': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'balance_car': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'balance_ton': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoadingStationSummary
     */
    'cargo_subgroups': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'available_car_station': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'approach_today': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'approach_week': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'backlog_plan_car': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'backlog_plan_percentage': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationSummary
     */
    'marked_car': number;
    /**
     * 
     * @type {Array<UnfulfilledPlanUnit>}
     * @memberof LoadingStationSummary
     */
    'unfulfilled_plan': Array<UnfulfilledPlanUnit>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const LoadingStationsAutocompleteFields = {
    Name: 'name',
    Code6: 'code6',
    RailwayName: 'railway_name',
    MarketType: 'market_type'
} as const;

export type LoadingStationsAutocompleteFields = typeof LoadingStationsAutocompleteFields[keyof typeof LoadingStationsAutocompleteFields];


/**
 * 
 * @export
 * @interface LoadingStationsDirection
 */
export interface LoadingStationsDirection {
    /**
     * 
     * @type {string}
     * @memberof LoadingStationsDirection
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoadingStationsDirection
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationsDirection
     */
    'car_count_station': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationsDirection
     */
    'car_count_transportation': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationsDirection
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationsDirection
     */
    'latitude'?: number;
}
/**
 * 
 * @export
 * @interface LoadingStationsResponse
 */
export interface LoadingStationsResponse {
    /**
     * 
     * @type {number}
     * @memberof LoadingStationsResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationsResponse
     */
    'pages_count': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationsResponse
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationsResponse
     */
    'output_size': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationsResponse
     */
    'objects_count': number;
    /**
     * 
     * @type {number}
     * @memberof LoadingStationsResponse
     */
    'objects_total_count': number;
    /**
     * 
     * @type {Array<StationObject>}
     * @memberof LoadingStationsResponse
     */
    'objects': Array<StationObject>;
}
/**
 * 
 * @export
 * @interface LocationInner
 */
export interface LocationInner {
}
/**
 * 
 * @export
 * @interface LoginSchema
 */
export interface LoginSchema {
    /**
     * 
     * @type {string}
     * @memberof LoginSchema
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginSchema
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof LoginSchema
     */
    'domain'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const MarketTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type MarketTypeEnum = typeof MarketTypeEnum[keyof typeof MarketTypeEnum];


/**
 * 
 * @export
 * @interface OperationData
 */
export interface OperationData {
    /**
     * 
     * @type {number}
     * @memberof OperationData
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof OperationData
     */
    'total_cost': number;
    /**
     * 
     * @type {number}
     * @memberof OperationData
     */
    'avg_days': number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const OperationEngLabelsEnum = {
    Parking: 'PARKING',
    Repair: 'REPAIR',
    Washing: 'WASHING',
    Loading: 'LOADING',
    Unloading: 'UNLOADING',
    Dontuse: 'DONTUSE'
} as const;

export type OperationEngLabelsEnum = typeof OperationEngLabelsEnum[keyof typeof OperationEngLabelsEnum];


/**
 * 
 * @export
 * @interface OperationsStats
 */
export interface OperationsStats {
    /**
     * 
     * @type {TransOpData}
     * @memberof OperationsStats
     */
    'goto_unload': TransOpData;
    /**
     * 
     * @type {TransOpData}
     * @memberof OperationsStats
     */
    'goto_load': TransOpData;
    /**
     * 
     * @type {TransOpData}
     * @memberof OperationsStats
     */
    'goto_wash': TransOpData;
    /**
     * 
     * @type {TransOpData}
     * @memberof OperationsStats
     */
    'goto_repair': TransOpData;
    /**
     * 
     * @type {TransOpData}
     * @memberof OperationsStats
     */
    'goto_wait': TransOpData;
    /**
     * 
     * @type {TransOpData}
     * @memberof OperationsStats
     */
    'goto_dontuse': TransOpData;
    /**
     * 
     * @type {OperationData}
     * @memberof OperationsStats
     */
    'unload': OperationData;
    /**
     * 
     * @type {OperationData}
     * @memberof OperationsStats
     */
    'load': OperationData;
    /**
     * 
     * @type {OperationData}
     * @memberof OperationsStats
     */
    'wash': OperationData;
    /**
     * 
     * @type {OperationData}
     * @memberof OperationsStats
     */
    'repair': OperationData;
    /**
     * 
     * @type {OperationData}
     * @memberof OperationsStats
     */
    'wait': OperationData;
}
/**
 * 
 * @export
 * @interface OptPeriod
 */
export interface OptPeriod {
    /**
     * 
     * @type {Period}
     * @memberof OptPeriod
     */
    'current': Period;
    /**
     * 
     * @type {Period}
     * @memberof OptPeriod
     */
    'next'?: Period;
}
/**
 * 
 * @export
 * @interface OptimizationStats
 */
export interface OptimizationStats {
    /**
     * 
     * @type {AlgoPhaseData}
     * @memberof OptimizationStats
     */
    'first_phase'?: AlgoPhaseData;
    /**
     * 
     * @type {AlgoPhaseData}
     * @memberof OptimizationStats
     */
    'second_phase'?: AlgoPhaseData;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const OwnershipTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type OwnershipTypeEnum = typeof OwnershipTypeEnum[keyof typeof OwnershipTypeEnum];


/**
 * 
 * @export
 * @interface Performance
 */
export interface Performance {
    /**
     * 
     * @type {PlanStats}
     * @memberof Performance
     */
    'current_period_stats': PlanStats;
    /**
     * 
     * @type {PlanStats}
     * @memberof Performance
     */
    'next_period_stats'?: PlanStats;
}
/**
 * 
 * @export
 * @interface Period
 */
export interface Period {
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'start_date': string;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'end_date': string;
}
/**
 * 
 * @export
 * @interface PlanData
 */
export interface PlanData {
    /**
     * 
     * @type {number}
     * @memberof PlanData
     */
    'planned_cars': number;
    /**
     * 
     * @type {number}
     * @memberof PlanData
     */
    'proposed_cars': number;
    /**
     * 
     * @type {number}
     * @memberof PlanData
     */
    'approved_cars'?: number;
}
/**
 * 
 * @export
 * @interface PlanStats
 */
export interface PlanStats {
    /**
     * 
     * @type {Period}
     * @memberof PlanStats
     */
    'period': Period;
    /**
     * 
     * @type {PlanData}
     * @memberof PlanStats
     */
    'data': PlanData;
}
/**
 * 
 * @export
 * @interface ProposedCars
 */
export interface ProposedCars {
    /**
     * 
     * @type {number}
     * @memberof ProposedCars
     */
    'used': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedCars
     */
    'unused': number;
}
/**
 * 
 * @export
 * @interface ProposedTrip
 */
export interface ProposedTrip {
    /**
     * 
     * @type {string}
     * @memberof ProposedTrip
     */
    'departure_date': string;
    /**
     * 
     * @type {string}
     * @memberof ProposedTrip
     */
    'arrival_date': string;
    /**
     * 
     * @type {StatusEngLabelsEnum}
     * @memberof ProposedTrip
     */
    'status': StatusEngLabelsEnum;
    /**
     * 
     * @type {CurrentTripStation}
     * @memberof ProposedTrip
     */
    'station': CurrentTripStation;
    /**
     * 
     * @type {OperationEngLabelsEnum}
     * @memberof ProposedTrip
     */
    'operation': OperationEngLabelsEnum;
    /**
     * 
     * @type {number}
     * @memberof ProposedTrip
     */
    'op_duration': number;
    /**
     * 
     * @type {Cargo}
     * @memberof ProposedTrip
     */
    'prev_cargo'?: Cargo;
    /**
     * 
     * @type {Cargo}
     * @memberof ProposedTrip
     */
    'cargo'?: Cargo;
}
/**
 * 
 * @export
 * @interface ProposedTripObject
 */
export interface ProposedTripObject {
    /**
     * 
     * @type {number}
     * @memberof ProposedTripObject
     */
    'id': number;
    /**
     * 
     * @type {Departure}
     * @memberof ProposedTripObject
     */
    'departure': Departure;
    /**
     * 
     * @type {Arrival}
     * @memberof ProposedTripObject
     */
    'arrival': Arrival;
    /**
     * 
     * @type {Car}
     * @memberof ProposedTripObject
     */
    'car': Car;
    /**
     * 
     * @type {OperationEngLabelsEnum}
     * @memberof ProposedTripObject
     */
    'objective': OperationEngLabelsEnum;
    /**
     * 
     * @type {Cargo}
     * @memberof ProposedTripObject
     */
    'cargo'?: Cargo;
}
/**
 * 
 * @export
 * @interface ProposedTripStation
 */
export interface ProposedTripStation {
    /**
     * 
     * @type {string}
     * @memberof ProposedTripStation
     */
    'railway': string;
    /**
     * 
     * @type {string}
     * @memberof ProposedTripStation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProposedTripStation
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProposedTripStation
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface ProposedTripVerdict
 */
export interface ProposedTripVerdict {
    /**
     * 
     * @type {VerdictActionEnum}
     * @memberof ProposedTripVerdict
     */
    'action': VerdictActionEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProposedTripVerdict
     */
    'reasons'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProposedTripVerdict
     */
    'ids'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProposedTripVerdict
     */
    'station_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProposedTripVerdict
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProposedTripVerdict
     */
    'date_gte'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProposedTripVerdict
     */
    'date_lte'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProposedTripVerdict
     */
    'subgroup_id'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProposedTripVerdict
     */
    'group_id'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ProposedTripVerdictReason
 */
export interface ProposedTripVerdictReason {
    /**
     * 
     * @type {string}
     * @memberof ProposedTripVerdictReason
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof ProposedTripVerdictReason
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface ProposedTripVerdictReasons
 */
export interface ProposedTripVerdictReasons {
    /**
     * 
     * @type {Array<ProposedTripVerdictReason>}
     * @memberof ProposedTripVerdictReasons
     */
    'data': Array<ProposedTripVerdictReason>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ProposedTripsAutocompleteFields = {
    DepartureStationName: 'departure_station_name',
    DepartureStationRailwayName: 'departure_station_railway_name',
    DepartureStationCode6: 'departure_station_code6',
    ArrivalStationName: 'arrival_station_name',
    ArrivalStationRailwayName: 'arrival_station_railway_name',
    ArrivalStationCode6: 'arrival_station_code6',
    ArrivalOperation: 'arrival_operation',
    CarModelName: 'car_model_name',
    CarNo: 'car_no',
    CargoName: 'cargo_name',
    CargoGroup: 'cargo_group',
    CargoSubgroup: 'cargo_subgroup'
} as const;

export type ProposedTripsAutocompleteFields = typeof ProposedTripsAutocompleteFields[keyof typeof ProposedTripsAutocompleteFields];


/**
 * 
 * @export
 * @interface ProposedTripsData
 */
export interface ProposedTripsData {
    /**
     * 
     * @type {number}
     * @memberof ProposedTripsData
     */
    'total_count': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedTripsData
     */
    'first_leg_count': number;
}
/**
 * 
 * @export
 * @interface ProposedTripsResponse
 */
export interface ProposedTripsResponse {
    /**
     * 
     * @type {number}
     * @memberof ProposedTripsResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedTripsResponse
     */
    'pages_count': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedTripsResponse
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedTripsResponse
     */
    'output_size': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedTripsResponse
     */
    'objects_count': number;
    /**
     * 
     * @type {number}
     * @memberof ProposedTripsResponse
     */
    'objects_total_count': number;
    /**
     * 
     * @type {Array<ProposedTripObject>}
     * @memberof ProposedTripsResponse
     */
    'objects': Array<ProposedTripObject>;
    /**
     * 
     * @type {Array<Sorting>}
     * @memberof ProposedTripsResponse
     */
    'sorting': Array<Sorting>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const RailwayMarketType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type RailwayMarketType = typeof RailwayMarketType[keyof typeof RailwayMarketType];


/**
 * 
 * @export
 * @interface RailwayRead
 */
export interface RailwayRead {
    /**
     * 
     * @type {number}
     * @memberof RailwayRead
     */
    'sync_id': number;
    /**
     * 
     * @type {string}
     * @memberof RailwayRead
     */
    'name': string;
    /**
     * 
     * @type {RailwayMarketType}
     * @memberof RailwayRead
     */
    'station_type': RailwayMarketType;
    /**
     * 
     * @type {number}
     * @memberof RailwayRead
     */
    'gpt_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RailwayRead
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface ReferenceErrors
 */
export interface ReferenceErrors {
    /**
     * 
     * @type {ReferenceErrorsBlockRailway}
     * @memberof ReferenceErrors
     */
    'railways'?: ReferenceErrorsBlockRailway;
    /**
     * 
     * @type {ReferenceErrorsBlockStationData}
     * @memberof ReferenceErrors
     */
    'stations'?: ReferenceErrorsBlockStationData;
    /**
     * 
     * @type {ReferenceErrorsBlockCarData}
     * @memberof ReferenceErrors
     */
    'cars'?: ReferenceErrorsBlockCarData;
    /**
     * 
     * @type {ReferenceErrorsBlockCarDestination}
     * @memberof ReferenceErrors
     */
    'current_trips'?: ReferenceErrorsBlockCarDestination;
    /**
     * 
     * @type {ReferenceErrorsBlockCargoData}
     * @memberof ReferenceErrors
     */
    'cargos'?: ReferenceErrorsBlockCargoData;
    /**
     * 
     * @type {ReferenceErrorsBlockStationLoading}
     * @memberof ReferenceErrors
     */
    'load_requests'?: ReferenceErrorsBlockStationLoading;
}
/**
 * 
 * @export
 * @interface ReferenceErrorsBlockCarData
 */
export interface ReferenceErrorsBlockCarData {
    /**
     * 
     * @type {Array<ErrorStatCarData>}
     * @memberof ReferenceErrorsBlockCarData
     */
    'errors': Array<ErrorStatCarData>;
}
/**
 * 
 * @export
 * @interface ReferenceErrorsBlockCarDestination
 */
export interface ReferenceErrorsBlockCarDestination {
    /**
     * 
     * @type {Array<ErrorStatCarDestination>}
     * @memberof ReferenceErrorsBlockCarDestination
     */
    'errors': Array<ErrorStatCarDestination>;
}
/**
 * 
 * @export
 * @interface ReferenceErrorsBlockCargoData
 */
export interface ReferenceErrorsBlockCargoData {
    /**
     * 
     * @type {Array<ErrorStatCargoData>}
     * @memberof ReferenceErrorsBlockCargoData
     */
    'errors': Array<ErrorStatCargoData>;
}
/**
 * 
 * @export
 * @interface ReferenceErrorsBlockRailway
 */
export interface ReferenceErrorsBlockRailway {
    /**
     * 
     * @type {Array<ErrorStatRailway>}
     * @memberof ReferenceErrorsBlockRailway
     */
    'errors': Array<ErrorStatRailway>;
}
/**
 * 
 * @export
 * @interface ReferenceErrorsBlockStationData
 */
export interface ReferenceErrorsBlockStationData {
    /**
     * 
     * @type {Array<ErrorStatStationData>}
     * @memberof ReferenceErrorsBlockStationData
     */
    'errors': Array<ErrorStatStationData>;
}
/**
 * 
 * @export
 * @interface ReferenceErrorsBlockStationLoading
 */
export interface ReferenceErrorsBlockStationLoading {
    /**
     * 
     * @type {Array<ErrorStatStationLoading>}
     * @memberof ReferenceErrorsBlockStationLoading
     */
    'errors': Array<ErrorStatStationLoading>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ResulModeEnum = {
    Available: 'available',
    Arriving: 'arriving'
} as const;

export type ResulModeEnum = typeof ResulModeEnum[keyof typeof ResulModeEnum];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ReturnWaybillCodeEngLabelsEnum = {
    Presented: 'PRESENTED'
} as const;

export type ReturnWaybillCodeEngLabelsEnum = typeof ReturnWaybillCodeEngLabelsEnum[keyof typeof ReturnWaybillCodeEngLabelsEnum];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const RoleEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type RoleEnum = typeof RoleEnum[keyof typeof RoleEnum];


/**
 * 
 * @export
 * @interface RunningAlgoExistsResponse
 */
export interface RunningAlgoExistsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RunningAlgoExistsResponse
     */
    'is_running': boolean;
    /**
     * 
     * @type {string}
     * @memberof RunningAlgoExistsResponse
     */
    'last_finished_job_finished_at'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SignEnum = {
    OwnerShipType: 'OWNER_SHIP_TYPE',
    Fitting45Deg: 'FITTING_45_DEG',
    CarRzdCode92: 'CAR_RZD_CODE_92',
    DrainDeviceProt3: 'DRAIN_DEVICE_PROT_3',
    SteamJacket: 'STEAM_JACKET',
    OnRoute: 'ON_ROUTE'
} as const;

export type SignEnum = typeof SignEnum[keyof typeof SignEnum];


/**
 * 
 * @export
 * @interface Sorting
 */
export interface Sorting {
    /**
     * 
     * @type {string}
     * @memberof Sorting
     */
    'property': string;
    /**
     * 
     * @type {string}
     * @memberof Sorting
     */
    'order': string;
}
/**
 * 
 * @export
 * @interface SortingFieldSchema
 */
export interface SortingFieldSchema {
    /**
     * 
     * @type {string}
     * @memberof SortingFieldSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SortingFieldSchema
     */
    'field': string;
}
/**
 * 
 * @export
 * @interface SortingFieldsResponseSchema
 */
export interface SortingFieldsResponseSchema {
    /**
     * 
     * @type {Array<SortingFieldSchema>}
     * @memberof SortingFieldsResponseSchema
     */
    'data': Array<SortingFieldSchema>;
}
/**
 * 
 * @export
 * @interface Station
 */
export interface Station {
    /**
     * 
     * @type {number}
     * @memberof Station
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Station
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Station
     */
    'code6': string;
    /**
     * 
     * @type {BackendAppSchemasTripsRailway}
     * @memberof Station
     */
    'railway': BackendAppSchemasTripsRailway;
}
/**
 * 
 * @export
 * @interface StationCapacityExceed
 */
export interface StationCapacityExceed {
    /**
     * 
     * @type {StationData}
     * @memberof StationCapacityExceed
     */
    'station': StationData;
    /**
     * 
     * @type {string}
     * @memberof StationCapacityExceed
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof StationCapacityExceed
     */
    'capacity': number;
    /**
     * 
     * @type {number}
     * @memberof StationCapacityExceed
     */
    'req_capacity': number;
}
/**
 * 
 * @export
 * @interface StationData
 */
export interface StationData {
    /**
     * 
     * @type {string}
     * @memberof StationData
     */
    'station': string;
    /**
     * 
     * @type {string}
     * @memberof StationData
     */
    'railway': string;
}
/**
 * 
 * @export
 * @interface StationLoading
 */
export interface StationLoading {
    /**
     * 
     * @type {StationData}
     * @memberof StationLoading
     */
    'station': StationData;
    /**
     * 
     * @type {CargoData}
     * @memberof StationLoading
     */
    'cargo': CargoData;
}
/**
 * 
 * @export
 * @interface StationObject
 */
export interface StationObject {
    /**
     * 
     * @type {number}
     * @memberof StationObject
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StationObject
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StationObject
     */
    'code6': string;
    /**
     * 
     * @type {BackendAppSchemasVerificationRailway}
     * @memberof StationObject
     */
    'railway': BackendAppSchemasVerificationRailway;
    /**
     * 
     * @type {number}
     * @memberof StationObject
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof StationObject
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof StationObject
     */
    'planned_cars': number;
    /**
     * 
     * @type {number}
     * @memberof StationObject
     */
    'loaded_cars': number;
    /**
     * 
     * @type {number}
     * @memberof StationObject
     */
    'plan_completion': number;
    /**
     * 
     * @type {boolean}
     * @memberof StationObject
     */
    'backlog': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StationObject
     */
    'abandoned_cars': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StationObject
     */
    'delays': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StationObject
     */
    'address_75': boolean;
}
/**
 * 
 * @export
 * @interface StationPerformance
 */
export interface StationPerformance {
    /**
     * 
     * @type {StationData}
     * @memberof StationPerformance
     */
    'station': StationData;
    /**
     * 
     * @type {CargoData}
     * @memberof StationPerformance
     */
    'cargo': CargoData;
    /**
     * 
     * @type {number}
     * @memberof StationPerformance
     */
    'cars_planned': number;
    /**
     * 
     * @type {number}
     * @memberof StationPerformance
     */
    'cars_fact': number;
}
/**
 * 
 * @export
 * @interface StationPlanData
 */
export interface StationPlanData {
    /**
     * 
     * @type {StationData}
     * @memberof StationPlanData
     */
    'from_station': StationData;
    /**
     * 
     * @type {FullPlanData}
     * @memberof StationPlanData
     */
    'current_period_data': FullPlanData;
    /**
     * 
     * @type {FullPlanData}
     * @memberof StationPlanData
     */
    'next_period_data': FullPlanData;
}
/**
 * 
 * @export
 * @interface StationRead
 */
export interface StationRead {
    /**
     * 
     * @type {number}
     * @memberof StationRead
     */
    'sync_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof StationRead
     */
    'code6'?: string;
    /**
     * 
     * @type {number}
     * @memberof StationRead
     */
    'gpt_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof StationRead
     */
    'railway_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof StationRead
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof StationRead
     */
    'point'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof StationRead
     */
    'is_branch': boolean;
    /**
     * 
     * @type {number}
     * @memberof StationRead
     */
    'total_capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof StationRead
     */
    'loading_power'?: number;
    /**
     * 
     * @type {string}
     * @memberof StationRead
     */
    'loading_tgnl'?: string;
    /**
     * 
     * @type {number}
     * @memberof StationRead
     */
    'nko'?: number;
    /**
     * 
     * @type {number}
     * @memberof StationRead
     */
    'unloading_power'?: number;
    /**
     * 
     * @type {string}
     * @memberof StationRead
     */
    'unloading_tgnl'?: string;
    /**
     * 
     * @type {number}
     * @memberof StationRead
     */
    'wash_power'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StationRead
     */
    'wash_tgnl'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StationRead
     */
    'repair_power'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StationRead
     */
    'repair_tgnl'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof StationRead
     */
    'address_75'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StationRead
     */
    'forbidden_subgroup_for_cars_with_prot_3'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StationRead
     */
    'is_loading_forbidden_for_cars_with_prot_3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StationRead
     */
    'is_loading_forbidden_for_cars_with_fitting_45'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StationRead
     */
    'forbidden_subgroup_for_cars_with_no_steam_jacket'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StationRead
     */
    'is_loading_forbidden_for_cars_with_steam_jacket'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StationRead
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface StationReferenceData
 */
export interface StationReferenceData {
    /**
     * 
     * @type {StationData}
     * @memberof StationReferenceData
     */
    'station': StationData;
    /**
     * 
     * @type {number}
     * @memberof StationReferenceData
     */
    'total_capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof StationReferenceData
     */
    'loading_power'?: number;
    /**
     * 
     * @type {number}
     * @memberof StationReferenceData
     */
    'unloading_power'?: number;
    /**
     * 
     * @type {number}
     * @memberof StationReferenceData
     */
    'wash_power'?: number;
    /**
     * 
     * @type {number}
     * @memberof StationReferenceData
     */
    'repair_power'?: number;
}
/**
 * 
 * @export
 * @interface StationSpeedStat
 */
export interface StationSpeedStat {
    /**
     * 
     * @type {StationData}
     * @memberof StationSpeedStat
     */
    'to_station': StationData;
    /**
     * 
     * @type {number}
     * @memberof StationSpeedStat
     */
    'empty_avg_speed': number;
    /**
     * 
     * @type {number}
     * @memberof StationSpeedStat
     */
    'loaded_avg_speed': number;
}
/**
 * 
 * @export
 * @interface Stats
 */
export interface Stats {
    /**
     * 
     * @type {Performance}
     * @memberof Stats
     */
    'performance': Performance;
    /**
     * 
     * @type {ProposedCars}
     * @memberof Stats
     */
    'proposed_cars': ProposedCars;
    /**
     * 
     * @type {Array<StationPlanData>}
     * @memberof Stats
     */
    'top_stations_data': Array<StationPlanData>;
    /**
     * 
     * @type {Array<CargoesSupplies>}
     * @memberof Stats
     */
    'cargoes_supplies': Array<CargoesSupplies>;
    /**
     * 
     * @type {ProposedTripsData}
     * @memberof Stats
     */
    'proposed_trips': ProposedTripsData;
    /**
     * 
     * @type {OperationsStats}
     * @memberof Stats
     */
    'operations_stats': OperationsStats;
    /**
     * 
     * @type {Array<StationSpeedStat>}
     * @memberof Stats
     */
    'avg_speed_to_stations': Array<StationSpeedStat>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StatusEngLabelsEnum = {
    New: 'NEW',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    PreApproved: 'PRE_APPROVED',
    PreRejected: 'PRE_REJECTED',
    Sent: 'SENT',
    Formed: 'FORMED',
    Error: 'ERROR',
    OnWay: 'ON_WAY',
    Completed: 'COMPLETED',
    RejectedByDispatcher: 'REJECTED_BY_DISPATCHER',
    OffNormalSituation: 'OFF_NORMAL_SITUATION',
    ErrorOnSending: 'ERROR_ON_SENDING'
} as const;

export type StatusEngLabelsEnum = typeof StatusEngLabelsEnum[keyof typeof StatusEngLabelsEnum];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StatusEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12
} as const;

export type StatusEnum = typeof StatusEnum[keyof typeof StatusEnum];


/**
 * 
 * @export
 * @interface SubGroup
 */
export interface SubGroup {
    /**
     * 
     * @type {number}
     * @memberof SubGroup
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SubGroup
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SubgroupData
 */
export interface SubgroupData {
    /**
     * 
     * @type {number}
     * @memberof SubgroupData
     */
    'cargo_subgroup_id': number;
    /**
     * 
     * @type {string}
     * @memberof SubgroupData
     */
    'cargo_subgroup_name': string;
    /**
     * 
     * @type {number}
     * @memberof SubgroupData
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface SupplyPlanData
 */
export interface SupplyPlanData {
    /**
     * 
     * @type {string}
     * @memberof SupplyPlanData
     */
    'dislocation_date'?: string;
    /**
     * 
     * @type {Array<DateData>}
     * @memberof SupplyPlanData
     */
    'supply_plan': Array<DateData>;
}
/**
 * 
 * @export
 * @interface SupplyRequestsRead
 */
export interface SupplyRequestsRead {
    /**
     * 
     * @type {number}
     * @memberof SupplyRequestsRead
     */
    'sync_id': number;
    /**
     * 
     * @type {MarketTypeEnum}
     * @memberof SupplyRequestsRead
     */
    'market_type': MarketTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SupplyRequestsRead
     */
    'from_date': string;
    /**
     * 
     * @type {string}
     * @memberof SupplyRequestsRead
     */
    'to_date': string;
    /**
     * 
     * @type {number}
     * @memberof SupplyRequestsRead
     */
    'from_station_id': number;
    /**
     * 
     * @type {number}
     * @memberof SupplyRequestsRead
     */
    'cargo_id': number;
    /**
     * 
     * @type {number}
     * @memberof SupplyRequestsRead
     */
    'approved_amount_ton': number;
    /**
     * 
     * @type {number}
     * @memberof SupplyRequestsRead
     */
    'current_amount_ton': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof SupplyRequestsRead
     */
    'current_schedule'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SupplyRequestsRead
     */
    'approved_schedule'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof SupplyRequestsRead
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface TransOpData
 */
export interface TransOpData {
    /**
     * 
     * @type {number}
     * @memberof TransOpData
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof TransOpData
     */
    'total_cost': number;
    /**
     * 
     * @type {number}
     * @memberof TransOpData
     */
    'avg_days': number;
    /**
     * 
     * @type {number}
     * @memberof TransOpData
     */
    'total_distance': number;
}
/**
 * 
 * @export
 * @interface TripAnalysis
 */
export interface TripAnalysis {
    /**
     * 
     * @type {CarData}
     * @memberof TripAnalysis
     */
    'car': CarData;
    /**
     * 
     * @type {StationData}
     * @memberof TripAnalysis
     */
    'proposed_from_station': StationData;
    /**
     * 
     * @type {StationData}
     * @memberof TripAnalysis
     */
    'expected_from_station': StationData;
}
/**
 * 
 * @export
 * @interface UnfulfilledPlanUnit
 */
export interface UnfulfilledPlanUnit {
    /**
     * 
     * @type {string}
     * @memberof UnfulfilledPlanUnit
     */
    'cargo': string;
    /**
     * 
     * @type {number}
     * @memberof UnfulfilledPlanUnit
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface UserRead
 */
export interface UserRead {
    /**
     * 
     * @type {number}
     * @memberof UserRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    'domain'?: string;
    /**
     * 
     * @type {RoleEnum}
     * @memberof UserRead
     */
    'role'?: RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    'display_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserRead
     */
    'is_active': boolean;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const UtilizationTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type UtilizationTypeEnum = typeof UtilizationTypeEnum[keyof typeof UtilizationTypeEnum];


/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<LocationInner>}
     * @memberof ValidationError
     */
    'loc': Array<LocationInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const VerdictActionEnum = {
    Approve: 'APPROVE',
    Decline: 'DECLINE',
    Commit: 'COMMIT',
    Reset: 'RESET'
} as const;

export type VerdictActionEnum = typeof VerdictActionEnum[keyof typeof VerdictActionEnum];


/**
 * 
 * @export
 * @interface Verification
 */
export interface Verification {
    /**
     * 
     * @type {VerificationBlockInt}
     * @memberof Verification
     */
    'plan_cargoes_in_reference'?: VerificationBlockInt;
    /**
     * 
     * @type {VerificationBlockInt}
     * @memberof Verification
     */
    'dislocation_cargoes_in_reference'?: VerificationBlockInt;
    /**
     * 
     * @type {VerificationBlockCargoData}
     * @memberof Verification
     */
    'plan_target_cargoes_wash_rules'?: VerificationBlockCargoData;
    /**
     * 
     * @type {VerificationBlockCargoData}
     * @memberof Verification
     */
    'dislocation_target_cargoes_wash_rules'?: VerificationBlockCargoData;
    /**
     * 
     * @type {VerificationBlockCargoData}
     * @memberof Verification
     */
    'plan_from_cargoes_wash_rules'?: VerificationBlockCargoData;
    /**
     * 
     * @type {VerificationBlockCargoData}
     * @memberof Verification
     */
    'dislocation_from_cargoes_wash_rules'?: VerificationBlockCargoData;
    /**
     * 
     * @type {VerificationBlockCarData}
     * @memberof Verification
     */
    'cargo_indication_for_loaded_cars_in_result'?: VerificationBlockCarData;
    /**
     * 
     * @type {VerificationBlockCarData}
     * @memberof Verification
     */
    'no_cargo_indication_for_empty_cars_in_result'?: VerificationBlockCarData;
    /**
     * 
     * @type {VerificationBlockInt}
     * @memberof Verification
     */
    'result_cars_in_reference'?: VerificationBlockInt;
    /**
     * 
     * @type {VerificationBlockCargoCarModel}
     * @memberof Verification
     */
    'result_car_models_cargoes_compatibility'?: VerificationBlockCargoCarModel;
    /**
     * 
     * @type {VerificationBlockCarModelData}
     * @memberof Verification
     */
    'result_car_models_in_compatibility_table'?: VerificationBlockCarModelData;
    /**
     * 
     * @type {VerificationBlockCargoData}
     * @memberof Verification
     */
    'result_cargoes_in_compatibility_table'?: VerificationBlockCargoData;
    /**
     * 
     * @type {VerificationBlockTripAnalysis}
     * @memberof Verification
     */
    'proposed_trips_dislocation_compliant'?: VerificationBlockTripAnalysis;
    /**
     * 
     * @type {VerificationBlockCarData}
     * @memberof Verification
     */
    'dislocation_cars_in_result'?: VerificationBlockCarData;
    /**
     * 
     * @type {VerificationBlockCarData}
     * @memberof Verification
     */
    'result_cars_in_dislocation'?: VerificationBlockCarData;
    /**
     * 
     * @type {VerificationBlockCargoAnalysis}
     * @memberof Verification
     */
    'proposed_cargoes_dislocation_compliant'?: VerificationBlockCargoAnalysis;
    /**
     * 
     * @type {VerificationBlockCarData}
     * @memberof Verification
     */
    'cars_have_loading_operations'?: VerificationBlockCarData;
    /**
     * 
     * @type {VerificationBlockCarData}
     * @memberof Verification
     */
    'unloading_after_loading'?: VerificationBlockCarData;
    /**
     * 
     * @type {VerificationBlockCarData}
     * @memberof Verification
     */
    'branch_station_after_unloading'?: VerificationBlockCarData;
    /**
     * 
     * @type {VerificationBlockCarDestination}
     * @memberof Verification
     */
    'branch_station_test'?: VerificationBlockCarDestination;
    /**
     * 
     * @type {VerificationBlockCarDestination}
     * @memberof Verification
     */
    'parking_on_branch_stations'?: VerificationBlockCarDestination;
    /**
     * 
     * @type {VerificationBlockStationPerformance}
     * @memberof Verification
     */
    'loading_plan_fulfilled'?: VerificationBlockStationPerformance;
    /**
     * 
     * @type {VerificationBlockStationPerformance}
     * @memberof Verification
     */
    'loading_plan_not_exceeded'?: VerificationBlockStationPerformance;
    /**
     * 
     * @type {VerificationBlockStationLoading}
     * @memberof Verification
     */
    'loaded_cargoes_are_planned'?: VerificationBlockStationLoading;
    /**
     * 
     * @type {VerificationBlockStationLoading}
     * @memberof Verification
     */
    'planned_cargoes_are_loaded'?: VerificationBlockStationLoading;
    /**
     * 
     * @type {VerificationBlockStationLoading}
     * @memberof Verification
     */
    'loading_on_planned_stations'?: VerificationBlockStationLoading;
    /**
     * 
     * @type {VerificationBlockStationCapacityExceed}
     * @memberof Verification
     */
    'stations_capacities_respected'?: VerificationBlockStationCapacityExceed;
    /**
     * 
     * @type {VerificationBlockStationData}
     * @memberof Verification
     */
    'washing_stations_have_capacity_configured'?: VerificationBlockStationData;
    /**
     * 
     * @type {VerificationBlockStationCapacityExceed}
     * @memberof Verification
     */
    'washing_stations_capacity_respected'?: VerificationBlockStationCapacityExceed;
    /**
     * 
     * @type {VerificationBlockStationData}
     * @memberof Verification
     */
    'repair_stations_have_capacity_configured'?: VerificationBlockStationData;
    /**
     * 
     * @type {VerificationBlockStationCapacityExceed}
     * @memberof Verification
     */
    'repair_stations_capacity_respected'?: VerificationBlockStationCapacityExceed;
    /**
     * 
     * @type {VerificationBlockStationData}
     * @memberof Verification
     */
    'load_stations_have_capacity_configured'?: VerificationBlockStationData;
    /**
     * 
     * @type {VerificationBlockStationCapacityExceed}
     * @memberof Verification
     */
    'load_stations_capacity_respected'?: VerificationBlockStationCapacityExceed;
    /**
     * 
     * @type {VerificationBlockStationData}
     * @memberof Verification
     */
    'unload_stations_have_capacity_configured'?: VerificationBlockStationData;
    /**
     * 
     * @type {VerificationBlockStationCapacityExceed}
     * @memberof Verification
     */
    'unload_stations_capacity_respected'?: VerificationBlockStationCapacityExceed;
    /**
     * 
     * @type {VerificationBlockCarData}
     * @memberof Verification
     */
    'unused_cars_go_to_branch_stations'?: VerificationBlockCarData;
    /**
     * 
     * @type {VerificationBlockCarDeparture}
     * @memberof Verification
     */
    'departure_within_opt_period'?: VerificationBlockCarDeparture;
    /**
     * 
     * @type {VerificationBlockCarCargoesChange}
     * @memberof Verification
     */
    'mandatory_wash_executed'?: VerificationBlockCarCargoesChange;
    /**
     * 
     * @type {VerificationBlockCarCargoesChange}
     * @memberof Verification
     */
    'no_optional_wash_executed'?: VerificationBlockCarCargoesChange;
    /**
     * 
     * @type {VerificationBlockCarCargoesChange}
     * @memberof Verification
     */
    'permitted_cargoes_compliance'?: VerificationBlockCarCargoesChange;
    /**
     * 
     * @type {VerificationBlockCarCargoesChangeWithStation}
     * @memberof Verification
     */
    'wash_station_cargoes_compliant'?: VerificationBlockCarCargoesChangeWithStation;
}
/**
 * 
 * @export
 * @interface VerificationBlockCarCargoesChange
 */
export interface VerificationBlockCarCargoesChange {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockCarCargoesChange
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockCarCargoesChange
     */
    'result': boolean;
    /**
     * 
     * @type {Array<CarCargoesChange>}
     * @memberof VerificationBlockCarCargoesChange
     */
    'failed': Array<CarCargoesChange>;
}
/**
 * 
 * @export
 * @interface VerificationBlockCarCargoesChangeWithStation
 */
export interface VerificationBlockCarCargoesChangeWithStation {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockCarCargoesChangeWithStation
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockCarCargoesChangeWithStation
     */
    'result': boolean;
    /**
     * 
     * @type {Array<CarCargoesChangeWithStation>}
     * @memberof VerificationBlockCarCargoesChangeWithStation
     */
    'failed': Array<CarCargoesChangeWithStation>;
}
/**
 * 
 * @export
 * @interface VerificationBlockCarData
 */
export interface VerificationBlockCarData {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockCarData
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockCarData
     */
    'result': boolean;
    /**
     * 
     * @type {Array<CarData>}
     * @memberof VerificationBlockCarData
     */
    'failed': Array<CarData>;
}
/**
 * 
 * @export
 * @interface VerificationBlockCarDeparture
 */
export interface VerificationBlockCarDeparture {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockCarDeparture
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockCarDeparture
     */
    'result': boolean;
    /**
     * 
     * @type {Array<CarDeparture>}
     * @memberof VerificationBlockCarDeparture
     */
    'failed': Array<CarDeparture>;
}
/**
 * 
 * @export
 * @interface VerificationBlockCarDestination
 */
export interface VerificationBlockCarDestination {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockCarDestination
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockCarDestination
     */
    'result': boolean;
    /**
     * 
     * @type {Array<CarDestination>}
     * @memberof VerificationBlockCarDestination
     */
    'failed': Array<CarDestination>;
}
/**
 * 
 * @export
 * @interface VerificationBlockCarModelData
 */
export interface VerificationBlockCarModelData {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockCarModelData
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockCarModelData
     */
    'result': boolean;
    /**
     * 
     * @type {Array<CarModelData>}
     * @memberof VerificationBlockCarModelData
     */
    'failed': Array<CarModelData>;
}
/**
 * 
 * @export
 * @interface VerificationBlockCargoAnalysis
 */
export interface VerificationBlockCargoAnalysis {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockCargoAnalysis
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockCargoAnalysis
     */
    'result': boolean;
    /**
     * 
     * @type {Array<CargoAnalysis>}
     * @memberof VerificationBlockCargoAnalysis
     */
    'failed': Array<CargoAnalysis>;
}
/**
 * 
 * @export
 * @interface VerificationBlockCargoCarModel
 */
export interface VerificationBlockCargoCarModel {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockCargoCarModel
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockCargoCarModel
     */
    'result': boolean;
    /**
     * 
     * @type {Array<CargoCarModel>}
     * @memberof VerificationBlockCargoCarModel
     */
    'failed': Array<CargoCarModel>;
}
/**
 * 
 * @export
 * @interface VerificationBlockCargoData
 */
export interface VerificationBlockCargoData {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockCargoData
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockCargoData
     */
    'result': boolean;
    /**
     * 
     * @type {Array<CargoData>}
     * @memberof VerificationBlockCargoData
     */
    'failed': Array<CargoData>;
}
/**
 * 
 * @export
 * @interface VerificationBlockInt
 */
export interface VerificationBlockInt {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockInt
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockInt
     */
    'result': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationBlockInt
     */
    'failed': Array<number>;
}
/**
 * 
 * @export
 * @interface VerificationBlockStationCapacityExceed
 */
export interface VerificationBlockStationCapacityExceed {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockStationCapacityExceed
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockStationCapacityExceed
     */
    'result': boolean;
    /**
     * 
     * @type {Array<StationCapacityExceed>}
     * @memberof VerificationBlockStationCapacityExceed
     */
    'failed': Array<StationCapacityExceed>;
}
/**
 * 
 * @export
 * @interface VerificationBlockStationData
 */
export interface VerificationBlockStationData {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockStationData
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockStationData
     */
    'result': boolean;
    /**
     * 
     * @type {Array<StationData>}
     * @memberof VerificationBlockStationData
     */
    'failed': Array<StationData>;
}
/**
 * 
 * @export
 * @interface VerificationBlockStationLoading
 */
export interface VerificationBlockStationLoading {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockStationLoading
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockStationLoading
     */
    'result': boolean;
    /**
     * 
     * @type {Array<StationLoading>}
     * @memberof VerificationBlockStationLoading
     */
    'failed': Array<StationLoading>;
}
/**
 * 
 * @export
 * @interface VerificationBlockStationPerformance
 */
export interface VerificationBlockStationPerformance {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockStationPerformance
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockStationPerformance
     */
    'result': boolean;
    /**
     * 
     * @type {Array<StationPerformance>}
     * @memberof VerificationBlockStationPerformance
     */
    'failed': Array<StationPerformance>;
}
/**
 * 
 * @export
 * @interface VerificationBlockTripAnalysis
 */
export interface VerificationBlockTripAnalysis {
    /**
     * 
     * @type {VerificationCategory}
     * @memberof VerificationBlockTripAnalysis
     */
    'category': VerificationCategory;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationBlockTripAnalysis
     */
    'result': boolean;
    /**
     * 
     * @type {Array<TripAnalysis>}
     * @memberof VerificationBlockTripAnalysis
     */
    'failed': Array<TripAnalysis>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const VerificationCategory = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9
} as const;

export type VerificationCategory = typeof VerificationCategory[keyof typeof VerificationCategory];


/**
 * 
 * @export
 * @interface VersionResponse
 */
export interface VersionResponse {
    /**
     * 
     * @type {string}
     * @memberof VersionResponse
     */
    'version': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const WidgetTypeEnum = {
    Multiselect: 'MULTISELECT',
    DateRange: 'DATE_RANGE',
    Toggle: 'TOGGLE',
    Range: 'RANGE'
} as const;

export type WidgetTypeEnum = typeof WidgetTypeEnum[keyof typeof WidgetTypeEnum];



/**
 * InternalApi - axios parameter creator
 * @export
 */
export const InternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/version/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalApi - functional programming interface
 * @export
 */
export const InternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalApi - factory interface
 * @export
 */
export const InternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options?: any): AxiosPromise<VersionResponse> {
            return localVarFp.getVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalApi - object-oriented interface
 * @export
 * @class InternalApi
 * @extends {BaseAPI}
 */
export class InternalApi extends BaseAPI {
    /**
     * 
     * @summary Get Version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getVersion(options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).getVersion(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReferencesApi - axios parameter creator
 * @export
 */
export const ReferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Files References
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFilesReferences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/references/delete_files_references`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fill Stations Fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillStationsFields: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/references/fill_stations_fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Car
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCar: async (pk: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getCar', 'pk', pk)
            const localVarPath = `/api/v1/cars/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Car Model
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarModel: async (pk: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getCarModel', 'pk', pk)
            const localVarPath = `/api/v1/car_models/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Car Models
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarModels: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/car_models/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cargo
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCargo: async (pk: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getCargo', 'pk', pk)
            const localVarPath = `/api/v1/cargos/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cargo Group
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCargoGroup: async (pk: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getCargoGroup', 'pk', pk)
            const localVarPath = `/api/v1/cargo_groups/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cargo Groups
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCargoGroups: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cargo_groups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cargo Subgroup
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCargoSubgroup: async (pk: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getCargoSubgroup', 'pk', pk)
            const localVarPath = `/api/v1/cargo_subgroups/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cargo Subgroups
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCargoSubgroups: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cargo_subgroups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cargos
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCargos: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cargos/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cars
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCars: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cars/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Legal Entities
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalEntities: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/legal_entities/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Legal Entity
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalEntity: async (pk: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getLegalEntity', 'pk', pk)
            const localVarPath = `/api/v1/legal_entities/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Railway
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRailway: async (pk: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getRailway', 'pk', pk)
            const localVarPath = `/api/v1/railways/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Railways
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRailways: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/railways/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Station
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStation: async (pk: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getStation', 'pk', pk)
            const localVarPath = `/api/v1/stations/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Stations
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStations: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load References
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadReferences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/references/load`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load References From Transportirovka Incremental
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadReferencesFromTransportirovkaIncremental: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/references/loading/transportirovka/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load References From Transportirovka With Deletion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadReferencesFromTransportirovkaWithDeletion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/references/loading/transportirovka/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load References With Deletion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadReferencesWithDeletion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/references/load_deletion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferencesApi - functional programming interface
 * @export
 */
export const ReferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Files References
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFilesReferences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFilesReferences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fill Stations Fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fillStationsFields(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fillStationsFields(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Car
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCar(pk: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarsRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCar(pk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Car Model
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCarModel(pk: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarModelsRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCarModel(pk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Car Models
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCarModels(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarModelsRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCarModels(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Cargo
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCargo(pk: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CargosRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCargo(pk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Cargo Group
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCargoGroup(pk: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CargoGroupsRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCargoGroup(pk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Cargo Groups
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCargoGroups(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CargoGroupsRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCargoGroups(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Cargo Subgroup
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCargoSubgroup(pk: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CargoSubgroupsRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCargoSubgroup(pk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Cargo Subgroups
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCargoSubgroups(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CargoSubgroupsRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCargoSubgroups(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Cargos
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCargos(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CargosRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCargos(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Cars
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCars(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarsRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCars(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Legal Entities
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalEntities(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LegalEntitiesRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalEntities(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Legal Entity
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalEntity(pk: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntitiesRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalEntity(pk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Railway
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRailway(pk: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RailwayRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRailway(pk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Railways
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRailways(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RailwayRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRailways(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Station
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStation(pk: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStation(pk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Stations
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStations(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StationRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStations(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Load References
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadReferences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadReferences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Load References From Transportirovka Incremental
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadReferencesFromTransportirovkaIncremental(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadReferencesFromTransportirovkaIncremental(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Load References From Transportirovka With Deletion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadReferencesFromTransportirovkaWithDeletion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadReferencesFromTransportirovkaWithDeletion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Load References With Deletion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadReferencesWithDeletion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadReferencesWithDeletion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferencesApi - factory interface
 * @export
 */
export const ReferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferencesApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Files References
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFilesReferences(options?: any): AxiosPromise<any> {
            return localVarFp.deleteFilesReferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fill Stations Fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillStationsFields(options?: any): AxiosPromise<any> {
            return localVarFp.fillStationsFields(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Car
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCar(pk: number, options?: any): AxiosPromise<CarsRead> {
            return localVarFp.getCar(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Car Model
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarModel(pk: number, options?: any): AxiosPromise<CarModelsRead> {
            return localVarFp.getCarModel(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Car Models
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarModels(skip?: number, limit?: number, options?: any): AxiosPromise<Array<CarModelsRead>> {
            return localVarFp.getCarModels(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cargo
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCargo(pk: number, options?: any): AxiosPromise<CargosRead> {
            return localVarFp.getCargo(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cargo Group
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCargoGroup(pk: number, options?: any): AxiosPromise<CargoGroupsRead> {
            return localVarFp.getCargoGroup(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cargo Groups
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCargoGroups(skip?: number, limit?: number, options?: any): AxiosPromise<Array<CargoGroupsRead>> {
            return localVarFp.getCargoGroups(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cargo Subgroup
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCargoSubgroup(pk: number, options?: any): AxiosPromise<CargoSubgroupsRead> {
            return localVarFp.getCargoSubgroup(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cargo Subgroups
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCargoSubgroups(skip?: number, limit?: number, options?: any): AxiosPromise<Array<CargoSubgroupsRead>> {
            return localVarFp.getCargoSubgroups(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cargos
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCargos(skip?: number, limit?: number, options?: any): AxiosPromise<Array<CargosRead>> {
            return localVarFp.getCargos(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cars
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCars(skip?: number, limit?: number, options?: any): AxiosPromise<Array<CarsRead>> {
            return localVarFp.getCars(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Legal Entities
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalEntities(skip?: number, limit?: number, options?: any): AxiosPromise<Array<LegalEntitiesRead>> {
            return localVarFp.getLegalEntities(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Legal Entity
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalEntity(pk: number, options?: any): AxiosPromise<LegalEntitiesRead> {
            return localVarFp.getLegalEntity(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Railway
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRailway(pk: number, options?: any): AxiosPromise<RailwayRead> {
            return localVarFp.getRailway(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Railways
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRailways(skip?: number, limit?: number, options?: any): AxiosPromise<Array<RailwayRead>> {
            return localVarFp.getRailways(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Station
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStation(pk: number, options?: any): AxiosPromise<StationRead> {
            return localVarFp.getStation(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Stations
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStations(skip?: number, limit?: number, options?: any): AxiosPromise<Array<StationRead>> {
            return localVarFp.getStations(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load References
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadReferences(options?: any): AxiosPromise<any> {
            return localVarFp.loadReferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load References From Transportirovka Incremental
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadReferencesFromTransportirovkaIncremental(options?: any): AxiosPromise<any> {
            return localVarFp.loadReferencesFromTransportirovkaIncremental(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load References From Transportirovka With Deletion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadReferencesFromTransportirovkaWithDeletion(options?: any): AxiosPromise<any> {
            return localVarFp.loadReferencesFromTransportirovkaWithDeletion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load References With Deletion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadReferencesWithDeletion(options?: any): AxiosPromise<any> {
            return localVarFp.loadReferencesWithDeletion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCar operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetCarRequest
 */
export interface ReferencesApiGetCarRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCar
     */
    readonly pk: number
}

/**
 * Request parameters for getCarModel operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetCarModelRequest
 */
export interface ReferencesApiGetCarModelRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCarModel
     */
    readonly pk: number
}

/**
 * Request parameters for getCarModels operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetCarModelsRequest
 */
export interface ReferencesApiGetCarModelsRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCarModels
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCarModels
     */
    readonly limit?: number
}

/**
 * Request parameters for getCargo operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetCargoRequest
 */
export interface ReferencesApiGetCargoRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCargo
     */
    readonly pk: number
}

/**
 * Request parameters for getCargoGroup operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetCargoGroupRequest
 */
export interface ReferencesApiGetCargoGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCargoGroup
     */
    readonly pk: number
}

/**
 * Request parameters for getCargoGroups operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetCargoGroupsRequest
 */
export interface ReferencesApiGetCargoGroupsRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCargoGroups
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCargoGroups
     */
    readonly limit?: number
}

/**
 * Request parameters for getCargoSubgroup operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetCargoSubgroupRequest
 */
export interface ReferencesApiGetCargoSubgroupRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCargoSubgroup
     */
    readonly pk: number
}

/**
 * Request parameters for getCargoSubgroups operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetCargoSubgroupsRequest
 */
export interface ReferencesApiGetCargoSubgroupsRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCargoSubgroups
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCargoSubgroups
     */
    readonly limit?: number
}

/**
 * Request parameters for getCargos operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetCargosRequest
 */
export interface ReferencesApiGetCargosRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCargos
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCargos
     */
    readonly limit?: number
}

/**
 * Request parameters for getCars operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetCarsRequest
 */
export interface ReferencesApiGetCarsRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCars
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetCars
     */
    readonly limit?: number
}

/**
 * Request parameters for getLegalEntities operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetLegalEntitiesRequest
 */
export interface ReferencesApiGetLegalEntitiesRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetLegalEntities
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetLegalEntities
     */
    readonly limit?: number
}

/**
 * Request parameters for getLegalEntity operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetLegalEntityRequest
 */
export interface ReferencesApiGetLegalEntityRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetLegalEntity
     */
    readonly pk: number
}

/**
 * Request parameters for getRailway operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetRailwayRequest
 */
export interface ReferencesApiGetRailwayRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetRailway
     */
    readonly pk: number
}

/**
 * Request parameters for getRailways operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetRailwaysRequest
 */
export interface ReferencesApiGetRailwaysRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetRailways
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetRailways
     */
    readonly limit?: number
}

/**
 * Request parameters for getStation operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetStationRequest
 */
export interface ReferencesApiGetStationRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetStation
     */
    readonly pk: number
}

/**
 * Request parameters for getStations operation in ReferencesApi.
 * @export
 * @interface ReferencesApiGetStationsRequest
 */
export interface ReferencesApiGetStationsRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetStations
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ReferencesApiGetStations
     */
    readonly limit?: number
}

/**
 * ReferencesApi - object-oriented interface
 * @export
 * @class ReferencesApi
 * @extends {BaseAPI}
 */
export class ReferencesApi extends BaseAPI {
    /**
     * 
     * @summary Delete Files References
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public deleteFilesReferences(options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).deleteFilesReferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fill Stations Fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public fillStationsFields(options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).fillStationsFields(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Car
     * @param {ReferencesApiGetCarRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getCar(requestParameters: ReferencesApiGetCarRequest, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getCar(requestParameters.pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Car Model
     * @param {ReferencesApiGetCarModelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getCarModel(requestParameters: ReferencesApiGetCarModelRequest, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getCarModel(requestParameters.pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Car Models
     * @param {ReferencesApiGetCarModelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getCarModels(requestParameters: ReferencesApiGetCarModelsRequest = {}, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getCarModels(requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cargo
     * @param {ReferencesApiGetCargoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getCargo(requestParameters: ReferencesApiGetCargoRequest, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getCargo(requestParameters.pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cargo Group
     * @param {ReferencesApiGetCargoGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getCargoGroup(requestParameters: ReferencesApiGetCargoGroupRequest, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getCargoGroup(requestParameters.pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cargo Groups
     * @param {ReferencesApiGetCargoGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getCargoGroups(requestParameters: ReferencesApiGetCargoGroupsRequest = {}, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getCargoGroups(requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cargo Subgroup
     * @param {ReferencesApiGetCargoSubgroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getCargoSubgroup(requestParameters: ReferencesApiGetCargoSubgroupRequest, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getCargoSubgroup(requestParameters.pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cargo Subgroups
     * @param {ReferencesApiGetCargoSubgroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getCargoSubgroups(requestParameters: ReferencesApiGetCargoSubgroupsRequest = {}, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getCargoSubgroups(requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cargos
     * @param {ReferencesApiGetCargosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getCargos(requestParameters: ReferencesApiGetCargosRequest = {}, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getCargos(requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cars
     * @param {ReferencesApiGetCarsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getCars(requestParameters: ReferencesApiGetCarsRequest = {}, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getCars(requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Legal Entities
     * @param {ReferencesApiGetLegalEntitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getLegalEntities(requestParameters: ReferencesApiGetLegalEntitiesRequest = {}, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getLegalEntities(requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Legal Entity
     * @param {ReferencesApiGetLegalEntityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getLegalEntity(requestParameters: ReferencesApiGetLegalEntityRequest, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getLegalEntity(requestParameters.pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Railway
     * @param {ReferencesApiGetRailwayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getRailway(requestParameters: ReferencesApiGetRailwayRequest, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getRailway(requestParameters.pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Railways
     * @param {ReferencesApiGetRailwaysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getRailways(requestParameters: ReferencesApiGetRailwaysRequest = {}, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getRailways(requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Station
     * @param {ReferencesApiGetStationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getStation(requestParameters: ReferencesApiGetStationRequest, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getStation(requestParameters.pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Stations
     * @param {ReferencesApiGetStationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public getStations(requestParameters: ReferencesApiGetStationsRequest = {}, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).getStations(requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load References
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public loadReferences(options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).loadReferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load References From Transportirovka Incremental
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public loadReferencesFromTransportirovkaIncremental(options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).loadReferencesFromTransportirovkaIncremental(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load References From Transportirovka With Deletion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public loadReferencesFromTransportirovkaWithDeletion(options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).loadReferencesFromTransportirovkaWithDeletion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load References With Deletion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public loadReferencesWithDeletion(options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).loadReferencesWithDeletion(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Auth:Jwt.Domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authJwtDomains: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Auth:Jwt.Login
         * @param {LoginSchema} loginSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authJwtLogin: async (loginSchema: LoginSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginSchema' is not null or undefined
            assertParamExists('authJwtLogin', 'loginSchema', loginSchema)
            const localVarPath = `/api/v1/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Users:Current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Auth:Jwt.Domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authJwtDomains(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authJwtDomains(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Auth:Jwt.Login
         * @param {LoginSchema} loginSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authJwtLogin(loginSchema: LoginSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BearerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authJwtLogin(loginSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Users:Current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Auth:Jwt.Domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authJwtDomains(options?: any): AxiosPromise<InfoSchema> {
            return localVarFp.authJwtDomains(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Auth:Jwt.Login
         * @param {LoginSchema} loginSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authJwtLogin(loginSchema: LoginSchema, options?: any): AxiosPromise<BearerResponse> {
            return localVarFp.authJwtLogin(loginSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Users:Current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentUser(options?: any): AxiosPromise<UserRead> {
            return localVarFp.usersCurrentUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authJwtLogin operation in UsersApi.
 * @export
 * @interface UsersApiAuthJwtLoginRequest
 */
export interface UsersApiAuthJwtLoginRequest {
    /**
     * 
     * @type {LoginSchema}
     * @memberof UsersApiAuthJwtLogin
     */
    readonly loginSchema: LoginSchema
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Auth:Jwt.Domains
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public authJwtDomains(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).authJwtDomains(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Auth:Jwt.Login
     * @param {UsersApiAuthJwtLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public authJwtLogin(requestParameters: UsersApiAuthJwtLoginRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).authJwtLogin(requestParameters.loginSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Users:Current User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCurrentUser(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VerificationApi - axios parameter creator
 * @export
 */
export const VerificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Control Trips Autocomplete
         * @param {ControlTripsAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlTripsAutocomplete: async (field?: ControlTripsAutocompleteFields, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trips/control/autocomplete/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Current Trips Autocomplete
         * @param {CurrentTripsAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentTripsAutocomplete: async (field?: CurrentTripsAutocompleteFields, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trips/current/autocomplete/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate Trips
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTrips: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trips/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Запуск оптимизатора на данных из ИС Транспортировка
         * @summary Generate Trips T13A
         * @param {string} [dislocationDatetime] 
         * @param {boolean} [usePreviouslyLoadedData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTripsT13a: async (dislocationDatetime?: string, usePreviouslyLoadedData?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trips/generate/t13a`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (dislocationDatetime !== undefined) {
                localVarQueryParameter['dislocation_datetime'] = (dislocationDatetime as any instanceof Date) ?
                    (dislocationDatetime as any).toISOString() :
                    dislocationDatetime;
            }

            if (usePreviouslyLoadedData !== undefined) {
                localVarQueryParameter['use_previously_loaded_data'] = usePreviouslyLoadedData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Algo Runs
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlgoRuns: async (pageSize: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAlgoRuns', 'pageSize', pageSize)
            const localVarPath = `/api/v1/algo_runs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Control Trips
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {number} [stationId] 
         * @param {Array<number>} [subgroupId] 
         * @param {Array<number>} [groupId] 
         * @param {ControlTripsSectionEnum} [section] 
         * @param {string} [date] 
         * @param {string} [dateGte] 
         * @param {string} [dateLte] 
         * @param {Array<string>} [washingStationName] 
         * @param {Array<string>} [parkingStationName] 
         * @param {boolean} [returnStationMatch] 
         * @param {boolean} [throughWashingStation] 
         * @param {boolean} [throughParkingStation] 
         * @param {boolean} [firstLeg] 
         * @param {string} [status] 
         * @param {Array<string>} [carNo] 
         * @param {Array<string>} [trainNo] 
         * @param {Array<string>} [fromStationName] 
         * @param {Array<string>} [toStationName] 
         * @param {Array<string>} [toOperation] 
         * @param {Array<string>} [cargoSubgroup] 
         * @param {Array<string>} [cargoGroup] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<string>} [fromRailway] 
         * @param {Array<string>} [fromStationEsr] 
         * @param {Array<string>} [toRailway] 
         * @param {Array<string>} [toStationEsr] 
         * @param {string} [arrivalEstimateLte] 
         * @param {string} [arrivalEstimateGte] 
         * @param {Array<string>} [carModel] 
         * @param {boolean} [isRented] 
         * @param {boolean} [fitting45Deg] 
         * @param {boolean} [address75] 
         * @param {boolean} [drainDeviceProt3] 
         * @param {boolean} [steamJacket] 
         * @param {boolean} [isOnWay] 
         * @param {boolean} [onRoute] 
         * @param {Array<string>} [returnStationName] 
         * @param {Array<string>} [returnStationEsr] 
         * @param {Array<string>} [operationStationName] 
         * @param {Array<string>} [operationStationEsr] 
         * @param {Array<string>} [operationRailway] 
         * @param {boolean} [carRzdCode92] 
         * @param {number} [opDurationLte] 
         * @param {number} [opDurationGte] 
         * @param {boolean} [isIdle] 
         * @param {number} [accessRemainingDistanceLte] 
         * @param {number} [accessRemainingDistanceGte] 
         * @param {boolean} [isOffScheduleRepair] 
         * @param {boolean} [isPlannedRepair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlTrips: async (pageSize: number, page?: number, stationId?: number, subgroupId?: Array<number>, groupId?: Array<number>, section?: ControlTripsSectionEnum, date?: string, dateGte?: string, dateLte?: string, washingStationName?: Array<string>, parkingStationName?: Array<string>, returnStationMatch?: boolean, throughWashingStation?: boolean, throughParkingStation?: boolean, firstLeg?: boolean, status?: string, carNo?: Array<string>, trainNo?: Array<string>, fromStationName?: Array<string>, toStationName?: Array<string>, toOperation?: Array<string>, cargoSubgroup?: Array<string>, cargoGroup?: Array<string>, cargoGroupId?: Array<number>, cargoName?: Array<string>, fromRailway?: Array<string>, fromStationEsr?: Array<string>, toRailway?: Array<string>, toStationEsr?: Array<string>, arrivalEstimateLte?: string, arrivalEstimateGte?: string, carModel?: Array<string>, isRented?: boolean, fitting45Deg?: boolean, address75?: boolean, drainDeviceProt3?: boolean, steamJacket?: boolean, isOnWay?: boolean, onRoute?: boolean, returnStationName?: Array<string>, returnStationEsr?: Array<string>, operationStationName?: Array<string>, operationStationEsr?: Array<string>, operationRailway?: Array<string>, carRzdCode92?: boolean, opDurationLte?: number, opDurationGte?: number, isIdle?: boolean, accessRemainingDistanceLte?: number, accessRemainingDistanceGte?: number, isOffScheduleRepair?: boolean, isPlannedRepair?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getControlTrips', 'pageSize', pageSize)
            const localVarPath = `/api/v1/trips/control/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (stationId !== undefined) {
                localVarQueryParameter['station_id'] = stationId;
            }

            if (subgroupId) {
                localVarQueryParameter['subgroup_id'] = subgroupId;
            }

            if (groupId) {
                localVarQueryParameter['group_id'] = groupId;
            }

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (dateGte !== undefined) {
                localVarQueryParameter['date_gte'] = (dateGte as any instanceof Date) ?
                    (dateGte as any).toISOString().substr(0,10) :
                    dateGte;
            }

            if (dateLte !== undefined) {
                localVarQueryParameter['date_lte'] = (dateLte as any instanceof Date) ?
                    (dateLte as any).toISOString().substr(0,10) :
                    dateLte;
            }

            if (washingStationName) {
                localVarQueryParameter['washing_station_name'] = washingStationName;
            }

            if (parkingStationName) {
                localVarQueryParameter['parking_station_name'] = parkingStationName;
            }

            if (returnStationMatch !== undefined) {
                localVarQueryParameter['return_station_match'] = returnStationMatch;
            }

            if (throughWashingStation !== undefined) {
                localVarQueryParameter['through_washing_station'] = throughWashingStation;
            }

            if (throughParkingStation !== undefined) {
                localVarQueryParameter['through_parking_station'] = throughParkingStation;
            }

            if (firstLeg !== undefined) {
                localVarQueryParameter['first_leg'] = firstLeg;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (carNo) {
                localVarQueryParameter['car_no'] = carNo;
            }

            if (trainNo) {
                localVarQueryParameter['train_no'] = trainNo;
            }

            if (fromStationName) {
                localVarQueryParameter['from_station_name'] = fromStationName;
            }

            if (toStationName) {
                localVarQueryParameter['to_station_name'] = toStationName;
            }

            if (toOperation) {
                localVarQueryParameter['to_operation'] = toOperation;
            }

            if (cargoSubgroup) {
                localVarQueryParameter['cargo_subgroup'] = cargoSubgroup;
            }

            if (cargoGroup) {
                localVarQueryParameter['cargo_group'] = cargoGroup;
            }

            if (cargoGroupId) {
                localVarQueryParameter['cargo_group_id'] = cargoGroupId;
            }

            if (cargoName) {
                localVarQueryParameter['cargo_name'] = cargoName;
            }

            if (fromRailway) {
                localVarQueryParameter['from_railway'] = fromRailway;
            }

            if (fromStationEsr) {
                localVarQueryParameter['from_station_esr'] = fromStationEsr;
            }

            if (toRailway) {
                localVarQueryParameter['to_railway'] = toRailway;
            }

            if (toStationEsr) {
                localVarQueryParameter['to_station_esr'] = toStationEsr;
            }

            if (arrivalEstimateLte !== undefined) {
                localVarQueryParameter['arrival_estimate_lte'] = (arrivalEstimateLte as any instanceof Date) ?
                    (arrivalEstimateLte as any).toISOString().substr(0,10) :
                    arrivalEstimateLte;
            }

            if (arrivalEstimateGte !== undefined) {
                localVarQueryParameter['arrival_estimate_gte'] = (arrivalEstimateGte as any instanceof Date) ?
                    (arrivalEstimateGte as any).toISOString().substr(0,10) :
                    arrivalEstimateGte;
            }

            if (carModel) {
                localVarQueryParameter['car_model'] = carModel;
            }

            if (isRented !== undefined) {
                localVarQueryParameter['is_rented'] = isRented;
            }

            if (fitting45Deg !== undefined) {
                localVarQueryParameter['fitting_45_deg'] = fitting45Deg;
            }

            if (address75 !== undefined) {
                localVarQueryParameter['address_75'] = address75;
            }

            if (drainDeviceProt3 !== undefined) {
                localVarQueryParameter['drain_device_prot_3'] = drainDeviceProt3;
            }

            if (steamJacket !== undefined) {
                localVarQueryParameter['steam_jacket'] = steamJacket;
            }

            if (isOnWay !== undefined) {
                localVarQueryParameter['is_on_way'] = isOnWay;
            }

            if (onRoute !== undefined) {
                localVarQueryParameter['on_route'] = onRoute;
            }

            if (returnStationName) {
                localVarQueryParameter['return_station_name'] = returnStationName;
            }

            if (returnStationEsr) {
                localVarQueryParameter['return_station_esr'] = returnStationEsr;
            }

            if (operationStationName) {
                localVarQueryParameter['operation_station_name'] = operationStationName;
            }

            if (operationStationEsr) {
                localVarQueryParameter['operation_station_esr'] = operationStationEsr;
            }

            if (operationRailway) {
                localVarQueryParameter['operation_railway'] = operationRailway;
            }

            if (carRzdCode92 !== undefined) {
                localVarQueryParameter['car_rzd_code_92'] = carRzdCode92;
            }

            if (opDurationLte !== undefined) {
                localVarQueryParameter['op_duration_lte'] = opDurationLte;
            }

            if (opDurationGte !== undefined) {
                localVarQueryParameter['op_duration_gte'] = opDurationGte;
            }

            if (isIdle !== undefined) {
                localVarQueryParameter['is_idle'] = isIdle;
            }

            if (accessRemainingDistanceLte !== undefined) {
                localVarQueryParameter['access_remaining_distance_lte'] = accessRemainingDistanceLte;
            }

            if (accessRemainingDistanceGte !== undefined) {
                localVarQueryParameter['access_remaining_distance_gte'] = accessRemainingDistanceGte;
            }

            if (isOffScheduleRepair !== undefined) {
                localVarQueryParameter['is_off_schedule_repair'] = isOffScheduleRepair;
            }

            if (isPlannedRepair !== undefined) {
                localVarQueryParameter['is_planned_repair'] = isPlannedRepair;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Control Trips Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlTripsFilters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trips/control/filters/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Current Trip
         * @param {number} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTrip: async (tripId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('getCurrentTrip', 'tripId', tripId)
            const localVarPath = `/api/v1/trips/current/{trip_id}`
                .replace(`{${"trip_id"}}`, encodeURIComponent(String(tripId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Current Trips
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {Array<number>} [id] 
         * @param {Array<string>} [carNo] 
         * @param {Array<string>} [trainNo] 
         * @param {Array<string>} [fromStationName] 
         * @param {Array<string>} [toStationName] 
         * @param {Array<string>} [toOperation] 
         * @param {Array<string>} [cargoSubgroup] 
         * @param {Array<string>} [cargoGroup] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<string>} [fromRailway] 
         * @param {Array<string>} [fromStationEsr] 
         * @param {Array<string>} [toRailway] 
         * @param {Array<string>} [toStationEsr] 
         * @param {string} [arrivalEstimateLte] 
         * @param {string} [arrivalEstimateGte] 
         * @param {Array<string>} [carModel] 
         * @param {boolean} [isRented] 
         * @param {boolean} [fitting45Deg] 
         * @param {boolean} [address75] 
         * @param {boolean} [drainDeviceProt3] 
         * @param {boolean} [steamJacket] 
         * @param {boolean} [isOnWay] 
         * @param {boolean} [onRoute] 
         * @param {Array<string>} [returnStationName] 
         * @param {Array<string>} [returnStationEsr] 
         * @param {Array<string>} [operationStationName] 
         * @param {Array<string>} [operationStationEsr] 
         * @param {Array<string>} [operationRailway] 
         * @param {boolean} [carRzdCode92] 
         * @param {string} [utilizationType] 
         * @param {number} [opDurationLte] 
         * @param {number} [opDurationGte] 
         * @param {boolean} [isIdle] 
         * @param {number} [accessRemainingDistanceLte] 
         * @param {number} [accessRemainingDistanceGte] 
         * @param {boolean} [isOffScheduleRepair] 
         * @param {boolean} [isPlannedRepair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTrips: async (pageSize: number, page?: number, id?: Array<number>, carNo?: Array<string>, trainNo?: Array<string>, fromStationName?: Array<string>, toStationName?: Array<string>, toOperation?: Array<string>, cargoSubgroup?: Array<string>, cargoGroup?: Array<string>, cargoGroupId?: Array<number>, cargoName?: Array<string>, fromRailway?: Array<string>, fromStationEsr?: Array<string>, toRailway?: Array<string>, toStationEsr?: Array<string>, arrivalEstimateLte?: string, arrivalEstimateGte?: string, carModel?: Array<string>, isRented?: boolean, fitting45Deg?: boolean, address75?: boolean, drainDeviceProt3?: boolean, steamJacket?: boolean, isOnWay?: boolean, onRoute?: boolean, returnStationName?: Array<string>, returnStationEsr?: Array<string>, operationStationName?: Array<string>, operationStationEsr?: Array<string>, operationRailway?: Array<string>, carRzdCode92?: boolean, utilizationType?: string, opDurationLte?: number, opDurationGte?: number, isIdle?: boolean, accessRemainingDistanceLte?: number, accessRemainingDistanceGte?: number, isOffScheduleRepair?: boolean, isPlannedRepair?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getCurrentTrips', 'pageSize', pageSize)
            const localVarPath = `/api/v1/trips/current/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (carNo) {
                localVarQueryParameter['car_no'] = carNo;
            }

            if (trainNo) {
                localVarQueryParameter['train_no'] = trainNo;
            }

            if (fromStationName) {
                localVarQueryParameter['from_station_name'] = fromStationName;
            }

            if (toStationName) {
                localVarQueryParameter['to_station_name'] = toStationName;
            }

            if (toOperation) {
                localVarQueryParameter['to_operation'] = toOperation;
            }

            if (cargoSubgroup) {
                localVarQueryParameter['cargo_subgroup'] = cargoSubgroup;
            }

            if (cargoGroup) {
                localVarQueryParameter['cargo_group'] = cargoGroup;
            }

            if (cargoGroupId) {
                localVarQueryParameter['cargo_group_id'] = cargoGroupId;
            }

            if (cargoName) {
                localVarQueryParameter['cargo_name'] = cargoName;
            }

            if (fromRailway) {
                localVarQueryParameter['from_railway'] = fromRailway;
            }

            if (fromStationEsr) {
                localVarQueryParameter['from_station_esr'] = fromStationEsr;
            }

            if (toRailway) {
                localVarQueryParameter['to_railway'] = toRailway;
            }

            if (toStationEsr) {
                localVarQueryParameter['to_station_esr'] = toStationEsr;
            }

            if (arrivalEstimateLte !== undefined) {
                localVarQueryParameter['arrival_estimate_lte'] = (arrivalEstimateLte as any instanceof Date) ?
                    (arrivalEstimateLte as any).toISOString().substr(0,10) :
                    arrivalEstimateLte;
            }

            if (arrivalEstimateGte !== undefined) {
                localVarQueryParameter['arrival_estimate_gte'] = (arrivalEstimateGte as any instanceof Date) ?
                    (arrivalEstimateGte as any).toISOString().substr(0,10) :
                    arrivalEstimateGte;
            }

            if (carModel) {
                localVarQueryParameter['car_model'] = carModel;
            }

            if (isRented !== undefined) {
                localVarQueryParameter['is_rented'] = isRented;
            }

            if (fitting45Deg !== undefined) {
                localVarQueryParameter['fitting_45_deg'] = fitting45Deg;
            }

            if (address75 !== undefined) {
                localVarQueryParameter['address_75'] = address75;
            }

            if (drainDeviceProt3 !== undefined) {
                localVarQueryParameter['drain_device_prot_3'] = drainDeviceProt3;
            }

            if (steamJacket !== undefined) {
                localVarQueryParameter['steam_jacket'] = steamJacket;
            }

            if (isOnWay !== undefined) {
                localVarQueryParameter['is_on_way'] = isOnWay;
            }

            if (onRoute !== undefined) {
                localVarQueryParameter['on_route'] = onRoute;
            }

            if (returnStationName) {
                localVarQueryParameter['return_station_name'] = returnStationName;
            }

            if (returnStationEsr) {
                localVarQueryParameter['return_station_esr'] = returnStationEsr;
            }

            if (operationStationName) {
                localVarQueryParameter['operation_station_name'] = operationStationName;
            }

            if (operationStationEsr) {
                localVarQueryParameter['operation_station_esr'] = operationStationEsr;
            }

            if (operationRailway) {
                localVarQueryParameter['operation_railway'] = operationRailway;
            }

            if (carRzdCode92 !== undefined) {
                localVarQueryParameter['car_rzd_code_92'] = carRzdCode92;
            }

            if (utilizationType !== undefined) {
                localVarQueryParameter['utilization_type'] = utilizationType;
            }

            if (opDurationLte !== undefined) {
                localVarQueryParameter['op_duration_lte'] = opDurationLte;
            }

            if (opDurationGte !== undefined) {
                localVarQueryParameter['op_duration_gte'] = opDurationGte;
            }

            if (isIdle !== undefined) {
                localVarQueryParameter['is_idle'] = isIdle;
            }

            if (accessRemainingDistanceLte !== undefined) {
                localVarQueryParameter['access_remaining_distance_lte'] = accessRemainingDistanceLte;
            }

            if (accessRemainingDistanceGte !== undefined) {
                localVarQueryParameter['access_remaining_distance_gte'] = accessRemainingDistanceGte;
            }

            if (isOffScheduleRepair !== undefined) {
                localVarQueryParameter['is_off_schedule_repair'] = isOffScheduleRepair;
            }

            if (isPlannedRepair !== undefined) {
                localVarQueryParameter['is_planned_repair'] = isPlannedRepair;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Current Trips Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTripsFilters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trips/current/filters/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Loaded Cargo Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoadedCargoFilters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/loaded_cargo/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Loading Stations
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {Array<number>} [id] 
         * @param {Array<string>} [name] 
         * @param {Array<string>} [code6] 
         * @param {Array<number>} [railwayId] 
         * @param {Array<string>} [railwayName] 
         * @param {Array<number>} [cargoId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoGroupName] 
         * @param {Array<number>} [cargoSubgroupId] 
         * @param {Array<string>} [cargoSubgroupName] 
         * @param {Array<string>} [marketType] 
         * @param {boolean} [backlog] 
         * @param {boolean} [abandonedCars] 
         * @param {number} [planCompletion] 
         * @param {number} [planCompletionGte] 
         * @param {number} [planCompletionLte] 
         * @param {boolean} [delays] 
         * @param {boolean} [address75] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoadingStations: async (pageSize: number, page?: number, id?: Array<number>, name?: Array<string>, code6?: Array<string>, railwayId?: Array<number>, railwayName?: Array<string>, cargoId?: Array<number>, cargoName?: Array<string>, cargoGroupId?: Array<number>, cargoGroupName?: Array<string>, cargoSubgroupId?: Array<number>, cargoSubgroupName?: Array<string>, marketType?: Array<string>, backlog?: boolean, abandonedCars?: boolean, planCompletion?: number, planCompletionGte?: number, planCompletionLte?: number, delays?: boolean, address75?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getLoadingStations', 'pageSize', pageSize)
            const localVarPath = `/api/v1/loading_stations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (name) {
                localVarQueryParameter['name'] = name;
            }

            if (code6) {
                localVarQueryParameter['code6'] = code6;
            }

            if (railwayId) {
                localVarQueryParameter['railway_id'] = railwayId;
            }

            if (railwayName) {
                localVarQueryParameter['railway_name'] = railwayName;
            }

            if (cargoId) {
                localVarQueryParameter['cargo_id'] = cargoId;
            }

            if (cargoName) {
                localVarQueryParameter['cargo_name'] = cargoName;
            }

            if (cargoGroupId) {
                localVarQueryParameter['cargo_group_id'] = cargoGroupId;
            }

            if (cargoGroupName) {
                localVarQueryParameter['cargo_group_name'] = cargoGroupName;
            }

            if (cargoSubgroupId) {
                localVarQueryParameter['cargo_subgroup_id'] = cargoSubgroupId;
            }

            if (cargoSubgroupName) {
                localVarQueryParameter['cargo_subgroup_name'] = cargoSubgroupName;
            }

            if (marketType) {
                localVarQueryParameter['market_type'] = marketType;
            }

            if (backlog !== undefined) {
                localVarQueryParameter['backlog'] = backlog;
            }

            if (abandonedCars !== undefined) {
                localVarQueryParameter['abandoned_cars'] = abandonedCars;
            }

            if (planCompletion !== undefined) {
                localVarQueryParameter['plan_completion'] = planCompletion;
            }

            if (planCompletionGte !== undefined) {
                localVarQueryParameter['plan_completion_gte'] = planCompletionGte;
            }

            if (planCompletionLte !== undefined) {
                localVarQueryParameter['plan_completion_lte'] = planCompletionLte;
            }

            if (delays !== undefined) {
                localVarQueryParameter['delays'] = delays;
            }

            if (address75 !== undefined) {
                localVarQueryParameter['address_75'] = address75;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Loading Stations Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoadingStationsFilters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/loading_stations/filters/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Logs File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsFile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/algo_runs/logs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Logs File Live
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsFileLive: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/algo_runs/logs/live`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Proposed Trips
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {Array<number>} [id] 
         * @param {string} [departureDate] Дата отправления
         * @param {string} [departureDateGte] Дата отправления (больше или равно)
         * @param {string} [departureDateLte] Дата отправления (меньше или равно)
         * @param {Array<number>} [departureStationId] 
         * @param {Array<string>} [departureStationName] 
         * @param {Array<string>} [departureStationCode6] 
         * @param {Array<number>} [departureStationRailwayId] 
         * @param {Array<string>} [departureStationRailwayName] 
         * @param {string} [arrivalDate] Предполагаемая дата прибытия
         * @param {string} [arrivalDateGte] Предполагаемая дата прибытия (больше или равно)
         * @param {string} [arrivalDateLte] Предполагаемая дата прибытия (меньше или равно)
         * @param {Array<number>} [arrivalStationId] 
         * @param {Array<string>} [arrivalStationName] 
         * @param {Array<string>} [arrivalStationCode6] 
         * @param {Array<number>} [arrivalStationRailwayId] 
         * @param {Array<string>} [arrivalStationRailwayName] 
         * @param {Array<string>} [arrivalOperation] Операция, которой должен подвергнуться вагон на станции назначения
         * @param {Array<number>} [carId] 
         * @param {Array<number>} [carNo] Идентификационный номер вагона
         * @param {boolean} [carRzdCode92] Код операции РЖД 92
         * @param {boolean} [carIsRented] 
         * @param {boolean} [carFitting45Deg] Признак \&quot;Арматура 45 град\&quot;
         * @param {boolean} [carDrainDeviceProt3] Признак \&quot;Сливное устройство с тремя степенями защиты\&quot;
         * @param {Array<number>} [carModelId] 
         * @param {Array<string>} [carModelName] Модель вагона
         * @param {boolean} [carModelSteamJacket] Признак наличия паровой рубашки
         * @param {Array<number>} [cargoId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoGroupName] 
         * @param {Array<number>} [cargoSubgroupId] 
         * @param {Array<string>} [cargoSubgroupName] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposedTrips: async (pageSize: number, page?: number, id?: Array<number>, departureDate?: string, departureDateGte?: string, departureDateLte?: string, departureStationId?: Array<number>, departureStationName?: Array<string>, departureStationCode6?: Array<string>, departureStationRailwayId?: Array<number>, departureStationRailwayName?: Array<string>, arrivalDate?: string, arrivalDateGte?: string, arrivalDateLte?: string, arrivalStationId?: Array<number>, arrivalStationName?: Array<string>, arrivalStationCode6?: Array<string>, arrivalStationRailwayId?: Array<number>, arrivalStationRailwayName?: Array<string>, arrivalOperation?: Array<string>, carId?: Array<number>, carNo?: Array<number>, carRzdCode92?: boolean, carIsRented?: boolean, carFitting45Deg?: boolean, carDrainDeviceProt3?: boolean, carModelId?: Array<number>, carModelName?: Array<string>, carModelSteamJacket?: boolean, cargoId?: Array<number>, cargoName?: Array<string>, cargoGroupId?: Array<number>, cargoGroupName?: Array<string>, cargoSubgroupId?: Array<number>, cargoSubgroupName?: Array<string>, sortBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getProposedTrips', 'pageSize', pageSize)
            const localVarPath = `/api/v1/trips/proposed/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (departureDate !== undefined) {
                localVarQueryParameter['departure_date'] = (departureDate as any instanceof Date) ?
                    (departureDate as any).toISOString().substr(0,10) :
                    departureDate;
            }

            if (departureDateGte !== undefined) {
                localVarQueryParameter['departure_date_gte'] = (departureDateGte as any instanceof Date) ?
                    (departureDateGte as any).toISOString().substr(0,10) :
                    departureDateGte;
            }

            if (departureDateLte !== undefined) {
                localVarQueryParameter['departure_date_lte'] = (departureDateLte as any instanceof Date) ?
                    (departureDateLte as any).toISOString().substr(0,10) :
                    departureDateLte;
            }

            if (departureStationId) {
                localVarQueryParameter['departure_station_id'] = departureStationId;
            }

            if (departureStationName) {
                localVarQueryParameter['departure_station_name'] = departureStationName;
            }

            if (departureStationCode6) {
                localVarQueryParameter['departure_station_code6'] = departureStationCode6;
            }

            if (departureStationRailwayId) {
                localVarQueryParameter['departure_station_railway_id'] = departureStationRailwayId;
            }

            if (departureStationRailwayName) {
                localVarQueryParameter['departure_station_railway_name'] = departureStationRailwayName;
            }

            if (arrivalDate !== undefined) {
                localVarQueryParameter['arrival_date'] = (arrivalDate as any instanceof Date) ?
                    (arrivalDate as any).toISOString().substr(0,10) :
                    arrivalDate;
            }

            if (arrivalDateGte !== undefined) {
                localVarQueryParameter['arrival_date_gte'] = (arrivalDateGte as any instanceof Date) ?
                    (arrivalDateGte as any).toISOString().substr(0,10) :
                    arrivalDateGte;
            }

            if (arrivalDateLte !== undefined) {
                localVarQueryParameter['arrival_date_lte'] = (arrivalDateLte as any instanceof Date) ?
                    (arrivalDateLte as any).toISOString().substr(0,10) :
                    arrivalDateLte;
            }

            if (arrivalStationId) {
                localVarQueryParameter['arrival_station_id'] = arrivalStationId;
            }

            if (arrivalStationName) {
                localVarQueryParameter['arrival_station_name'] = arrivalStationName;
            }

            if (arrivalStationCode6) {
                localVarQueryParameter['arrival_station_code6'] = arrivalStationCode6;
            }

            if (arrivalStationRailwayId) {
                localVarQueryParameter['arrival_station_railway_id'] = arrivalStationRailwayId;
            }

            if (arrivalStationRailwayName) {
                localVarQueryParameter['arrival_station_railway_name'] = arrivalStationRailwayName;
            }

            if (arrivalOperation) {
                localVarQueryParameter['arrival_operation'] = arrivalOperation;
            }

            if (carId) {
                localVarQueryParameter['car_id'] = carId;
            }

            if (carNo) {
                localVarQueryParameter['car_no'] = carNo;
            }

            if (carRzdCode92 !== undefined) {
                localVarQueryParameter['car_rzd_code_92'] = carRzdCode92;
            }

            if (carIsRented !== undefined) {
                localVarQueryParameter['car_is_rented'] = carIsRented;
            }

            if (carFitting45Deg !== undefined) {
                localVarQueryParameter['car_fitting_45_deg'] = carFitting45Deg;
            }

            if (carDrainDeviceProt3 !== undefined) {
                localVarQueryParameter['car_drain_device_prot_3'] = carDrainDeviceProt3;
            }

            if (carModelId) {
                localVarQueryParameter['car_model_id'] = carModelId;
            }

            if (carModelName) {
                localVarQueryParameter['car_model_name'] = carModelName;
            }

            if (carModelSteamJacket !== undefined) {
                localVarQueryParameter['car_model_steam_jacket'] = carModelSteamJacket;
            }

            if (cargoId) {
                localVarQueryParameter['cargo_id'] = cargoId;
            }

            if (cargoName) {
                localVarQueryParameter['cargo_name'] = cargoName;
            }

            if (cargoGroupId) {
                localVarQueryParameter['cargo_group_id'] = cargoGroupId;
            }

            if (cargoGroupName) {
                localVarQueryParameter['cargo_group_name'] = cargoGroupName;
            }

            if (cargoSubgroupId) {
                localVarQueryParameter['cargo_subgroup_id'] = cargoSubgroupId;
            }

            if (cargoSubgroupName) {
                localVarQueryParameter['cargo_subgroup_name'] = cargoSubgroupName;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Proposed Trips Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposedTripsFilters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trips/proposed/filters/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Proposed Trips Verdict Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposedTripsVerdictReasons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trips/proposed/verdict/reasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Result File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultFile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trips/proposed/export/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Station Charts
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationCharts: async (pk: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getStationCharts', 'pk', pk)
            const localVarPath = `/api/v1/loading_stations/{pk}/charts`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Station Markers
         * @param {number} pk 
         * @param {LoadingStationMarkersViewEnum} [view] 
         * @param {LoadingStationMarkersDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationMarkers: async (pk: number, view?: LoadingStationMarkersViewEnum, direction?: LoadingStationMarkersDirectionEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getStationMarkers', 'pk', pk)
            const localVarPath = `/api/v1/loading_stations/{pk}/markers`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (view !== undefined) {
                localVarQueryParameter['view'] = view;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Station Summary
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationSummary: async (pk: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getStationSummary', 'pk', pk)
            const localVarPath = `/api/v1/loading_stations/{pk}/info_loading`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Stations Charts
         * @param {Array<number>} [id] 
         * @param {Array<string>} [name] 
         * @param {Array<string>} [code6] 
         * @param {Array<number>} [railwayId] 
         * @param {Array<string>} [railwayName] 
         * @param {Array<number>} [cargoId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoGroupName] 
         * @param {Array<number>} [cargoSubgroupId] 
         * @param {Array<string>} [cargoSubgroupName] 
         * @param {Array<string>} [marketType] 
         * @param {boolean} [backlog] 
         * @param {boolean} [abandonedCars] 
         * @param {number} [planCompletion] 
         * @param {number} [planCompletionGte] 
         * @param {number} [planCompletionLte] 
         * @param {boolean} [delays] 
         * @param {boolean} [address75] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationsCharts: async (id?: Array<number>, name?: Array<string>, code6?: Array<string>, railwayId?: Array<number>, railwayName?: Array<string>, cargoId?: Array<number>, cargoName?: Array<string>, cargoGroupId?: Array<number>, cargoGroupName?: Array<string>, cargoSubgroupId?: Array<number>, cargoSubgroupName?: Array<string>, marketType?: Array<string>, backlog?: boolean, abandonedCars?: boolean, planCompletion?: number, planCompletionGte?: number, planCompletionLte?: number, delays?: boolean, address75?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/loading_stations/charts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (name) {
                localVarQueryParameter['name'] = name;
            }

            if (code6) {
                localVarQueryParameter['code6'] = code6;
            }

            if (railwayId) {
                localVarQueryParameter['railway_id'] = railwayId;
            }

            if (railwayName) {
                localVarQueryParameter['railway_name'] = railwayName;
            }

            if (cargoId) {
                localVarQueryParameter['cargo_id'] = cargoId;
            }

            if (cargoName) {
                localVarQueryParameter['cargo_name'] = cargoName;
            }

            if (cargoGroupId) {
                localVarQueryParameter['cargo_group_id'] = cargoGroupId;
            }

            if (cargoGroupName) {
                localVarQueryParameter['cargo_group_name'] = cargoGroupName;
            }

            if (cargoSubgroupId) {
                localVarQueryParameter['cargo_subgroup_id'] = cargoSubgroupId;
            }

            if (cargoSubgroupName) {
                localVarQueryParameter['cargo_subgroup_name'] = cargoSubgroupName;
            }

            if (marketType) {
                localVarQueryParameter['market_type'] = marketType;
            }

            if (backlog !== undefined) {
                localVarQueryParameter['backlog'] = backlog;
            }

            if (abandonedCars !== undefined) {
                localVarQueryParameter['abandoned_cars'] = abandonedCars;
            }

            if (planCompletion !== undefined) {
                localVarQueryParameter['plan_completion'] = planCompletion;
            }

            if (planCompletionGte !== undefined) {
                localVarQueryParameter['plan_completion_gte'] = planCompletionGte;
            }

            if (planCompletionLte !== undefined) {
                localVarQueryParameter['plan_completion_lte'] = planCompletionLte;
            }

            if (delays !== undefined) {
                localVarQueryParameter['delays'] = delays;
            }

            if (address75 !== undefined) {
                localVarQueryParameter['address_75'] = address75;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Supply Plan Data
         * @param {number} stationId 
         * @param {boolean} [oil] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoGroupName] 
         * @param {ResulModeEnum} [resultMode] 
         * @param {boolean} [addLoadedCars] 
         * @param {boolean} [addEmptyProposed] 
         * @param {boolean} [addEmptyProposedApproved] 
         * @param {boolean} [addLoadedProposed] 
         * @param {boolean} [loadingByPlan] 
         * @param {boolean} [loadingByAvailable] 
         * @param {boolean} [showShortage] 
         * @param {boolean} [firstLeg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplyPlanData: async (stationId: number, oil?: boolean, cargoGroupId?: Array<number>, cargoGroupName?: Array<string>, resultMode?: ResulModeEnum, addLoadedCars?: boolean, addEmptyProposed?: boolean, addEmptyProposedApproved?: boolean, addLoadedProposed?: boolean, loadingByPlan?: boolean, loadingByAvailable?: boolean, showShortage?: boolean, firstLeg?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stationId' is not null or undefined
            assertParamExists('getSupplyPlanData', 'stationId', stationId)
            const localVarPath = `/api/v1/supply_plan/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (stationId !== undefined) {
                localVarQueryParameter['station_id'] = stationId;
            }

            if (oil !== undefined) {
                localVarQueryParameter['oil'] = oil;
            }

            if (cargoGroupId) {
                localVarQueryParameter['cargo_group_id'] = cargoGroupId;
            }

            if (cargoGroupName) {
                localVarQueryParameter['cargo_group_name'] = cargoGroupName;
            }

            if (resultMode !== undefined) {
                localVarQueryParameter['result_mode'] = resultMode;
            }

            if (addLoadedCars !== undefined) {
                localVarQueryParameter['add_loaded_cars'] = addLoadedCars;
            }

            if (addEmptyProposed !== undefined) {
                localVarQueryParameter['add_empty_proposed'] = addEmptyProposed;
            }

            if (addEmptyProposedApproved !== undefined) {
                localVarQueryParameter['add_empty_proposed_approved'] = addEmptyProposedApproved;
            }

            if (addLoadedProposed !== undefined) {
                localVarQueryParameter['add_loaded_proposed'] = addLoadedProposed;
            }

            if (loadingByPlan !== undefined) {
                localVarQueryParameter['loading_by_plan'] = loadingByPlan;
            }

            if (loadingByAvailable !== undefined) {
                localVarQueryParameter['loading_by_available'] = loadingByAvailable;
            }

            if (showShortage !== undefined) {
                localVarQueryParameter['show_shortage'] = showShortage;
            }

            if (firstLeg !== undefined) {
                localVarQueryParameter['first_leg'] = firstLeg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Supply Request
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplyRequest: async (pk: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pk' is not null or undefined
            assertParamExists('getSupplyRequest', 'pk', pk)
            const localVarPath = `/api/v1/supply_requests/{pk}`
                .replace(`{${"pk"}}`, encodeURIComponent(String(pk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Supply Requests
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplyRequests: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/supply_requests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Verification File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationFile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/algo_runs/verification/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Info Calculation
         * @param {number} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCalculation: async (runId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('infoCalculation', 'runId', runId)
            const localVarPath = `/api/v1/algo_runs/{run_id}`
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Is Algo Running
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAlgoRunning: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/algo_runs/is_running/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Loaded Cargo Autocomplete
         * @param {LoadedCargoAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadedCargoAutocomplete: async (field?: LoadedCargoAutocompleteFields, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cargo/loaded/autocomplete/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Loading Stations Autocomplete
         * @param {LoadingStationsAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadingStationsAutocomplete: async (field?: LoadingStationsAutocompleteFields, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/loading_stations/autocomplete/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Proposed Trips Autocomplete
         * @param {ProposedTripsAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposedTripsAutocomplete: async (field?: ProposedTripsAutocompleteFields, query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trips/proposed/autocomplete/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ручка для отладки и тестирования: сбрасывает статус всех прогнозных рейсов на NEW
         * @summary Proposed Trips Reset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposedTripsReset: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trips/proposed/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Proposed Trips Sorting Fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposedTripsSortingFields: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trips/proposed/sorting_fields/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Proposed Trips Verdict
         * @param {ProposedTripVerdict} proposedTripVerdict 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposedTripsVerdict: async (proposedTripVerdict: ProposedTripVerdict, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proposedTripVerdict' is not null or undefined
            assertParamExists('proposedTripsVerdict', 'proposedTripVerdict', proposedTripVerdict)
            const localVarPath = `/api/v1/trips/proposed/verdict`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proposedTripVerdict, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VerificationApi - functional programming interface
 * @export
 */
export const VerificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VerificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Control Trips Autocomplete
         * @param {ControlTripsAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlTripsAutocomplete(field?: ControlTripsAutocompleteFields, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutocompleteResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlTripsAutocomplete(field, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Current Trips Autocomplete
         * @param {CurrentTripsAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentTripsAutocomplete(field?: CurrentTripsAutocompleteFields, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutocompleteResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentTripsAutocomplete(field, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate Trips
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTrips(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTrips(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Запуск оптимизатора на данных из ИС Транспортировка
         * @summary Generate Trips T13A
         * @param {string} [dislocationDatetime] 
         * @param {boolean} [usePreviouslyLoadedData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTripsT13a(dislocationDatetime?: string, usePreviouslyLoadedData?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTripsT13a(dislocationDatetime, usePreviouslyLoadedData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Algo Runs
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlgoRuns(pageSize: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlgoRunsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlgoRuns(pageSize, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Control Trips
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {number} [stationId] 
         * @param {Array<number>} [subgroupId] 
         * @param {Array<number>} [groupId] 
         * @param {ControlTripsSectionEnum} [section] 
         * @param {string} [date] 
         * @param {string} [dateGte] 
         * @param {string} [dateLte] 
         * @param {Array<string>} [washingStationName] 
         * @param {Array<string>} [parkingStationName] 
         * @param {boolean} [returnStationMatch] 
         * @param {boolean} [throughWashingStation] 
         * @param {boolean} [throughParkingStation] 
         * @param {boolean} [firstLeg] 
         * @param {string} [status] 
         * @param {Array<string>} [carNo] 
         * @param {Array<string>} [trainNo] 
         * @param {Array<string>} [fromStationName] 
         * @param {Array<string>} [toStationName] 
         * @param {Array<string>} [toOperation] 
         * @param {Array<string>} [cargoSubgroup] 
         * @param {Array<string>} [cargoGroup] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<string>} [fromRailway] 
         * @param {Array<string>} [fromStationEsr] 
         * @param {Array<string>} [toRailway] 
         * @param {Array<string>} [toStationEsr] 
         * @param {string} [arrivalEstimateLte] 
         * @param {string} [arrivalEstimateGte] 
         * @param {Array<string>} [carModel] 
         * @param {boolean} [isRented] 
         * @param {boolean} [fitting45Deg] 
         * @param {boolean} [address75] 
         * @param {boolean} [drainDeviceProt3] 
         * @param {boolean} [steamJacket] 
         * @param {boolean} [isOnWay] 
         * @param {boolean} [onRoute] 
         * @param {Array<string>} [returnStationName] 
         * @param {Array<string>} [returnStationEsr] 
         * @param {Array<string>} [operationStationName] 
         * @param {Array<string>} [operationStationEsr] 
         * @param {Array<string>} [operationRailway] 
         * @param {boolean} [carRzdCode92] 
         * @param {number} [opDurationLte] 
         * @param {number} [opDurationGte] 
         * @param {boolean} [isIdle] 
         * @param {number} [accessRemainingDistanceLte] 
         * @param {number} [accessRemainingDistanceGte] 
         * @param {boolean} [isOffScheduleRepair] 
         * @param {boolean} [isPlannedRepair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlTrips(pageSize: number, page?: number, stationId?: number, subgroupId?: Array<number>, groupId?: Array<number>, section?: ControlTripsSectionEnum, date?: string, dateGte?: string, dateLte?: string, washingStationName?: Array<string>, parkingStationName?: Array<string>, returnStationMatch?: boolean, throughWashingStation?: boolean, throughParkingStation?: boolean, firstLeg?: boolean, status?: string, carNo?: Array<string>, trainNo?: Array<string>, fromStationName?: Array<string>, toStationName?: Array<string>, toOperation?: Array<string>, cargoSubgroup?: Array<string>, cargoGroup?: Array<string>, cargoGroupId?: Array<number>, cargoName?: Array<string>, fromRailway?: Array<string>, fromStationEsr?: Array<string>, toRailway?: Array<string>, toStationEsr?: Array<string>, arrivalEstimateLte?: string, arrivalEstimateGte?: string, carModel?: Array<string>, isRented?: boolean, fitting45Deg?: boolean, address75?: boolean, drainDeviceProt3?: boolean, steamJacket?: boolean, isOnWay?: boolean, onRoute?: boolean, returnStationName?: Array<string>, returnStationEsr?: Array<string>, operationStationName?: Array<string>, operationStationEsr?: Array<string>, operationRailway?: Array<string>, carRzdCode92?: boolean, opDurationLte?: number, opDurationGte?: number, isIdle?: boolean, accessRemainingDistanceLte?: number, accessRemainingDistanceGte?: number, isOffScheduleRepair?: boolean, isPlannedRepair?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlTripsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlTrips(pageSize, page, stationId, subgroupId, groupId, section, date, dateGte, dateLte, washingStationName, parkingStationName, returnStationMatch, throughWashingStation, throughParkingStation, firstLeg, status, carNo, trainNo, fromStationName, toStationName, toOperation, cargoSubgroup, cargoGroup, cargoGroupId, cargoName, fromRailway, fromStationEsr, toRailway, toStationEsr, arrivalEstimateLte, arrivalEstimateGte, carModel, isRented, fitting45Deg, address75, drainDeviceProt3, steamJacket, isOnWay, onRoute, returnStationName, returnStationEsr, operationStationName, operationStationEsr, operationRailway, carRzdCode92, opDurationLte, opDurationGte, isIdle, accessRemainingDistanceLte, accessRemainingDistanceGte, isOffScheduleRepair, isPlannedRepair, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Control Trips Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlTripsFilters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiltersResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlTripsFilters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Current Trip
         * @param {number} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentTrip(tripId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentTripResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentTrip(tripId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Current Trips
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {Array<number>} [id] 
         * @param {Array<string>} [carNo] 
         * @param {Array<string>} [trainNo] 
         * @param {Array<string>} [fromStationName] 
         * @param {Array<string>} [toStationName] 
         * @param {Array<string>} [toOperation] 
         * @param {Array<string>} [cargoSubgroup] 
         * @param {Array<string>} [cargoGroup] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<string>} [fromRailway] 
         * @param {Array<string>} [fromStationEsr] 
         * @param {Array<string>} [toRailway] 
         * @param {Array<string>} [toStationEsr] 
         * @param {string} [arrivalEstimateLte] 
         * @param {string} [arrivalEstimateGte] 
         * @param {Array<string>} [carModel] 
         * @param {boolean} [isRented] 
         * @param {boolean} [fitting45Deg] 
         * @param {boolean} [address75] 
         * @param {boolean} [drainDeviceProt3] 
         * @param {boolean} [steamJacket] 
         * @param {boolean} [isOnWay] 
         * @param {boolean} [onRoute] 
         * @param {Array<string>} [returnStationName] 
         * @param {Array<string>} [returnStationEsr] 
         * @param {Array<string>} [operationStationName] 
         * @param {Array<string>} [operationStationEsr] 
         * @param {Array<string>} [operationRailway] 
         * @param {boolean} [carRzdCode92] 
         * @param {string} [utilizationType] 
         * @param {number} [opDurationLte] 
         * @param {number} [opDurationGte] 
         * @param {boolean} [isIdle] 
         * @param {number} [accessRemainingDistanceLte] 
         * @param {number} [accessRemainingDistanceGte] 
         * @param {boolean} [isOffScheduleRepair] 
         * @param {boolean} [isPlannedRepair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentTrips(pageSize: number, page?: number, id?: Array<number>, carNo?: Array<string>, trainNo?: Array<string>, fromStationName?: Array<string>, toStationName?: Array<string>, toOperation?: Array<string>, cargoSubgroup?: Array<string>, cargoGroup?: Array<string>, cargoGroupId?: Array<number>, cargoName?: Array<string>, fromRailway?: Array<string>, fromStationEsr?: Array<string>, toRailway?: Array<string>, toStationEsr?: Array<string>, arrivalEstimateLte?: string, arrivalEstimateGte?: string, carModel?: Array<string>, isRented?: boolean, fitting45Deg?: boolean, address75?: boolean, drainDeviceProt3?: boolean, steamJacket?: boolean, isOnWay?: boolean, onRoute?: boolean, returnStationName?: Array<string>, returnStationEsr?: Array<string>, operationStationName?: Array<string>, operationStationEsr?: Array<string>, operationRailway?: Array<string>, carRzdCode92?: boolean, utilizationType?: string, opDurationLte?: number, opDurationGte?: number, isIdle?: boolean, accessRemainingDistanceLte?: number, accessRemainingDistanceGte?: number, isOffScheduleRepair?: boolean, isPlannedRepair?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentTripsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentTrips(pageSize, page, id, carNo, trainNo, fromStationName, toStationName, toOperation, cargoSubgroup, cargoGroup, cargoGroupId, cargoName, fromRailway, fromStationEsr, toRailway, toStationEsr, arrivalEstimateLte, arrivalEstimateGte, carModel, isRented, fitting45Deg, address75, drainDeviceProt3, steamJacket, isOnWay, onRoute, returnStationName, returnStationEsr, operationStationName, operationStationEsr, operationRailway, carRzdCode92, utilizationType, opDurationLte, opDurationGte, isIdle, accessRemainingDistanceLte, accessRemainingDistanceGte, isOffScheduleRepair, isPlannedRepair, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Current Trips Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentTripsFilters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiltersResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentTripsFilters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Loaded Cargo Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoadedCargoFilters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiltersResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoadedCargoFilters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Loading Stations
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {Array<number>} [id] 
         * @param {Array<string>} [name] 
         * @param {Array<string>} [code6] 
         * @param {Array<number>} [railwayId] 
         * @param {Array<string>} [railwayName] 
         * @param {Array<number>} [cargoId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoGroupName] 
         * @param {Array<number>} [cargoSubgroupId] 
         * @param {Array<string>} [cargoSubgroupName] 
         * @param {Array<string>} [marketType] 
         * @param {boolean} [backlog] 
         * @param {boolean} [abandonedCars] 
         * @param {number} [planCompletion] 
         * @param {number} [planCompletionGte] 
         * @param {number} [planCompletionLte] 
         * @param {boolean} [delays] 
         * @param {boolean} [address75] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoadingStations(pageSize: number, page?: number, id?: Array<number>, name?: Array<string>, code6?: Array<string>, railwayId?: Array<number>, railwayName?: Array<string>, cargoId?: Array<number>, cargoName?: Array<string>, cargoGroupId?: Array<number>, cargoGroupName?: Array<string>, cargoSubgroupId?: Array<number>, cargoSubgroupName?: Array<string>, marketType?: Array<string>, backlog?: boolean, abandonedCars?: boolean, planCompletion?: number, planCompletionGte?: number, planCompletionLte?: number, delays?: boolean, address75?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoadingStationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoadingStations(pageSize, page, id, name, code6, railwayId, railwayName, cargoId, cargoName, cargoGroupId, cargoGroupName, cargoSubgroupId, cargoSubgroupName, marketType, backlog, abandonedCars, planCompletion, planCompletionGte, planCompletionLte, delays, address75, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Loading Stations Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoadingStationsFilters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiltersResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoadingStationsFilters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Logs File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogsFile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogsFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Logs File Live
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogsFileLive(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogsFileLive(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Proposed Trips
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {Array<number>} [id] 
         * @param {string} [departureDate] Дата отправления
         * @param {string} [departureDateGte] Дата отправления (больше или равно)
         * @param {string} [departureDateLte] Дата отправления (меньше или равно)
         * @param {Array<number>} [departureStationId] 
         * @param {Array<string>} [departureStationName] 
         * @param {Array<string>} [departureStationCode6] 
         * @param {Array<number>} [departureStationRailwayId] 
         * @param {Array<string>} [departureStationRailwayName] 
         * @param {string} [arrivalDate] Предполагаемая дата прибытия
         * @param {string} [arrivalDateGte] Предполагаемая дата прибытия (больше или равно)
         * @param {string} [arrivalDateLte] Предполагаемая дата прибытия (меньше или равно)
         * @param {Array<number>} [arrivalStationId] 
         * @param {Array<string>} [arrivalStationName] 
         * @param {Array<string>} [arrivalStationCode6] 
         * @param {Array<number>} [arrivalStationRailwayId] 
         * @param {Array<string>} [arrivalStationRailwayName] 
         * @param {Array<string>} [arrivalOperation] Операция, которой должен подвергнуться вагон на станции назначения
         * @param {Array<number>} [carId] 
         * @param {Array<number>} [carNo] Идентификационный номер вагона
         * @param {boolean} [carRzdCode92] Код операции РЖД 92
         * @param {boolean} [carIsRented] 
         * @param {boolean} [carFitting45Deg] Признак \&quot;Арматура 45 град\&quot;
         * @param {boolean} [carDrainDeviceProt3] Признак \&quot;Сливное устройство с тремя степенями защиты\&quot;
         * @param {Array<number>} [carModelId] 
         * @param {Array<string>} [carModelName] Модель вагона
         * @param {boolean} [carModelSteamJacket] Признак наличия паровой рубашки
         * @param {Array<number>} [cargoId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoGroupName] 
         * @param {Array<number>} [cargoSubgroupId] 
         * @param {Array<string>} [cargoSubgroupName] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProposedTrips(pageSize: number, page?: number, id?: Array<number>, departureDate?: string, departureDateGte?: string, departureDateLte?: string, departureStationId?: Array<number>, departureStationName?: Array<string>, departureStationCode6?: Array<string>, departureStationRailwayId?: Array<number>, departureStationRailwayName?: Array<string>, arrivalDate?: string, arrivalDateGte?: string, arrivalDateLte?: string, arrivalStationId?: Array<number>, arrivalStationName?: Array<string>, arrivalStationCode6?: Array<string>, arrivalStationRailwayId?: Array<number>, arrivalStationRailwayName?: Array<string>, arrivalOperation?: Array<string>, carId?: Array<number>, carNo?: Array<number>, carRzdCode92?: boolean, carIsRented?: boolean, carFitting45Deg?: boolean, carDrainDeviceProt3?: boolean, carModelId?: Array<number>, carModelName?: Array<string>, carModelSteamJacket?: boolean, cargoId?: Array<number>, cargoName?: Array<string>, cargoGroupId?: Array<number>, cargoGroupName?: Array<string>, cargoSubgroupId?: Array<number>, cargoSubgroupName?: Array<string>, sortBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedTripsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProposedTrips(pageSize, page, id, departureDate, departureDateGte, departureDateLte, departureStationId, departureStationName, departureStationCode6, departureStationRailwayId, departureStationRailwayName, arrivalDate, arrivalDateGte, arrivalDateLte, arrivalStationId, arrivalStationName, arrivalStationCode6, arrivalStationRailwayId, arrivalStationRailwayName, arrivalOperation, carId, carNo, carRzdCode92, carIsRented, carFitting45Deg, carDrainDeviceProt3, carModelId, carModelName, carModelSteamJacket, cargoId, cargoName, cargoGroupId, cargoGroupName, cargoSubgroupId, cargoSubgroupName, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Proposed Trips Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProposedTripsFilters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiltersResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProposedTripsFilters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Proposed Trips Verdict Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProposedTripsVerdictReasons(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedTripVerdictReasons>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProposedTripsVerdictReasons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Result File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResultFile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResultFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Station Charts
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStationCharts(pk: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoadingStationChartsData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStationCharts(pk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Station Markers
         * @param {number} pk 
         * @param {LoadingStationMarkersViewEnum} [view] 
         * @param {LoadingStationMarkersDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStationMarkers(pk: number, view?: LoadingStationMarkersViewEnum, direction?: LoadingStationMarkersDirectionEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoadingStationMarkers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStationMarkers(pk, view, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Station Summary
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStationSummary(pk: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoadingStationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStationSummary(pk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Stations Charts
         * @param {Array<number>} [id] 
         * @param {Array<string>} [name] 
         * @param {Array<string>} [code6] 
         * @param {Array<number>} [railwayId] 
         * @param {Array<string>} [railwayName] 
         * @param {Array<number>} [cargoId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoGroupName] 
         * @param {Array<number>} [cargoSubgroupId] 
         * @param {Array<string>} [cargoSubgroupName] 
         * @param {Array<string>} [marketType] 
         * @param {boolean} [backlog] 
         * @param {boolean} [abandonedCars] 
         * @param {number} [planCompletion] 
         * @param {number} [planCompletionGte] 
         * @param {number} [planCompletionLte] 
         * @param {boolean} [delays] 
         * @param {boolean} [address75] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStationsCharts(id?: Array<number>, name?: Array<string>, code6?: Array<string>, railwayId?: Array<number>, railwayName?: Array<string>, cargoId?: Array<number>, cargoName?: Array<string>, cargoGroupId?: Array<number>, cargoGroupName?: Array<string>, cargoSubgroupId?: Array<number>, cargoSubgroupName?: Array<string>, marketType?: Array<string>, backlog?: boolean, abandonedCars?: boolean, planCompletion?: number, planCompletionGte?: number, planCompletionLte?: number, delays?: boolean, address75?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoadingStationChartsData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStationsCharts(id, name, code6, railwayId, railwayName, cargoId, cargoName, cargoGroupId, cargoGroupName, cargoSubgroupId, cargoSubgroupName, marketType, backlog, abandonedCars, planCompletion, planCompletionGte, planCompletionLte, delays, address75, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Supply Plan Data
         * @param {number} stationId 
         * @param {boolean} [oil] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoGroupName] 
         * @param {ResulModeEnum} [resultMode] 
         * @param {boolean} [addLoadedCars] 
         * @param {boolean} [addEmptyProposed] 
         * @param {boolean} [addEmptyProposedApproved] 
         * @param {boolean} [addLoadedProposed] 
         * @param {boolean} [loadingByPlan] 
         * @param {boolean} [loadingByAvailable] 
         * @param {boolean} [showShortage] 
         * @param {boolean} [firstLeg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupplyPlanData(stationId: number, oil?: boolean, cargoGroupId?: Array<number>, cargoGroupName?: Array<string>, resultMode?: ResulModeEnum, addLoadedCars?: boolean, addEmptyProposed?: boolean, addEmptyProposedApproved?: boolean, addLoadedProposed?: boolean, loadingByPlan?: boolean, loadingByAvailable?: boolean, showShortage?: boolean, firstLeg?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplyPlanData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupplyPlanData(stationId, oil, cargoGroupId, cargoGroupName, resultMode, addLoadedCars, addEmptyProposed, addEmptyProposedApproved, addLoadedProposed, loadingByPlan, loadingByAvailable, showShortage, firstLeg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Supply Request
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupplyRequest(pk: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplyRequestsRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupplyRequest(pk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Supply Requests
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupplyRequests(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SupplyRequestsRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupplyRequests(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Verification File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerificationFile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerificationFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Info Calculation
         * @param {number} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoCalculation(runId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlgoRunRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.infoCalculation(runId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Is Algo Running
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isAlgoRunning(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunningAlgoExistsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isAlgoRunning(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Loaded Cargo Autocomplete
         * @param {LoadedCargoAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadedCargoAutocomplete(field?: LoadedCargoAutocompleteFields, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutocompleteResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadedCargoAutocomplete(field, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Loading Stations Autocomplete
         * @param {LoadingStationsAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadingStationsAutocomplete(field?: LoadingStationsAutocompleteFields, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutocompleteResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadingStationsAutocomplete(field, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Proposed Trips Autocomplete
         * @param {ProposedTripsAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposedTripsAutocomplete(field?: ProposedTripsAutocompleteFields, query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutocompleteResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposedTripsAutocomplete(field, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ручка для отладки и тестирования: сбрасывает статус всех прогнозных рейсов на NEW
         * @summary Proposed Trips Reset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposedTripsReset(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposedTripsReset(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Proposed Trips Sorting Fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposedTripsSortingFields(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SortingFieldsResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposedTripsSortingFields(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Proposed Trips Verdict
         * @param {ProposedTripVerdict} proposedTripVerdict 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposedTripsVerdict(proposedTripVerdict: ProposedTripVerdict, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposedTripsVerdict(proposedTripVerdict, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VerificationApi - factory interface
 * @export
 */
export const VerificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VerificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Control Trips Autocomplete
         * @param {ControlTripsAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlTripsAutocomplete(field?: ControlTripsAutocompleteFields, query?: string, options?: any): AxiosPromise<AutocompleteResponseSchema> {
            return localVarFp.controlTripsAutocomplete(field, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Current Trips Autocomplete
         * @param {CurrentTripsAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentTripsAutocomplete(field?: CurrentTripsAutocompleteFields, query?: string, options?: any): AxiosPromise<AutocompleteResponseSchema> {
            return localVarFp.currentTripsAutocomplete(field, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate Trips
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTrips(options?: any): AxiosPromise<any> {
            return localVarFp.generateTrips(options).then((request) => request(axios, basePath));
        },
        /**
         * Запуск оптимизатора на данных из ИС Транспортировка
         * @summary Generate Trips T13A
         * @param {string} [dislocationDatetime] 
         * @param {boolean} [usePreviouslyLoadedData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTripsT13a(dislocationDatetime?: string, usePreviouslyLoadedData?: boolean, options?: any): AxiosPromise<any> {
            return localVarFp.generateTripsT13a(dislocationDatetime, usePreviouslyLoadedData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Algo Runs
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlgoRuns(pageSize: number, page?: number, options?: any): AxiosPromise<AlgoRunsListResponse> {
            return localVarFp.getAlgoRuns(pageSize, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Control Trips
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {number} [stationId] 
         * @param {Array<number>} [subgroupId] 
         * @param {Array<number>} [groupId] 
         * @param {ControlTripsSectionEnum} [section] 
         * @param {string} [date] 
         * @param {string} [dateGte] 
         * @param {string} [dateLte] 
         * @param {Array<string>} [washingStationName] 
         * @param {Array<string>} [parkingStationName] 
         * @param {boolean} [returnStationMatch] 
         * @param {boolean} [throughWashingStation] 
         * @param {boolean} [throughParkingStation] 
         * @param {boolean} [firstLeg] 
         * @param {string} [status] 
         * @param {Array<string>} [carNo] 
         * @param {Array<string>} [trainNo] 
         * @param {Array<string>} [fromStationName] 
         * @param {Array<string>} [toStationName] 
         * @param {Array<string>} [toOperation] 
         * @param {Array<string>} [cargoSubgroup] 
         * @param {Array<string>} [cargoGroup] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<string>} [fromRailway] 
         * @param {Array<string>} [fromStationEsr] 
         * @param {Array<string>} [toRailway] 
         * @param {Array<string>} [toStationEsr] 
         * @param {string} [arrivalEstimateLte] 
         * @param {string} [arrivalEstimateGte] 
         * @param {Array<string>} [carModel] 
         * @param {boolean} [isRented] 
         * @param {boolean} [fitting45Deg] 
         * @param {boolean} [address75] 
         * @param {boolean} [drainDeviceProt3] 
         * @param {boolean} [steamJacket] 
         * @param {boolean} [isOnWay] 
         * @param {boolean} [onRoute] 
         * @param {Array<string>} [returnStationName] 
         * @param {Array<string>} [returnStationEsr] 
         * @param {Array<string>} [operationStationName] 
         * @param {Array<string>} [operationStationEsr] 
         * @param {Array<string>} [operationRailway] 
         * @param {boolean} [carRzdCode92] 
         * @param {number} [opDurationLte] 
         * @param {number} [opDurationGte] 
         * @param {boolean} [isIdle] 
         * @param {number} [accessRemainingDistanceLte] 
         * @param {number} [accessRemainingDistanceGte] 
         * @param {boolean} [isOffScheduleRepair] 
         * @param {boolean} [isPlannedRepair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlTrips(pageSize: number, page?: number, stationId?: number, subgroupId?: Array<number>, groupId?: Array<number>, section?: ControlTripsSectionEnum, date?: string, dateGte?: string, dateLte?: string, washingStationName?: Array<string>, parkingStationName?: Array<string>, returnStationMatch?: boolean, throughWashingStation?: boolean, throughParkingStation?: boolean, firstLeg?: boolean, status?: string, carNo?: Array<string>, trainNo?: Array<string>, fromStationName?: Array<string>, toStationName?: Array<string>, toOperation?: Array<string>, cargoSubgroup?: Array<string>, cargoGroup?: Array<string>, cargoGroupId?: Array<number>, cargoName?: Array<string>, fromRailway?: Array<string>, fromStationEsr?: Array<string>, toRailway?: Array<string>, toStationEsr?: Array<string>, arrivalEstimateLte?: string, arrivalEstimateGte?: string, carModel?: Array<string>, isRented?: boolean, fitting45Deg?: boolean, address75?: boolean, drainDeviceProt3?: boolean, steamJacket?: boolean, isOnWay?: boolean, onRoute?: boolean, returnStationName?: Array<string>, returnStationEsr?: Array<string>, operationStationName?: Array<string>, operationStationEsr?: Array<string>, operationRailway?: Array<string>, carRzdCode92?: boolean, opDurationLte?: number, opDurationGte?: number, isIdle?: boolean, accessRemainingDistanceLte?: number, accessRemainingDistanceGte?: number, isOffScheduleRepair?: boolean, isPlannedRepair?: boolean, options?: any): AxiosPromise<ControlTripsResponse> {
            return localVarFp.getControlTrips(pageSize, page, stationId, subgroupId, groupId, section, date, dateGte, dateLte, washingStationName, parkingStationName, returnStationMatch, throughWashingStation, throughParkingStation, firstLeg, status, carNo, trainNo, fromStationName, toStationName, toOperation, cargoSubgroup, cargoGroup, cargoGroupId, cargoName, fromRailway, fromStationEsr, toRailway, toStationEsr, arrivalEstimateLte, arrivalEstimateGte, carModel, isRented, fitting45Deg, address75, drainDeviceProt3, steamJacket, isOnWay, onRoute, returnStationName, returnStationEsr, operationStationName, operationStationEsr, operationRailway, carRzdCode92, opDurationLte, opDurationGte, isIdle, accessRemainingDistanceLte, accessRemainingDistanceGte, isOffScheduleRepair, isPlannedRepair, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Control Trips Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlTripsFilters(options?: any): AxiosPromise<FiltersResponseSchema> {
            return localVarFp.getControlTripsFilters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Current Trip
         * @param {number} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTrip(tripId: number, options?: any): AxiosPromise<CurrentTripResponse> {
            return localVarFp.getCurrentTrip(tripId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Current Trips
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {Array<number>} [id] 
         * @param {Array<string>} [carNo] 
         * @param {Array<string>} [trainNo] 
         * @param {Array<string>} [fromStationName] 
         * @param {Array<string>} [toStationName] 
         * @param {Array<string>} [toOperation] 
         * @param {Array<string>} [cargoSubgroup] 
         * @param {Array<string>} [cargoGroup] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<string>} [fromRailway] 
         * @param {Array<string>} [fromStationEsr] 
         * @param {Array<string>} [toRailway] 
         * @param {Array<string>} [toStationEsr] 
         * @param {string} [arrivalEstimateLte] 
         * @param {string} [arrivalEstimateGte] 
         * @param {Array<string>} [carModel] 
         * @param {boolean} [isRented] 
         * @param {boolean} [fitting45Deg] 
         * @param {boolean} [address75] 
         * @param {boolean} [drainDeviceProt3] 
         * @param {boolean} [steamJacket] 
         * @param {boolean} [isOnWay] 
         * @param {boolean} [onRoute] 
         * @param {Array<string>} [returnStationName] 
         * @param {Array<string>} [returnStationEsr] 
         * @param {Array<string>} [operationStationName] 
         * @param {Array<string>} [operationStationEsr] 
         * @param {Array<string>} [operationRailway] 
         * @param {boolean} [carRzdCode92] 
         * @param {string} [utilizationType] 
         * @param {number} [opDurationLte] 
         * @param {number} [opDurationGte] 
         * @param {boolean} [isIdle] 
         * @param {number} [accessRemainingDistanceLte] 
         * @param {number} [accessRemainingDistanceGte] 
         * @param {boolean} [isOffScheduleRepair] 
         * @param {boolean} [isPlannedRepair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTrips(pageSize: number, page?: number, id?: Array<number>, carNo?: Array<string>, trainNo?: Array<string>, fromStationName?: Array<string>, toStationName?: Array<string>, toOperation?: Array<string>, cargoSubgroup?: Array<string>, cargoGroup?: Array<string>, cargoGroupId?: Array<number>, cargoName?: Array<string>, fromRailway?: Array<string>, fromStationEsr?: Array<string>, toRailway?: Array<string>, toStationEsr?: Array<string>, arrivalEstimateLte?: string, arrivalEstimateGte?: string, carModel?: Array<string>, isRented?: boolean, fitting45Deg?: boolean, address75?: boolean, drainDeviceProt3?: boolean, steamJacket?: boolean, isOnWay?: boolean, onRoute?: boolean, returnStationName?: Array<string>, returnStationEsr?: Array<string>, operationStationName?: Array<string>, operationStationEsr?: Array<string>, operationRailway?: Array<string>, carRzdCode92?: boolean, utilizationType?: string, opDurationLte?: number, opDurationGte?: number, isIdle?: boolean, accessRemainingDistanceLte?: number, accessRemainingDistanceGte?: number, isOffScheduleRepair?: boolean, isPlannedRepair?: boolean, options?: any): AxiosPromise<CurrentTripsResponse> {
            return localVarFp.getCurrentTrips(pageSize, page, id, carNo, trainNo, fromStationName, toStationName, toOperation, cargoSubgroup, cargoGroup, cargoGroupId, cargoName, fromRailway, fromStationEsr, toRailway, toStationEsr, arrivalEstimateLte, arrivalEstimateGte, carModel, isRented, fitting45Deg, address75, drainDeviceProt3, steamJacket, isOnWay, onRoute, returnStationName, returnStationEsr, operationStationName, operationStationEsr, operationRailway, carRzdCode92, utilizationType, opDurationLte, opDurationGte, isIdle, accessRemainingDistanceLte, accessRemainingDistanceGte, isOffScheduleRepair, isPlannedRepair, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Current Trips Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTripsFilters(options?: any): AxiosPromise<FiltersResponseSchema> {
            return localVarFp.getCurrentTripsFilters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Loaded Cargo Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoadedCargoFilters(options?: any): AxiosPromise<FiltersResponseSchema> {
            return localVarFp.getLoadedCargoFilters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Loading Stations
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {Array<number>} [id] 
         * @param {Array<string>} [name] 
         * @param {Array<string>} [code6] 
         * @param {Array<number>} [railwayId] 
         * @param {Array<string>} [railwayName] 
         * @param {Array<number>} [cargoId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoGroupName] 
         * @param {Array<number>} [cargoSubgroupId] 
         * @param {Array<string>} [cargoSubgroupName] 
         * @param {Array<string>} [marketType] 
         * @param {boolean} [backlog] 
         * @param {boolean} [abandonedCars] 
         * @param {number} [planCompletion] 
         * @param {number} [planCompletionGte] 
         * @param {number} [planCompletionLte] 
         * @param {boolean} [delays] 
         * @param {boolean} [address75] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoadingStations(pageSize: number, page?: number, id?: Array<number>, name?: Array<string>, code6?: Array<string>, railwayId?: Array<number>, railwayName?: Array<string>, cargoId?: Array<number>, cargoName?: Array<string>, cargoGroupId?: Array<number>, cargoGroupName?: Array<string>, cargoSubgroupId?: Array<number>, cargoSubgroupName?: Array<string>, marketType?: Array<string>, backlog?: boolean, abandonedCars?: boolean, planCompletion?: number, planCompletionGte?: number, planCompletionLte?: number, delays?: boolean, address75?: boolean, options?: any): AxiosPromise<LoadingStationsResponse> {
            return localVarFp.getLoadingStations(pageSize, page, id, name, code6, railwayId, railwayName, cargoId, cargoName, cargoGroupId, cargoGroupName, cargoSubgroupId, cargoSubgroupName, marketType, backlog, abandonedCars, planCompletion, planCompletionGte, planCompletionLte, delays, address75, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Loading Stations Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoadingStationsFilters(options?: any): AxiosPromise<FiltersResponseSchema> {
            return localVarFp.getLoadingStationsFilters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Logs File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsFile(options?: any): AxiosPromise<void> {
            return localVarFp.getLogsFile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Logs File Live
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsFileLive(options?: any): AxiosPromise<any> {
            return localVarFp.getLogsFileLive(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Proposed Trips
         * @param {number} pageSize 
         * @param {number} [page] 
         * @param {Array<number>} [id] 
         * @param {string} [departureDate] Дата отправления
         * @param {string} [departureDateGte] Дата отправления (больше или равно)
         * @param {string} [departureDateLte] Дата отправления (меньше или равно)
         * @param {Array<number>} [departureStationId] 
         * @param {Array<string>} [departureStationName] 
         * @param {Array<string>} [departureStationCode6] 
         * @param {Array<number>} [departureStationRailwayId] 
         * @param {Array<string>} [departureStationRailwayName] 
         * @param {string} [arrivalDate] Предполагаемая дата прибытия
         * @param {string} [arrivalDateGte] Предполагаемая дата прибытия (больше или равно)
         * @param {string} [arrivalDateLte] Предполагаемая дата прибытия (меньше или равно)
         * @param {Array<number>} [arrivalStationId] 
         * @param {Array<string>} [arrivalStationName] 
         * @param {Array<string>} [arrivalStationCode6] 
         * @param {Array<number>} [arrivalStationRailwayId] 
         * @param {Array<string>} [arrivalStationRailwayName] 
         * @param {Array<string>} [arrivalOperation] Операция, которой должен подвергнуться вагон на станции назначения
         * @param {Array<number>} [carId] 
         * @param {Array<number>} [carNo] Идентификационный номер вагона
         * @param {boolean} [carRzdCode92] Код операции РЖД 92
         * @param {boolean} [carIsRented] 
         * @param {boolean} [carFitting45Deg] Признак \&quot;Арматура 45 град\&quot;
         * @param {boolean} [carDrainDeviceProt3] Признак \&quot;Сливное устройство с тремя степенями защиты\&quot;
         * @param {Array<number>} [carModelId] 
         * @param {Array<string>} [carModelName] Модель вагона
         * @param {boolean} [carModelSteamJacket] Признак наличия паровой рубашки
         * @param {Array<number>} [cargoId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoGroupName] 
         * @param {Array<number>} [cargoSubgroupId] 
         * @param {Array<string>} [cargoSubgroupName] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposedTrips(pageSize: number, page?: number, id?: Array<number>, departureDate?: string, departureDateGte?: string, departureDateLte?: string, departureStationId?: Array<number>, departureStationName?: Array<string>, departureStationCode6?: Array<string>, departureStationRailwayId?: Array<number>, departureStationRailwayName?: Array<string>, arrivalDate?: string, arrivalDateGte?: string, arrivalDateLte?: string, arrivalStationId?: Array<number>, arrivalStationName?: Array<string>, arrivalStationCode6?: Array<string>, arrivalStationRailwayId?: Array<number>, arrivalStationRailwayName?: Array<string>, arrivalOperation?: Array<string>, carId?: Array<number>, carNo?: Array<number>, carRzdCode92?: boolean, carIsRented?: boolean, carFitting45Deg?: boolean, carDrainDeviceProt3?: boolean, carModelId?: Array<number>, carModelName?: Array<string>, carModelSteamJacket?: boolean, cargoId?: Array<number>, cargoName?: Array<string>, cargoGroupId?: Array<number>, cargoGroupName?: Array<string>, cargoSubgroupId?: Array<number>, cargoSubgroupName?: Array<string>, sortBy?: Array<string>, options?: any): AxiosPromise<ProposedTripsResponse> {
            return localVarFp.getProposedTrips(pageSize, page, id, departureDate, departureDateGte, departureDateLte, departureStationId, departureStationName, departureStationCode6, departureStationRailwayId, departureStationRailwayName, arrivalDate, arrivalDateGte, arrivalDateLte, arrivalStationId, arrivalStationName, arrivalStationCode6, arrivalStationRailwayId, arrivalStationRailwayName, arrivalOperation, carId, carNo, carRzdCode92, carIsRented, carFitting45Deg, carDrainDeviceProt3, carModelId, carModelName, carModelSteamJacket, cargoId, cargoName, cargoGroupId, cargoGroupName, cargoSubgroupId, cargoSubgroupName, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Proposed Trips Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposedTripsFilters(options?: any): AxiosPromise<FiltersResponseSchema> {
            return localVarFp.getProposedTripsFilters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Proposed Trips Verdict Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposedTripsVerdictReasons(options?: any): AxiosPromise<ProposedTripVerdictReasons> {
            return localVarFp.getProposedTripsVerdictReasons(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Result File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultFile(options?: any): AxiosPromise<void> {
            return localVarFp.getResultFile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Station Charts
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationCharts(pk: number, options?: any): AxiosPromise<LoadingStationChartsData> {
            return localVarFp.getStationCharts(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Station Markers
         * @param {number} pk 
         * @param {LoadingStationMarkersViewEnum} [view] 
         * @param {LoadingStationMarkersDirectionEnum} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationMarkers(pk: number, view?: LoadingStationMarkersViewEnum, direction?: LoadingStationMarkersDirectionEnum, options?: any): AxiosPromise<LoadingStationMarkers> {
            return localVarFp.getStationMarkers(pk, view, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Station Summary
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationSummary(pk: number, options?: any): AxiosPromise<LoadingStationSummary> {
            return localVarFp.getStationSummary(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Stations Charts
         * @param {Array<number>} [id] 
         * @param {Array<string>} [name] 
         * @param {Array<string>} [code6] 
         * @param {Array<number>} [railwayId] 
         * @param {Array<string>} [railwayName] 
         * @param {Array<number>} [cargoId] 
         * @param {Array<string>} [cargoName] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoGroupName] 
         * @param {Array<number>} [cargoSubgroupId] 
         * @param {Array<string>} [cargoSubgroupName] 
         * @param {Array<string>} [marketType] 
         * @param {boolean} [backlog] 
         * @param {boolean} [abandonedCars] 
         * @param {number} [planCompletion] 
         * @param {number} [planCompletionGte] 
         * @param {number} [planCompletionLte] 
         * @param {boolean} [delays] 
         * @param {boolean} [address75] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationsCharts(id?: Array<number>, name?: Array<string>, code6?: Array<string>, railwayId?: Array<number>, railwayName?: Array<string>, cargoId?: Array<number>, cargoName?: Array<string>, cargoGroupId?: Array<number>, cargoGroupName?: Array<string>, cargoSubgroupId?: Array<number>, cargoSubgroupName?: Array<string>, marketType?: Array<string>, backlog?: boolean, abandonedCars?: boolean, planCompletion?: number, planCompletionGte?: number, planCompletionLte?: number, delays?: boolean, address75?: boolean, options?: any): AxiosPromise<LoadingStationChartsData> {
            return localVarFp.getStationsCharts(id, name, code6, railwayId, railwayName, cargoId, cargoName, cargoGroupId, cargoGroupName, cargoSubgroupId, cargoSubgroupName, marketType, backlog, abandonedCars, planCompletion, planCompletionGte, planCompletionLte, delays, address75, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Supply Plan Data
         * @param {number} stationId 
         * @param {boolean} [oil] 
         * @param {Array<number>} [cargoGroupId] 
         * @param {Array<string>} [cargoGroupName] 
         * @param {ResulModeEnum} [resultMode] 
         * @param {boolean} [addLoadedCars] 
         * @param {boolean} [addEmptyProposed] 
         * @param {boolean} [addEmptyProposedApproved] 
         * @param {boolean} [addLoadedProposed] 
         * @param {boolean} [loadingByPlan] 
         * @param {boolean} [loadingByAvailable] 
         * @param {boolean} [showShortage] 
         * @param {boolean} [firstLeg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplyPlanData(stationId: number, oil?: boolean, cargoGroupId?: Array<number>, cargoGroupName?: Array<string>, resultMode?: ResulModeEnum, addLoadedCars?: boolean, addEmptyProposed?: boolean, addEmptyProposedApproved?: boolean, addLoadedProposed?: boolean, loadingByPlan?: boolean, loadingByAvailable?: boolean, showShortage?: boolean, firstLeg?: boolean, options?: any): AxiosPromise<SupplyPlanData> {
            return localVarFp.getSupplyPlanData(stationId, oil, cargoGroupId, cargoGroupName, resultMode, addLoadedCars, addEmptyProposed, addEmptyProposedApproved, addLoadedProposed, loadingByPlan, loadingByAvailable, showShortage, firstLeg, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Supply Request
         * @param {number} pk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplyRequest(pk: number, options?: any): AxiosPromise<SupplyRequestsRead> {
            return localVarFp.getSupplyRequest(pk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Supply Requests
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplyRequests(skip?: number, limit?: number, options?: any): AxiosPromise<Array<SupplyRequestsRead>> {
            return localVarFp.getSupplyRequests(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Verification File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationFile(options?: any): AxiosPromise<void> {
            return localVarFp.getVerificationFile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Info Calculation
         * @param {number} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCalculation(runId: number, options?: any): AxiosPromise<AlgoRunRead> {
            return localVarFp.infoCalculation(runId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Is Algo Running
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAlgoRunning(options?: any): AxiosPromise<RunningAlgoExistsResponse> {
            return localVarFp.isAlgoRunning(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Loaded Cargo Autocomplete
         * @param {LoadedCargoAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadedCargoAutocomplete(field?: LoadedCargoAutocompleteFields, query?: string, options?: any): AxiosPromise<AutocompleteResponseSchema> {
            return localVarFp.loadedCargoAutocomplete(field, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Loading Stations Autocomplete
         * @param {LoadingStationsAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadingStationsAutocomplete(field?: LoadingStationsAutocompleteFields, query?: string, options?: any): AxiosPromise<AutocompleteResponseSchema> {
            return localVarFp.loadingStationsAutocomplete(field, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Proposed Trips Autocomplete
         * @param {ProposedTripsAutocompleteFields} [field] Название поля
         * @param {string} [query] Запрос (начинается с...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposedTripsAutocomplete(field?: ProposedTripsAutocompleteFields, query?: string, options?: any): AxiosPromise<AutocompleteResponseSchema> {
            return localVarFp.proposedTripsAutocomplete(field, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Ручка для отладки и тестирования: сбрасывает статус всех прогнозных рейсов на NEW
         * @summary Proposed Trips Reset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposedTripsReset(options?: any): AxiosPromise<any> {
            return localVarFp.proposedTripsReset(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Proposed Trips Sorting Fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposedTripsSortingFields(options?: any): AxiosPromise<SortingFieldsResponseSchema> {
            return localVarFp.proposedTripsSortingFields(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Proposed Trips Verdict
         * @param {ProposedTripVerdict} proposedTripVerdict 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposedTripsVerdict(proposedTripVerdict: ProposedTripVerdict, options?: any): AxiosPromise<any> {
            return localVarFp.proposedTripsVerdict(proposedTripVerdict, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for controlTripsAutocomplete operation in VerificationApi.
 * @export
 * @interface VerificationApiControlTripsAutocompleteRequest
 */
export interface VerificationApiControlTripsAutocompleteRequest {
    /**
     * Название поля
     * @type {ControlTripsAutocompleteFields}
     * @memberof VerificationApiControlTripsAutocomplete
     */
    readonly field?: ControlTripsAutocompleteFields

    /**
     * Запрос (начинается с...)
     * @type {string}
     * @memberof VerificationApiControlTripsAutocomplete
     */
    readonly query?: string
}

/**
 * Request parameters for currentTripsAutocomplete operation in VerificationApi.
 * @export
 * @interface VerificationApiCurrentTripsAutocompleteRequest
 */
export interface VerificationApiCurrentTripsAutocompleteRequest {
    /**
     * Название поля
     * @type {CurrentTripsAutocompleteFields}
     * @memberof VerificationApiCurrentTripsAutocomplete
     */
    readonly field?: CurrentTripsAutocompleteFields

    /**
     * Запрос (начинается с...)
     * @type {string}
     * @memberof VerificationApiCurrentTripsAutocomplete
     */
    readonly query?: string
}

/**
 * Request parameters for generateTripsT13a operation in VerificationApi.
 * @export
 * @interface VerificationApiGenerateTripsT13aRequest
 */
export interface VerificationApiGenerateTripsT13aRequest {
    /**
     * 
     * @type {string}
     * @memberof VerificationApiGenerateTripsT13a
     */
    readonly dislocationDatetime?: string

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGenerateTripsT13a
     */
    readonly usePreviouslyLoadedData?: boolean
}

/**
 * Request parameters for getAlgoRuns operation in VerificationApi.
 * @export
 * @interface VerificationApiGetAlgoRunsRequest
 */
export interface VerificationApiGetAlgoRunsRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetAlgoRuns
     */
    readonly pageSize: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetAlgoRuns
     */
    readonly page?: number
}

/**
 * Request parameters for getControlTrips operation in VerificationApi.
 * @export
 * @interface VerificationApiGetControlTripsRequest
 */
export interface VerificationApiGetControlTripsRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetControlTrips
     */
    readonly pageSize: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetControlTrips
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetControlTrips
     */
    readonly stationId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly subgroupId?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly groupId?: Array<number>

    /**
     * 
     * @type {ControlTripsSectionEnum}
     * @memberof VerificationApiGetControlTrips
     */
    readonly section?: ControlTripsSectionEnum

    /**
     * 
     * @type {string}
     * @memberof VerificationApiGetControlTrips
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof VerificationApiGetControlTrips
     */
    readonly dateGte?: string

    /**
     * 
     * @type {string}
     * @memberof VerificationApiGetControlTrips
     */
    readonly dateLte?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly washingStationName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly parkingStationName?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly returnStationMatch?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly throughWashingStation?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly throughParkingStation?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly firstLeg?: boolean

    /**
     * 
     * @type {string}
     * @memberof VerificationApiGetControlTrips
     */
    readonly status?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly carNo?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly trainNo?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly fromStationName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly toStationName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly toOperation?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly cargoSubgroup?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly cargoGroup?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly cargoGroupId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly cargoName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly fromRailway?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly fromStationEsr?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly toRailway?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly toStationEsr?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof VerificationApiGetControlTrips
     */
    readonly arrivalEstimateLte?: string

    /**
     * 
     * @type {string}
     * @memberof VerificationApiGetControlTrips
     */
    readonly arrivalEstimateGte?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly carModel?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly isRented?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly fitting45Deg?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly address75?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly drainDeviceProt3?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly steamJacket?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly isOnWay?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly onRoute?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly returnStationName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly returnStationEsr?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly operationStationName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly operationStationEsr?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetControlTrips
     */
    readonly operationRailway?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly carRzdCode92?: boolean

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetControlTrips
     */
    readonly opDurationLte?: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetControlTrips
     */
    readonly opDurationGte?: number

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly isIdle?: boolean

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetControlTrips
     */
    readonly accessRemainingDistanceLte?: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetControlTrips
     */
    readonly accessRemainingDistanceGte?: number

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly isOffScheduleRepair?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetControlTrips
     */
    readonly isPlannedRepair?: boolean
}

/**
 * Request parameters for getCurrentTrip operation in VerificationApi.
 * @export
 * @interface VerificationApiGetCurrentTripRequest
 */
export interface VerificationApiGetCurrentTripRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetCurrentTrip
     */
    readonly tripId: number
}

/**
 * Request parameters for getCurrentTrips operation in VerificationApi.
 * @export
 * @interface VerificationApiGetCurrentTripsRequest
 */
export interface VerificationApiGetCurrentTripsRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly pageSize: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly page?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly id?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly carNo?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly trainNo?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly fromStationName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly toStationName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly toOperation?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly cargoSubgroup?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly cargoGroup?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly cargoGroupId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly cargoName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly fromRailway?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly fromStationEsr?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly toRailway?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly toStationEsr?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly arrivalEstimateLte?: string

    /**
     * 
     * @type {string}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly arrivalEstimateGte?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly carModel?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly isRented?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly fitting45Deg?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly address75?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly drainDeviceProt3?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly steamJacket?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly isOnWay?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly onRoute?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly returnStationName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly returnStationEsr?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly operationStationName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly operationStationEsr?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly operationRailway?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly carRzdCode92?: boolean

    /**
     * 
     * @type {string}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly utilizationType?: string

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly opDurationLte?: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly opDurationGte?: number

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly isIdle?: boolean

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly accessRemainingDistanceLte?: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly accessRemainingDistanceGte?: number

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly isOffScheduleRepair?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetCurrentTrips
     */
    readonly isPlannedRepair?: boolean
}

/**
 * Request parameters for getLoadingStations operation in VerificationApi.
 * @export
 * @interface VerificationApiGetLoadingStationsRequest
 */
export interface VerificationApiGetLoadingStationsRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly pageSize: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly page?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly id?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly name?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly code6?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly railwayId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly railwayName?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly cargoId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly cargoName?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly cargoGroupId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly cargoGroupName?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly cargoSubgroupId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly cargoSubgroupName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly marketType?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly backlog?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly abandonedCars?: boolean

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly planCompletion?: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly planCompletionGte?: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly planCompletionLte?: number

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly delays?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetLoadingStations
     */
    readonly address75?: boolean
}

/**
 * Request parameters for getProposedTrips operation in VerificationApi.
 * @export
 * @interface VerificationApiGetProposedTripsRequest
 */
export interface VerificationApiGetProposedTripsRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly pageSize: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly page?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly id?: Array<number>

    /**
     * Дата отправления
     * @type {string}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly departureDate?: string

    /**
     * Дата отправления (больше или равно)
     * @type {string}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly departureDateGte?: string

    /**
     * Дата отправления (меньше или равно)
     * @type {string}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly departureDateLte?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly departureStationId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly departureStationName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly departureStationCode6?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly departureStationRailwayId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly departureStationRailwayName?: Array<string>

    /**
     * Предполагаемая дата прибытия
     * @type {string}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly arrivalDate?: string

    /**
     * Предполагаемая дата прибытия (больше или равно)
     * @type {string}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly arrivalDateGte?: string

    /**
     * Предполагаемая дата прибытия (меньше или равно)
     * @type {string}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly arrivalDateLte?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly arrivalStationId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly arrivalStationName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly arrivalStationCode6?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly arrivalStationRailwayId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly arrivalStationRailwayName?: Array<string>

    /**
     * Операция, которой должен подвергнуться вагон на станции назначения
     * @type {Array<string>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly arrivalOperation?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly carId?: Array<number>

    /**
     * Идентификационный номер вагона
     * @type {Array<number>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly carNo?: Array<number>

    /**
     * Код операции РЖД 92
     * @type {boolean}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly carRzdCode92?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly carIsRented?: boolean

    /**
     * Признак \&quot;Арматура 45 град\&quot;
     * @type {boolean}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly carFitting45Deg?: boolean

    /**
     * Признак \&quot;Сливное устройство с тремя степенями защиты\&quot;
     * @type {boolean}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly carDrainDeviceProt3?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly carModelId?: Array<number>

    /**
     * Модель вагона
     * @type {Array<string>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly carModelName?: Array<string>

    /**
     * Признак наличия паровой рубашки
     * @type {boolean}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly carModelSteamJacket?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly cargoId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly cargoName?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly cargoGroupId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly cargoGroupName?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly cargoSubgroupId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly cargoSubgroupName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetProposedTrips
     */
    readonly sortBy?: Array<string>
}

/**
 * Request parameters for getStationCharts operation in VerificationApi.
 * @export
 * @interface VerificationApiGetStationChartsRequest
 */
export interface VerificationApiGetStationChartsRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetStationCharts
     */
    readonly pk: number
}

/**
 * Request parameters for getStationMarkers operation in VerificationApi.
 * @export
 * @interface VerificationApiGetStationMarkersRequest
 */
export interface VerificationApiGetStationMarkersRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetStationMarkers
     */
    readonly pk: number

    /**
     * 
     * @type {LoadingStationMarkersViewEnum}
     * @memberof VerificationApiGetStationMarkers
     */
    readonly view?: LoadingStationMarkersViewEnum

    /**
     * 
     * @type {LoadingStationMarkersDirectionEnum}
     * @memberof VerificationApiGetStationMarkers
     */
    readonly direction?: LoadingStationMarkersDirectionEnum
}

/**
 * Request parameters for getStationSummary operation in VerificationApi.
 * @export
 * @interface VerificationApiGetStationSummaryRequest
 */
export interface VerificationApiGetStationSummaryRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetStationSummary
     */
    readonly pk: number
}

/**
 * Request parameters for getStationsCharts operation in VerificationApi.
 * @export
 * @interface VerificationApiGetStationsChartsRequest
 */
export interface VerificationApiGetStationsChartsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly id?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly name?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly code6?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly railwayId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly railwayName?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly cargoId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly cargoName?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly cargoGroupId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly cargoGroupName?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly cargoSubgroupId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly cargoSubgroupName?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly marketType?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly backlog?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly abandonedCars?: boolean

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly planCompletion?: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly planCompletionGte?: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly planCompletionLte?: number

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly delays?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetStationsCharts
     */
    readonly address75?: boolean
}

/**
 * Request parameters for getSupplyPlanData operation in VerificationApi.
 * @export
 * @interface VerificationApiGetSupplyPlanDataRequest
 */
export interface VerificationApiGetSupplyPlanDataRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetSupplyPlanData
     */
    readonly stationId: number

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetSupplyPlanData
     */
    readonly oil?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof VerificationApiGetSupplyPlanData
     */
    readonly cargoGroupId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationApiGetSupplyPlanData
     */
    readonly cargoGroupName?: Array<string>

    /**
     * 
     * @type {ResulModeEnum}
     * @memberof VerificationApiGetSupplyPlanData
     */
    readonly resultMode?: ResulModeEnum

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetSupplyPlanData
     */
    readonly addLoadedCars?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetSupplyPlanData
     */
    readonly addEmptyProposed?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetSupplyPlanData
     */
    readonly addEmptyProposedApproved?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetSupplyPlanData
     */
    readonly addLoadedProposed?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetSupplyPlanData
     */
    readonly loadingByPlan?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetSupplyPlanData
     */
    readonly loadingByAvailable?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetSupplyPlanData
     */
    readonly showShortage?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof VerificationApiGetSupplyPlanData
     */
    readonly firstLeg?: boolean
}

/**
 * Request parameters for getSupplyRequest operation in VerificationApi.
 * @export
 * @interface VerificationApiGetSupplyRequestRequest
 */
export interface VerificationApiGetSupplyRequestRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetSupplyRequest
     */
    readonly pk: number
}

/**
 * Request parameters for getSupplyRequests operation in VerificationApi.
 * @export
 * @interface VerificationApiGetSupplyRequestsRequest
 */
export interface VerificationApiGetSupplyRequestsRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetSupplyRequests
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof VerificationApiGetSupplyRequests
     */
    readonly limit?: number
}

/**
 * Request parameters for infoCalculation operation in VerificationApi.
 * @export
 * @interface VerificationApiInfoCalculationRequest
 */
export interface VerificationApiInfoCalculationRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationApiInfoCalculation
     */
    readonly runId: number
}

/**
 * Request parameters for loadedCargoAutocomplete operation in VerificationApi.
 * @export
 * @interface VerificationApiLoadedCargoAutocompleteRequest
 */
export interface VerificationApiLoadedCargoAutocompleteRequest {
    /**
     * Название поля
     * @type {LoadedCargoAutocompleteFields}
     * @memberof VerificationApiLoadedCargoAutocomplete
     */
    readonly field?: LoadedCargoAutocompleteFields

    /**
     * Запрос (начинается с...)
     * @type {string}
     * @memberof VerificationApiLoadedCargoAutocomplete
     */
    readonly query?: string
}

/**
 * Request parameters for loadingStationsAutocomplete operation in VerificationApi.
 * @export
 * @interface VerificationApiLoadingStationsAutocompleteRequest
 */
export interface VerificationApiLoadingStationsAutocompleteRequest {
    /**
     * Название поля
     * @type {LoadingStationsAutocompleteFields}
     * @memberof VerificationApiLoadingStationsAutocomplete
     */
    readonly field?: LoadingStationsAutocompleteFields

    /**
     * Запрос (начинается с...)
     * @type {string}
     * @memberof VerificationApiLoadingStationsAutocomplete
     */
    readonly query?: string
}

/**
 * Request parameters for proposedTripsAutocomplete operation in VerificationApi.
 * @export
 * @interface VerificationApiProposedTripsAutocompleteRequest
 */
export interface VerificationApiProposedTripsAutocompleteRequest {
    /**
     * Название поля
     * @type {ProposedTripsAutocompleteFields}
     * @memberof VerificationApiProposedTripsAutocomplete
     */
    readonly field?: ProposedTripsAutocompleteFields

    /**
     * Запрос (начинается с...)
     * @type {string}
     * @memberof VerificationApiProposedTripsAutocomplete
     */
    readonly query?: string
}

/**
 * Request parameters for proposedTripsVerdict operation in VerificationApi.
 * @export
 * @interface VerificationApiProposedTripsVerdictRequest
 */
export interface VerificationApiProposedTripsVerdictRequest {
    /**
     * 
     * @type {ProposedTripVerdict}
     * @memberof VerificationApiProposedTripsVerdict
     */
    readonly proposedTripVerdict: ProposedTripVerdict
}

/**
 * VerificationApi - object-oriented interface
 * @export
 * @class VerificationApi
 * @extends {BaseAPI}
 */
export class VerificationApi extends BaseAPI {
    /**
     * 
     * @summary Control Trips Autocomplete
     * @param {VerificationApiControlTripsAutocompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public controlTripsAutocomplete(requestParameters: VerificationApiControlTripsAutocompleteRequest = {}, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).controlTripsAutocomplete(requestParameters.field, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Current Trips Autocomplete
     * @param {VerificationApiCurrentTripsAutocompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public currentTripsAutocomplete(requestParameters: VerificationApiCurrentTripsAutocompleteRequest = {}, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).currentTripsAutocomplete(requestParameters.field, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate Trips
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public generateTrips(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).generateTrips(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Запуск оптимизатора на данных из ИС Транспортировка
     * @summary Generate Trips T13A
     * @param {VerificationApiGenerateTripsT13aRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public generateTripsT13a(requestParameters: VerificationApiGenerateTripsT13aRequest = {}, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).generateTripsT13a(requestParameters.dislocationDatetime, requestParameters.usePreviouslyLoadedData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Algo Runs
     * @param {VerificationApiGetAlgoRunsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getAlgoRuns(requestParameters: VerificationApiGetAlgoRunsRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getAlgoRuns(requestParameters.pageSize, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Control Trips
     * @param {VerificationApiGetControlTripsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getControlTrips(requestParameters: VerificationApiGetControlTripsRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getControlTrips(requestParameters.pageSize, requestParameters.page, requestParameters.stationId, requestParameters.subgroupId, requestParameters.groupId, requestParameters.section, requestParameters.date, requestParameters.dateGte, requestParameters.dateLte, requestParameters.washingStationName, requestParameters.parkingStationName, requestParameters.returnStationMatch, requestParameters.throughWashingStation, requestParameters.throughParkingStation, requestParameters.firstLeg, requestParameters.status, requestParameters.carNo, requestParameters.trainNo, requestParameters.fromStationName, requestParameters.toStationName, requestParameters.toOperation, requestParameters.cargoSubgroup, requestParameters.cargoGroup, requestParameters.cargoGroupId, requestParameters.cargoName, requestParameters.fromRailway, requestParameters.fromStationEsr, requestParameters.toRailway, requestParameters.toStationEsr, requestParameters.arrivalEstimateLte, requestParameters.arrivalEstimateGte, requestParameters.carModel, requestParameters.isRented, requestParameters.fitting45Deg, requestParameters.address75, requestParameters.drainDeviceProt3, requestParameters.steamJacket, requestParameters.isOnWay, requestParameters.onRoute, requestParameters.returnStationName, requestParameters.returnStationEsr, requestParameters.operationStationName, requestParameters.operationStationEsr, requestParameters.operationRailway, requestParameters.carRzdCode92, requestParameters.opDurationLte, requestParameters.opDurationGte, requestParameters.isIdle, requestParameters.accessRemainingDistanceLte, requestParameters.accessRemainingDistanceGte, requestParameters.isOffScheduleRepair, requestParameters.isPlannedRepair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Control Trips Filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getControlTripsFilters(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getControlTripsFilters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Current Trip
     * @param {VerificationApiGetCurrentTripRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getCurrentTrip(requestParameters: VerificationApiGetCurrentTripRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getCurrentTrip(requestParameters.tripId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Current Trips
     * @param {VerificationApiGetCurrentTripsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getCurrentTrips(requestParameters: VerificationApiGetCurrentTripsRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getCurrentTrips(requestParameters.pageSize, requestParameters.page, requestParameters.id, requestParameters.carNo, requestParameters.trainNo, requestParameters.fromStationName, requestParameters.toStationName, requestParameters.toOperation, requestParameters.cargoSubgroup, requestParameters.cargoGroup, requestParameters.cargoGroupId, requestParameters.cargoName, requestParameters.fromRailway, requestParameters.fromStationEsr, requestParameters.toRailway, requestParameters.toStationEsr, requestParameters.arrivalEstimateLte, requestParameters.arrivalEstimateGte, requestParameters.carModel, requestParameters.isRented, requestParameters.fitting45Deg, requestParameters.address75, requestParameters.drainDeviceProt3, requestParameters.steamJacket, requestParameters.isOnWay, requestParameters.onRoute, requestParameters.returnStationName, requestParameters.returnStationEsr, requestParameters.operationStationName, requestParameters.operationStationEsr, requestParameters.operationRailway, requestParameters.carRzdCode92, requestParameters.utilizationType, requestParameters.opDurationLte, requestParameters.opDurationGte, requestParameters.isIdle, requestParameters.accessRemainingDistanceLte, requestParameters.accessRemainingDistanceGte, requestParameters.isOffScheduleRepair, requestParameters.isPlannedRepair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Current Trips Filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getCurrentTripsFilters(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getCurrentTripsFilters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Loaded Cargo Filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getLoadedCargoFilters(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getLoadedCargoFilters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Loading Stations
     * @param {VerificationApiGetLoadingStationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getLoadingStations(requestParameters: VerificationApiGetLoadingStationsRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getLoadingStations(requestParameters.pageSize, requestParameters.page, requestParameters.id, requestParameters.name, requestParameters.code6, requestParameters.railwayId, requestParameters.railwayName, requestParameters.cargoId, requestParameters.cargoName, requestParameters.cargoGroupId, requestParameters.cargoGroupName, requestParameters.cargoSubgroupId, requestParameters.cargoSubgroupName, requestParameters.marketType, requestParameters.backlog, requestParameters.abandonedCars, requestParameters.planCompletion, requestParameters.planCompletionGte, requestParameters.planCompletionLte, requestParameters.delays, requestParameters.address75, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Loading Stations Filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getLoadingStationsFilters(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getLoadingStationsFilters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Logs File
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getLogsFile(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getLogsFile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Logs File Live
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getLogsFileLive(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getLogsFileLive(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Proposed Trips
     * @param {VerificationApiGetProposedTripsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getProposedTrips(requestParameters: VerificationApiGetProposedTripsRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getProposedTrips(requestParameters.pageSize, requestParameters.page, requestParameters.id, requestParameters.departureDate, requestParameters.departureDateGte, requestParameters.departureDateLte, requestParameters.departureStationId, requestParameters.departureStationName, requestParameters.departureStationCode6, requestParameters.departureStationRailwayId, requestParameters.departureStationRailwayName, requestParameters.arrivalDate, requestParameters.arrivalDateGte, requestParameters.arrivalDateLte, requestParameters.arrivalStationId, requestParameters.arrivalStationName, requestParameters.arrivalStationCode6, requestParameters.arrivalStationRailwayId, requestParameters.arrivalStationRailwayName, requestParameters.arrivalOperation, requestParameters.carId, requestParameters.carNo, requestParameters.carRzdCode92, requestParameters.carIsRented, requestParameters.carFitting45Deg, requestParameters.carDrainDeviceProt3, requestParameters.carModelId, requestParameters.carModelName, requestParameters.carModelSteamJacket, requestParameters.cargoId, requestParameters.cargoName, requestParameters.cargoGroupId, requestParameters.cargoGroupName, requestParameters.cargoSubgroupId, requestParameters.cargoSubgroupName, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Proposed Trips Filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getProposedTripsFilters(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getProposedTripsFilters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Proposed Trips Verdict Reasons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getProposedTripsVerdictReasons(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getProposedTripsVerdictReasons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Result File
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getResultFile(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getResultFile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Station Charts
     * @param {VerificationApiGetStationChartsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getStationCharts(requestParameters: VerificationApiGetStationChartsRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getStationCharts(requestParameters.pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Station Markers
     * @param {VerificationApiGetStationMarkersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getStationMarkers(requestParameters: VerificationApiGetStationMarkersRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getStationMarkers(requestParameters.pk, requestParameters.view, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Station Summary
     * @param {VerificationApiGetStationSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getStationSummary(requestParameters: VerificationApiGetStationSummaryRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getStationSummary(requestParameters.pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Stations Charts
     * @param {VerificationApiGetStationsChartsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getStationsCharts(requestParameters: VerificationApiGetStationsChartsRequest = {}, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getStationsCharts(requestParameters.id, requestParameters.name, requestParameters.code6, requestParameters.railwayId, requestParameters.railwayName, requestParameters.cargoId, requestParameters.cargoName, requestParameters.cargoGroupId, requestParameters.cargoGroupName, requestParameters.cargoSubgroupId, requestParameters.cargoSubgroupName, requestParameters.marketType, requestParameters.backlog, requestParameters.abandonedCars, requestParameters.planCompletion, requestParameters.planCompletionGte, requestParameters.planCompletionLte, requestParameters.delays, requestParameters.address75, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Supply Plan Data
     * @param {VerificationApiGetSupplyPlanDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getSupplyPlanData(requestParameters: VerificationApiGetSupplyPlanDataRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getSupplyPlanData(requestParameters.stationId, requestParameters.oil, requestParameters.cargoGroupId, requestParameters.cargoGroupName, requestParameters.resultMode, requestParameters.addLoadedCars, requestParameters.addEmptyProposed, requestParameters.addEmptyProposedApproved, requestParameters.addLoadedProposed, requestParameters.loadingByPlan, requestParameters.loadingByAvailable, requestParameters.showShortage, requestParameters.firstLeg, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Supply Request
     * @param {VerificationApiGetSupplyRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getSupplyRequest(requestParameters: VerificationApiGetSupplyRequestRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getSupplyRequest(requestParameters.pk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Supply Requests
     * @param {VerificationApiGetSupplyRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getSupplyRequests(requestParameters: VerificationApiGetSupplyRequestsRequest = {}, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getSupplyRequests(requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Verification File
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public getVerificationFile(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).getVerificationFile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Info Calculation
     * @param {VerificationApiInfoCalculationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public infoCalculation(requestParameters: VerificationApiInfoCalculationRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).infoCalculation(requestParameters.runId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Is Algo Running
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public isAlgoRunning(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).isAlgoRunning(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Loaded Cargo Autocomplete
     * @param {VerificationApiLoadedCargoAutocompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public loadedCargoAutocomplete(requestParameters: VerificationApiLoadedCargoAutocompleteRequest = {}, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).loadedCargoAutocomplete(requestParameters.field, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Loading Stations Autocomplete
     * @param {VerificationApiLoadingStationsAutocompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public loadingStationsAutocomplete(requestParameters: VerificationApiLoadingStationsAutocompleteRequest = {}, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).loadingStationsAutocomplete(requestParameters.field, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Proposed Trips Autocomplete
     * @param {VerificationApiProposedTripsAutocompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public proposedTripsAutocomplete(requestParameters: VerificationApiProposedTripsAutocompleteRequest = {}, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).proposedTripsAutocomplete(requestParameters.field, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ручка для отладки и тестирования: сбрасывает статус всех прогнозных рейсов на NEW
     * @summary Proposed Trips Reset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public proposedTripsReset(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).proposedTripsReset(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Proposed Trips Sorting Fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public proposedTripsSortingFields(options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).proposedTripsSortingFields(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Proposed Trips Verdict
     * @param {VerificationApiProposedTripsVerdictRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public proposedTripsVerdict(requestParameters: VerificationApiProposedTripsVerdictRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).proposedTripsVerdict(requestParameters.proposedTripVerdict, options).then((request) => request(this.axios, this.basePath));
    }
}


