export const variants = {
  visible: {
    opacity: 1,
    transform: 'scale(1)',
    transition: {
      type: 'easeOut',
      duration: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    transform: 'scale(0.95)',
    transition: {
      type: 'easeIn',
      duration: 0.075,
    },
  },
};
