import { createEffect, createEvent, createStore } from 'effector';
import { camelCase } from 'lodash';
import dayjs from 'dayjs';
import download from 'js-file-download';
import { persist } from 'effector-storage/local';

import api from 'api';

import {
  ProposedTripList,
  ProposedTripListRequestParams,
} from './proposedTrips.types';

// MODELS -------------------

export const $proposedTripListStore = createStore<ProposedTripList>({
  objects: [],
});

export const $proposedTripListRequestParams =
  createStore<ProposedTripListRequestParams>({
    page: 1,
    pageSize: 25,
    sortDirection: 'asc',
  });

persist({ store: $proposedTripListRequestParams, key: 'proposedTripsRequest' });

// EFFECTS -------------------

export const getProposedTripsFx = createEffect({
  handler: async (params: ProposedTripListRequestParams) => {
    const formattedParams: { [k: string]: any } = Object.fromEntries(
      Object.entries(params).map(([k, v]) => [camelCase(k), v]),
    );
    const {
      departureDate,
      arrivalDate,
      sortDirection,
      sortBy,
      cargoGroup: cargoGroupName,
      cargoSubgroup: cargoSubgroupName,
      ...restFilters
    } = {
      ...params,
      ...formattedParams,
    };
    return await api.verification.getProposedTrips({
      ...restFilters,
      departureDateGte:
        departureDate && departureDate[0]
          ? dayjs(departureDate[0]).format('YYYY-MM-DD')
          : undefined,
      departureDateLte:
        departureDate && departureDate[1]
          ? dayjs(departureDate[1]).format('YYYY-MM-DD')
          : undefined,
      arrivalDateGte:
        arrivalDate && arrivalDate[0]
          ? dayjs(arrivalDate[0]).format('YYYY-MM-DD')
          : undefined,
      arrivalDateLte:
        arrivalDate && arrivalDate[1]
          ? dayjs(arrivalDate[1]).format('YYYY-MM-DD')
          : undefined,
      sortBy:
        sortDirection === 'asc' ? sortBy : sortBy?.map((field) => `-${field}`),
      cargoGroupName,
      cargoSubgroupName,
    });
  },
});

export const getFile = createEffect({
  handler: async () => {
    const { data, headers } = await api.verification.getResultFile({
      responseType: 'blob',
    });
    download(
      data as unknown as string,
      decodeURIComponent(headers['content-disposition']).split("''")[1],
    );
    return data;
  },
});

// EVENTS -------------------

export const changeProposedTripListRequestParams =
  createEvent<Partial<ProposedTripListRequestParams>>();

export const resetProposedTripListRequestParams = createEvent();

// STORE METHODS -------------------

$proposedTripListStore.on(
  getProposedTripsFx.done,
  (state, { result }) => result.data,
);

$proposedTripListRequestParams
  .reset(resetProposedTripListRequestParams)
  .on(changeProposedTripListRequestParams, (state, payload) => ({
    ...state,
    ...payload,
  }));
