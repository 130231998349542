import React, { useState, useRef, useMemo } from 'react';
import cx from 'classnames';
import { useStore } from 'effector-react';
import { Button, CheckboxGroup, Checkbox } from '@factory5/ui-kit';
import { AnimatePresence, motion } from 'framer-motion';
import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/solid';
import { useClickOutside } from 'hooks';

import { SortingFieldSchema } from 'api';

import { variants } from '../../../filters/utils';
import {
  $proposedTripListRequestParams,
  changeProposedTripListRequestParams,
} from '../../models/proposedTrips.model';

interface ISortProps {
  data: SortingFieldSchema[];
  className?: string;
  sortClassName?: string;
  dropdownClassName?: string;
}

const Sort = ({
  data,
  className,
  sortClassName,
  dropdownClassName,
}: ISortProps) => {
  const { sortBy, sortDirection } = useStore($proposedTripListRequestParams);
  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setIsVisible(false));

  const formatSortTitles = useMemo(() => {
    const cutted = sortBy?.slice(0, 2);
    const names = cutted?.map(
      (item) => data.find((elem) => elem.field === item)?.title,
    );
    return names?.join(', ') + `${sortBy && sortBy.length > 2 ? '...' : ''}`;
  }, [sortBy, data]);

  return (
    <div className={cx('flex items-center', className)}>
      <Button
        size="sm"
        className="mr-2"
        icon={
          sortDirection === 'asc' ? <BarsArrowUpIcon /> : <BarsArrowDownIcon />
        }
        onClick={() => {
          if (sortDirection === 'asc') {
            changeProposedTripListRequestParams({ sortDirection: 'desc' });
          } else {
            changeProposedTripListRequestParams({ sortDirection: 'asc' });
          }
        }}
      />
      <div className={cx('relative', sortClassName)} ref={dropdownRef}>
        <Button
          size="sm"
          icon={{
            icon: isVisible ? <ChevronUpIcon /> : <ChevronDownIcon />,
            placement: 'trailing',
          }}
          onClick={() => {
            setIsVisible((prev) => !prev);
          }}
        >
          Сортировка
          {sortBy && sortBy.length > 0 && `: ${formatSortTitles}`}
        </Button>
        <AnimatePresence>
          {isVisible && (
            <motion.div
              variants={variants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className={cx(
                'w-64 bg-white shadow-md rounded-md absolute p-2.5 top-9 right-0 z-10 max-h-96 overflow-y-auto',
                dropdownClassName,
              )}
            >
              {data && data.length > 0 && (
                <CheckboxGroup
                  onChange={(values) => {
                    changeProposedTripListRequestParams({
                      sortBy: values as string[],
                    });
                  }}
                  values={sortBy}
                >
                  {data.map((item) => (
                    <Checkbox value={item.field} key={`sort-${item.field}`}>
                      {item.title}
                    </Checkbox>
                  ))}
                </CheckboxGroup>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Sort;
