import React, { createRef, useState } from 'react';

import { resetFilterByKey } from 'features/filters';

import ApproveTripsSlideOver from '../ApproveTripsSlideOver';

import PlanToEnsureTable, {
  PlanToEnsureTableForwardedRef,
} from './PlanToEnsureTable';

export type ActiveCellType = {
  date?: string;
  dates?: [string, string];
  type: string;
  subgroupId?: number[];
  groupId?: number[];
  overrideFilters: { [key: string]: any };
};

export default function PlanToEnsure() {
  const [activeCell, setActiveCell] = useState<ActiveCellType>();

  const tableRef = createRef<PlanToEnsureTableForwardedRef>();

  const onCloseApproveTrips = () => {
    setActiveCell(undefined);
    resetFilterByKey({
      key: 'controlTrips',
      protectedFilterKeys: ['firstLeg'],
    });
    tableRef.current?.fetchPlan();
  };

  return (
    <>
      {activeCell && (
        <ApproveTripsSlideOver
          onClose={onCloseApproveTrips}
          params={activeCell}
        />
      )}

      <PlanToEnsureTable
        ref={tableRef}
        setActiveCell={setActiveCell}
        hasActiveCell={!!activeCell}
      />
    </>
  );
}
