import React from 'react';
import { Button } from '@factory5/ui-kit';
import { MinusCircleIcon } from '@heroicons/react/24/solid';
import { useStore } from 'effector-react';
import { isEqual, isEmpty } from 'lodash';
import cx from 'classnames';

import {
  resetFilterByKey,
  deactivateFilter,
  setFilterValues,
  $selectedFilters,
} from '../../models/filters.model';
import {
  IFiltersStore,
  IFilter,
  FilterRootKeys,
} from '../../models/filters.types';

import MoreButton from './MoreButton';
import Autocomplete from './Autocomplete';
import Toggle from './Toggle';
import Picker from './Picker';
import Range from './Range';

interface ITagsFiltersProps {
  data: IFiltersStore;
  autocompleteIsLoading?: boolean;
  noSearch?: string[];
  filterRootKey: FilterRootKeys;
  autocompleteOptionsHandler: ({
    field,
    query,
  }: {
    field: any;
    query?: string;
  }) => void;
  showClearButton?: boolean;
  showElCount?: boolean;
  maxElCount?: number;
  currentElCount?: number;
}

const TagsFilters = ({
  data,
  noSearch,
  filterRootKey,
  autocompleteIsLoading,
  autocompleteOptionsHandler,
  showClearButton = true,
  showElCount = true,
  maxElCount,
  currentElCount,
}: ITagsFiltersProps) => {
  const selectedFilters = useStore($selectedFilters)[filterRootKey];

  const renderItem = (item: IFilter, type?: 'additional' | 'general') => {
    const valueIsEmpty =
      isEqual(selectedFilters[item.field], [null, null]) ||
      isEqual(selectedFilters[item.field], []) ||
      selectedFilters[item.field] === undefined ||
      (item.widget_type === 'TOGGLE' &&
        (selectedFilters[item.field] === false ||
          selectedFilters[item.field] === true));

    return (
      <div className="flex mr-2.5 mb-1.5" key={`station-filter-${item.field}`}>
        {item.widget_type === 'MULTISELECT' && (
          <Autocomplete
            data={item}
            filterRootKey={filterRootKey}
            noSearch={noSearch ? noSearch?.includes(item.field) : undefined}
            isLoading={autocompleteIsLoading}
            fetchOptionsHandler={autocompleteOptionsHandler}
          />
        )}

        {item.widget_type === 'TOGGLE' && (
          <Toggle filterRootKey={filterRootKey} data={item} />
        )}

        {item.widget_type === 'RANGE' && (
          <Range filterRootKey={filterRootKey} data={item} />
        )}

        {item.widget_type === 'DATE_RANGE' && (
          <Picker filterRootKey={filterRootKey} data={item} />
        )}

        {type === 'additional' && valueIsEmpty && (
          <Button
            type="link"
            className="ml-1 text-gray-300 hover:text-gray-400"
            icon={<MinusCircleIcon />}
            size="md"
            onClick={() => {
              deactivateFilter(item.field);
              setFilterValues({
                rootKey: filterRootKey,
                field: item.field,
                values: undefined,
              });
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="flex flex-wrap items-center min-h-[38px]">
        {data.general.map((item) => renderItem(item))}

        {data.additional
          .filter(
            (item) => item.active || selectedFilters[item.field] !== undefined,
          )
          .map((item) => renderItem(item, 'additional'))}
      </div>

      <div className="flex flex-wrap items-center h-7 pl-2.5 mb-3">
        <div className="flex gap-2 items-center">
          {showElCount &&
            maxElCount !== undefined &&
            currentElCount !== undefined && (
              <div className="font-medium text-[11px] text-gray-700">{`${currentElCount} из ${maxElCount}`}</div>
            )}

          {showClearButton && (
            <Button
              type="secondary"
              size="xs"
              onClick={() => resetFilterByKey({ key: filterRootKey })}
              className={cx(
                'text-[10px] rounded-full font-medium text-red-600 px-1.5 py-0 text-gray-400',
                {
                  'bg-red-100 hover:bg-red-300 text-red-600':
                    !isEmpty(selectedFilters),
                },
              )}
              disabled={isEmpty(selectedFilters)}
            >
              Сбросить все
            </Button>
          )}

          <MoreButton data={data.additional} filters={data} />
        </div>
      </div>
    </div>
  );
};

export default TagsFilters;
