import axios, { AxiosError } from 'axios';

export const baseURL =
  process.env.REACT_APP_BACKEND_URI || 'https://dev01.gpt.xplanet.tech';

const controller = new AbortController();

const request = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('access')}`,
  },
  signal: controller.signal,
});

request.interceptors.request.use((config) => {
  config.headers!.Authorization = `Bearer ${localStorage.getItem('access')}`;
  return config;
});

request.interceptors.response.use(undefined, async (error: AxiosError) => {
  if (
    error.response?.status === 500 ||
    error.response?.status === 502 ||
    error.response?.status === 503
  ) {
    console.error({ message: 'Что-то пошло не так, повторите попытку' });
  }

  if (
    (error.response?.status === 403 || error.response?.status === 401) &&
    !error.config.url?.includes('/login') &&
    !error.config.url?.includes('auth/info')
  ) {
    localStorage.clear();
    window.location.replace('/login');
  }

  return Promise.reject(error);
});

export default request;
