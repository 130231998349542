import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { Helmet } from 'react-helmet';
import { PageHeader, Card, Description, Loading, Tabs } from '@factory5/ui-kit';
import { useParams } from 'react-router-dom';
import L from 'leaflet';
import { motion } from 'framer-motion';
import { pageMotion, pageHeaderMotion } from 'lib';
import { FormattedValue } from 'ui';

import { MapView } from 'features/map';
import {
  $cargoFilters,
  CheckFilters,
  fetchCargoGroupsFiltersFx,
  resetFilterByKey,
} from 'features/filters';
import { CircleStatistic } from 'features/statistic';
import {
  PlanToEnsure,
  OtherTrips,
  StationCharts,
  fetchStationInfoFx,
  $stationInfo,
  resetStationInfo,
} from 'features/stations';
import useCurrentStation from 'features/stations/hooks/useCurrentStation';
import { getStationFx } from 'features/stations/models/stations.model';
import StationMapMarkerList from 'features/map/containers/StationMapMarkerList';

import { OneColumnLayout } from 'layouts';

import './styles.css';

const StationPage = () => {
  const { id: stationId } = useParams();
  const station = useCurrentStation();

  const cargoGroups = useStore($cargoFilters);
  const stationInfo = useStore($stationInfo);
  const isStationInfoPending = useStore(fetchStationInfoFx.pending);

  useEffect(() => {
    if (!stationId || !Number.isInteger(Number(stationId))) return;

    if (!station) getStationFx(Number(stationId));

    fetchStationInfoFx(Number(stationId));

    return () => {
      resetStationInfo();
      resetFilterByKey({ key: 'station' });
      resetFilterByKey({
        key: 'controlTrips',
        protectedFilterKeys: ['firstLeg'],
      });
    };
  }, [stationId]);

  useEffect(() => {
    fetchCargoGroupsFiltersFx();
  }, []);

  return (
    <>
      <Helmet>
        <title>{`ЦПД: ${station?.name}`}</title>
      </Helmet>

      <OneColumnLayout
        pageHeader={
          <PageHeader
            title={
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageHeaderMotion}
              >
                <div className="flex gap-2 items-center">
                  <div className="text-xl">{station?.name}</div>
                  <div className="text-xs font-normal">
                    ({station?.code6}) - {station?.railway.name}
                  </div>
                </div>
              </motion.div>
            }
          />
        }
      >
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageMotion}
        >
          <div className="py-6 flex flex-col gap-6">
            {station && station.planned_cars !== null && (
              <div className="flex gap-6">
                <Card
                  noSpace
                  className="w-full overflow-hidden"
                  bodyClassName="h-full"
                >
                  <MapView
                    center={
                      station &&
                      station.latitude !== null &&
                      station.longitude !== null
                        ? L.latLng(station.latitude!, station.longitude!)
                        : undefined
                    }
                  >
                    {station?.latitude !== null &&
                      station?.longitude !== null && <StationMapMarkerList />}
                  </MapView>
                </Card>

                <Card
                  className="w-[480px] min-h-[480px] shrink-0"
                  bodyClassName="relative h-full"
                  noSpace
                >
                  {isStationInfoPending && (
                    <div className="absolute left-0 top-0 w-full h-full bg-white/50 z-10">
                      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                        <Loading size="lg" />
                      </div>
                    </div>
                  )}
                  {stationInfo && (
                    <>
                      <div className="flex justify-between border-b px-4 py-5">
                        <CircleStatistic
                          value={stationInfo.plan_car_chart}
                          total={stationInfo.plan_ton_chart}
                          description="план, ваг/тн (по графику)"
                        />
                        <CircleStatistic
                          value={stationInfo.plan_car_operational}
                          total={stationInfo.plan_ton_operational}
                          description="план, ваг/тн (оперативный)"
                        />
                        <CircleStatistic
                          value={stationInfo.fact_car}
                          total={stationInfo.fact_ton}
                          borderColor="border-blue-200"
                          description="факт, ваг/тн"
                        />
                        <CircleStatistic
                          value={stationInfo.balance_car}
                          total={stationInfo.balance_ton}
                          borderColor="border-red-200"
                          description="остаток, ваг/тн"
                        />
                      </div>

                      <div className="px-4 py-5">
                        <Description
                          size="sm"
                          className="station-stats"
                          columns={2}
                          items={[
                            {
                              title: 'Подгруппы грузов',
                              value: stationInfo.cargo_subgroups.join(', '),
                            },
                            {
                              title: 'Наличие на станции',
                              value: (
                                <FormattedValue
                                  value={stationInfo.available_car_station}
                                />
                              ),
                            },
                            {
                              title: 'Подход на сегодня',
                              value: (
                                <FormattedValue
                                  value={stationInfo.approach_today}
                                />
                              ),
                            },
                            {
                              title: 'Подход на ближ. 7 дней',
                              value: (
                                <FormattedValue
                                  value={stationInfo.approach_week}
                                />
                              ),
                            },
                            {
                              title: 'Отставание от плана, ваг',
                              value: (
                                <FormattedValue
                                  value={stationInfo.backlog_plan_car}
                                />
                              ),
                            },
                            {
                              title: 'Отставание от плана, %',
                              value: (
                                <FormattedValue
                                  value={stationInfo.backlog_plan_percentage}
                                />
                              ),
                            },
                            {
                              title: 'Размечено, ваг',
                              value: (
                                <FormattedValue
                                  value={stationInfo.marked_car}
                                />
                              ),
                            },
                          ]}
                        />
                      </div>
                    </>
                  )}

                  <div className="px-5 pb-5 flex flex-col gap-1">
                    <div className="text-left text-sm font-medium text-gray-500">
                      Невыполнение плана
                    </div>

                    {stationInfo?.unfulfilled_plan.map((item) => (
                      <div className="flex flex-row" key={JSON.stringify(item)}>
                        {item.cargo}:{' '}
                        <span className="ml-2 font-semibold">{item.value}</span>
                      </div>
                    ))}
                  </div>
                </Card>
              </div>
            )}

            {station && station.planned_cars !== null && <StationCharts />}

            <Tabs defaultValue="plan">
              <Tabs.Tab title="План обеспечения" id="plan" noSpace>
                <PlanToEnsure />
              </Tabs.Tab>
              <Tabs.Tab title="Прочие грузы" id="other" noSpace>
                <OtherTrips />
              </Tabs.Tab>
            </Tabs>
          </div>
          <div className="sticky left-0 bottom-4 flex justify-center pointer-events-none z-30">
            <div className="backdrop-blur-sm bg-slate-100/90 p-4 mt-2 rounded-lg pointer-events-auto">
              <CheckFilters data={cargoGroups} />
            </div>
          </div>
        </motion.div>
      </OneColumnLayout>
    </>
  );
};

export default StationPage;
