import React, { createRef, useCallback, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Tooltip, useMap, FeatureGroup } from 'react-leaflet';
import { Cell, Pie, PieChart } from 'recharts';
import { divIcon, FeatureGroup as LeafletFeatureGroup } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import { Button } from '@factory5/ui-kit';
import { ArrowsPointingInIcon } from '@heroicons/react/24/solid';
import { FormattedValue } from 'ui';

import { StationAddress, $stationListStore } from 'features/stations';
import { MarkerClusterGroup, CustomMarker } from 'features/map';

export default function StationsMarkerList() {
  const { objects } = useStore($stationListStore);

  const stationList = objects.filter(
    (obj) => obj.latitude !== null && obj.longitude !== null,
  );

  const navigate = useNavigate();

  const map = useMap();
  const featureGroupRef = createRef<LeafletFeatureGroup>();

  const centerMap = useCallback(() => {
    if (featureGroupRef.current && stationList.length > 0)
      map.fitBounds(featureGroupRef.current?.getBounds(), {
        padding: [25, 25],
      });
  }, [stationList, map, featureGroupRef]);

  useEffect(() => {
    centerMap();
  }, [centerMap]);

  return (
    <FeatureGroup ref={featureGroupRef}>
      <div className="absolute top-0 right-0 z-1000 pr-3 pt-3">
        <Button size="sm" onClick={centerMap}>
          <ArrowsPointingInIcon width={18} />
        </Button>
      </div>

      <MarkerClusterGroup>
        {stationList
          .filter((station) => station.latitude && station.longitude)
          .map((station) => (
            <CustomMarker
              icon={divIcon({
                className: '',
                html: renderToStaticMarkup(
                  <div className="flex flex-col justify-center items-center relative">
                    <PieChart width={30} height={30}>
                      <Pie
                        isAnimationActive={false}
                        data={
                          station.planned_cars === 0 &&
                          station.loaded_cars === 0
                            ? [{ value: 1 }]
                            : [
                                {
                                  value: station.loaded_cars,
                                },
                                {
                                  value:
                                    station.planned_cars - station.loaded_cars,
                                },
                              ]
                        }
                        dataKey="value"
                        outerRadius={15}
                        innerRadius={3}
                      >
                        <Cell fill="#4F46E5" />
                        <Cell fill="#DC2626" />
                      </Pie>
                    </PieChart>

                    {!(
                      station.planned_cars === 0 && station.loaded_cars === 0
                    ) && (
                      <div className="flex flex-row gap-0.5 items-center font-black whitespace-nowrap absolute top-8">
                        <span className="text-rose-600">
                          <FormattedValue value={station.planned_cars} />, вг
                        </span>{' '}
                        /{' '}
                        <span className="text-blue-600">
                          <FormattedValue value={station.loaded_cars} />, вг
                        </span>
                      </div>
                    )}
                  </div>,
                ),
                iconAnchor: [15, 15],
              })}
              key={station.id}
              position={[station.latitude!, station.longitude!]}
              onClick={() => navigate(`/stations/${station.id}`)}
            >
              <Tooltip opacity={1}>
                <div className="flex flex-col gap-2 p-1">
                  <div className="border-b pb-2">
                    <StationAddress
                      station={station.name}
                      road={station.railway.name}
                    />
                    <div>{station.code6}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="text-sm">
                      <div>План, вг</div>
                      <div className="font-semibold">
                        <FormattedValue value={station.planned_cars} />
                      </div>
                    </div>

                    <div className="text-sm">
                      <div>Факт, вг</div>
                      <div className="font-semibold">
                        <FormattedValue value={station.loaded_cars} />
                      </div>
                    </div>

                    <div className="text-sm">
                      <div>Остаток, вг</div>
                      <div className="font-semibold">
                        <FormattedValue
                          value={station.planned_cars - station.loaded_cars}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Tooltip>
            </CustomMarker>
          ))}
      </MarkerClusterGroup>
    </FeatureGroup>
  );
}
