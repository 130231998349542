import React from 'react';
import { Button } from '@factory5/ui-kit';
import { useStore } from 'effector-react';

import {
  $selectedFilters,
  setFilterValues,
} from 'features/filters/models/filters.model';

interface ICheckFiltersProps {
  data: { id: number; name: string }[];
}

const CheckFilters = ({ data }: ICheckFiltersProps) => {
  const selectedCargo = useStore($selectedFilters)['station'].cargo as
    | number[]
    | undefined;

  return (
    <div className="flex gap-4">
      {data.map((item) => (
        <Button
          size="sm"
          shape="rounded"
          className="shrink-0"
          key={item.name}
          type={
            selectedCargo && selectedCargo.includes(item.id)
              ? 'primary'
              : 'default'
          }
          onClick={() => {
            if (!selectedCargo) {
              setFilterValues({
                rootKey: 'station',
                field: 'cargo',
                values: [item.id],
              });
              return;
            }

            const isExist = selectedCargo ? selectedCargo.indexOf(item.id) : -1;

            setFilterValues({
              rootKey: 'station',
              field: 'cargo',
              values:
                isExist !== -1
                  ? selectedCargo?.filter((i) => i !== item.id)
                  : [...(selectedCargo as number[]), item.id],
            });
          }}
        >
          {item.name}
        </Button>
      ))}
    </div>
  );
};

export default CheckFilters;
