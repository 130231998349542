import React from 'react';
import { Table } from '@factory5/ui-kit';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customStr from 'dayjs/plugin/customParseFormat';

import { CargoesSupplies, Period } from 'api/generated';

dayjs.locale('ru');
dayjs.extend(customStr);

interface IWaybillsTableProps {
  data: CargoesSupplies[];
  current: Period;
}

const WaybillsTable = ({ data, current }: IWaybillsTableProps) => {
  const columns = [
    {
      key: 'from_station',
      dataIndex: 'from_station',
      title: 'Станция отправления',
      render: (record: any) => {
        return (
          <div>
            {record.station}
            <br />({record.railway})
          </div>
        );
      },
    },
    // {
    //   key: 'to_station',
    //   dataIndex: 'to_station',
    //   title: 'Станция назначения',
    //   render: (record: any) => {
    //     return (
    //       <div>
    //         {record.station}
    //         <br />({record.railway})
    //       </div>
    //     );
    //   },
    // },
    {
      key: 'cargo',
      dataIndex: 'cargo',
      title: 'Груз',
      render: (record: any) => {
        return record ? (
          <div>
            {record.name}
            <br />({record.etsng})
          </div>
        ) : (
          '-'
        );
      },
    },
    {
      key: 'current_month_plan',
      dataIndex: 'current_month_plan',
      title: `План ${dayjs(current.start_date, 'DD.MM.YYYY')
        .format('MMMM')
        .toUpperCase()}, вг`,
      width: 150,
    },
    {
      key: 'total_cars',
      dataIndex: 'total_cars',
      title: 'Факт, вг',
      width: 100,
      render: (record: any) => Intl.NumberFormat('ru-RU').format(record),
    },
    {
      key: 'total_cars_percent',
      title: 'Факт, %',
      render: (record: any) => {
        return record.current_month_plan === 0
          ? '0%'
          : ((record.total_cars * 100) / record.current_month_plan).toFixed(2) +
              '%';
      },
      width: 100,
    },
  ];

  // const total = {
  //   plan: 0,
  //   fact: 0,
  //   factPercent: 0,
  // };

  // data.forEach((item) => {
  //   total.plan += item.current_month_plan;
  //   total.fact += item.total_cars;
  // });

  // if (total.plan > 0) {
  //   total.factPercent = Math.floor((total.fact * 100) / total.plan);
  // }

  return (
    <Table
      pagination={{ total: data.length, pageSize: 5 }}
      columns={columns}
      data={data}
      emptyText="Нет данных"
      // footer={() => {
      //   return (
      //     <div className="flex w-full justify-end text-sm font-medium py-4 bg-slate-50">
      //       <div className="pr-6">Итого:</div>
      //       <div className="w-[150px] px-6">{total.plan}</div>
      //       <div className="w-[100px] px-6">{total.fact}</div>
      //       <div className="w-[100px] px-6">{total.factPercent}%</div>
      //     </div>
      //   );
      // }}
    />
  );
};

export default WaybillsTable;
