import React from 'react';
import cx from 'classnames';

export type StationAddressProps = {
  station: string;
  road: string;
  className?: string;
};

export default function StationAddress({
  station,
  road,
  className,
}: StationAddressProps) {
  return (
    <div className={cx('flex flex-col', className)}>
      <div className="text-sm font-semibold">{station}</div>
      <div className="text-xs">{road}</div>
    </div>
  );
}
