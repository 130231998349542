export enum Time {
  NOW,
  FUTURE,
}

export enum RelativeDay {
  TODAY,
  PAST,
  FUTURE,
}
