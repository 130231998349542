import React, {
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
} from 'react';
import { Badge, Button, Card, Checkbox, Modal } from '@factory5/ui-kit';
import cx from 'classnames';
import { difference, includes } from 'lodash';
import { useStore } from 'effector-react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';

import { CarsStats } from 'api';

import {
  $stationsSupplyPlanDataListStore,
  getStationSupplyPlanDataFx,
} from 'features/stations/models/stationSupplyPlanData.model';
import { $selectedFilters } from 'features/filters';
import { $resultBlock } from 'features/stations/models/resultBlock.model';
import { Mode } from 'features/stations/models/resultBlock.types';

import ResultBlockSettings from '../ResultBlockSettings';

import { RelativeDay, Time } from './types';
import VerticalLine from './VerticalLine';
import PlanHeadDates from './PlanHeadDates';
import collectPlanData from './collectPlanData';
import { getDayRelatives } from './getDayRelatives';
import { getIndicators } from './getIndicators';
import styles from './styles.module.scss';

import { ActiveCellType } from './';

export type PlanToEnsureTableForwardedRef = {
  fetchPlan: () => void;
};

export type PlanToEnsureTableProps = {
  setActiveCell: (cell: ActiveCellType) => void;
  hasActiveCell: boolean;
};

export type SelectedCellType = {
  col: string | null;
  row: { type: string; group: string } | null;
};

const rowsSequence: (keyof CarsStats | 'result_block')[] = [
  'result_block',
  'plan',
  'backlog',
  'loaded',
  'available',
  'arriving_loaded',
  'arriving_loaded_proposed',
  'arriving_empty',
  'arriving_empty_proposed',
  'arriving_empty_proposed_approved',
  'arriving_after_unloading',
];

const PlanToEnsureTable: React.ForwardRefExoticComponent<
  PlanToEnsureTableProps & React.RefAttributes<PlanToEnsureTableForwardedRef>
> = React.forwardRef(
  (
    { setActiveCell, hasActiveCell }: PlanToEnsureTableProps,
    ref: React.ForwardedRef<PlanToEnsureTableForwardedRef>,
  ) => {
    const { id: stationId } = useParams();

    const [cursor, setCursor] = useState<Time>(Time.FUTURE);

    const [selectedCell, setSelectedCell] = useState<SelectedCellType>({
      col: null,
      row: null,
    });

    const [hiddenSections, setHiddenSections] = useState<string[]>(
      difference(rowsSequence, ['result_block', 'arriving_empty_proposed']),
    );

    const [availableLoadedCars] = useState(false);
    const [firstLeg, setFirstLeg] = useState(true);
    const [showResultBlock, setShowResultBlock] = useState(false);

    const toggleHiddenSection = (selectedSection: string) =>
      setHiddenSections((sections) =>
        includes(sections, selectedSection)
          ? sections.filter((a) => a !== selectedSection)
          : [...sections, selectedSection],
      );

    const stationsSupplyPlanDataList = useStore(
      $stationsSupplyPlanDataListStore,
    );
    const selectedFilters = useStore($selectedFilters)['station'];
    const resultBlock = useStore($resultBlock);

    const stationSupplyPlanData = stationId
      ? stationsSupplyPlanDataList[stationId]
      : undefined;

    const supplyPlanData = collectPlanData(stationSupplyPlanData?.supply_plan);

    const dislocation_start = stationSupplyPlanData?.dislocation_date;

    const pending = useStore(getStationSupplyPlanDataFx.pending);

    const fetchPlan = useCallback(() => {
      if (stationId && !hasActiveCell)
        getStationSupplyPlanDataFx({
          stationId: Number(stationId),
          cargoGroupId: selectedFilters.cargo,
          firstLeg,
          ...resultBlock,
        });
    }, [
      stationId,
      selectedFilters,
      availableLoadedCars,
      firstLeg,
      resultBlock,
    ]);

    useEffect(() => {
      fetchPlan();
    }, [fetchPlan]);

    useEffect(() => {
      const callback = (e: any) => {
        e.key === 'Escape' && setSelectedCell({ row: null, col: null });
      };

      document.addEventListener('keydown', callback);

      return () => {
        document.removeEventListener('keydown', callback);
      };
    }, []);

    useImperativeHandle(ref, () => ({ fetchPlan }), [fetchPlan]);

    const filteredDates =
      supplyPlanData?.dates.filter((date) => {
        if (
          cursor === Time.FUTURE &&
          dayjs(date, 'YYYY-MM-DD').isBefore(
            dayjs(dislocation_start, 'YYYY-MM-DD'),
            'day',
          )
        ) {
          return false;
        }
        return true;
      }) ?? [];

    return (
      <>
        <div className="relative">
          <Card noSpace bodyClassName="overflow-x-auto h-[calc(100vh-50px)]">
            <table
              className={cx(
                'text-sm divide-y',
                cursor === Time.NOW ? 'table-auto w-full' : 'table-fixed',
              )}
            >
              <thead>
                <VerticalLine
                  dates={filteredDates}
                  cursor={cursor}
                  onClick={setCursor}
                  startDate={dislocation_start}
                />
                <PlanHeadDates
                  dates={filteredDates}
                  cursor={cursor}
                  setSelectedCell={setSelectedCell}
                  selectedCell={selectedCell}
                />
              </thead>

              <tbody className="divide-y">
                {supplyPlanData?.data
                  .sort(
                    (a, b) =>
                      rowsSequence.indexOf(
                        a.key as keyof CarsStats | 'result_block',
                      ) -
                      rowsSequence.indexOf(
                        b.key as keyof CarsStats | 'result_block',
                      ),
                  )
                  .map((type, typeIdx, { length: typesLength }) => (
                    <React.Fragment key={type.key}>
                      {type.rows.map(
                        (row, rowIndex, { length: rowsLength }) => {
                          const isTotalRow = rowIndex === 0;

                          const isGroup =
                            !!row.cargo_group_id && !row.cargo_subgroup_id;

                          const isHidden = includes(hiddenSections, type.key);

                          const filteredRowValues = (row.values as any).filter(
                            (item: any) => {
                              if (
                                cursor === Time.FUTURE &&
                                dayjs(item.date, 'YYYY-MM-DD').isBefore(
                                  dayjs(dislocation_start, 'YYYY-MM-DD'),
                                  'day',
                                )
                              ) {
                                return false;
                              }
                              return true;
                            },
                          );

                          // const isSubGroup =
                          //   !!row.cargo_group_id && !!row.cargo_subgroup_id;

                          if (isHidden && !isTotalRow) return null;

                          return (
                            <React.Fragment key={row.key}>
                              <tr
                                className={cx(
                                  'divide-x align-top',
                                  isTotalRow && 'font-semibold bg-slate-200',
                                  isGroup && 'font-semibold bg-slate-50',
                                )}
                              >
                                <td
                                  colSpan={4}
                                  rowSpan={
                                    isTotalRow
                                      ? isHidden
                                        ? 1
                                        : rowsLength
                                      : undefined
                                  }
                                  className={cx(
                                    'p-2 border-r border-slate-200 relative',
                                    isTotalRow &&
                                      'bg-white text-sm font-medium',
                                    // Выделение строки
                                    'before:h-[calc(100%+2px)] before:w-[calc(100%+2px)] before:p-2 before:absolute before:-left-px before:-top-px before:border-y before:border-l before:border-sky-600 before:opacity-0 before:pointer-events-none before:transition',
                                    row.name === selectedCell.row?.group
                                      ? 'before:opacity-100 bg-sky-500 text-white'
                                      : 'bg-white',

                                    {
                                      'min-w-44 max-w-44':
                                        cursor === Time.FUTURE && isTotalRow,
                                      'min-w-[100px] max-w-[100px]':
                                        cursor === Time.FUTURE && !isTotalRow,
                                    },
                                  )}
                                >
                                  <div
                                    className="flex gap-2 cursor-pointer justify-between items-center"
                                    onClick={() => {
                                      if (row.cargo_group_id && type.name) {
                                        setSelectedCell((prev) => ({
                                          ...prev,
                                          row:
                                            prev.row &&
                                            prev.row.group === row.name
                                              ? null
                                              : {
                                                  type: type.name as string,
                                                  group: row.name,
                                                },
                                        }));
                                      }
                                    }}
                                  >
                                    <div className="flex gap-2">
                                      {isTotalRow && isHidden && (
                                        <div
                                          className="shrink-0 rounded-full bg-slate-100 cursor-pointer w-4 h-4 flex justify-center mt-0.5 items-center"
                                          onClick={() => {
                                            if (isTotalRow)
                                              toggleHiddenSection(type.key);
                                          }}
                                        >
                                          <ChevronDownIcon className="w-3 h-3" />
                                        </div>
                                      )}

                                      {isTotalRow && !isHidden && (
                                        <div
                                          className="shrink-0 rounded-full bg-slate-100 cursor-pointer w-4 h-4 flex justify-center mt-0.5 items-center"
                                          onClick={() => {
                                            if (isTotalRow)
                                              toggleHiddenSection(type.key);
                                          }}
                                        >
                                          <ChevronUpIcon className="w-3 h-3" />
                                        </div>
                                      )}

                                      <div
                                        onClick={() => {
                                          if (isTotalRow)
                                            toggleHiddenSection(type.key);
                                        }}
                                      >
                                        {isTotalRow ? type.name : row.name}
                                      </div>
                                    </div>
                                    {isTotalRow && type.key === 'available' && (
                                      <Button
                                        className="self-start ml-2"
                                        type="link"
                                        size="sm"
                                        onClick={() => setShowResultBlock(true)}
                                        icon={
                                          <PencilSquareIcon className="w-5 h-5 text-black" />
                                        }
                                      />
                                    )}
                                  </div>

                                  {isTotalRow &&
                                    type.key === 'available' &&
                                    !isHidden && (
                                      <div className="flex flex-col gap-2 mt-4 items-start">
                                        {getIndicators(resultBlock).map(
                                          (item) => (
                                            <Badge
                                              key={item.text}
                                              color={item.color}
                                              className="whitespace-nowrap"
                                            >
                                              {item.text}
                                            </Badge>
                                          ),
                                        )}
                                      </div>
                                    )}

                                  {isTotalRow &&
                                    type.key === 'arriving_empty_proposed' && (
                                      <div className="p-2 flex flex-col text-xs gap-2">
                                        <div className="flex flex-row">
                                          <Checkbox
                                            checked={firstLeg}
                                            className={styles.check}
                                            onChange={({
                                              target: { checked },
                                            }) => {
                                              setFirstLeg(checked);
                                            }}
                                          >
                                            только первое плечо
                                          </Checkbox>
                                        </div>
                                      </div>
                                    )}
                                </td>

                                {isTotalRow && (
                                  <td
                                    colSpan={4}
                                    className={cx('p-2', {
                                      'min-w-[80px] max-w-[80px]':
                                        cursor === Time.FUTURE,
                                    })}
                                  >
                                    Итого:
                                  </td>
                                )}

                                {filteredRowValues.map(
                                  (rowValue: any, rowValueIndex: any) => (
                                    <td
                                      key={`${rowValue.type}-${row.key}-${
                                        rowValue.date
                                          ? rowValue.date
                                          : 'totalByDay'
                                      }-${rowValue.type}-${rowValueIndex}`}
                                      className={cx(
                                        'p-2 h-full relative after:transition',
                                        {
                                          'hover:bg-slate-300 hover:cursor-pointer':
                                            getDayRelatives(
                                              rowValue.date,
                                              stationSupplyPlanData?.dislocation_date,
                                            ) !== RelativeDay.PAST &&
                                            rowValue.type !== 'plan' &&
                                            rowValue.type !== 'loaded' &&
                                            rowValue.type !== 'available',
                                          'bg-red-100':
                                            rowValue.value! < 0 &&
                                            rowValue.value! > -100 &&
                                            rowValue.type === 'available' &&
                                            resultBlock.resultMode ===
                                              Mode.available &&
                                            rowValue.date &&
                                            getDayRelatives(
                                              rowValue.date,
                                              stationSupplyPlanData?.dislocation_date,
                                            ) !== RelativeDay.PAST,
                                          'bg-red-200':
                                            rowValue.value! <= -100 &&
                                            rowValue.type === 'available' &&
                                            resultBlock.resultMode ===
                                              Mode.available &&
                                            rowValue.date &&
                                            getDayRelatives(
                                              rowValue.date,
                                              stationSupplyPlanData?.dislocation_date,
                                            ) !== RelativeDay.PAST,
                                          'bg-emerald-100':
                                            rowValue.type === 'available' &&
                                            resultBlock.resultMode &&
                                            rowValue.value > 0 &&
                                            rowValue.date &&
                                            getDayRelatives(
                                              rowValue.date,
                                              stationSupplyPlanData?.dislocation_date,
                                            ) !== RelativeDay.PAST,
                                        },
                                        // выделение столбца
                                        'after:h-[calc(100%+2px)] after:w-[calc(100%+2px)] after:p-2 after:absolute after:-left-px after:-top-px after:border-x after:border-sky-600 after:opacity-0',
                                        {
                                          'after:border-b':
                                            typeIdx === typesLength - 1 &&
                                            rowIndex === rowsLength - 1,
                                        },
                                        {
                                          'after:!opacity-100':
                                            (rowValue.date &&
                                              rowValue.date ===
                                                selectedCell.col) ||
                                            (!rowValue.date &&
                                              selectedCell.col === 'total'),
                                        },

                                        // выделение строки
                                        'before:h-[calc(100%+2px)] before:w-[calc(100%+2px)] before:p-2 before:absolute before:-left-px before:-top-px before:border-y before:border-sky-600 before:opacity-0 before:transition',
                                        {
                                          'before:border-r':
                                            row.name ===
                                              selectedCell.row?.group &&
                                            row.values.length - 1 ===
                                              rowValueIndex,
                                        },
                                        {
                                          'before:!opacity-100':
                                            row.name ===
                                            selectedCell.row?.group,
                                        },
                                        // Подсветка ячейки на пересечнии выделенных строки и столбца
                                        {
                                          '!bg-emerald-500 text-white':
                                            row.name ===
                                              selectedCell.row?.group &&
                                            ((rowValue.date &&
                                              rowValue.date ===
                                                selectedCell.col) ||
                                              (!rowValue.date &&
                                                selectedCell.col ===
                                                  'total')) &&
                                            rowValue.value! >= 0,

                                          '!bg-red-500 text-white':
                                            row.name ===
                                              selectedCell.row?.group &&
                                            ((rowValue.date &&
                                              rowValue.date ===
                                                selectedCell.col) ||
                                              (!rowValue.date &&
                                                selectedCell.col ===
                                                  'total')) &&
                                            rowValue.value! < 0,
                                        },
                                      )}
                                      onClick={() => {
                                        if (
                                          rowValue.date &&
                                          rowValue.type !== 'plan' &&
                                          rowValue.type !== 'loaded' &&
                                          getDayRelatives(
                                            rowValue.date,
                                            stationSupplyPlanData?.dislocation_date,
                                          ) === RelativeDay.PAST
                                        ) {
                                          return;
                                        }

                                        if (
                                          rowValue.type !== 'plan' &&
                                          rowValue.type !== 'loaded' &&
                                          rowValue.type !== 'available'
                                        ) {
                                          setActiveCell({
                                            date: rowValue.date
                                              ? rowValue.date
                                              : undefined,
                                            type: rowValue.type,
                                            groupId: rowValue.cargo_group_id
                                              ? [rowValue.cargo_group_id]
                                              : undefined,
                                            subgroupId:
                                              rowValue.cargo_subgroup_id
                                                ? [rowValue.cargo_subgroup_id]
                                                : undefined,
                                            overrideFilters: {
                                              firstLeg:
                                                type.key !==
                                                'arriving_empty_proposed'
                                                  ? undefined
                                                  : firstLeg,
                                            },
                                          });
                                        }
                                      }}
                                    >
                                      {(rowValue.type === 'available' &&
                                        resultBlock.resultMode ===
                                          Mode.available &&
                                        !rowValue.date) ||
                                      (rowValue.date &&
                                        rowValue.type !== 'plan' &&
                                        rowValue.type !== 'loaded' &&
                                        getDayRelatives(
                                          rowValue.date,
                                          stationSupplyPlanData?.dislocation_date,
                                        ) === RelativeDay.PAST) ||
                                      (rowValue.date &&
                                        rowValue.type === 'loaded' &&
                                        getDayRelatives(
                                          rowValue.date,
                                          stationSupplyPlanData?.dislocation_date,
                                        ) === RelativeDay.FUTURE)
                                        ? ''
                                        : rowValue.value}
                                    </td>
                                  ),
                                )}
                              </tr>
                            </React.Fragment>
                          );
                        },
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </Card>
          {pending && <div className="absolute inset-0 bg-gray-900/[.2]" />}
        </div>
        <Modal
          title="Настройки"
          visible={showResultBlock}
          onDismiss={() => setShowResultBlock(false)}
          zIndex={1000}
        >
          <ResultBlockSettings onClose={() => setShowResultBlock(false)} />
        </Modal>
      </>
    );
  },
);

export default PlanToEnsureTable;
