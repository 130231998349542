import React, { useState, useRef } from 'react';
import { useStore } from 'effector-react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { Input, Button } from '@factory5/ui-kit';
import cx from 'classnames';
import { useClickOutside } from 'hooks';

import { $selectedFilters, setFilterValues } from 'features/filters';

import { variants } from '../../utils';
import './styles.css';
import { FilterRootKeys, IFilter } from '../../models/filters.types';

interface IRangeProps {
  className?: string;
  data: IFilter;
  filterRootKey: FilterRootKeys;
}

const formatOutput = (range: [number | null, number | null]) => {
  if (range) {
    return `${range[0] ? range[0] + '%' : ''} — ${
      range[1] ? range[1] + '%' : ''
    }`;
  }
};

const Range = ({ data, className, filterRootKey }: IRangeProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setIsVisible(false));
  const range = useStore($selectedFilters)[filterRootKey][data.field];

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (event.key === 'Enter') setIsVisible(false);
  };

  return (
    <div
      className={cx('relative flex items-center ml', className)}
      ref={dropdownRef}
    >
      <Button
        icon={{
          icon: isVisible ? <ChevronUpIcon /> : <ChevronDownIcon />,
          placement: 'trailing',
        }}
        onClick={() => {
          setIsVisible((prev) => !prev);
        }}
        size="sm"
        type={range && (range[0] || range[1]) ? 'primary' : undefined}
      >
        {range && range.filter((item: number | null) => item).length > 0
          ? formatOutput(range)
          : data.title}
      </Button>
      {range && range.filter((item: number | null) => item).length > 0 && (
        <Button
          type="link"
          className="ml-1.5 !text-gray-300"
          icon={<XCircleIcon />}
          onClick={() => {
            setFilterValues({
              rootKey: filterRootKey,
              field: data.field,
              values: [null, null],
            });
            setIsVisible((prev) => !prev);
          }}
        />
      )}
      <AnimatePresence>
        {isVisible && (
          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={cx(
              'bg-white shadow-md rounded-md absolute p-2.5 top-9 z-[2000] max-h-96',
            )}
          >
            <div className="flex f5-input items-center">
              <Input
                className="h-[30px] w-20"
                placeholder="От, %"
                onChange={(e) => {
                  setFilterValues({
                    rootKey: filterRootKey,
                    field: data.field,
                    values: [
                      e.target.value,
                      range && range[1] ? range[1] : null,
                    ],
                  });
                }}
                value={range && range[0] ? range[0] : undefined}
                onKeyDown={handleKeyDown}
              />
              <div className="mx-2">-</div>
              <Input
                className="h-[30px] w-20"
                placeholder="До, %"
                onChange={(e) => {
                  setFilterValues({
                    rootKey: filterRootKey,
                    field: data.field,
                    values: [
                      range && range[0] ? range[0] : null,
                      e.target.value,
                    ],
                  });
                }}
                value={range && range[1] ? range[1] : undefined}
                onKeyDown={handleKeyDown}
              />
              {/* <Slider range /> */}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Range;
