import React from 'react';

import { VerdictActionEnum } from 'api';

import AcceptControl from './AcceptControl';
import RejectControl from './RejectControl';
import ShowControl from './ShowControl';

interface ITripsControlProps {
  approveManyHandler: (
    value: string,
    action: VerdictActionEnum,
    ids?: number[],
  ) => void;
  rejectManyHandler: (
    value: string,
    action: VerdictActionEnum,
    ids?: number[],
  ) => void;
}

const TripsControl = ({
  approveManyHandler,
  rejectManyHandler,
}: ITripsControlProps) => {
  return (
    <div className="flex gap-4 shrink-0">
      <ShowControl />
      <AcceptControl allHandler={approveManyHandler} />
      <RejectControl allHandler={rejectManyHandler} />
    </div>
  );
};

export default TripsControl;
