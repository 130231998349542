import { createStore, createEffect, createEvent, sample } from 'effector';
import download from 'js-file-download';

import api, {
  AlgoRunsListResponse,
  VerificationApiGetAlgoRunsRequest,
  RunningAlgoExistsResponse,
} from 'api';

import { $me } from 'features/auth';

const filenameRegex = /filename\*=(?:utf-8'')?(.*)/;

function getFileNameFromHeaderContentDisposition(contentDisposition: string) {
  const matches = filenameRegex.exec(contentDisposition);
  const filenameMatches = matches && matches[1];

  return filenameMatches && decodeURIComponent(filenameMatches);
}

// MODELS -------------------

export const $isAlgoRunning = createStore<RunningAlgoExistsResponse>({
  is_running: false,
});
export const $runningResults = createStore<AlgoRunsListResponse | null>(null);
export const $toastVisible = createStore(false);

// EVENTS -------------------

export const changeIsRunning = createEvent<boolean>();
export const changeToastVisibility = createEvent<boolean>();

// EFFECTS -------------------

export const generateFx = createEffect({
  handler: async () => {
    const { data } = await api.verification.generateTrips();

    return data;
  },
});

export const isAlgoRunningFx = createEffect({
  handler: async () => {
    const { data } = await api.verification.isAlgoRunning();

    return data;
  },
});

export const fetchLogsFx = createEffect({
  handler: async () => {
    const { data, headers } = await api.verification.getLogsFile();

    const filename = getFileNameFromHeaderContentDisposition(
      headers['content-disposition'],
    );

    download(data as unknown as string, filename ? filename : 'Логи.txt');

    return;
  },
});

export const fetchVerificationFileFx = createEffect({
  handler: async () => {
    const { headers, data } = await api.verification.getVerificationFile({
      responseType: 'blob',
    });

    const filename = getFileNameFromHeaderContentDisposition(
      headers['content-disposition'],
    );

    download(
      data as unknown as string,
      filename ? filename : 'Верификация.xlsx',
    );

    return;
  },
});

export const fetchRunningResultsFx = createEffect({
  handler: async ({
    pageSize = 10,
    page = 1,
  }: VerificationApiGetAlgoRunsRequest) => {
    const { data } = await api.verification.getAlgoRuns({ page, pageSize });

    return data;
  },
});

// STORE METHODS -------------------

$isAlgoRunning
  .on(isAlgoRunningFx.doneData, (state, data) =>
    state.is_running !== data.is_running ? data : state,
  )
  .on(changeIsRunning, (state, data) => ({ ...state, is_running: data }));

$runningResults.on(fetchRunningResultsFx.doneData, (_, data) => data);

sample({
  source: { isRunning: $isAlgoRunning, me: $me },
  filter: ({ isRunning, me }) => !isRunning.is_running && me?.role !== 2, // 2 == Диспетчер
  fn: () => ({ pageSize: 10, page: 1 }),
  target: fetchRunningResultsFx,
});

generateFx.done.watch(async () => {
  await wait(500);
  fetchRunningResultsFx({ pageSize: 10, page: 1 });
});

function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

$toastVisible.on(changeToastVisibility, (_, data) => {
  return data;
});

sample({
  source: {
    isRunning: $isAlgoRunning,
  },
  filter: ({ isRunning }) => {
    return !isRunning.is_running;
  },
  fn: () => true,
  target: changeToastVisibility,
});
