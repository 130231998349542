import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';

import { $me, fetchMeFx } from 'features/auth';
import { isAlgoRunningFx } from 'features/manage';

export function useAppInit() {
  const [loaded, setLoaded] = useState(false);

  const me = !!useStore($me);

  useEffect(() => {
    if (localStorage.getItem('access') && !me) {
      fetchMeFx();
      return;
    }

    setLoaded(true);
  }, [me]);

  useEffect(() => {
    const unwatchFetchMeDoneFx = fetchMeFx.done.watch(() => setLoaded(true));

    return () => {
      unwatchFetchMeDoneFx();
    };
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (localStorage.getItem('access') && me) {
      isAlgoRunningFx();
      interval = setInterval(() => {
        isAlgoRunningFx();
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [!!me]);

  return loaded;
}
