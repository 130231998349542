import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Loading, NotificationProvider } from '@factory5/ui-kit';
import { RunningPopup } from 'ui';
import { PrivateRoute } from 'lib';

import AuthPage from 'pages/auth';
import NotFoundPage from 'pages/notFound';
import StationsPage from 'pages/stations';
import StationPage from 'pages/stations/station';
import ProposedTripsPage from 'pages/proposedTrips';
import ManagementPage from 'pages/management';
import DislocationPage from 'pages/dislocation';
import CalculationResultPage from 'pages/management/result';

import { setFilterValues } from 'features/filters';
import { Notifications } from 'features/notifications';
import { useAppInit } from 'features/app/hooks/useAppInit';

function App() {
  const appIsLoaded = useAppInit();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/proposed-trips') {
      setFilterValues({
        rootKey: 'proposedTrips',
        field: 'car_no',
        values: [],
      });
    }
  }, [location]);

  if (!appIsLoaded) {
    return (
      <div className="absolute top-1/2 left-1/2 -translate-1/2 z-10">
        <Loading size="xl" />
      </div>
    );
  }

  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            index
            element={
              <PrivateRoute>
                <StationsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="dislocation"
            element={
              <PrivateRoute>
                <DislocationPage />
              </PrivateRoute>
            }
          />
          <Route
            path="proposed-trips"
            element={
              <PrivateRoute>
                <ProposedTripsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="stations/:id"
            element={
              <PrivateRoute>
                <StationPage />
              </PrivateRoute>
            }
          />
          <Route
            path="stations/:id/:tab"
            element={
              <PrivateRoute>
                <StationPage />
              </PrivateRoute>
            }
          />
          <Route
            path="management"
            element={
              <PrivateRoute>
                <ManagementPage />
              </PrivateRoute>
            }
          />
          <Route
            path="management/:id"
            element={
              <PrivateRoute>
                <CalculationResultPage />
              </PrivateRoute>
            }
          />
          <Route path="login" element={<AuthPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>

        <NotificationProvider>
          <Notifications />
        </NotificationProvider>
      </AnimatePresence>
      <RunningPopup />
    </>
  );
}

export default App;
