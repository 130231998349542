import React from 'react';
import { useStore } from 'effector-react';
import { ThreeStateSwitch } from '@factory5/ui-kit';

import { $selectedFilters, setFilterValues } from 'features/filters';

import { FilterRootKeys, IFilter } from '../../models/filters.types';

interface IToggleFilterProps {
  key?: string;
  data: IFilter;
  filterRootKey: FilterRootKeys;
}

const ToggleFilter = ({ data, filterRootKey }: IToggleFilterProps) => {
  const value = useStore($selectedFilters)[filterRootKey][data.field];

  return (
    <div className="flex text-sm items-center">
      <div className="mr-2 ml-2.5">{data.title}</div>
      <ThreeStateSwitch
        value={value}
        onChange={(value) => {
          setFilterValues({
            rootKey: filterRootKey,
            field: data.field,
            values: value,
          });
        }}
      />
    </div>
  );
};

export default ToggleFilter;
