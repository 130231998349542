import React from 'react';
import { Card } from '@factory5/ui-kit';
import PieChart from 'ui/PieChart';

import { BarChart, BarChartLayout } from 'features/statistic';
import { StationCharts } from 'features/stations/models/stationCharts.types';

type ChartListProps = {
  data: NonNullable<StationCharts>;
  isDetail?: boolean;
};

export default function ChartList({
  data: stationCharts,
  isDetail = false,
}: ChartListProps) {
  return (
    <Card bodyClassName="flex h-96">
      <div className="flex flex-col flex-1 gap-4">
        <div className="font-semibold text-center text-sm text-slate-600">
          Соотношение потоков на {isDetail ? `станции` : `станциях`}, %
        </div>

        <PieChart
          data={[
            {
              name: 'В пути на станцию порожние',
              value: stationCharts.cars_empty_on_way,
            },
            {
              name: 'Груженые в пути со станции',
              value: stationCharts.cars_loaded_on_way,
            },
            {
              name:'Прибыли на станцию порожние',
              value: stationCharts.cars_empty_on_station,
            },
            {
              name: isDetail ? 'Прибыли на выгрузку со станции' : 'Прибыли на выгрузку со станций',
              value: stationCharts.cars_loaded_on_station,
            },
          ].filter((a) => a.value !== 0)}
          colors={['#3063bd', '#f47926', '#a4a4a4', '#f4bf02']}
          dataKey="value"
        />
      </div>

      <div className="flex flex-col flex-1 gap-4">
        <div className="font-semibold text-center text-sm text-slate-600">
          Простой на {isDetail ? `станции` : `станциях`}, ваг.
        </div>

        <BarChart
          data={[
            {
              name: '< 3 суток',
              value: stationCharts.idle_cars_less_than_3_days,
            },
            {
              name: '3-5 суток',
              value: stationCharts.idle_cars_3_5_days,
            },
            {
              name: '6-9 суток',
              value: stationCharts.idle_cars_6_9_days,
            },
            {
              name: '> 10 суток',
              value: stationCharts.idle_cars_more_than_10_days,
            },
          ]}
          width="100%"
          hideLegend
          layout={BarChartLayout.horizontal}
          colors={['#70ad48', '#ffc102', '#ed7d31', '#cc0000']}
          tooltipFormatter={(value, name, props) => [value, props.payload.name]}
        />
      </div>

      <div className="flex flex-col flex-1 gap-4">
        <div className="font-semibold text-center text-sm text-slate-600">
          Брошенные вагоны на {isDetail ? `станции` : `станциях`}, ваг.
        </div>

        <BarChart
          data={[
            {
              name: isDetail ? 'Порожние на станцию' : 'Порожние на станциях',
              Брошенные: stationCharts.empty_cars_abandoned,
              'В движении': stationCharts.empty_cars_in_move,
            },
            {
              name: isDetail ? 'Груженые со станции' : 'Груженые со станций',
              Брошенные: stationCharts.loaded_cars_abandoned,
              'В движении': stationCharts.loaded_cars_in_move,
            },
          ]}
          width="100%"
          hideLegend
          layout={BarChartLayout.vertical}
          yAxisWidth={160}
          colors={['#cc0000', '#70ad48']}
          stacked
        />
      </div>
    </Card>
  );
}
