import React from 'react';
import { Table } from '@factory5/ui-kit';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customStr from 'dayjs/plugin/customParseFormat';

import { StationPlanData, Period } from 'api/generated';

import './styles.css';

dayjs.locale('ru');
dayjs.extend(customStr);

interface ITopStationsTableProps {
  data: StationPlanData[];
  current: Period;
  next?: Period;
}

const TopStationsTable = ({ data, current, next }: ITopStationsTableProps) => {
  const columns = [
    {
      key: 'from_station',
      dataIndex: 'from_station',
      title: 'Станция отправления',
      className: 'custom-cell',
      render: (record: any) => {
        return (
          <div>
            {record.station}
            <br />({record.railway})
          </div>
        );
      },
    },
    {
      title: `${dayjs(current.start_date, 'DD.MM.YYYY').format('D')}—${dayjs(
        current.end_date,
        'DD.MM.YYYY',
      )
        .format("D MMMM[']YY")
        .toUpperCase()}`,
      className: 'custom-cell',
      children: [
        {
          key: 'month_planned_cars',
          dataIndex: 'current_period_data',
          title: 'Месячный план, вг',
          className: 'custom-cell',
          render: (record: any) =>
            record.month_planned_cars !== undefined
              ? Intl.NumberFormat('ru-RU').format(record.month_planned_cars)
              : '-',
        },
        {
          key: 'period_planned_cars',
          dataIndex: 'current_period_data',
          title: 'План на период, вг',
          className: 'custom-cell',
          render: (record: any) =>
            record.period_planned_cars !== undefined
              ? Intl.NumberFormat('ru-RU').format(record.period_planned_cars)
              : '-',
        },
        {
          key: 'proposed_cars',
          dataIndex: 'current_period_data',
          title: 'Расчётные отправки за период, вг',
          className: 'custom-cell',
          render: (record: any) =>
            record.proposed_cars !== undefined
              ? Intl.NumberFormat('ru-RU').format(record.proposed_cars)
              : '-',
        },
        {
          key: 'current_percent',
          dataIndex: 'current_period_data',
          title: '% выполнения за период',
          className: 'custom-cell',
          render: (record: any) =>
            record.period_planned_cars === 0
              ? '0%'
              : (
                  (record.proposed_cars * 100) /
                  record.period_planned_cars
                ).toFixed(2) + '%',
        },
      ],
    },
    ...(next
      ? [
          {
            title: `${dayjs(next.start_date, 'DD.MM.YYYY').format('D')}—${dayjs(
              next.end_date,
              'DD.MM.YYYY',
            )
              .format("D MMMM[']YY")
              .toUpperCase()}`,
            className: 'custom-cell',
            children: [
              {
                key: 'month_planned_cars',
                dataIndex: 'next_period_data',
                title: 'Месячный план, вг',
                className: 'custom-cell',
                render: (record: any) =>
                  record.month_planned_cars !== undefined
                    ? Intl.NumberFormat('ru-RU').format(
                        record.month_planned_cars,
                      )
                    : '-',
              },
              {
                key: 'period_planned_cars',
                dataIndex: 'next_period_data',
                title: 'План на период, вг',
                className: 'custom-cell',
                render: (record: any) =>
                  record.period_planned_cars !== undefined
                    ? Intl.NumberFormat('ru-RU').format(
                        record.period_planned_cars,
                      )
                    : '-',
              },
              {
                key: 'proposed_cars',
                dataIndex: 'next_period_data',
                title: 'Расчётные отправки за период, вг',
                className: 'custom-cell',
                render: (record: any) =>
                  record.proposed_cars !== undefined
                    ? Intl.NumberFormat('ru-RU').format(record.proposed_cars)
                    : '-',
              },
              {
                key: 'current_percent',
                dataIndex: 'next_period_data',
                title: '% выполнения за период',
                className: 'custom-cell',
                render: (record: any) =>
                  record.period_planned_cars === 0
                    ? '0%'
                    : (
                        (record.proposed_cars * 100) /
                        record.period_planned_cars
                      ).toFixed(2) + '%',
              },
            ],
          },
        ]
      : []),
  ];

  return (
    <Table
      columns={columns}
      data={data}
      emptyText="Нет данных"
      pagination={{ hideOnSinglePage: true, total: data.length }}
    />
  );
};

export default TopStationsTable;
