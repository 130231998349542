import React from 'react';
import { useStore } from 'effector-react';
import { Pagination, Loading, Select, Empty } from '@factory5/ui-kit';
import { uniqueId } from 'lodash';
import cx from 'classnames';

import { $selectedFilters } from 'features/filters';
import { DislocationRow } from 'features/stations/components';

import {
  $wagonDeploymentListStore,
  $wagonDeploymentListRequestParams,
  getWagonDeploymentListFx,
  changeWagonDeploymentListRequestParams,
} from '../../models/wagonDeployment.model';

export default function WagonDeploymentList() {
  const { objects, page, page_size, objects_count } = useStore(
    $wagonDeploymentListStore,
  );
  const requestParams = useStore($wagonDeploymentListRequestParams);
  const filters = useStore($selectedFilters)['station'];
  const isLoading = useStore(getWagonDeploymentListFx.pending);

  return (
    <>
      <div className="flex flex-col gap-4 relative min-h-[100px]">
        {objects.map((wagonDeployment) => (
          <DislocationRow
            key={uniqueId()}
            wagonDeployment={wagonDeployment}
            className={cx({ 'opacity-30': isLoading })}
            filterKey="station"
          />
        ))}
        {isLoading && (
          <div className="z-10 absolute top-20 left-1/2 -translate-x-1/2">
            <Loading size="xl" />
          </div>
        )}
        {!objects.length && !isLoading && (
          <div className="flex justify-center my-6">
            <Empty />
          </div>
        )}
      </div>

      {objects.length > 0 && !isLoading && (
        <div className="mt-2 flex flex-col items-center relative">
          <Pagination
            current={page}
            pageSize={page_size}
            total={objects_count || 0}
            onChange={(page) => {
              changeWagonDeploymentListRequestParams({
                page: page.current,
              });
              getWagonDeploymentListFx({
                ...requestParams,
                page: page.current,
                pageSize: Number(page_size),
                ...filters,
              });
            }}
          />
          <div className="absolute right-0 w-20">
            <Select
              search={false}
              options={[
                {
                  label: '25',
                  value: 25,
                },
                {
                  label: '50',
                  value: 50,
                },
                {
                  label: '100',
                  value: 100,
                },
              ]}
              value={requestParams.pageSize}
              dropdownClassName="bottom-12"
              onSelect={(pageSize) =>
                changeWagonDeploymentListRequestParams({
                  page: 1,
                  pageSize: Number(pageSize),
                })
              }
              editable={false}
            />
          </div>
        </div>
      )}
    </>
  );
}
