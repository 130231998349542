import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { Empty } from '@factory5/ui-kit';
import cx from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

import { $selectedFilters } from 'features/filters';

import {
  $stationListStore,
  $stationsRequestParams,
  getStationListFx,
} from '../../models/stations.model';

import StationListItem from './StationListItem';

export default function StationList() {
  const stationList = useStore($stationListStore);
  const requestParams = useStore($stationsRequestParams);
  const filters = useStore($selectedFilters)['stations'];

  useEffect(() => {
    getStationListFx({ ...requestParams, ...filters });
  }, [requestParams, filters]);

  return (
    <div className="h-full py-6">
      <PerfectScrollbar
        className={cx('flex flex-col gap-2 bg-white h-full overflow-y-auto bg-white shadow rounded-lg', {
          'justify-center': !stationList.objects.length,
        })}
      >
        {stationList.objects.length > 0 ? (
          stationList.objects.map((station, index, array) => (
            <React.Fragment key={station.id}>
              <div key={station.id}>
                <StationListItem
                  station={station}
                  className="hover:bg-gray-50 transition-colors cursor-pointer"
                />
              </div>

              {index !== array.length - 1 && <hr className="border-gray-100" />}
            </React.Fragment>
          ))
        ) : (
          <Empty />
        )}
      </PerfectScrollbar>
    </div>
  );
}
