import { createEffect, createStore } from 'effector';
import { camelCase } from 'lodash';

import api from 'api';

import {
  StationCharts,
  StationChartsRequestParams,
} from './stationCharts.types';

// MODELS -------------------

export const $stationChartsStore = createStore<StationCharts>(null);

export const $stationsChartsStore = createStore<StationCharts>(null);

// EFFECTS -------------------

export const getStationChartsFx = createEffect(
  async (id: number) => await api.verification.getStationCharts({ pk: id }),
);

export const getStationsChartsFx = createEffect(
  async (params: StationChartsRequestParams) => {
    const formattedParams: { [k: string]: any } = Object.fromEntries(
      Object.entries(params).map(([k, v]) => [camelCase(k), v]),
    );

    const { planCompletion, ...restFilters } = {
      ...params,
      ...formattedParams,
    };

    return await api.verification.getStationsCharts({
      planCompletionGte:
        planCompletion && planCompletion[0] ? planCompletion[0] : undefined,
      planCompletionLte:
        planCompletion && planCompletion[1] ? planCompletion[1] : undefined,
      ...restFilters,
    });
  },
);

// STORE METHODS -------------------

$stationChartsStore.on(
  [getStationChartsFx.done],
  (state, { result }) => result.data,
);

$stationsChartsStore.on(
  [getStationsChartsFx.done],
  (state, { result }) => result.data,
);
