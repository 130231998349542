import React, { useState, useEffect } from 'react';
import { Radio, RadioGroup, Checkbox, Button, Divider } from '@factory5/ui-kit';
import { useStore } from 'effector-react';
import cx from 'classnames';
import { useFirstMountState } from 'hooks';

import { $resultBlock, setValue } from '../../models/resultBlock.model';
import { LoadingType, Mode } from '../../models/resultBlock.types';

interface ResultBlockSettingsProps {
  onClose: () => void;
}

const ResultBlockSettings = ({ onClose }: ResultBlockSettingsProps) => {
  const resultBlock = useStore($resultBlock);
  const [tempOptions, setTempOptions] = useState(resultBlock);
  const firstMount = useFirstMountState();

  useEffect(() => {
    !firstMount && setTempOptions(resultBlock);
  }, [resultBlock]);

  return (
    <div className="flex flex-col">
      <div className="flex gap-4">
        <div className="w-1/2 flex flex-col gap-4">
          <h4 className="font-medium">Режим</h4>
          <Radio
            checked={tempOptions.resultMode === Mode.available}
            onChange={() => {
              setTempOptions((prev) => ({
                ...prev,
                resultMode: Mode.available,
              }));
            }}
          >
            Наличие
          </Radio>

          <div className="ml-6 flex flex-col gap-4">
            <Checkbox
              checked={tempOptions.withLoading}
              className={cx({
                'opacity-50': tempOptions.resultMode !== Mode.available,
              })}
              disabled={tempOptions.resultMode !== Mode.available}
              onChange={(e) =>
                setTempOptions((prev) => ({
                  ...prev,
                  withLoading: e.target.checked,
                }))
              }
            >
              Учитывать погрузки
            </Checkbox>

            <RadioGroup
              className={cx('ml-6', {
                'opacity-50':
                  !tempOptions.withLoading ||
                  tempOptions.resultMode !== Mode.available,
              })}
              disabled={
                !tempOptions.withLoading ||
                tempOptions.resultMode !== Mode.available
              }
              value={tempOptions.loadingType}
              onChange={(value) =>
                setTempOptions((prev) => ({
                  ...prev,
                  loadingType: value as LoadingType,
                }))
              }
            >
              <Radio value={LoadingType.loadingByPlan}>По графику</Radio>
              <Radio value={LoadingType.loadingByAvailable}>По наличию</Radio>
            </RadioGroup>

            <Checkbox
              checked={tempOptions.showShortage}
              className={cx({
                'opacity-50': tempOptions.resultMode !== Mode.available,
              })}
              disabled={tempOptions.resultMode !== Mode.available}
              onChange={(e) =>
                setTempOptions((prev) => ({
                  ...prev,
                  showShortage: e.target.checked,
                }))
              }
            >
              Показывать нехватку/избыток
            </Checkbox>
          </div>

          <Radio
            checked={tempOptions.resultMode === Mode.arriving}
            onChange={() => {
              setTempOptions((prev) => ({
                ...prev,
                withLoading: false,
                showShortage: false,
                resultMode: Mode.arriving,
              }));
            }}
          >
            Подход
          </Radio>
        </div>
        <div className="w-1/2 flex flex-col gap-4">
          <h4 className="font-medium">Добавить в расчет</h4>
          <Checkbox
            checked={tempOptions.addLoadedCars}
            onChange={(e) =>
              setTempOptions((prev) => ({
                ...prev,
                addLoadedCars: e.target.checked,
              }))
            }
          >
            Подход груженых
          </Checkbox>
          <Checkbox
            checked={tempOptions.addEmptyProposed}
            onChange={(e) =>
              setTempOptions((prev) => ({
                ...prev,
                addEmptyProposed: e.target.checked,
              }))
            }
          >
            Прогноз порожних
          </Checkbox>
          <Checkbox
            checked={tempOptions.addEmptyProposedApproved}
            disabled={!tempOptions.addEmptyProposed}
            className={cx('ml-6', {
              'opacity-50': !tempOptions.addEmptyProposed,
            })}
            onChange={(e) =>
              setTempOptions((prev) => ({
                ...prev,
                addEmptyProposedApproved: e.target.checked,
              }))
            }
          >
            Только одобренные
          </Checkbox>
          <Checkbox
            checked={tempOptions.addLoadedProposed}
            onChange={(e) =>
              setTempOptions((prev) => ({
                ...prev,
                addLoadedProposed: e.target.checked,
              }))
            }
          >
            Расчетные груженые
          </Checkbox>
        </div>
      </div>
      <Divider />
      <div className="flex justify-end gap-3">
        <Button onClick={() => onClose()}>Отмена</Button>
        <Button
          onClick={() => {
            setValue(tempOptions);
            onClose();
          }}
          type="primary"
        >
          Применить
        </Button>
      </div>
    </div>
  );
};

export default ResultBlockSettings;
