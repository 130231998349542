import React, { useState, useEffect } from 'react';
import { Button, Description, Modal, Table } from '@factory5/ui-kit';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import { ReferenceErrors as ReferenceErrorsType } from 'api/generated';

interface IReferenceErrorsProps {
  data: ReferenceErrorsType;
}

const getReferenceText = (key: string) => {
  switch (key) {
    case 'current_trips':
      return 'Дислокация';
    case 'load_requests':
      return 'План погрузки';
    case 'railways':
      return 'Дороги';
    case 'stations':
      return 'Станции';
    case 'distances':
      return 'Расстояния';
    case 'cars':
      return 'Подвижной состав';
    case 'car_models':
      return 'Модели вагонов';
    case 'car_types':
      return 'Типы вагонов';
    case 'measurements/wheels':
      return 'КТИ';
    case 'countries':
      return 'Страны';
    case 'waybills':
      return 'Накладные';
    case 'cargos/subgroups':
      return 'Подгруппы грузов';
    case 'cargos/groups':
      return 'Группы грузов';
    case 'cargos':
      return 'Грузы';
    case 'legal_entities':
      return 'Юридические лица';
    default:
      return '';
  }
};

const getErrorsCount = (errors: { count: number; error: string }[]) =>
  errors.reduce((acc, val) => acc + val.count, 0);

const columns = [
  {
    key: 'error',
    dataIndex: 'error',
    title: 'Ошибка',
  },
  {
    key: 'count',
    dataIndex: 'count',
    title: 'Количество',
  },
];

const ReferenceErrors = ({ data }: IReferenceErrorsProps) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<{
    title: string;
    table: any;
  }>({
    title: '',
    table: [],
  });

  useEffect(() => {
    if (!showModal) {
      setTimeout(() => {
        setModalData({
          title: '',
          table: [],
        });
      }, 300);
    }
  }, [showModal]);

  return (
    <>
      <Description
        layout="horizontal"
        columns={1}
        items={Object.keys(data)
          .filter((i) => data[i as keyof typeof data])
          .map((key) => {
            const item = data[key as keyof typeof data];
            return {
              title: getReferenceText(key),
              value: (
                <>
                  {getErrorsCount(item!.errors) > 0 ? (
                    <div className="flex justify-between w-full">
                      <div className="font-semibold text-red-600">
                        {getErrorsCount(item!.errors)}
                      </div>
                      <Button
                        type="link"
                        className="ml-6"
                        onClick={() => {
                          setShowModal(true);
                          setModalData({
                            title: getReferenceText(key),
                            table: item!.errors,
                          });
                        }}
                      >
                        Подробнее
                      </Button>
                    </div>
                  ) : (
                    <CheckCircleIcon className="w-4 h-4 text-green-500" />
                  )}
                </>
              ),
            };
          })}
      />
      <Modal
        visible={showModal}
        onDismiss={() => {
          setShowModal(false);
        }}
        title={modalData.title}
      >
        <Table
          columns={columns}
          data={modalData.table}
          pagination={{ total: modalData.table.length, hideOnSinglePage: true }}
        />
      </Modal>
    </>
  );
};

export default ReferenceErrors;
