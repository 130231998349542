import dayjs from 'dayjs';

import { RelativeDay } from './types';

export const getDayRelatives = (
  today: string,
  testDate?: string,
): RelativeDay => {
  // IS PAST
  if (
    dayjs(today, 'YYYY-MM-DD').isBefore(dayjs(testDate, 'YYYY-MM-DD'), 'day')
  ) {
    return RelativeDay.PAST;
  }

  // IS TODAY
  if (dayjs(today, 'YYYY-MM-DD').isSame(dayjs(testDate, 'YYYY-MM-DD'), 'day')) {
    return RelativeDay.TODAY;
  }

  // IS FUTURE
  if (
    dayjs(today, 'YYYY-MM-DD').isAfter(dayjs(testDate, 'YYYY-MM-DD'), 'day')
  ) {
    return RelativeDay.FUTURE;
  }

  return RelativeDay.TODAY;
};
