import React from 'react';
import { Badge, BadgeProps } from '@factory5/ui-kit';
import cx from 'classnames';

import { OperationEngLabelsEnum } from 'api';

export type ObjectiveStatusProps = {
  objective: OperationEngLabelsEnum;
};

const ObjectiveLabel: Record<OperationEngLabelsEnum, string> = {
  [OperationEngLabelsEnum.Loading]: 'На погрузку',
  [OperationEngLabelsEnum.Parking]: 'В отстой',
  [OperationEngLabelsEnum.Repair]: 'Ремонт',
  [OperationEngLabelsEnum.Unloading]: 'На выгрузку',
  [OperationEngLabelsEnum.Washing]: 'На промывку',
  [OperationEngLabelsEnum.Dontuse]: 'На филиал',
};

export default function ObjectiveStatus({ objective }: ObjectiveStatusProps) {
  return (
    <Badge
      size="md"
      color={
        cx({
          red: objective === OperationEngLabelsEnum.Repair,
          emerald: objective === OperationEngLabelsEnum.Loading,
          indigo: objective === OperationEngLabelsEnum.Unloading,
          amber: objective === OperationEngLabelsEnum.Washing,
        }) as BadgeProps['color']
      }
    >
      {ObjectiveLabel[objective]}
    </Badge>
  );
}
