import React, { useState, useRef } from 'react';
import { Button, RadioGroup, Radio } from '@factory5/ui-kit';
import { useStore } from 'effector-react';
import { Dropdown } from 'ui';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useClickOutside } from 'hooks';

import { ControlProposedTripStatus, VerdictActionEnum } from 'api';

import {
  $approveType,
  $controlTripListStore,
  changeApproveType,
} from 'features/stations/models/controlTrips.model';
import { ActionType } from 'features/stations/models/controlTrips.types';

const getButtonText = (value: ActionType) => {
  if (value === ActionType.manual) {
    return 'Одобрить';
  } else if (value === ActionType.all) {
    return 'Одобрить все рейсы';
  } else {
    return 'Одобрить все рейсы на странице';
  }
};

interface IAcceptControlProps {
  allHandler: (
    value: string,
    action: VerdictActionEnum,
    ids?: number[],
  ) => void;
}
const AcceptControl = ({ allHandler }: IAcceptControlProps) => {
  const [visible, setVisible] = useState(false);
  const approveType = useStore($approveType);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setVisible(false));
  const { objects: controlTrips } = useStore($controlTripListStore);

  return (
    <div className="relative flex" ref={dropdownRef}>
      <Button
        size="sm"
        icon={{
          icon: <ChevronDownIcon />,
          placement: 'trailing',
        }}
        onClick={() => {
          setVisible((prev) => !prev);
        }}
        type={approveType === ActionType.manual ? 'default' : 'primary'}
      >
        {getButtonText(approveType)}
      </Button>
      <Dropdown visible={visible}>
        <div className="flex flex-col">
          <RadioGroup
            value={approveType}
            onChange={(value) => {
              changeApproveType(value as ActionType);
              if (value === ActionType.all) {
                allHandler(value as string, VerdictActionEnum.Approve);
              } else if (value === ActionType.page) {
                allHandler(
                  value as string,
                  VerdictActionEnum.Approve,
                  controlTrips
                    .filter((item) => item.proposed_trip_status?.first_leg)
                    .map(
                      (item) => (item.proposed_trip_status as ControlProposedTripStatus).id,
                    ),
                );
              } else {
                // allHandler(value as string, VerdictActionEnum.Approve);
              }
            }}
          >
            <Radio value={ActionType.all}>Одобрить все рейсы</Radio>
            <Radio value={ActionType.page}>
              Одобрить все рейсы на странице
            </Radio>
            <Radio value={ActionType.manual}>Одобрять индивидуально</Radio>
          </RadioGroup>
        </div>
      </Dropdown>
    </div>
  );
};

export default AcceptControl;
