import React, { useEffect } from 'react';
import { useStore } from 'effector-react';

import {
  TagsFilters,
  $filtersSet,
  $selectedFilters,
  fetchProposedTripsFilterOptionsFx,
} from 'features/filters';

import {
  getProposedTripsFx,
  $proposedTripListRequestParams,
  $proposedTripListStore,
} from '../../models/proposedTrips.model';

const ProposedTripsFiltersProvider = () => {
  const filters = useStore($filtersSet);
  const selectedFilters = useStore($selectedFilters)['proposedTrips'];
  const autocompleteIsLoading = useStore(
    fetchProposedTripsFilterOptionsFx.pending,
  );
  const requestParams = useStore($proposedTripListRequestParams);
  const { objects_total_count, objects_count } = useStore(
    $proposedTripListStore,
  );

  useEffect(() => {
    getProposedTripsFx({ ...requestParams, ...selectedFilters });
  }, [selectedFilters, requestParams]);

  return (
    <TagsFilters
      data={filters}
      filterRootKey="proposedTrips"
      noSearch={['arrival_operation']}
      autocompleteOptionsHandler={fetchProposedTripsFilterOptionsFx}
      autocompleteIsLoading={autocompleteIsLoading}
      maxElCount={objects_total_count}
      currentElCount={objects_count}
    />
  );
};

export default ProposedTripsFiltersProvider;
