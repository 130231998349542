import React from 'react';
import { Header } from 'ui';

interface ITwoColumnsProps {
  children: React.ReactNode;
  sidebar: React.ReactNode;
  pageHeader?: React.ReactNode;
}

const TwoColumns = ({ children, pageHeader, sidebar }: ITwoColumnsProps) => {
  return (
    <div className="grid grid-rows-full h-full bg-slate-100">
      <div className="h-14 px-6 box-border border-b border-slate-100 bg-white">
        <div className="container mx-auto h-full">
          <Header />
        </div>
      </div>

      {pageHeader && (
        <div className="px-6 py-4 box-border border-b border-slate-100 bg-white">
          <div className="container mx-auto">{pageHeader}</div>
        </div>
      )}

      <div className="min-h-full flex-1 gap-6 flex container mx-auto">
        {/* Сайдбар */}
        {React.isValidElement(sidebar) &&
          React.cloneElement(sidebar, {
            ...sidebar.props,
            className: 'w-80 h-full overflow-hidden',
          })}
        {/* Основная область */}
        {React.isValidElement(children) &&
          React.cloneElement(children, {
            ...children.props,
            className: 'flex grow',
          })}
      </div>
    </div>
  );
};

export default TwoColumns;
