import React from 'react';
import { includes } from 'lodash';
import Icon from 'ui/Icon';
import { WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Tooltip from 'ui/Tooltip';

import { SignEnum } from 'api';

import { WagonDeploymentListItem } from 'features/stations/models/wagonDeplyment.types';
import { activateFilter, setFilterValues } from 'features/filters';
import { FilterRootKeys } from 'features/filters/models/filters.types';
import { ControlTripListItem } from 'features/stations/models/controlTrips.types';

dayjs.extend(localizedFormat);

export type SignListProps = {
  wagonDeployment:
    | WagonDeploymentListItem
    | ControlTripListItem['current_trip'];
  filterKey: FilterRootKeys;
};

export function SignList({
  wagonDeployment: {
    signs,
    on_route,
    off_schedule_repair,
    upcoming_repair_flag,
    planned_repair,
    repair_pending_period,
  },
  filterKey,
}: SignListProps) {
  return (
    <div className="flex flex-row gap-2 leading-none cursor-pointer">
      <Tooltip
        content={cx({
          Брошенный: includes(signs, SignEnum.CarRzdCode92),
          'Добавить фильтр по РЖД коду 92': !includes(
            signs,
            SignEnum.CarRzdCode92,
          ),
        })}
      >
        <div
          onClick={() => {
            activateFilter('car_rzd_code_92');
            setFilterValues({
              rootKey: filterKey,
              field: 'car_rzd_code_92',
              values: includes(signs, SignEnum.CarRzdCode92),
            });
          }}
          className={cx(
            'flex w-[19px] h-[19px] rounded-full items-center justify-center cursor-pointer',
            {
              'bg-red-600': includes(signs, SignEnum.CarRzdCode92),
              'bg-gray-300': !includes(signs, SignEnum.CarRzdCode92),
            },
          )}
        >
          <Icon icon="92" className="text-white w-[11px]" />
        </div>
      </Tooltip>

      <Tooltip
        content={cx({
          'Вагон на срочном ремонте': off_schedule_repair,
          'Добавить фильтр по признаку срочного ремонта': !off_schedule_repair,
        })}
      >
        <div
          className={cx(
            'flex w-[19px] h-[19px] rounded-full items-center justify-center cursor-pointer relative',
            {
              'bg-red-600': off_schedule_repair,
              'bg-gray-300': !off_schedule_repair,
            },
          )}
          onClick={() => {
            activateFilter('is_off_schedule_repair');
            setFilterValues({
              rootKey: filterKey,
              field: 'is_off_schedule_repair',
              values: off_schedule_repair,
            });
          }}
        >
          <Icon
            icon={off_schedule_repair ? 'warning-active' : 'warning-nonactive'}
            className={cx('w-[12.5px] top-[2.5px] absolute', {
              'text-white': !off_schedule_repair,
            })}
          />
        </div>
      </Tooltip>

      <Tooltip
        content={
          upcoming_repair_flag && planned_repair ? (
            dayjs().isBefore(dayjs(planned_repair)) ? (
              <div>
                Дата следующего ремонта {dayjs(planned_repair).format('LL')}{' '}
                <br />
                ближе, чем {repair_pending_period} дней норматива
              </div>
            ) : (
              <div>
                Дата следующего ремонта {dayjs(planned_repair).format('LL')}
                <br />
                просрочена
              </div>
            )
          ) : (
            'Добавить фильтр по признаку скорого ремонта'
          )
        }
      >
        <div
          className={cx(
            'flex w-[19px] h-[19px] rounded-full items-center justify-center cursor-pointer',
            {
              'bg-gray-300 text-white': !upcoming_repair_flag,
              'bg-amber-400': upcoming_repair_flag,
            },
          )}
          onClick={() => {
            activateFilter('is_planned_repair');
            setFilterValues({
              rootKey: filterKey,
              field: 'is_planned_repair',
              values: upcoming_repair_flag,
            });
          }}
        >
          <WrenchScrewdriverIcon className="w-[11px]" />
        </div>
      </Tooltip>

      <Tooltip content="Добавить фильтр ПОМ">
        <div
          className={cx(
            'flex items-center justify-center border-2 text-xxs font-bold leading-none px-[4px] rounded-md cursor-pointer',
            {
              'border-emerald-600 text-emerald-600': on_route,
            },
            {
              'border-transparent bg-gray-300 text-white': !on_route,
            },
          )}
          onClick={() => {
            activateFilter('on_route');
            setFilterValues({
              rootKey: filterKey,
              field: 'on_route',
              values: on_route,
            });
          }}
        >
          ПОМ
        </div>
      </Tooltip>
    </div>
  );
}
