import React, { useState, useRef, useEffect } from 'react';
import { Button, RadioGroup, Radio } from '@factory5/ui-kit';
import { Dropdown } from 'ui';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useClickOutside } from 'hooks';

import { StatusEnum } from 'api';

import { changeControlTripListRequestParams } from 'features/stations/models/controlTrips.model';

const ShowControl = () => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState<string>('-1');
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setVisible(false));

  useEffect(() => {
    return () => {
      changeControlTripListRequestParams({ status: undefined });
    };
  }, []);

  return (
    <div className="relative flex" ref={dropdownRef}>
      <Button
        size="sm"
        icon={{
          icon: <ChevronDownIcon />,
          placement: 'trailing',
        }}
        onClick={() => {
          setVisible((prev) => !prev);
        }}
      >
        Отображать рейсы
      </Button>
      <Dropdown visible={visible}>
        <div className="flex flex-col">
          <RadioGroup
            value={value}
            onChange={(value) => {
              changeControlTripListRequestParams({
                status: value === '-1' ? undefined : String(value),
              });
              setValue(value as string);
            }}
          >
            <Radio value="-1">Все</Radio>
            <Radio value="3">Одобренные</Radio>
            <Radio value="4">Отклоненные</Radio>
            <Radio value="0">Нет решения</Radio>
          </RadioGroup>
        </div>
      </Dropdown>
    </div>
  );
};

export default ShowControl;
