import React from 'react';
import cx from 'classnames';
import Icon from 'ui/Icon';
import { Tooltip } from 'ui';

import { ProposedTripListItem } from 'features/stations/models/proposedTrips.types';
import StationAddress from 'features/stations/components/StationAddress';
import ObjectiveStatus from 'features/stations/components/ObjectiveStatus';

export type ProposedTripListRowProps = {
  className?: string;

  proposedTrip: ProposedTripListItem;
};

export default function ProposedTripListRow({
  className,
  proposedTrip,
}: ProposedTripListRowProps) {
  const {
    departure: { date: departureDate, station: departureStation },
    arrival: { date: arrivalDate, station: arrivalStation },
    cargo,
    car: {
      no: number,
      model,
      is_rented,
      rzd_code_92,
      fitting_45_deg,
      drain_device_prot_3,
      steam_jacket,
    },
    objective,
  } = proposedTrip;

  return (
    <div
      className={cx(
        'flex flex-row justify-between px-6 py-4 items-center',
        className,
      )}
    >
      <div className="flex flex-row gap-5 items-start">
        <div className="flex flex-row items-start w-60 border-r border-slate-100">
          <div className="rounded-full mr-2">
            <Icon icon="wagon" size={18} />
          </div>

          <div className="flex flex-col items-start gap-2">
            <StationAddress
              station={String(number)}
              road={model ? model.name : ''}
            />

            <ObjectiveStatus objective={objective} />
          </div>
        </div>

        {/* START */}
        <div className="flex items-start w-60 border-r border-slate-100">
          <div className="text-center !leading-[18px] text-xs font-medium rounded-full bg-blue-500 text-white w-[18px] h-[18px] mr-2 shrink-0">
            A
          </div>

          <div className="flex flex-col w-full gap-1">
            <StationAddress
              station={departureStation.name}
              road={departureStation.railway.name}
            />
            <div className="flex pr-5 items-center">
              <div className="text-xs text-slate-500">
                {departureStation.code6}
              </div>
              <div className="w-1 h-1 rounded-full bg-slate-200 mx-2" />
              <div className="text-xs text-slate-500">{departureDate}</div>
            </div>
          </div>
        </div>

        {/* FINISH */}
        <div className="flex items-start w-60 border-r border-slate-100">
          <div className="text-center !leading-[18px] text-xs font-medium rounded-full bg-blue-500 text-white w-[18px] h-[18px] mr-2 shrink-0">
            B
          </div>

          <div className="flex flex-col w-full gap-1">
            <StationAddress
              station={arrivalStation.name}
              road={arrivalStation.railway.name}
            />
            <div className="flex pr-5 items-center">
              <div className="text-xs text-slate-500">
                {arrivalStation.code6}
              </div>
              <div className="w-1 h-1 rounded-full bg-slate-200 mx-2" />
              <div className="text-xs text-slate-500">{arrivalDate}</div>
            </div>
          </div>
        </div>

        {/* CARGO */}
        {cargo && (
          <div className="flex flex-row w-60 items-center">
            <Icon icon="storage" className="mr-4 flex-none" size={24} />

            <div className="text-xs text-ellipsis">{cargo.name}</div>
          </div>
        )}
      </div>

      <div className="flex flex-row gap-4">
        {fitting_45_deg && (
          <Tooltip content="Угол арматуры 45 градусов">
            <div>
              <Icon icon="angle45" size={20} />
            </div>
          </Tooltip>
        )}
        {drain_device_prot_3 && (
          <Tooltip content="3 степень защиты">
            <div>
              <Icon icon="protection3" size={20} />
            </div>
          </Tooltip>
        )}
        {rzd_code_92 && (
          <Tooltip content="92 код">
            <div>
              <Icon icon="code92" size={20} />
            </div>
          </Tooltip>
        )}
        {is_rented && (
          <Tooltip content="Арендованный">
            <div>
              <Icon icon="coins" size={20} />
            </div>
          </Tooltip>
        )}
        {steam_jacket && (
          <Tooltip content="Паровая рубашка">
            <div>
              <Icon icon="steam" size={20} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
