import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { PageHeader, Card } from '@factory5/ui-kit';
import { motion } from 'framer-motion';
import { pageMotion, pageHeaderMotion } from 'lib';

import { StationList, StationsFiltersProvider, StationsCharts } from 'features/stations';
import { MainMap } from 'features/map';
import { fetchLoadingStationsFiltersFx } from 'features/filters';

import { TwoColumnsLayout } from 'layouts';

const StationsPage = () => {
  useEffect(() => {
    fetchLoadingStationsFiltersFx();
  }, []);

  return (
    <>
      <Helmet>
        <title>ЦПД: Станции погрузки</title>
      </Helmet>

      <TwoColumnsLayout
        sidebar={
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageMotion}
          >
            <StationList />
          </motion.div>
        }
        pageHeader={
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageHeaderMotion}
          >
            <PageHeader
              title={<span className="text-xl">Станции погрузки</span>}
              meta={
                <div className="mt-4">
                  <StationsFiltersProvider />
                </div>
              }
            />
          </motion.div>
        }
      >
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageMotion}
        >
          <div className="flex flex-col w-full gap-6 my-6">
            <Card
              noSpace
              className="w-full grow overflow-hidden"
              bodyClassName="h-full"
            >
              <MainMap />
            </Card>

            <StationsCharts />
          </div>
        </motion.div>
      </TwoColumnsLayout>
    </>
  );
};

export default StationsPage;
