import { createEffect, createStore } from 'effector';
import { omitBy, pick } from 'lodash';

import api from 'api';

import {
  StationsSupplyPlanDataList,
  GetStationSupplyPlanDataFxRequestParams,
} from './stationSupplyPlanData.types';
import { LoadingType, IResultBlock } from './resultBlock.types';

export const $stationsSupplyPlanDataListStore =
  createStore<StationsSupplyPlanDataList>({});

export const $stationOtherTrips = createStore<StationsSupplyPlanDataList>({});

export const getStationSupplyPlanDataFx = createEffect(
  async (
    requestParams: Omit<GetStationSupplyPlanDataFxRequestParams, 'resultMode'> &
      IResultBlock,
  ) => {
    const {
      loadingType,
      withLoading,
      addEmptyProposed,
      addEmptyProposedApproved,
    } = requestParams;

    Reflect.deleteProperty(requestParams, 'loadingType');
    Reflect.deleteProperty(requestParams, 'withLoading');
    Reflect.deleteProperty(requestParams, 'addEmptyProposed');
    Reflect.deleteProperty(requestParams, 'addEmptyProposedApproved');

    const params = {
      ...pick(requestParams, 'stationId', 'oil'),
      ...omitBy(requestParams, (value) => !value),
    };

    if (loadingType === LoadingType.loadingByAvailable && withLoading) {
      params[LoadingType.loadingByAvailable] = true;
    }

    if (loadingType === LoadingType.loadingByPlan && withLoading) {
      params[LoadingType.loadingByPlan] = true;
    }

    if (addEmptyProposed && addEmptyProposedApproved) {
      params.addEmptyProposedApproved = true;
    } else if (addEmptyProposed && !addEmptyProposedApproved) {
      params.addEmptyProposed = true;
    }

    return await api.verification.getSupplyPlanData(params);
  },
);

export const getStationOtherTripsFx = createEffect(
  async (requestParams: GetStationSupplyPlanDataFxRequestParams) => {
    return await api.verification.getSupplyPlanData({
      ...pick(requestParams, 'stationId', 'oil'),
      ...omitBy(requestParams, (value) => !value),
    });
  },
);

$stationsSupplyPlanDataListStore.on(
  getStationSupplyPlanDataFx.done,
  (state, { params, result }) => {
    return { ...state, [params.stationId]: result.data };
  },
);

$stationOtherTrips.on(
  getStationOtherTripsFx.done,
  (state, { params, result }) => {
    return { ...state, [params.stationId]: result.data };
  },
);
