import React from 'react';
import { Table } from '@factory5/ui-kit';

import { StationSpeedStat } from 'api/generated';

interface ISpeedToStationsTableProps {
  data: StationSpeedStat[];
}

const SpeedToStationsTable = ({ data }: ISpeedToStationsTableProps) => {
  const columns = [
    {
      key: 'to_station',
      dataIndex: 'to_station',
      title: 'Станция назначения',
      render: (record: any) => {
        return (
          <div>
            {record.station}
            <br />({record.railway})
          </div>
        );
      },
    },
    {
      key: 'empty_avg_speed',
      dataIndex: 'empty_avg_speed',
      title: 'Сред. скорость на погрузку, км/сут.',
    },
    {
      key: 'loaded_avg_speed',
      dataIndex: 'loaded_avg_speed',
      title: 'Сред. скорость на выгрузку, км/сут.',
    },
  ];

  return (
    <Table
      pagination={{ total: data.length, pageSize: 5 }}
      columns={columns}
      data={data}
      emptyText="Нет данных"
    />
  );
};

export default SpeedToStationsTable;
