import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import {
  flattenDeep,
  groupBy,
  includes,
  keys,
  mapValues,
  reduce,
  values,
} from 'lodash';
import { Card } from '@factory5/ui-kit';
import dayjs from 'dayjs';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

import { BreakdownBySubgroups, CarsStats, ControlTripsSectionEnum } from 'api';

import { $selectedFilters } from 'features/filters';
import { getStationOtherTripsFx, $stationOtherTrips } from 'features/stations';
import { StationSupplyPlanData } from 'features/stations/models/stationSupplyPlanData.types';

function collectPlanData(otherTripsData: StationSupplyPlanData | undefined) {
  if (!otherTripsData) return;

  // Собираем список дат
  const dates = otherTripsData.map(({ date }) => date);

  // Типы срезов (График, В наличии, Подход груженных и прочее)
  const types = keys(otherTripsData[0].cars).filter(
    (type) =>
      otherTripsData[0].cars[type as keyof CarsStats] && type !== 'backlog',
  );

  const data = types.map((type) => {
    const totalRow = {
      key: `${type}-total`,
      name: 'Итого',
      cargo_group_id: null,
      cargo_subgroup_id: null,

      values: otherTripsData.map((dataByDate) => ({
        date: dataByDate.date,
        type,
        cargo_group_id: null,
        cargo_subgroup_id: null,
        value: dataByDate.cars[type as keyof CarsStats]?.total_amount,
      })),
    };

    const groupsByCargoGroupId = mapValues(
      groupBy(
        flattenDeep(
          otherTripsData.map(
            (date) => date.cars[type as keyof CarsStats]?.by_cargo_groups,
          ),
        ),
        'cargo_group_id',
      ),
      (groupById) => {
        const groupsReduce = reduce<
          BreakdownBySubgroups,
          {
            values: {
              type: string;
              date: string;
              value: number;
              cargo_group_id: number;
              cargo_subgroup_id: null;
            }[];
          }
        >(
          groupById as unknown as BreakdownBySubgroups[],
          (prev, current, index) => ({
            key: `${type}-cargo_group_id-${current.cargo_group_id}`,
            name: current.cargo_group_name,
            cargo_group_id: current.cargo_group_id,
            cargo_subgroup_id: null,
            values: [
              ...prev.values,
              {
                type,
                date: dates[index],
                value: current.total_amount,
                cargo_group_id: current.cargo_group_id,
                cargo_subgroup_id: null,
              },
            ],
          }),
          { values: [] },
        );

        return [groupsReduce];
      },
    ) as Record<
      string,
      {
        key: string;
        name: string;
        cargo_group_id: string;
        cargo_subgroup_id: string | null;
        values: {
          type: string;
          date: string;
          value: number;
          cargo_group_id: number;
          cargo_subgroup_id: null | number;
        }[];
      }[]
    >;

    return {
      key: type,
      name: otherTripsData[0].cars[type as keyof CarsStats]?.name,
      rows: [totalRow, ...flattenDeep(values(groupsByCargoGroupId))],
    };
  });

  return {
    dates,
    data,
  };
}

const OtherTrips = () => {
  const { id: stationId } = useParams();
  const otherTripsDataStore = useStore($stationOtherTrips);
  const selectedFilters = useStore($selectedFilters)['station'];
  const [hiddenSections, setHiddenSections] = useState<string[]>([]);
  const pending = useStore(getStationOtherTripsFx.pending);

  const toggleHiddenSection = (selectedSection: string) =>
    setHiddenSections((sections) =>
      includes(sections, selectedSection)
        ? sections.filter((a) => a !== selectedSection)
        : [...sections, selectedSection],
    );

  const otherTripsData = stationId ? otherTripsDataStore[stationId] : undefined;

  const preparedTripsData = collectPlanData(
    otherTripsData ? otherTripsData.supply_plan : undefined,
  );

  const fetchOther = useCallback(() => {
    if (stationId)
      getStationOtherTripsFx({
        stationId: Number(stationId),
        cargoGroupId: selectedFilters.cargo,
        oil: false,
      });
  }, [stationId, selectedFilters]);

  useEffect(() => {
    fetchOther();
  }, [fetchOther]);

  if (!preparedTripsData) return null;

  return (
    <div className="relative">
      <Card noSpace bodyClassName="overflow-x-auto h-[calc(100vh-50px)]">
        <table className="table-auto w-full text-sm divide-y ">
          <thead>
            <tr className="divide-x">
              <th
                className="p-2 font-semibold text-left sticky top-0 bg-white"
                colSpan={8}
              ></th>

              {preparedTripsData.dates.map((date) => (
                <th
                  key={date}
                  className="p-2 font-semibold sticky top-0 bg-white"
                >
                  {dayjs(date).format('DD')}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="divide-y">
            {preparedTripsData.data.map((type) => (
              <React.Fragment key={type.key}>
                {type.rows.map((row, rowIndex, { length: rowsLength }) => {
                  const isTotalRow = rowIndex === 0;

                  const isGroup =
                    !!row.cargo_group_id && !row.cargo_subgroup_id;

                  const isHidden = includes(hiddenSections, type.key);

                  if (isHidden && !isTotalRow) return null;

                  return (
                    <React.Fragment key={row.key}>
                      <tr
                        className={cx(
                          'divide-x align-top',
                          isTotalRow && 'font-bold bg-slate-200',
                          isGroup && 'font-semibold bg-slate-50',
                        )}
                      >
                        <td
                          colSpan={4}
                          rowSpan={
                            isTotalRow ? (isHidden ? 1 : rowsLength) : undefined
                          }
                          className={cx(
                            'p-2 border-r border-slate-200',
                            isTotalRow && 'bg-white text-base font-semibold',
                          )}
                        >
                          <div className="flex gap-2">
                            {isTotalRow && isHidden && (
                              <ChevronDownIcon
                                className="shrink-0 rounded-full bg-slate-100 cursor-pointer"
                                width={16}
                                height={16}
                                onClick={() => {
                                  if (isTotalRow) toggleHiddenSection(type.key);
                                }}
                              />
                            )}

                            {isTotalRow && !isHidden && (
                              <ChevronUpIcon
                                className="shrink-0 rounded-full bg-slate-100 cursor-pointer"
                                width={16}
                                height={16}
                                onClick={() => {
                                  if (isTotalRow) toggleHiddenSection(type.key);
                                }}
                              />
                            )}

                            {isTotalRow ? type.name : row.name}
                          </div>
                        </td>

                        {isTotalRow && (
                          <td colSpan={4} className="p-2">
                            Итого:
                          </td>
                        )}

                        {row.values.map((rowValue, rowValueIndex) => (
                          <td
                            key={`${rowValue.type}-${row.key}-${rowValue.date}-${rowValue.type}-${rowValueIndex}`}
                            className={cx('p-2', {
                              'hover:bg-slate-300 hover:cursor-pointer':
                                !!rowValue.cargo_subgroup_id &&
                                Object.values(ControlTripsSectionEnum).includes(
                                  rowValue.type as ControlTripsSectionEnum,
                                ),
                              'bg-red-100':
                                rowValue.value! < 0 && rowValue.value! > -100,
                              'bg-red-200': rowValue.value! <= -100,
                            })}
                          >
                            {rowValue.value}
                          </td>
                        ))}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </Card>
      {pending && <div className="absolute inset-0 bg-gray-900/[.2]" />}
    </div>
  );
};

export default OtherTrips;
