import React, { useImperativeHandle, useMemo, useState } from 'react';
import cx from 'classnames';
import {
  Placement,
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useClick,
  useRole,
  useDismiss,
} from '@floating-ui/react-dom-interactions';
import { motion, AnimatePresence } from 'framer-motion';
import { mergeRefs } from 'react-merge-refs';
import { createPortal } from 'react-dom';

import { TooltipType } from './Tooltip.types';

export type TooltipForwardedRef = {
  close: () => void;
};

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  placement?: Placement;
  type?: TooltipType;
  trigger?: 'hover' | 'click';
  className?: string;
  disabled?: boolean;
  onOpen?: () => void;
}

const Tooltip: React.ForwardRefExoticComponent<
  TooltipProps & React.RefAttributes<TooltipForwardedRef>
> = React.forwardRef(
  (
    {
      children,
      content,
      placement = 'top',
      type = 'dark',
      trigger = 'hover',
      disabled = false,
      className,
      onOpen,
    }: TooltipProps,
    forwardRef: React.ForwardedRef<TooltipForwardedRef>,
  ) => {
    const [open, setOpen] = useState(false);

    const { x, y, reference, floating, strategy, context } = useFloating({
      placement,
      open,
      onOpenChange: (value) => {
        setOpen(value);
        if (onOpen && value) onOpen();
      },
      middleware: [offset(5), flip(), shift({ padding: 8 })],
      whileElementsMounted: autoUpdate,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
      ...(trigger === 'hover'
        ? [
            useHover(context, {
              enabled: !disabled,
              move: false,
            }),
          ]
        : []),
      ...(trigger === 'click'
        ? [
            useClick(context, {
              enabled: !disabled,
            }),
          ]
        : []),
      useRole(context, { role: 'tooltip' }),
      useDismiss(context),
    ]);

    const ref = useMemo(
      () => mergeRefs([reference, (children as any).ref]),
      [reference, children],
    );

    const close = () => setOpen(false);

    useImperativeHandle(forwardRef, () => ({ close }), [close]);

    return (
      <>
        {React.isValidElement(children) &&
          React.cloneElement(
            children,
            getReferenceProps({ ref, ...children.props }),
          )}
        {createPortal(
          <AnimatePresence>
            {open && (
              <motion.div
                initial={{
                  opacity: 0,
                  scale: 0.85,
                }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'spring', damping: 20, stiffness: 300 }}
                ref={floating}
                className={cx(
                  'py-2 px-3 text-sm font-medium rounded-lg shadow-sm z-1000 absolute',
                  {
                    'text-white bg-gray-900': type === 'dark',
                    'text-gray-900 bg-white': type === 'light',
                    '!text-black bg-yellow-500': type === 'warning',
                    'text-white bg-red-600': type === 'error',
                  },
                  className,
                )}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  pointerEvents: 'none',
                }}
                {...getFloatingProps()}
              >
                {content}
              </motion.div>
            )}
          </AnimatePresence>,
          document.body,
        )}
      </>
    );
  },
);

export default Tooltip;
