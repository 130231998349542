import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { Checkbox, CheckboxGroup } from '@factory5/ui-kit';
import { AnimatePresence, motion } from 'framer-motion';
import { useClickOutside } from 'hooks';
import { without } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { activateFilter, deactivateFilter } from 'features/filters';

import { IFilter, IFiltersStore } from '../../models/filters.types';
import { variants } from '../../utils';

interface IMoreButton {
  className?: string;
  data: IFilter[];
  filters: IFiltersStore;
}

const MoreButton = ({ data, filters, className }: IMoreButton) => {
  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setIsVisible(false));

  const activeFields = filters.additional
    .filter((item) => item.active)
    .map((item) => item.field);

  return (
    <div ref={dropdownRef} className={cx('relative', className)}>
      <div
        onClick={() => setIsVisible((prev) => !prev)}
        className="block rounded-lg border border-gray-300 text px-1 h-[17px] leading-[11px] text-center font-medium hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 cursor-pointer text-gray-700"
      >
        ...
      </div>

      <AnimatePresence>
        {isVisible && (
          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="absolute top-9 z-[1001]"
          >
            <div  className="scrollbar min-w-60 max-h-96 bg-white shadow-md rounded-md absolute p-2.5 overflow-auto">
              <CheckboxGroup
                values={activeFields}
                onChange={(values) => {
                  if (values.length > activeFields.length) {
                    activateFilter(
                      without(values as string[], ...activeFields)[0],
                    );
                  } else {
                    deactivateFilter(
                      without(activeFields, ...(values as string[]))[0],
                    );
                  }
                }}
              >
                {data.map((item) => (
                  <Checkbox key={item.field} value={item.field}>
                    {item.title}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MoreButton;
