import { createEffect, createStore } from 'effector';

import { AlgoRunRead } from 'api/generated';

import api from 'api';

// MODELS -------------------

export const $calculationInfo = createStore<AlgoRunRead | null>(null);

// EFFECTS -------------------

export const fetchCalculationInfoFx = createEffect({
  handler: async ({ runId }: { runId: number }) => {
    const { data } = await api.verification.infoCalculation({ runId });

    return data;
  },
});

$calculationInfo.on(fetchCalculationInfoFx.doneData, (_, data) => data);
