import {
  UsersApi,
  ReferencesApi,
  Configuration,
  VerificationApi,
  InternalApi,
} from './generated';
import { default as axiosInstance, baseURL } from './axiosInstance';

const configuration = new Configuration({ basePath: baseURL });

export default {
  verification: new VerificationApi(configuration, baseURL, axiosInstance),
  users: new UsersApi(configuration, baseURL, axiosInstance),
  references: new ReferencesApi(configuration, baseURL, axiosInstance),
  internal: new InternalApi(configuration, baseURL, axiosInstance),
};

export * from './generated';
