import React, { useState, useRef, useEffect } from 'react';
import { useStore } from 'effector-react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { AnimatePresence, motion } from 'framer-motion';
import cx from 'classnames';
import { debounce } from 'lodash';
import { useClickOutside } from 'hooks';
import { Tooltip } from 'ui';
import {
  Button,
  CheckboxGroup,
  Checkbox,
  Input,
  Loading,
  Empty,
} from '@factory5/ui-kit';

import { FilterRootKeys, IFilter } from '../../models/filters.types';
import { $selectedFilters, setFilterValues } from '../../models/filters.model';
import { $filtersAutocomplete } from '../../models/autocomplete.model';
import { variants } from '../../utils';

interface IAutocompleteProps {
  key?: string;
  data: IFilter;
  className?: string;
  isLoading?: boolean;
  noSearch?: boolean;
  filterRootKey: FilterRootKeys;
  fetchOptionsHandler: ({
    field,
    query,
  }: {
    field: any;
    query?: string;
  }) => void;
}

const Autocomplete = ({
  data,
  isLoading,
  noSearch,
  filterRootKey,
  fetchOptionsHandler,
  className,
}: IAutocompleteProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [query, setQuery] = useState('');
  const filterOptions = useStore($filtersAutocomplete)[data.field];
  const selectedOptions = useStore($selectedFilters)[filterRootKey][data.field];
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => {
    setIsVisible(false);
  });

  useEffect(() => {
    if (isVisible && !filterOptions) {
      fetchOptionsHandler({ field: data.field });
    }
    return () => {
      if (query.length > 0) {
        fetchOptionsHandler({ field: data.field });
        setQuery('');
      }
    };
  }, [isVisible]);

  const formatOptions = (options: string[]) => {
    if (options.length > 2) {
      return options.slice(0, 2).join(', ') + '...';
    }
    return options.join(', ');
  };

  const debouncedSearch = debounce((value) => {
    fetchOptionsHandler({ field: data.field, query: value });
  }, 100);

  return (
    <div className={cx('relative', className)} ref={dropdownRef}>
      <div className="flex items-center ml">
        <Tooltip
          content={selectedOptions && selectedOptions.join(', ')}
          disabled={
            !selectedOptions || (selectedOptions && !selectedOptions.length)
          }
        >
          <Button
            icon={{
              icon: isVisible ? <ChevronUpIcon /> : <ChevronDownIcon />,
              placement: 'trailing',
            }}
            onClick={() => setIsVisible((prev) => !prev)}
            size="sm"
            type={
              selectedOptions && selectedOptions.length ? 'primary' : undefined
            }
            className="py-0.5 px-2.5"
          >
            {selectedOptions && selectedOptions.length > 0
              ? formatOptions(selectedOptions)
              : data.title}
          </Button>
        </Tooltip>

        {(selectedOptions || selectedOptions?.length > 0) && (
          <Button
            type="link"
            className="ml-1.5 !text-gray-300"
            icon={<XCircleIcon />}
            onClick={() =>
              setFilterValues({
                rootKey: filterRootKey,
                field: data.field,
                values: [],
              })
            }
          />
        )}
      </div>

      <AnimatePresence>
        {isVisible && (
          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={cx(
              'w-64 bg-white shadow-md rounded-md absolute p-2.5 top-9 z-[2000] max-h-96',
              isLoading ? 'overflow-hidden' : 'overflow-y-auto',
            )}
          >
            {!noSearch && (
              <Input
                placeholder="Найти"
                className="mb-3"
                clear
                value={query}
                onChange={(e) => {
                  debouncedSearch(e.target.value);
                  setQuery(e.target.value);
                }}
                onClear={() => {
                  debouncedSearch(undefined);
                  setQuery('');
                }}
              />
            )}
            <AnimatePresence>
              {selectedOptions && selectedOptions.length > 0 && (
                <motion.div
                  initial={{ opacity: 0, y: -10, height: 0 }}
                  animate={{ opacity: 1, y: 0, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                >
                  <Button
                    size="xs"
                    onClick={() => {
                      setFilterValues({
                        rootKey: filterRootKey,
                        field: data.field,
                        values: [],
                      });
                      setIsVisible(false);
                    }}
                    className="w-full justify-center mb-3"
                  >
                    Сбросить
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>
            <div className="relative">
              {isLoading && (
                <div
                  className={cx(
                    'flex justify-center pt-4 pb-5 bg-white/70 z-10',
                    {
                      'absolute left-0 top-0 right-0 bottom-0':
                        isLoading && filterOptions,
                    },
                  )}
                >
                  <Loading />
                </div>
              )}
              {!filterOptions ||
                (filterOptions && !filterOptions.length && (
                  <div className="scale-90">
                    <Empty />
                  </div>
                ))}
              {filterOptions && filterOptions.length > 0 && (
                <CheckboxGroup
                  values={selectedOptions}
                  onChange={(values) => {
                    setFilterValues({
                      rootKey: filterRootKey,
                      field: data.field,
                      values: values as string[],
                    });
                  }}
                >
                  {selectedOptions &&
                    selectedOptions.map((item: string) => (
                      <Checkbox key={item} value={item}>
                        {item}
                      </Checkbox>
                    ))}
                  {filterOptions
                    .filter((item) => {
                      if (!selectedOptions) {
                        return true;
                      }
                      return !selectedOptions.includes(item);
                    })
                    .map((item) => (
                      <Checkbox key={item} value={item}>
                        {item}
                      </Checkbox>
                    ))}
                </CheckboxGroup>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Autocomplete;
