import React from 'react';
import { Header } from 'ui';

interface IOneColumnProps {
  children: React.ReactNode;
  pageHeader?: React.ReactNode;
}

const OneColumn = ({ children, pageHeader }: IOneColumnProps) => {
  return (
    <div className="min-h-full grid grid-rows-full bg-slate-100">
      <div className="h-14 px-6 box-border border-b border-slate-100 bg-white">
        <div className="container mx-auto h-full">
          <Header />
        </div>
      </div>

      {pageHeader && (
        <div className="px-6 py-4 box-border border-b border-slate-100 bg-white">
          <div className="container mx-auto">{pageHeader}</div>
        </div>
      )}

      <div className="bg-slate-100">
        <div className="container mx-auto">{children}</div>
      </div>
    </div>
  );
};

export default OneColumn;
