import { createEffect, createStore } from 'effector';

import api from 'api';

import {
  StationMapMarkers,
  GetStationMapMarkersRequestParams,
} from './stationMap.types';

// MODELS -------------------

export const $stationMapMarkersStore = createStore<StationMapMarkers>(null);

// EFFECTS -------------------

export const getStationMapMarkersFx = createEffect(
  async (params: GetStationMapMarkersRequestParams) =>
    await api.verification.getStationMarkers(params),
);

// STORE METHODS -------------------

$stationMapMarkersStore
  .reset(getStationMapMarkersFx)
  .on([getStationMapMarkersFx.done], (_state, { result }) => result.data);
