import React, { ReactNode } from 'react';
import cx from 'classnames';
import { FormattedValue } from 'ui';

type CircleStatisticProps = {
  value?: number | string;
  total?: number | string;
  description: ReactNode;
  className?: string;
  borderColor?: string;
};

const CircleStatistic = ({
  value,
  total,
  description,
  className,
  borderColor,
}: CircleStatisticProps) => {
  return (
    <div className="w-[100px]">
      <div
        className={cx(
          'w-full h-[100px] rounded-full border-8 border-slate-200 flex justify-center items-center flex-col p-2',
          className,
          borderColor ? borderColor : 'border-slate-200',
        )}
      >
        <div className="font-bold text-lg !leading-none mb-1">
          {value ? <FormattedValue value={Number(value)} /> : '-'}
        </div>
        <div className="text-lg text-slate-400 !leading-none">
          {total ? <FormattedValue value={Number(total)} /> : '-'}
        </div>
      </div>
      <div className="uppercase text-xxs leading-[14px] text-center font-medium mt-3">
        {description}
      </div>
    </div>
  );
};

export default CircleStatistic;
