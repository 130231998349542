import { createStore, createEvent } from 'effector';

import { IResultBlock, Mode, LoadingType } from './resultBlock.types';

// MODELS -------------------

export const $resultBlock = createStore<IResultBlock>({
  resultMode: Mode.available,
  withLoading: true,
  loadingType: LoadingType.loadingByAvailable,
  showShortage: false,
  addLoadedCars: false,
  addEmptyProposed: false,
  addEmptyProposedApproved: false,
  addLoadedProposed: false,
});

// EVENTS -------------------

export const setValue = createEvent<Partial<IResultBlock>>();

// STORE METHODS -------------------

$resultBlock.on(setValue, (state, data) => ({ ...state, ...data }));
