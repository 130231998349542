import { flattenDeep, groupBy, keys, mapValues, reduce, values } from 'lodash';

import { BreakdownBySubgroups, CarsStats, SubgroupData } from 'api';

import { StationSupplyPlanData } from 'features/stations/models/stationSupplyPlanData.types';

export default function collectPlanData(
  supplyPlanData: StationSupplyPlanData | undefined,
) {
  if (!supplyPlanData) return;

  // Собираем список дат
  const dates = supplyPlanData.map(({ date }) => date);

  // Типы срезов (График, В наличии, Подход груженных и прочее)
  const types = keys(supplyPlanData[0].cars).filter(
    (type) => type !== 'backlog',
  );

  const data = types.map((type) => {
    const totalRow = {
      key: `${type}-total`,
      name: 'Итого',
      cargo_group_id: null,
      cargo_subgroup_id: null,

      values: [
        // Итого по всем дням

        ...supplyPlanData.map((dataByDate) => ({
          date: dataByDate.date,
          type,
          cargo_group_id: null,
          cargo_subgroup_id: null,
          value: dataByDate.cars[type as keyof CarsStats]?.total_amount,
        })),

        {
          type,
          cargo_group_id: null,
          cargo_subgroup_id: null,
          date: null,
          value: supplyPlanData.reduce(
            (acc, val) =>
              acc + (val.cars[type as keyof CarsStats]?.total_amount || 0),
            0,
          ),
        },
      ],
    };

    const groupsByCargoGroupId = mapValues(
      groupBy(
        flattenDeep(
          supplyPlanData.map(
            (date) => date.cars[type as keyof CarsStats]?.by_cargo_groups,
          ),
        ),
        'cargo_group_id',
      ),
      (groupById) => {
        const groupId = (groupById as unknown as BreakdownBySubgroups[]).map(
          ({ cargo_group_id }) => cargo_group_id,
        )[0];

        const groupsReduce = reduce<
          BreakdownBySubgroups,
          {
            values: {
              type: string;
              date: string | null;
              value: number;
              cargo_group_id: number;
              cargo_subgroup_id: null;
            }[];
          }
        >(
          groupById as unknown as BreakdownBySubgroups[],
          (prev, current, index) => ({
            key: `${type}-cargo_group_id-${current.cargo_group_id}`,
            name: current.cargo_group_name,
            cargo_group_id: current.cargo_group_id,
            cargo_subgroup_id: null,
            values: [
              ...prev.values,
              {
                type,
                date: dates[index],
                value: current.total_amount,
                cargo_group_id: current.cargo_group_id,
                cargo_subgroup_id: null,
              },
            ],
          }),
          {
            values: [],
          },
        );

        // Итого по всем датам в группе
        groupsReduce.values = [
          ...groupsReduce.values,
          {
            type,
            date: null,
            value: groupsReduce.values.reduce((acc, val) => acc + val.value, 0),
            cargo_group_id: (groupsReduce as unknown as BreakdownBySubgroups)
              .cargo_group_id,
            cargo_subgroup_id: null,
          },
        ];

        const subgroups = mapValues(
          groupBy(
            flattenDeep(
              (groupById as unknown as BreakdownBySubgroups[]).map(
                ({ by_cargo_subgroups }) => by_cargo_subgroups,
              ),
            ),
            'cargo_subgroup_id',
          ),
          (subgroupById) => {
            const subgroupsReduce = reduce<
              SubgroupData,
              {
                values: {
                  type: string;
                  date: string | null;
                  value: number;
                  cargo_group_id: number;
                  cargo_subgroup_id: number;
                }[];
              }
            >(
              subgroupById as SubgroupData[],
              (prev, current, index) => ({
                key: `${type}-cargo_group_id-${groupId}-cargo_subgroup_id-${current.cargo_subgroup_id}`,
                name: current.cargo_subgroup_name,
                cargo_group_id: groupId,
                cargo_subgroup_id: current.cargo_subgroup_id,
                values: [
                  ...prev.values,
                  {
                    type,
                    date: dates[index],
                    value: current.amount,
                    cargo_group_id: groupId,
                    cargo_subgroup_id: current.cargo_subgroup_id,
                  },
                ],
              }),
              { values: [] },
            );

            // Итого по всем датам в подгруппе
            subgroupsReduce.values = [
              ...subgroupsReduce.values,

              {
                type,
                date: null,
                value: subgroupsReduce.values.reduce(
                  (acc, val) => acc + val.value,
                  0,
                ),
                cargo_group_id: groupId,
                cargo_subgroup_id: (subgroupsReduce as unknown as SubgroupData)
                  .cargo_subgroup_id,
              },
            ];

            return subgroupsReduce;
          },
        );

        return [groupsReduce, values(subgroups)];
      },
    ) as Record<
      string,
      {
        key: string;
        name: string;
        cargo_group_id: string;
        cargo_subgroup_id: string | null;
        values: {
          type: string;
          date: string;
          value: number;
          cargo_group_id: number;
          cargo_subgroup_id: null | number;
        }[];
      }[]
    >;

    return {
      key: type,
      name: supplyPlanData[0].cars[type as keyof CarsStats]?.name,
      rows: [totalRow, ...flattenDeep(values(groupsByCargoGroupId))],
    };
  });

  return {
    dates,
    data,
  };
}
