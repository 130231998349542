import { ChartBarIcon } from '@heroicons/react/24/outline';
import { flatten, omit, sum, values } from 'lodash';
import React, { useId } from 'react';
import {
  BarChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';
import { StackOffsetType } from 'recharts/types/util/types';

import { IChartItem, BarChartLayout } from '../Chart.types';

type BarChartProps = {
  data: IChartItem[];
  hideXAxis?: boolean;
  hideYAxis?: boolean;
  width?: string;
  height?: string;
  hideLegend?: boolean;
  layout?: BarChartLayout;
  xAxisWidth?: number;
  yAxisWidth?: number;
  colors?: string[];
  stacked?: boolean;
  tooltipFormatter?: Formatter<any, any>;
  stackOffset?: StackOffsetType;
};

const defaultColors = ['#60a5fa', '#4ade80', '#22d3ee', '#34d399', '#facc15'];

function isEmptyData(data: Array<Record<'name' | string, number | string>>) {
  const omitNameFieldData = data.map((el) => omit(el, 'name'));

  const sumOfAllDataFields = sum(
    flatten(omitNameFieldData.map((el) => values(el))),
  );

  if (sumOfAllDataFields === 0) return true;

  return false;
}

const BarChart = ({
  data,
  hideXAxis,
  hideYAxis,
  hideLegend,
  width = '100%',
  height = '100%',
  layout = BarChartLayout.horizontal,
  xAxisWidth,
  yAxisWidth,
  colors = defaultColors,
  stacked,
  tooltipFormatter,
  stackOffset,
}: BarChartProps) => {
  const chartId = useId();

  const XAxisTick = ({ x, y, stroke, payload }: any) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} fill="#666" textAnchor="middle" fontSize={12}>
          {payload.value}
        </text>
      </g>
    );
  };

  const YAxisTick = ({ x, y, stroke, payload }: any) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={4} fill="#666" textAnchor="end" fontSize={12}>
          {payload.value}
        </text>
      </g>
    );
  };

  const bars = Object.keys(data[0]).filter((i) => i !== 'name');

  if (data.length === 0 || isEmptyData(data)) {
    return (
      <div className="w-full h-full border bg-slate-50 flex flex-col justify-center items-center">
        <ChartBarIcon className="w-24 text-slate-300" />
        <div className="text-lg text-slate-500">Нет данных для отображения</div>
      </div>
    );
  }

  return (
    <ResponsiveContainer width={width} height={height}>
      <ReBarChart
        layout={layout}
        data={data}
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
        stackOffset={stackOffset}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {!hideXAxis && (
          <XAxis
            type={layout === BarChartLayout.vertical ? 'number' : 'category'}
            dataKey={layout === BarChartLayout.horizontal ? 'name' : undefined}
            tick={<XAxisTick />}
            width={xAxisWidth}
            allowDataOverflow
            minTickGap={0}
            tickCount={4}
          />
        )}
        {!hideYAxis && (
          <YAxis
            type={layout === BarChartLayout.vertical ? 'category' : 'number'}
            dataKey={layout === BarChartLayout.vertical ? 'name' : undefined}
            tick={<YAxisTick />}
            width={yAxisWidth}
            allowDataOverflow
            minTickGap={0}
          />
        )}
        <Tooltip
          cursor={{ fill: '#f1f5f9' }}
          formatter={tooltipFormatter}
          labelFormatter={() => ''}
        />
        {!hideLegend && <Legend />}
        {stacked
          ? bars.map((bar, idx) => (
              <Bar
                key={`${chartId}-${bar}`}
                dataKey={bar}
                stackId="a"
                maxBarSize={20}
                fill={colors[idx]}
              />
            ))
          : bars.map((bar, idx) => (
              <Bar
                key={`${chartId}-${bar}`}
                dataKey={bar}
                stackId="a"
                maxBarSize={20}
              >
                {data.map((value, index) => (
                  <Cell
                    key={`${chartId}-${bar}-${index}`}
                    fill={colors[index]}
                  />
                ))}
              </Bar>
            ))}
      </ReBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
