import { createStore, createEvent, createEffect } from 'effector';
import { persist } from 'effector-storage/local';
import { isEqual, omit, pickBy } from 'lodash';

import api, { FilterFieldSchema, CargoGroupsRead } from 'api';

import { resetProposedTripListRequestParams } from 'features/stations/models/proposedTrips.model';

import {
  FilterRootKeys,
  IFiltersStore,
  ISelectedFilter,
} from './filters.types';

// MODELS -------------------

export const $filtersSet = createStore<IFiltersStore>({
  general: [],
  additional: [],
});

export const $cargoFilters = createStore<CargoGroupsRead[]>([]);

export const $selectedFilters = createStore<ISelectedFilter>({
  stations: {},
  proposedTrips: {},
  station: {},
  controlTrips: {},
});

persist({ store: $selectedFilters, key: 'filters' });

// EVENTS -------------------

export const addFilter = createEvent();
export const activateFilter = createEvent<string>();
export const deactivateFilter = createEvent<string>();
export const setFilterValues = createEvent<{
  rootKey: FilterRootKeys;
  field: string;
  values: any;
}>();
export const resetFilters = createEvent();
export const resetFilterByKey = createEvent<{
  key: FilterRootKeys;
  protectedFilterKeys?: string[];
}>();

// EFFECTS -------------------

export const fetchProposedTripsFiltersFx = createEffect({
  handler: async () => {
    const { data } = await api.verification.getProposedTripsFilters();
    return data.data;
  },
});

export const fetchLoadingStationsFiltersFx = createEffect({
  handler: async () => {
    const { data } = await api.verification.getLoadingStationsFilters();
    return data.data;
  },
});

export const fetchWagonDeploymentFiltersFx = createEffect({
  handler: async () => {
    const { data } = await api.verification.getCurrentTripsFilters();
    return data.data;
  },
});

export const fetchCargoGroupsFiltersFx = createEffect({
  handler: async () => {
    const { data } = await api.references.getCargoGroups();
    return data;
  },
});

export const fetchControlTripsFiltersFx = createEffect({
  handler: async () => {
    const { data } = await api.verification.getControlTripsFilters();
    return data.data;
  },
});

// STORE METHODS -------------------

$filtersSet.on(fetchProposedTripsFiltersFx.doneData, (_, data) => {
  const headFilters = data.slice(0, 7);
  const tailFilters: FilterFieldSchema[] = data.slice(7);

  return {
    general: headFilters,
    additional: tailFilters,
  };
});

$filtersSet.on(fetchLoadingStationsFiltersFx.doneData, (_, data) => {
  const headFilters = data.slice(0, 7);
  const tailFilters: FilterFieldSchema[] = data.slice(7);

  return {
    general: headFilters,
    additional: tailFilters,
  };
});

$filtersSet.on(fetchWagonDeploymentFiltersFx.doneData, (_, data) => {
  const headFilters = data.slice(0, 7);
  const tailFilters: FilterFieldSchema[] = data.slice(7);

  return {
    general: headFilters,
    additional: tailFilters,
  };
});

$filtersSet.on(fetchControlTripsFiltersFx.doneData, (_, data) => {
  const headFilters: FilterFieldSchema[] = [
    ...data.slice(0, 7),
    ...data.filter(({ field }) => field === 'through_washing_station'),
  ];
  const tailFilters: FilterFieldSchema[] = data
    .slice(7)
    .filter(({ field }) => field !== 'through_washing_station');

  return {
    general: headFilters,
    additional: tailFilters,
  };
});

$filtersSet.on(activateFilter, (store, field) => {
  const findedIdx = store.additional.findIndex((item) => item.field === field);
  store.additional[findedIdx].active = true;
  return {
    general: store.general,
    additional: store.additional,
  };
});

$filtersSet.on(deactivateFilter, (store, field) => {
  const findedIdx = store.additional.findIndex((item) => item.field === field);
  delete store.additional[findedIdx].active;
  return {
    general: store.general,
    additional: store.additional,
  };
});

$selectedFilters
  .on(setFilterValues, (store, { rootKey, field, values }) => {
    const filters = store[rootKey];

    if (
      values === undefined ||
      isEqual(values, []) ||
      isEqual(values, [null, null])
    ) {
      return {
        ...store,
        [rootKey]: {
          ...omit(filters, field),
        },
      };
    }

    return {
      ...store,
      [rootKey]: {
        ...store[rootKey],
        [field]: values,
      },
    };
  })
  .on(resetFilterByKey, (store, { key, protectedFilterKeys }) => {
    const pickedProtectedFilterKeys = pickBy(store[key], (_value, key) =>
      protectedFilterKeys?.includes(key) ? true : false,
    );

    return {
      ...store,
      [key]: {
        ...pickedProtectedFilterKeys,
      },
    };
  })
  .reset(resetFilters);

$cargoFilters.on(fetchCargoGroupsFiltersFx.doneData, (_, data) => data);

setFilterValues.watch(({ rootKey }) => {
  if (rootKey === 'proposedTrips') resetProposedTripListRequestParams();
});
