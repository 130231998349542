export enum Mode {
  available = 'available', // Наличие
  arriving = 'arriving', // Подход груженых
}

export enum LoadingType {
  loadingByPlan = 'loadingByPlan', // По графику
  loadingByAvailable = 'loadingByAvailable', // По наличию
}

export interface IResultBlock {
  resultMode: Mode; // Режим
  withLoading: boolean; // Учитывать погрузки
  loadingType: LoadingType; // По наличию | По графику
  showShortage: boolean; // Показать нехватку
  addLoadedCars: boolean; // Подход груженых
  addEmptyProposed: boolean; // Прогноз порожних
  addEmptyProposedApproved: boolean; // Только одобренные прогнозные порожние
  addLoadedProposed: boolean; // Прогнозные груженые
}
