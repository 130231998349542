import React, { useEffect } from 'react';
import { useStore } from 'effector-react';

import {
  TagsFilters,
  $filtersSet,
  fetchLoadingStationsFilterOptionsFx,
  resetAutocomplete,
} from 'features/filters';
import { $stationListStore } from 'features/stations/models/stations.model';

const StationsFiltersProvider = () => {
  const filters = useStore($filtersSet);
  const autocompleteIsLoading = useStore(
    fetchLoadingStationsFilterOptionsFx.pending,
  );
  const { objects_total_count, objects_count } = useStore($stationListStore);

  useEffect(() => {
    return () => {
      resetAutocomplete();
    };
  }, []);

  return (
    <TagsFilters
      data={filters}
      filterRootKey="stations"
      noSearch={['market_type']}
      autocompleteOptionsHandler={fetchLoadingStationsFilterOptionsFx}
      autocompleteIsLoading={autocompleteIsLoading}
      maxElCount={objects_total_count}
      currentElCount={objects_count}
    />
  );
};

export default StationsFiltersProvider;
