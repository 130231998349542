import React from 'react';

import MapView from '../MapView';
import StationsMarkerList from '../StationsMarkerList';

export default function MainMap() {
  return (
    <MapView center={[55.75222, 37.61556]}>
      <StationsMarkerList />
    </MapView>
  );
}
