import { createEffect, createStore, createEvent } from 'effector';
import { camelCase } from 'lodash';
import dayjs from 'dayjs';

import api from 'api';

import { setFilterValues } from 'features/filters';

import {
  WagonDeploymentList,
  WagonDeploymentListRequestParams,
} from './wagonDeplyment.types';

// MODELS -------------------

export const $wagonDeploymentListStore = createStore<WagonDeploymentList>({
  objects: [],
});

export const $wagonDeploymentListRequestParams =
  createStore<WagonDeploymentListRequestParams>({
    page: 1,
    pageSize: 25,
    sortDirection: 'asc',
  });

// EVENTS -------------------

export const changeWagonDeploymentListRequestParams =
  createEvent<Partial<WagonDeploymentListRequestParams>>();

// EFFECTS -------------------

export const getWagonDeploymentListFx = createEffect(
  async (params: WagonDeploymentListRequestParams) => {
    const formattedParams: { [k: string]: any } = Object.fromEntries(
      Object.entries(params).map(([k, v]) => [camelCase(k), v]),
    );

    const {
      // sortBy,
      arrivalEstimate,
      opDuration,
      accessRemainingDistance,
      cargo,
      ...restFilters
    } = {
      ...params,
      ...formattedParams,
    };

    return await api.verification.getCurrentTrips({
      ...restFilters,
      arrivalEstimateGte:
        arrivalEstimate && arrivalEstimate[0]
          ? dayjs(arrivalEstimate[0]).format('YYYY-MM-DD')
          : undefined,
      arrivalEstimateLte:
        arrivalEstimate && arrivalEstimate[1]
          ? dayjs(arrivalEstimate[1]).format('YYYY-MM-DD')
          : undefined,

      opDurationLte: opDuration && opDuration[1] ? opDuration[1] : undefined,
      opDurationGte: opDuration && opDuration[0] ? opDuration[0] : undefined,

      accessRemainingDistanceLte:
        accessRemainingDistance && accessRemainingDistance[1]
          ? accessRemainingDistance[1]
          : undefined,
      accessRemainingDistanceGte:
        accessRemainingDistance && accessRemainingDistance[0]
          ? accessRemainingDistance[0]
          : undefined,

      cargoGroupId: cargo,
    });
  },
);

// STORE METHODS -------------------

$wagonDeploymentListStore.on(
  getWagonDeploymentListFx.done,
  (state, { result }) => result.data,
);

$wagonDeploymentListRequestParams
  .on(changeWagonDeploymentListRequestParams, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(setFilterValues, (store) => ({ ...store, page: 1 }));
