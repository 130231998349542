import React from 'react';
import { Table } from '@factory5/ui-kit';

import { StationReferenceData } from 'api/generated';

interface IPowerTableProps {
  data?: {
    [key: string]: StationReferenceData;
  };
}

const PowerTable = ({ data }: IPowerTableProps) => {
  const columns = [
    {
      key: 'station',
      dataIndex: 'station',
      title: 'Станция',
      render: (record: { station: string; railway: string }) => {
        return (
          <div>
            {record.station}
            <br />({record.railway})
          </div>
        );
      },
    },
    {
      key: 'total_capacity',
      dataIndex: 'total_capacity',
      title: 'Емкость',
      render: (record: number | null) => record ?? '-',
    },
    {
      key: 'loading_power',
      dataIndex: 'loading_power',
      title: 'Мощность погрузки',
      render: (record: number | null) => record ?? '-',
    },
    {
      key: 'unloading_power',
      dataIndex: 'unloading_power',
      title: 'Мощность разгрузки',
      render: (record: number | null) => record ?? '-',
    },
    {
      key: 'wash_power',
      dataIndex: 'wash_power',
      title: 'Мощность промывки',
      render: (record: number | null) => record ?? '-',
    },
    {
      key: 'repair_power',
      dataIndex: 'repair_power',
      title: 'Мощность ремонта',
      render: (record: number | null) => record ?? '-',
    },
  ];

  const mappedData = Object.values(data || {});

  return (
    <Table
      pagination={{ total: mappedData.length, pageSize: 10 }}
      columns={columns}
      data={mappedData}
      emptyText="Нет данных"
    />
  );
};

export default PowerTable;
