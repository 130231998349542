import React from 'react';
import cx from 'classnames';
import Icon from 'ui/Icon';
import { Tooltip } from 'ui';

import { OperationEngLabelsEnum } from 'api';

import { WagonDeploymentListItem } from 'features/stations/models/wagonDeplyment.types';
import { activateFilter, setFilterValues } from 'features/filters';
import { FilterRootKeys } from 'features/filters/models/filters.types';

type TrainStatusIconProps = {
  is_on_way: WagonDeploymentListItem['is_on_way'];
  objective: WagonDeploymentListItem['objective'];
  filterKey: FilterRootKeys;
  accessRemainingDistance?: number;
  accessRemainingDays?: number;
};

export default function TrainStatusIcon({
  objective,
  is_on_way,
  accessRemainingDistance,
  accessRemainingDays,
  ...props
}: TrainStatusIconProps) {
  const icon = cx({
    'train-move': is_on_way,
    'train-loading':
      !is_on_way && objective === OperationEngLabelsEnum['Loading'],
    'train-unloading':
      !is_on_way && objective === OperationEngLabelsEnum['Unloading'],
    'train-washing':
      !is_on_way && objective === OperationEngLabelsEnum['Washing'],
    'train-on-station':
      !is_on_way && objective === OperationEngLabelsEnum['Parking'],
    repair: !is_on_way && objective === OperationEngLabelsEnum['Repair'],
  });

  const className = cx('absolute -top-1.5 cursor-pointer', {
    'w-[46px]': is_on_way,
    'w-[31px]':
      !is_on_way &&
      (objective === OperationEngLabelsEnum['Loading'] ||
        objective === OperationEngLabelsEnum['Unloading']),
    'w-[16px]':
      !is_on_way &&
      (objective === OperationEngLabelsEnum['Washing'] ||
        objective === OperationEngLabelsEnum['Parking']),
    'w-[20px]': !is_on_way && objective === OperationEngLabelsEnum['Repair'],
  });

  const tooltip = is_on_way ? (
    <div>
      В пути.
      <br />
      Расстояние до ст. назначения {accessRemainingDistance} км (
      {accessRemainingDays} сут.)
    </div>
  ) : (
    cx({
      'На погрузке':
        !is_on_way && objective === OperationEngLabelsEnum['Loading'],
      'На выгрузке':
        !is_on_way && objective === OperationEngLabelsEnum['Unloading'],
      'На промывке':
        !is_on_way && objective === OperationEngLabelsEnum['Washing'],
      'На станции':
        !is_on_way && objective === OperationEngLabelsEnum['Parking'],
      'В ремонте': !is_on_way && objective === OperationEngLabelsEnum['Repair'],
    })
  );

  return (
    <div className="flex w-[46px] items-center justify-center relative">
      <Tooltip content={tooltip}>
        <div
          className={className}
          onClick={() => {
            activateFilter('is_on_way');
            setFilterValues({
              rootKey: 'station',
              field: 'is_on_way',
              values: is_on_way,
            });
          }}
        >
          <Icon icon={icon} {...props} />
        </div>
      </Tooltip>
    </div>
  );
}
