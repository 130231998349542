import { createStore, createEffect } from 'effector';

import api from 'api';

// MODELS -------------------

export const $appVersion = createStore<string>('');

// EFFECTS ------------------

export const getVersionFx = createEffect({
  handler: async () => await api.internal.getVersion(),
});

// STORE METHODS -------------------

$appVersion.on(getVersionFx.doneData, (_, { data: { version } }) => version);
