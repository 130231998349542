import { createEffect, createEvent, createStore } from 'effector';
import { camelCase } from 'lodash';
import dayjs from 'dayjs';

import api, {
  ProposedTripVerdict,
  ProposedTripVerdictReason,
  VerdictActionEnum,
} from 'api';

import { setFilterValues } from 'features/filters';

import {
  ActionType,
  ControlTripList,
  ControlTripListRequestParams,
} from './controlTrips.types';

// MODELS -------------------

export const $controlTripListStore = createStore<ControlTripList>({
  objects: [],
});

export const $controlTripListRequestParams = createStore<
  Partial<ControlTripListRequestParams> &
    Pick<ControlTripListRequestParams, 'page' | 'pageSize' | 'status'>
>({
  page: 1,
  pageSize: 25,
});

export const $verdictReasons = createStore<ProposedTripVerdictReason[]>([]);
export const $approveType = createStore<ActionType>(ActionType.manual);
export const $rejectType = createStore<ActionType>(ActionType.manual);

// EFFECTS -------------------

export const getControlTripsFx = createEffect({
  handler: async (params: ControlTripListRequestParams) => {
    const formattedParams: { [k: string]: any } = Object.fromEntries(
      Object.entries(params).map(([k, v]) => [camelCase(k), v]),
    );

    const {
      arrivalEstimate,
      opDuration,
      accessRemainingDistance,
      ...restFilters
    } = {
      ...params,
      ...formattedParams,
    };

    const { data } = await api.verification.getControlTrips({
      ...restFilters,
      arrivalEstimateGte:
        arrivalEstimate && arrivalEstimate[0]
          ? dayjs(arrivalEstimate[0]).format('YYYY-MM-DD')
          : undefined,
      arrivalEstimateLte:
        arrivalEstimate && arrivalEstimate[1]
          ? dayjs(arrivalEstimate[1]).format('YYYY-MM-DD')
          : undefined,
      opDurationLte: opDuration && opDuration[1] ? opDuration[1] : undefined,
      opDurationGte: opDuration && opDuration[0] ? opDuration[0] : undefined,
      accessRemainingDistanceLte:
        accessRemainingDistance && accessRemainingDistance[1]
          ? accessRemainingDistance[1]
          : undefined,
      accessRemainingDistanceGte:
        accessRemainingDistance && accessRemainingDistance[0]
          ? accessRemainingDistance[0]
          : undefined,
    } as ControlTripListRequestParams);

    return data;
  },
});

export const setTripVerdictFx = createEffect({
  handler: async (params: ProposedTripVerdict) => {
    const { data } = await api.verification.proposedTripsVerdict({
      proposedTripVerdict: {
        action: params.action,
        reasons: params.reasons,
        ids: params.ids,
        station_id: params.station_id,
        date: params.date,
        subgroup_id: params.subgroup_id,
      },
    });

    return data;
  },
});

export const commitAllVerdictsFx = createEffect({
  handler: async (params: {
    station_id: number;
    subgroup_id?: number[];
    group_id?: number[];
    date?: string;
  }) => {
    const { data } = await api.verification.proposedTripsVerdict({
      proposedTripVerdict: {
        action: VerdictActionEnum.Commit,
        ...params,
      },
    });

    return data;
  },
});

export const resetAllVerdictsFx = createEffect({
  handler: async (params: Omit<ProposedTripVerdict, 'action'>) => {
    const { data } = await api.verification.proposedTripsVerdict({
      proposedTripVerdict: {
        action: VerdictActionEnum.Reset,
        station_id: params.station_id,
        date: params.date,
        subgroup_id: params.subgroup_id,
      },
    });

    return data;
  },
});

export const fetchVerdictReasonsFx = createEffect({
  handler: async () => {
    const { data } = await api.verification.getProposedTripsVerdictReasons();

    return data.data;
  },
});

// EVENTS -------------------

export const changeControlTripListRequestParams =
  createEvent<Partial<ControlTripListRequestParams>>();

export const changeApproveType = createEvent<ActionType>();
export const changeRejectType = createEvent<ActionType>();

// STORE METHODS -------------------

$controlTripListStore.on(getControlTripsFx.doneData, (state, data) => data);

$controlTripListRequestParams
  .on(changeControlTripListRequestParams, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(setFilterValues, (store) => ({ ...store, page: 1 }));

$verdictReasons.on(fetchVerdictReasonsFx.doneData, (_, data) => data);

$approveType.on(changeApproveType, (_, value) => value);
$rejectType.on(changeRejectType, (_, value) => value);
