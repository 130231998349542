import { useStore } from 'effector-react';
import React, {
  createRef,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  divIcon,
  FeatureGroup as LeafletFeatureGroup,
  latLng,
  Marker,
} from 'leaflet';
import { Tooltip, useMap, FeatureGroup, Polyline } from 'react-leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { Select } from '@factory5/ui-kit';
import cx from 'classnames';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { FormattedValue } from 'ui';

import {
  LoadingStationMarkersViewEnum,
  LoadingStationMarkersDirectionEnum,
} from 'api';

import {
  $stationMapMarkersStore,
  getStationMapMarkersFx,
} from 'features/stations/models/stationMap.model';
import useCurrentStation from 'features/stations/hooks/useCurrentStation';
import { CustomMarker } from 'features/map';
import { StationAddress } from 'features/stations';

export default function StationMapMarkerList() {
  const currentStation = useCurrentStation();
  const stationMapMarkers = useStore($stationMapMarkersStore);

  const [view, setView] = useState<LoadingStationMarkersViewEnum>(
    LoadingStationMarkersViewEnum.Dislocation,
  );
  const [direction, setDirection] =
    useState<LoadingStationMarkersDirectionEnum>(
      LoadingStationMarkersDirectionEnum.FromCurrentStation,
    );

  const map = useMap();
  const featureGroupRef = createRef<LeafletFeatureGroup>();
  const stationMarkerRef = createRef<Marker>();

  const isLoading = useStore(getStationMapMarkersFx.pending);

  const centerMap = useCallback(() => {
    if (
      featureGroupRef.current &&
      stationMarkerRef.current &&
      stationMapMarkers?.current_station
    ) {
      map.fitBounds(featureGroupRef.current?.getBounds(), {
        padding: [25, 25],
      });
    }
  }, [featureGroupRef.current, stationMapMarkers, stationMarkerRef.current]);

  useEffect(() => {
    centerMap();
  }, [centerMap]);

  useEffect(() => {
    if (currentStation) {
      getStationMapMarkersFx({ pk: currentStation.id, direction, view });
    }
  }, [currentStation, view, direction]);

  if (isLoading) {
    return (
      <div className="flex gap-2 absolute top-1 right-2 z-1000">
        <ArrowPathIcon className="animate-spin" width={48} />

        <Select
          key="LoadingStationMarkersViewEnum"
          search={false}
          options={[
            {
              label: 'Дислокация',
              value: LoadingStationMarkersViewEnum.Dislocation,
            },
            {
              label: 'Прогнозные рейсы',
              value: LoadingStationMarkersViewEnum.ScheduledTrips,
            },
          ]}
          value={view}
          disabled
        />

        <Select
          key="LoadingStationMarkersDirectionEnum"
          search={false}
          options={[
            {
              label: 'От станции',
              value: LoadingStationMarkersDirectionEnum.FromCurrentStation,
            },
            {
              label: 'На станцию',
              value: LoadingStationMarkersDirectionEnum.ToCurrentStation,
            },
          ]}
          value={direction}
          disabled
        />
      </div>
    );
  }

  if (!stationMapMarkers || !currentStation) return null;

  const { current_station: station, stations_directions } = stationMapMarkers;

  const filteredStationDirections = stations_directions.filter(
    (st) => st.latitude !== null && st.longitude !== null,
  );

  return (
    <>
      <div className="flex gap-2 absolute top-1 right-2 z-1000">
        <Select
          key="LoadingStationMarkersViewEnum"
          search={false}
          options={[
            {
              label: 'Дислокация',
              value: LoadingStationMarkersViewEnum.Dislocation,
            },
            {
              label: 'Прогнозные рейсы',
              value: LoadingStationMarkersViewEnum.ScheduledTrips,
            },
          ]}
          value={view}
          onSelect={(value) => setView(value as LoadingStationMarkersViewEnum)}
          editable={false}
        />

        <Select
          key="LoadingStationMarkersDirectionEnum"
          search={false}
          options={[
            {
              label: 'От станции',
              value: LoadingStationMarkersDirectionEnum.FromCurrentStation,
            },
            {
              label: 'На станцию',
              value: LoadingStationMarkersDirectionEnum.ToCurrentStation,
            },
          ]}
          value={direction}
          onSelect={(value) =>
            setDirection(value as LoadingStationMarkersDirectionEnum)
          }
          editable={false}
        />
      </div>

      <FeatureGroup ref={featureGroupRef}>
        {filteredStationDirections.map((st) => (
          <Fragment key={JSON.stringify(st)}>
            <CustomMarker
              icon={divIcon({
                className: '',
                html: renderToStaticMarkup(
                  <div
                    className={cx(
                      'flex flex-col justify-center items-center relative',
                    )}
                  >
                    <div
                      className={cx('w-4 h-4 border-2 rounded-full', {
                        'bg-white border-sky-600':
                          direction ===
                          LoadingStationMarkersDirectionEnum.FromCurrentStation,
                        'bg-white border-red-700':
                          direction ===
                          LoadingStationMarkersDirectionEnum.ToCurrentStation,
                      })}
                    ></div>
                  </div>,
                ),
                iconAnchor: [8, 8],
              })}
              position={[st.latitude!, st.longitude!]}
            >
              <Tooltip opacity={1}>
                <div className="flex flex-col gap-2 p-1">
                  <div className="border-b pb-2">
                    <StationAddress station={st.name} road={''} />
                  </div>

                  <div className="text-sm">
                    <span className="font-semibold">Количество вагонов: </span>
                    {st.car_count_station}
                  </div>
                </div>
              </Tooltip>
            </CustomMarker>

            <CustomMarker
              ref={stationMarkerRef}
              icon={divIcon({
                className: '',
                html: renderToStaticMarkup(
                  <div
                    className={cx(
                      'flex flex-col justify-center items-center w-6 h-6 border-2 rounded-full',
                      {
                        'bg-sky-500 border-sky-600':
                          direction ===
                          LoadingStationMarkersDirectionEnum.FromCurrentStation,
                        'bg-red-600 border-red-700':
                          direction ===
                          LoadingStationMarkersDirectionEnum.ToCurrentStation,
                      },
                    )}
                  >
                    <span className="text-xs">
                      {st.car_count_transportation}
                    </span>
                  </div>,
                ),
                iconAnchor: [15, 15],
              })}
              position={[
                (currentStation.latitude! + st.latitude!) / 2,
                (currentStation.longitude! + st.longitude!) / 2,
              ]}
            />

            <Polyline
              positions={
                direction ===
                LoadingStationMarkersDirectionEnum.FromCurrentStation
                  ? [
                      latLng(
                        currentStation.latitude!,
                        currentStation.longitude!,
                      ),
                      latLng(st.latitude!, st.longitude!),
                    ]
                  : [
                      latLng(st.latitude!, st.longitude!),
                      latLng(
                        currentStation.latitude!,
                        currentStation.longitude!,
                      ),
                    ]
              }
              pathOptions={{
                dashArray:
                  view === LoadingStationMarkersViewEnum.ScheduledTrips
                    ? '5, 5'
                    : '0',
                color:
                  direction ===
                  LoadingStationMarkersDirectionEnum.ToCurrentStation
                    ? 'rgb(220 38 38)'
                    : 'rgb(14 165 233)',
              }}
            />
          </Fragment>
        ))}

        <CustomMarker
          icon={divIcon({
            className: '',
            html: renderToStaticMarkup(
              <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col gap-0.5 w-8 h-8 rounded-full border-2 border-blue-600 bg-white justify-center items-center font-black whitespace-nowrap">
                  <span className="text-blue-600">
                    <FormattedValue value={station.car_count_station} />
                  </span>
                </div>
              </div>,
            ),
            iconAnchor: [15, 15],
          })}
          position={[currentStation.latitude!, currentStation.longitude!]}
        >
          <Tooltip opacity={1}>
            <div className="flex flex-col gap-2 p-1">
              <div className="border-b pb-2">
                <StationAddress
                  station={station.name}
                  road={currentStation.railway.name}
                />
                <div>{station.code6}</div>
              </div>
              <div className="flex flex-row gap-4">
                <div className="text-sm">
                  <div>План, вг</div>
                  <div className="font-semibold">
                    <FormattedValue value={currentStation.planned_cars} />
                  </div>
                </div>

                <div className="text-sm">
                  <div>Факт, вг</div>
                  <div className="font-semibold">
                    <FormattedValue value={currentStation.loaded_cars} />
                  </div>
                </div>

                <div className="text-sm">
                  <div>Остаток, вг</div>
                  <div className="font-semibold">
                    <FormattedValue
                      value={
                        currentStation.planned_cars - currentStation.loaded_cars
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Tooltip>
        </CustomMarker>
      </FeatureGroup>
    </>
  );
}
