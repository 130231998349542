import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link, useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import { pageHeaderMotion, pageMotion, history } from 'lib';
import {
  Card,
  Description,
  Button,
  PageHeader,
  Breadcrumbs,
  Empty,
  Loading,
} from '@factory5/ui-kit';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';

import {
  $calculationInfo,
  fetchCalculationInfoFx,
  OperationsTable,
  Stats,
  ReferenceErrors,
  Verification,
} from 'features/manage';

import { OneColumnLayout } from 'layouts';

const CalculationResultPage = () => {
  const { id } = useParams();
  const calculationInfo = useStore($calculationInfo);
  const isPending = useStore(fetchCalculationInfoFx.pending);

  useEffect(() => {
    fetchCalculationInfoFx({ runId: Number(id) });
  }, []);

  return (
    <>
      <Helmet>
        <title>ЦПД: Расчет {id}</title>
      </Helmet>

      <OneColumnLayout
        pageHeader={
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageHeaderMotion}
          >
            <PageHeader
              title={
                <span className="text-xl">
                  Расчет №{id}{' '}
                  {calculationInfo ? `от ${calculationInfo.started_at}` : ''}
                </span>
              }
              over={
                <Breadcrumbs
                  path={[
                    {
                      path: '/management',
                      title: 'Оптимизатор',
                    },
                    {
                      path: `/management/${id}`,
                      title: `Расчет ${id}`,
                    },
                  ]}
                  renderItem={(item) => (
                    <Link to={item.path}>{item.title}</Link>
                  )}
                />
              }
            />
          </motion.div>
        }
      >
        {!calculationInfo && isPending && (
          <div className="flex justify-center my-20">
            <Loading size="xl" />
          </div>
        )}
        {calculationInfo && (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageMotion}
            className="flex py-6 gap-6 w-full"
          >
            <div className="w-full grid grid-cols-2 gap-5">
              <Card header="Основная информация" bodyClassName="!px-1 !py-3">
                {calculationInfo && (
                  <Description
                    columns={1}
                    layout="horizontal"
                    items={[
                      {
                        title: 'Длительность расчета',
                        value: calculationInfo.duration,
                      },
                      {
                        title: 'Инициатор',
                        value: calculationInfo.initiator,
                      },
                      {
                        title: 'Состояние',
                        value: calculationInfo.status,
                      },
                      {
                        title: 'Горизонт планирования',
                        value: `${
                          calculationInfo.opt_period.current.start_date
                        } - ${
                          calculationInfo.opt_period.next
                            ? calculationInfo.opt_period.next.end_date
                            : calculationInfo.opt_period.current.end_date
                        }`,
                      },
                      {
                        title: 'Дата/время дислокации',
                        value: calculationInfo.dislocation_datetime,
                      },
                    ]}
                  />
                )}
              </Card>
              <Card header="Документы" bodyClassName="!py-3">
                {calculationInfo &&
                  calculationInfo.files &&
                  calculationInfo.files.length > 0 && (
                    <ul className="flex flex-col gap-2">
                      {calculationInfo.files.map((item) => {
                        return (
                          <li key={item.description}>
                            <Button
                              type="link"
                              icon={<ArrowDownTrayIcon className="w-5 h-5" />}
                              onClick={() => {
                                history.push(item.path);
                              }}
                            >
                              {item.description}
                            </Button>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                {calculationInfo?.files && calculationInfo?.files.length === 0 && (
                  <div className="my-5">
                    <Empty />
                  </div>
                )}
              </Card>
              <Card
                header="Показатели расчета"
                className="col-span-2"
                bodyClassName="!py-3"
              >
                {calculationInfo && (
                  <div className="w-1/2">
                    <Stats
                      data={calculationInfo.stats}
                      stations={calculationInfo.stations}
                    />
                  </div>
                )}
                {!calculationInfo?.stats && (
                  <div className="my-5">
                    <Empty />
                  </div>
                )}
              </Card>
              <Card
                header={
                  <div className="flex justify-between items-center">
                    <span>Статистика по операциям</span>
                    {/* <Button size="sm" type="secondary">
                      Загрузить XLSX
                    </Button> */}
                  </div>
                }
                className="col-span-2"
              >
                {calculationInfo?.stats &&
                  calculationInfo?.stats.operations_stats && (
                    <OperationsTable
                      data={calculationInfo?.stats.operations_stats}
                    />
                  )}
                {!calculationInfo?.stats ||
                  (calculationInfo?.stats &&
                    !calculationInfo?.stats.operations_stats && (
                      <div className="my-5">
                        <Empty />
                      </div>
                    ))}
              </Card>
              <Card header="Верификация результатов" bodyClassName="!py-3">
                {calculationInfo && calculationInfo?.verification && (
                  <Verification data={calculationInfo.verification} />
                )}
                {!calculationInfo?.verification && (
                  <div className="my-5">
                    <Empty />
                  </div>
                )}
              </Card>
              <Card header="Ошибки в справочниках" bodyClassName="!px-1 !py-3">
                {calculationInfo && calculationInfo?.references_errors && (
                  <ReferenceErrors data={calculationInfo?.references_errors} />
                )}
                {!calculationInfo?.references_errors && (
                  <div className="my-5">
                    <Empty />
                  </div>
                )}
              </Card>
            </div>
          </motion.div>
        )}
      </OneColumnLayout>
    </>
  );
};

export default CalculationResultPage;
