import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { variants } from './utils';

interface IDropdownProps {
  children: React.ReactNode;
  visible?: boolean;
}

const Dropdown = ({ children, visible }: IDropdownProps) => {
  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    if (visible !== isVisible) {
      setIsVisible(visible);
    }
  }, [visible]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="w-64 bg-white shadow-md rounded-md absolute p-2.5 top-9 right-0 z-[2000] max-h-96"
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Dropdown;
