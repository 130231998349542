import { useStore } from 'effector-react';
import { useParams } from 'react-router-dom';

import { $stationListStore } from '../models/stations.model';

export default function useCurrentStation() {
  const { id: stationId } = useParams();
  const { objects: stations } = useStore($stationListStore);

  const station =
    stationId && Number.isInteger(Number(stationId))
      ? stations.find(({ id }) => Number(stationId) === id)
      : null;

  if (station) return station;

  return null;
}
