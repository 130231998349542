import React, { forwardRef } from 'react';
import { useStore } from 'effector-react';
import DatePicker from 'react-datepicker';
import cx from 'classnames';
import { Button } from '@factory5/ui-kit';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';

import { $selectedFilters, setFilterValues } from 'features/filters';

import { FilterRootKeys, IFilter } from '../../models/filters.types';
import './styles.css';

interface IPickerProps {
  data: IFilter;
  filterRootKey: FilterRootKeys;
}

const Picker = ({ data, filterRootKey }: IPickerProps) => {
  
  const selectedDates = useStore($selectedFilters)[filterRootKey][data.field];

  const CustomDateView = forwardRef(
    ({ value, onClick, className }: any, ref: React.Ref<HTMLInputElement>) => {
      return (
        <div ref={ref}>
          <Button
            size="sm"
            className={cx('whitespace-nowrap', className)}
            onClick={onClick}
            type={
              selectedDates && (selectedDates[0] || selectedDates[1])
                ? 'primary'
                : undefined
            }
          >
            {value ? value : data.title}
          </Button>
        </div>
      );
    },
  );

  return (
    <div className="flex items-center">
      <DatePicker
        selectsRange={true}
        onChange={(date) => {
          setFilterValues({
            rootKey: filterRootKey,
            field: data.field,
            values: !date[0] && !date[1] ? undefined : date,
          });
        }}
        locale={ru}
        startDate={selectedDates ? selectedDates[0] : null}
        endDate={selectedDates ? selectedDates[1] : null}
        dateFormat="dd.MM.yyyy"
        isClearable
        customInput={<CustomDateView />}
        calendarClassName="f5-calendar"
      />
    </div>
  );
};

export default Picker;
