import React from 'react';

import { useAlgoRunningToast } from '../../hooks';

const Notifications = () => {
  useAlgoRunningToast();
  return null;
};

export default Notifications;
