import React from 'react';
import { Table } from '@factory5/ui-kit';

import { OperationData, OperationsStats, TransOpData } from 'api/generated';

interface IOperationsTableProps {
  data: OperationsStats;
}

const getMnemonicByKey = (key: string) => {
  switch (key) {
    case 'goto_dontuse':
      return 'GO_TO_BRANCH_ST → DONTUSE';
    case 'goto_load':
      return 'GO_TO_LOADING_ST';
    case 'goto_repair':
      return 'GO_TO_REPAIR';
    case 'goto_unload':
      return 'GO_TO_UNLOADING_ST';
    case 'goto_wait':
      return 'GO_TO_BRANCH_ST → WAITING';
    case 'goto_wash':
      return 'GO_TO_WASH_ST';
    case 'load':
      return 'LOADING';
    case 'repair':
      return 'REPAIR';
    case 'unload':
      return 'UNLOADING';
    case 'wait':
      return 'WAITING';
    case 'wash':
      return 'WASH';
    default:
      return '';
  }
};

const getOperationByKey = (key: string) => {
  switch (key) {
    case 'goto_dontuse':
      return 'Транспортировка на филиал (вывод из расчёта)';
    case 'goto_load':
      return 'Транспортировка на погрузку';
    case 'goto_repair':
      return 'Транспортировка в ремонт';
    case 'goto_unload':
      return 'Транспортировка на разгрузку';
    case 'goto_wait':
      return 'Транспортировка в отстой';
    case 'goto_wash':
      return 'Транспортировка на ППС';
    case 'load':
      return 'Погрузка';
    case 'repair':
      return 'Ремонт';
    case 'unload':
      return 'Разгрузка';
    case 'wait':
      return 'Отстой';
    case 'wash':
      return 'Промывка';
    default:
      return '';
  }
};

const OperationsTable = ({ data }: IOperationsTableProps) => {
  const columns = [
    {
      title: 'Операция',
      dataIndex: 'operation',
      key: 'operation',
    },
    {
      title: 'Мнемоника операции',
      dataIndex: 'mnemonic',
      key: 'mnemonic',
    },
    {
      title: 'Кол-во',
      dataIndex: 'count',
      key: 'count',
      render: (value: number) => Intl.NumberFormat('ru-RU').format(value),
    },
    {
      title: 'Совокупная стоимость, млн руб.',
      dataIndex: 'totalCost',
      key: 'totalCost',
      render: (value: any) => (value / 1000000).toFixed(2),
    },
    {
      title: 'Пробег, км.',
      dataIndex: 'distance',
      key: 'distance',
      render: (value: any) =>
        value ? Intl.NumberFormat('ru-RU').format(value) : '-',
    },
    {
      title: 'Среднее время операции, дн',
      dataIndex: 'days',
      key: 'days',
    },
  ];

  const parsedData: any[] = [];

  (Object.keys(data) as (keyof typeof data)[]).forEach((key) => {
    const row: any = {};

    row.operation = getOperationByKey(key);
    row.mnemonic = getMnemonicByKey(key);
    row.distance = (data[key] as TransOpData).total_distance;
    row.count = (data[key] as OperationData).count;
    row.totalCost = (data[key] as OperationData).total_cost;
    row.days = (data[key] as OperationData).avg_days;

    parsedData.push(row);
  });

  return (
    <Table
      columns={columns}
      data={parsedData}
      emptyText="Нет данных"
      pagination={{ total: 11, pageSize: 11, hideOnSinglePage: true }}
    />
  );
};

export default OperationsTable;
