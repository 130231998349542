import React from 'react';
import { useStore } from 'effector-react';

import {
  TagsFilters,
  $filtersSet,
  fetchApproveTripsFilterOptionsFx,
} from 'features/filters';
import { $controlTripListStore } from 'features/stations/models/controlTrips.model';

const ApproveTripsFilterProvider = () => {
  const filters = useStore($filtersSet);
  const autocompleteIsLoading = useStore(
    fetchApproveTripsFilterOptionsFx.pending,
  );
  const { objects_total_count, objects_count } = useStore(
    $controlTripListStore,
  );

  return (
    <TagsFilters
      data={filters}
      filterRootKey="controlTrips"
      autocompleteOptionsHandler={fetchApproveTripsFilterOptionsFx}
      autocompleteIsLoading={autocompleteIsLoading}
      maxElCount={objects_total_count}
      currentElCount={objects_count}
    />
  );
};

export default ApproveTripsFilterProvider;
