import React from 'react';
import { CogIcon } from '@heroicons/react/24/outline';
import { useStore } from 'effector-react';

import { $isAlgoRunning } from 'features/manage';

const RunningPopup = () => {
  const isAlgoRunning = useStore($isAlgoRunning);

  if (!isAlgoRunning.is_running) {
    return null;
  }

  return (
    <div className="bg-white p-4 fixed bottom-4 left-6 z-50 flex items-center rounded-lg shadow">
      <CogIcon className="animate-spin w-5 h-5 mr-2" />
      <div className="text-sm">Данные пересчитываются...</div>
    </div>
  );
};

export default RunningPopup;
