import React from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';

import { Time } from './types';
import { SelectedCellType } from './PlanToEnsureTable';

export type PlanHeadDatesProps = {
  dates: string[];
  cursor: Time;
  setSelectedCell: React.Dispatch<React.SetStateAction<SelectedCellType>>;
  selectedCell: SelectedCellType;
};

export default function PlanHeadDates({
  dates,
  cursor,
  setSelectedCell,
  selectedCell,
}: PlanHeadDatesProps) {
  return (
    <tr className="sticky top-[34px] divide-x bg-white z-10 shadow">
      <th
        className="p-2 font-semibold text-sm text-left bg-white rounded-tl-lg"
        colSpan={8}
      ></th>
      {dates.map((date) => (
        <th
          key={date}
          className={cx(
            'relative font-semibold p-2 cursor-pointer transition after:h-[calc(100%+2px)] after:w-[calc(100%+2px)] after:p-2 after:absolute after:-left-px after:top-0 after:border-x after:border-t after:border-sky-600 after:transition',
            selectedCell.col === date
              ? 'after:opacity-100 bg-sky-500 text-white'
              : 'after:opacity-0 bg-white',
          )}
          onClick={() =>
            setSelectedCell((prev) => ({
              ...prev,
              col: prev.col === date ? null : date,
            }))
          }
        >
          {dayjs(date).format('DD')}
        </th>
      ))}

      <th
        className={cx(
          'relative font-semibold p-2 cursor-pointer transition after:h-[calc(100%+2px)] after:w-[calc(100%+2px)] after:p-2 after:absolute after:-left-px after:top-0 after:border-x after:border-t after:border-sky-600 after:transition',
          selectedCell.col === 'total'
            ? 'after:opacity-100 bg-sky-500 text-white'
            : 'after:opacity-0 bg-white ',
          { 'min-w-[60px] max-w-[60px]': cursor === Time.FUTURE },
        )}
        onClick={() =>
          setSelectedCell((prev) => ({
            ...prev,
            col: prev.col === 'total' ? null : 'total',
          }))
        }
      >
        Итого
      </th>
    </tr>
  );
}
