import { useStore } from 'effector-react';
import React, { useEffect } from 'react';

import useCurrentStation from 'features/stations/hooks/useCurrentStation';
import { $stationListStore } from 'features/stations/models/stations.model';
import {
  $stationChartsStore,
  getStationChartsFx,
} from 'features/stations/models/stationCharts.model';

import ChartList from '../ChartList';

export default function StationCharts() {
  const currentStation = useCurrentStation();
  const stationList = useStore($stationListStore);
  const stationCharts = useStore($stationChartsStore);

  useEffect(() => {
    if (currentStation) {
      getStationChartsFx(currentStation.id);
    }
  }, [currentStation, stationList]);

  if (stationCharts) return <ChartList isDetail data={stationCharts} />;

  return null;
}
