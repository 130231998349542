import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { pageMotion, pageHeaderMotion } from 'lib';
import { PageHeader } from '@factory5/ui-kit';
import { useFirstMountState } from 'hooks';

import {
  WagonDeploymentList,
  WagonDeploymentFiltersProvider,
} from 'features/stations';
import {
  fetchWagonDeploymentFiltersFx,
  resetAutocomplete,
} from 'features/filters';

import { OneColumnLayout } from 'layouts';

const DislocationPage = () => {
  const isFirstMount = useFirstMountState();

  useEffect(() => {
    if (isFirstMount) {
      fetchWagonDeploymentFiltersFx();
    }
    return () => {
      resetAutocomplete();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>ЦПД: Дислокация</title>
      </Helmet>
      <OneColumnLayout
        pageHeader={
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageHeaderMotion}
          >
            <PageHeader
              title={<span className="text-xl">Дислокация</span>}
              meta={
                <div className="mt-4">
                  <WagonDeploymentFiltersProvider />
                </div>
              }
            />
          </motion.div>
        }
      >
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageMotion}
        >
          <div className="py-6 flex flex-col gap-4">
            <WagonDeploymentList />
          </div>
        </motion.div>
      </OneColumnLayout>
    </>
  );
};

export default DislocationPage;
