import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { useNotification, Button } from '@factory5/ui-kit';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  $toastVisible,
  $isAlgoRunning,
  changeToastVisibility,
} from 'features/manage';

const useAlgoCompleteToast = () => {
  const isToastShow = useStore($toastVisible);
  const isRunning = useStore($isAlgoRunning);
  const toast = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (isToastShow && isRunning) {
      toast.open({
        persistent: true,
        title: 'Новый результат расчета',
        description: isRunning.last_finished_job_finished_at
          ? dayjs(isRunning.last_finished_job_finished_at).format(
              'DD.MM.YYYY HH:mm',
            )
          : undefined,
        type: 'info',
        footer: (
          <div>
            <Button
              size="xs"
              type="primary"
              onClick={() => {
                toast.closeAll();
                navigate(0);
              }}
            >
              Обновить
            </Button>
          </div>
        ),
      });
      changeToastVisibility(false);
    }
  }, [isToastShow, isRunning]);
};

export default useAlgoCompleteToast;
