import React, { useState } from 'react';
import { Button, Collapse, Modal } from '@factory5/ui-kit';

import { Stats as StatsType, StationReferenceData } from 'api/generated';

import TopStationsTable from '../TopStationsTable';
import WaybillsTable from '../WaybillsTable';
import SpeedToStationsTable from '../SpeedToStationsTable';
import PowerTable from '../PowerTable';

const Stats = ({
  data,
  stations,
}: {
  data?: StatsType;
  stations?: { [key: string]: StationReferenceData };
}) => {
  const [isTopModalOpen, setIsTopModalOpen] = useState(false);
  const [isWaybillModalOpen, setIsWaybillModalOpen] = useState(false);
  const [isSpeedModalOpen, setIsSpeedModalOpen] = useState(false);
  const [isPowerModalOpen, setIsPowerModalOpen] = useState(false);
  if (!data) {
    return null;
  }

  const {
    performance,
    proposed_cars,
    top_stations_data,
    cargoes_supplies,
    proposed_trips,
    avg_speed_to_stations,
  } = data;

  const getPerformancePercent = () => {
    const planned =
      performance.current_period_stats.data.planned_cars +
      (performance.next_period_stats?.data.planned_cars || 0);

    const proposed =
      performance.current_period_stats.data.proposed_cars +
      (performance.next_period_stats?.data.proposed_cars || 0);

    if (planned === 0) {
      return 0;
    }

    return ((proposed * 100) / planned).toFixed(2);
  };

  return (
    <>
      <Collapse
        title="Выполнение входящего плана"
        trailing={getPerformancePercent() + '%'}
        arrowPosition="left"
        design="simple"
        noSpace
        contentClassName="pl-8"
      >
        <Collapse
          title={`${performance.current_period_stats.period.start_date} -
                        ${performance.current_period_stats.period.end_date}`}
          trailing={`${
            performance.current_period_stats.data.planned_cars === 0
              ? '0'
              : (
                  (performance.current_period_stats.data.proposed_cars * 100) /
                  performance.current_period_stats.data.planned_cars
                ).toFixed(2)
          }%`}
          arrowPosition="left"
          design="simple"
          noSpace
          contentClassName="pl-8"
        >
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <div className="w-3/5">План вагоноотправок</div>
              <div className="flex justify-end w-1/5">
                {new Intl.NumberFormat('ru-RU').format(
                  performance.current_period_stats.data.planned_cars,
                )}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="w-3/5">Расчетные вагоноотправки</div>
              <div className="flex justify-end w-1/5">
                {new Intl.NumberFormat('ru-RU').format(
                  performance.current_period_stats.data.proposed_cars,
                )}
              </div>
            </div>
          </div>
        </Collapse>
        {performance.next_period_stats && (
          <Collapse
            title={`${performance.next_period_stats.period.start_date} -
                        ${performance.next_period_stats.period.end_date}`}
            trailing={`${
              performance.next_period_stats.data.planned_cars === 0
                ? '0'
                : (
                    (performance.next_period_stats.data.proposed_cars * 100) /
                    performance.next_period_stats.data.planned_cars
                  ).toFixed(2)
            }%`}
            arrowPosition="left"
            design="simple"
            noSpace
            contentClassName="pl-8"
          >
            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <div className="w-3/5">План вагоноотправок</div>
                <div className="flex justify-end w-1/5">
                  {new Intl.NumberFormat('ru-RU').format(
                    performance.next_period_stats.data.planned_cars,
                  )}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="w-3/5">Расчетные вагоноотправки</div>
                <div className="flex justify-end w-1/5">
                  {new Intl.NumberFormat('ru-RU').format(
                    performance.next_period_stats.data.proposed_cars,
                  )}
                </div>
              </div>
            </div>
          </Collapse>
        )}
      </Collapse>
      <Collapse
        title="Кол-во уникальных вагонов в расчете"
        trailing={new Intl.NumberFormat('ru-RU').format(proposed_cars.used)}
        arrowPosition="left"
        design="simple"
        noSpace
        contentClassName="pl-8"
      >
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <div className="w-3/5">Задействованные</div>
            <div className="flex justify-end w-1/5">
              {new Intl.NumberFormat('ru-RU').format(proposed_cars.used)}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-3/5">Незадействованные</div>
            <div className="flex justify-end w-1/5">
              {new Intl.NumberFormat('ru-RU').format(proposed_cars.unused)}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-3/5">Всего вагонов</div>
            <div className="flex justify-end w-1/5">
              {new Intl.NumberFormat('ru-RU').format(
                proposed_cars.used + proposed_cars.unused,
              )}
            </div>
          </div>
        </div>
      </Collapse>
      <div className="flex justify-between font-medium pl-8 py-2">
        <div className="w-2/3">
          Детализация выполнения плана по ТОП-10 станциям
        </div>
        <div>
          <Button
            type="link"
            onClick={() => {
              setIsTopModalOpen(true);
            }}
          >
            Подробнее
          </Button>
        </div>
      </div>
      <div className="flex justify-between font-medium pl-8 py-2">
        <div className="w-2/3">Учтенный ФАКТ вывоза по накладным</div>
        <div>
          <Button
            type="link"
            onClick={() => {
              setIsWaybillModalOpen(true);
            }}
          >
            Подробнее
          </Button>
        </div>
      </div>
      <Collapse
        title="Количество прогнозных рейсов на погрузку"
        trailing={new Intl.NumberFormat('ru-RU').format(
          proposed_trips.total_count,
        )}
        arrowPosition="left"
        design="simple"
        noSpace
        contentClassName="pl-8"
      >
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <div className="w-3/5">Рейсы первого плеча</div>
            <div className="flex justify-end w-1/5">
              {new Intl.NumberFormat('ru-RU').format(
                proposed_trips.first_leg_count,
              )}
            </div>
          </div>
        </div>
      </Collapse>
      <div className="flex justify-between font-medium pl-8 py-2">
        <div className="w-2/3">Скорость на станциях</div>
        <div>
          <Button
            type="link"
            onClick={() => {
              setIsSpeedModalOpen(true);
            }}
          >
            Подробнее
          </Button>
        </div>
      </div>
      <div className="flex justify-between font-medium pl-8 py-2">
        <div className="w-2/3">Мощности и емкость станций</div>
        <div>
          <Button
            type="link"
            onClick={() => {
              setIsPowerModalOpen(true);
            }}
          >
            Подробнее
          </Button>
        </div>
      </div>
      <Modal
        visible={isTopModalOpen}
        onDismiss={() => {
          setIsTopModalOpen(false);
        }}
        width={1280}
        title="Детализация выполнения плана по ТОП-10 станциям"
      >
        <TopStationsTable
          data={top_stations_data}
          current={performance.current_period_stats.period}
          next={performance.next_period_stats?.period || undefined}
        />
      </Modal>
      <Modal
        visible={isWaybillModalOpen}
        onDismiss={() => {
          setIsWaybillModalOpen(false);
        }}
        width={1080}
        title="Учтенный ФАКТ вывоза по накладным"
      >
        <WaybillsTable
          data={cargoes_supplies}
          current={performance.current_period_stats.period}
        />
      </Modal>
      <Modal
        visible={isSpeedModalOpen}
        onDismiss={() => {
          setIsSpeedModalOpen(false);
        }}
        width={1080}
        title="Скорость на станциях"
      >
        <SpeedToStationsTable data={avg_speed_to_stations} />
      </Modal>
      <Modal
        visible={isPowerModalOpen}
        onDismiss={() => {
          setIsPowerModalOpen(false);
        }}
        width={1080}
        title="Мощности и емкость станций"
      >
        <PowerTable data={stations} />
      </Modal>
    </>
  );
};

export default Stats;
