import React, { useEffect } from 'react';
import { useStore } from 'effector-react';

import {
  TagsFilters,
  $filtersSet,
  $selectedFilters,
  fetchWagonDeploymentFilterOptionsFx,
} from 'features/filters';

import {
  getWagonDeploymentListFx,
  $wagonDeploymentListRequestParams,
  $wagonDeploymentListStore,
} from '../../models/wagonDeployment.model';

const WagonDeploymentFiltersProvider = () => {
  const filters = useStore($filtersSet);
  const selectedFilters = useStore($selectedFilters)['station'];
  const autocompleteIsLoading = useStore(
    fetchWagonDeploymentFilterOptionsFx.pending,
  );
  const requestParams = useStore($wagonDeploymentListRequestParams);
  const { objects_count, objects_total_count } = useStore(
    $wagonDeploymentListStore,
  );

  useEffect(() => {
    getWagonDeploymentListFx({ ...requestParams, ...selectedFilters });
  }, [selectedFilters, requestParams]);

  return (
    <TagsFilters
      data={filters}
      filterRootKey="station"
      noSearch={['arrival_operation', 'to_operation']}
      autocompleteOptionsHandler={fetchWagonDeploymentFilterOptionsFx}
      autocompleteIsLoading={autocompleteIsLoading}
      maxElCount={objects_total_count}
      currentElCount={objects_count}
    />
  );
};

export default WagonDeploymentFiltersProvider;
