import React from 'react';
import { NotFound } from '@factory5/ui-kit';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>ЦПД: Страница не найдена</title>
      </Helmet>
      <div className="h-screen flex justify-center items-center">
        <NotFound />
      </div>
    </>
  );
};

export default NotFoundPage;
