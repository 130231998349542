import React, { useEffect, useState } from 'react';
import { Card, Input, Button, Select, Alert } from '@factory5/ui-kit';
import { useStore } from 'effector-react';

import {
  $domains,
  fetchDomainsFx,
  resetLoginErrors,
  loginFx,
  $loginErrors,
} from '../../models/auth.model';

const AuthForm = () => {
  const domains = useStore($domains);
  const isPending = useStore(loginFx.pending);
  const errors = useStore($loginErrors);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [domain, setDomain] = useState('local');

  useEffect(() => {
    fetchDomainsFx();
  }, []);

  return (
    <div>
      <Card className="w-[460px]" bodyClassName="flex flex-col !p-9">
        <Select
          label="Домен"
          className="mb-4"
          value={domain}
          editable={false}
          onSelect={(value) => setDomain(value as unknown as string)}
          options={[
            ...(domains
              ? domains.domains.map((el) => ({ label: el, value: el }))
              : []),
            { label: 'Локальный пользователь', value: 'local' },
          ]}
        />
        <Input
          label="Логин"
          value={username}
          className="mb-4"
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          label="Пароль"
          value={password}
          type="password"
          className="mb-4"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Alert
          className="mb-4"
          message="Разрешение на вход можно получить у администратора домена или ЦПД."
        />
        {errors && (
          <Alert
            className="mb-4"
            type="error"
            message={
              Array.isArray(errors) ? errors.map((el: any) => el.msg) : errors
            }
          />
        )}
        <Button
          type="primary"
          className="w-full justify-center"
          pending={isPending}
          onClick={() => {
            resetLoginErrors();
            loginFx({ username, password, domain });
          }}
        >
          Войти
        </Button>
      </Card>
    </div>
  );
};

export default AuthForm;
