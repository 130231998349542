import React from 'react';
import cx from 'classnames';

import { OperationEngLabelsEnum } from 'api';

export type ObjectiveStatusProps = {
  objective: OperationEngLabelsEnum;
} & React.ComponentProps<'div'>;

const ObjectiveLabel: Record<OperationEngLabelsEnum, string> = {
  [OperationEngLabelsEnum.Loading]: 'Погрузка',
  [OperationEngLabelsEnum.Parking]: 'Отстой',
  [OperationEngLabelsEnum.Repair]: 'Ремонт',
  [OperationEngLabelsEnum.Unloading]: 'Выгрузка',
  [OperationEngLabelsEnum.Washing]: 'Промывка',
  [OperationEngLabelsEnum.Dontuse]: 'Не используется',
};

export default function ObjectiveStatus({
  objective,
  className,
  ...props
}: ObjectiveStatusProps) {
  return (
    <div
      className={cx(
        className,
        'text-xxs uppercase font-semibold border border-dashed border-black px-1 cursor-pointer',
        {
          'bg-amber-400': objective === OperationEngLabelsEnum.Repair,
          'bg-emerald-200': objective === OperationEngLabelsEnum.Loading,
          'bg-sky-400 text-white': objective === OperationEngLabelsEnum.Washing,
          'bg-red-600 text-white': objective === OperationEngLabelsEnum.Dontuse,
        },
      )}
      {...props}
    >
      {ObjectiveLabel[objective]}
    </div>
  );
}
