import { createStore, createEffect, createEvent, forward } from 'effector';
import { history } from 'lib';

import axiosInstance from 'api/axiosInstance';

import api, { LoginSchema, UserRead, InfoSchema } from 'api';

// MODELS -------------------

export const $me = createStore<UserRead | null>(null);
export const $domains = createStore<InfoSchema | null>(null);
export const $loginErrors = createStore<any>(null);

// EVENTS -------------------

export const resetMe = createEvent();
export const logout = createEvent();
export const resetLoginErrors = createEvent();

// EFFECTS ------------------

export const loginFx = createEffect({
  handler: async (loginSchema: LoginSchema) => {
    const { data } = await api.users.authJwtLogin({ loginSchema });

    return data;
  },
});

export const fetchMeFx = createEffect({
  handler: async () => {
    const { data } = await api.users.usersCurrentUser();

    return data;
  },
});

export const fetchDomainsFx = createEffect({
  handler: async () => {
    const { data } = await api.users.authJwtDomains();
    return data;
  },
});

export const redirectFx = createEffect({
  handler: (route: string) => history.push(route),
});

// STORE METHODS -------------------

loginFx.doneData.watch(({ access_token }) => {
  localStorage.setItem('access', access_token);
  axiosInstance.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${access_token}`;

  fetchMeFx();
});

logout.watch(() => localStorage.clear());

$me.on(fetchMeFx.doneData, (_, userData) => userData).reset(resetMe);

$domains.on(fetchDomainsFx.doneData, (_, response) => response);

forward({
  from: loginFx.done,
  to: [redirectFx.prepend(() => '/')],
});

forward({ from: resetMe, to: [logout, redirectFx.prepend(() => '/login')] });

$loginErrors
  .on(loginFx.failData, (_, error: any) => {
    if (error.response.status === 401) {
      return 'Неверное имя пользователя или пароль';
    } else {
      return `Ошибка HTTP в процессе атуентификации ${
        error.response.data.detail
          ? ': ' +
            error.response.data.detail.map((el: any) => el.msg).join(', ')
          : ''
      }`;
    }
  })
  .reset(resetLoginErrors);
