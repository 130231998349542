import React from 'react';
import { Avatar } from '@factory5/ui-kit';
import { useStore } from 'effector-react';

import { $me } from '../../models/auth.model';

const User = () => {
  const me = useStore($me);

  return (
    <div className="flex">
      <Avatar image size="sm" />
      {me && (
        <div className="ml-2 flex flex-col justify-center">
          <div className="text-xs font-semibold">{me?.username}</div>
          {me.email && (
            <div className="text-xs font-medium text-gray-600">{me?.email}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default User;
